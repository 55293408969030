import { captureException as sentryCaptureException } from '@sentry/react';

const captureException = (exception: Error) => {
    if (process.env.NODE_ENV !== 'test') {
        console.error(exception);
    }

    sentryCaptureException(exception);
};

export { captureException };
