import { Au10tixApiInterface } from 'apiClients/msc-api-gateway/proxy/au10tix/client/au10tix-api';
import { Au10tixDataMapperInterface } from './Au10tix.service';
import { mapAccessTokenResponse } from './Au10tix.mapper';
import { Au10tixAccessToken, CreateAccessTokenRequest } from './Au10tix.types';

export class Au10tixDataMapper implements Au10tixDataMapperInterface {
    constructor(private apiClient: Au10tixApiInterface) {}

    public createAccessToken(request: CreateAccessTokenRequest): Promise<Au10tixAccessToken> {
        const { actorId, actorType, isForced, studioId } = request;

        return new Promise<Au10tixAccessToken>((resolve, reject) => {
            this.apiClient
                .createAu10tixWebSdkAccessToken(actorId, actorType, { isForced, studioId })
                .then((axiosResponse) => {
                    const { data } = axiosResponse.data;
                    if (!data) {
                        return reject(new Error('Missing response data'));
                    }
                    resolve(mapAccessTokenResponse(data));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
