/**
 * Builds HTML template from the error messages.
 *
 * @param {Array} errors   Array of the error objects.
 * @returns {string}
 */
export default function buildErrorMsg(errors) {
	var ret = '<span class="protip-close"><i class="icon-x"></i></span><ul>';

	if (!Array.isArray(errors)) {
		errors = [errors];
	}

	var encodeStr = rawStr => {
		return rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
			return '&#' + i.charCodeAt(0) + ';';
		});
	};

	errors.forEach(function(val) {
		if (typeof val === 'string') {
			ret += '<li>' + val + '</li>';
		}
		else {
			var k = Object.keys(val)[0];
			ret += '<li><span class="uploader5__tooltip-filename">' + encodeStr(k) + '</span> ' + val[k] + '</li>';
		}
	});
	ret += '</ul>';

	return ret;
}