import HTML5UploaderController from './HTML5UploaderController';
import MWHDocumentStatusCheckerController from '../MyContent/MWHDocumentStatusCheckerController';
import DOMPurify from 'dompurify';
import MWHDocumentStatusCheckerModel from '../MyContent/MWHDocumentStatusCheckerModel';
import { push } from '../../component/GoogleAnalyticsService';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import routes from 'configs/routes';
import { isOranum } from 'configs/sites';

export const EVENTS = {
	FILE_STATUS_UPDATED: 'promotion-tools-file-status-updated',
	FILE_STATUS_CONVERTING: 'promotion-tools-upload-conversion-started',
	UPLOAD_STARTED: 'promotion-tools-upload-upload-started',
	UPLOAD_ENDED: 'promotion-tools-upload-upload-ended',
	UPLOAD_FAILED: 'promotion-tools-upload-upload-failed',
	VALIDATION_FAILED: 'promotion-tools-upload-validation-failed',
	SYSTEM_ERROR: 'promotion-tools-upload-system-error',
	VIDEO_ORIENTATION_ERROR: 'promotion-tools-video-orientation-error',
	DELETE_ITEM_FROM_DOM: 'promotion-tools-delete-item-from-dom'
};

const promoTeaserRoutes = [routes.promoTeaserMobile, routes.promoTeaserDesktop];
const portraitOrientationRoutes = [routes.videoCallTeaser, routes.portraitTeaser, routes.promoTeaserMobile];

export default class PromotionToolsHTML5UploaderController extends HTML5UploaderController {
	get properties() {
		return {
			...super.properties,
			tokensToContentIds: {},
			isTokenRequired : true
		};
	}

	/**
	 * Init method.
	 *
	 * @param {Object} el
	 * @param {Object} config
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);
		this.setTeaserType();
		this.setTeaserOrientation();
		Broadcaster.on('promo-list-ready', ::this.setUploaderElements);
		this.setMWHStatusChecker();
		this.csrfToken = config.csrfToken;
	}

	setTeaserType () {
		if (isOranum) {
			this.teaserType = 'video-ads';
		}
		else if (location.pathname === routes.videoCallTeaser) {
			this.teaserType = 'video-call';
		}
		else if (promoTeaserRoutes.includes(location.pathname)) {
			this.teaserType = 'promo-teaser';
		}
		else {
			this.teaserType = 'erotic-teaser';
		}
	}

	setTeaserOrientation () {
		if (portraitOrientationRoutes.includes(location.pathname)) {
			this.teaserOrientation = 'portrait';
		}
		else {
			this.teaserOrientation = 'landscape';
		}
	}

	setUploaderElements() {
		// Override progressbar HTML5UploaderController UI selectors
		this.ui.progressBar = 'promolist .commonProgressBar';
		this.ui.progressBarBar = 'promolist .progressContainer';
		this.ui.text = 'promolist .progressTitle';
		this.ui.percentText = 'promolist .progressTitle em';
		this.ui.buttonLink = '.uploader5__container';
		// Re-fetch with overridden selectors
		this._fetchElements(true);
	}

	/**
	 * Sets a controller for mwh document checking
	 *
	 * @method _setMWHDocumentStatusCheckerController
	 * @private
	 *
	 * @return {Object} instance of MWH Document Status Checker Controller
	 */
	setMWHStatusChecker() {
		this.MWHStatusChecker = new MWHDocumentStatusCheckerController({
			items : {
				MWHDocumentStatusCheckerModel : {
					component : new MWHDocumentStatusCheckerModel(document.body, {
						mediaDocumentStatusUrlRoute : 'PromotionToolsContentStatus/Get',
						contentType: this.teaserType,
						documentIdKey: 'contentIds'
					}),
					listeners : {
						'get-document-status-success' : 'onGetDocumentStatusSuccess'
					}
				}
			}
		});
		this.MWHStatusChecker.on(
			MWHDocumentStatusCheckerController.EVENT_FILE_CONVERT_FAILED,
			this.onFileStatusFailed,
			this
		);
		this.MWHStatusChecker.on(
			MWHDocumentStatusCheckerController.EVENT_CONTENT_STATUS_ENABLED,
			this.onContentStatusEnabled,
			this
		);
		return this.MWHStatusChecker;
	}

	getAnalyticsGenericEvent() {
		const viewportName = this.teaserOrientation === 'portrait' ? 'mobile' : 'desktop';

		return {
			'event': 'GA - Event - Generic Event',
			'eventCategory':
				this.teaserType === 'video-call' ? 'video call teaser' : `promotion tools - ${viewportName} teasers`,
			'eventInteraction': true,
			'eventAction': 'status',
			'eventValue': 0,
			'eventLabel': ''
		};
	}

	/**
	 * Attach csrf token to the get token request.
	 */
	getTokens(files) {
		this.getVideoOrientation(files[0]).then((videoOrientation) => {
			if (videoOrientation !== this.teaserOrientation) {
				Broadcaster.fireEvent(EVENTS.VIDEO_ORIENTATION_ERROR, this.teaserOrientation);
				push({
					...this.getAnalyticsGenericEvent(),
					'eventLabel': 'failed - aspect-ratio'
				});

				this.hideProgressBar();
				return;
			}
			const fileName = files[0].name;
			this.options.formData = {
				csrfToken   : this.csrfToken,
				fileName    : DOMPurify.sanitize(fileName),
				orientation : videoOrientation,
				contentType : this.teaserType
			};
			super.getTokens();
		})
	}

	getVideoOrientation(file) {
		return new Promise(resolve => {
			const videoEl = document.createElement('video');
			videoEl.src = URL.createObjectURL(file);
			// if we can't detect the file.type (e.g. flv files), we return the correct orientation
			// and the default uploader validator will catch and handle the error.
			if (!file.type) resolve(this.teaserOrientation);

			videoEl.ondurationchange = () => {
				if (videoEl.videoWidth !== 0) {
					return resolve(videoEl.videoWidth > videoEl.videoHeight ? 'landscape' : 'portrait')
				}
				else {
					// if we can't detect the videoWidth, we return the correct orientation
					// and the default uploader validator will catch and handle the error.
					return resolve(this.teaserOrientation);
				}
			}
		})
	}

	_onChange() {
		Broadcaster.fireEvent(EVENTS.UPLOAD_STARTED);
		super._onChange();
	}

	/**
	 * Status failed after MWH status checking
	 */
	onFileStatusFailed(ev) {
		const document = ev.document[ev.documentId];

		push({
			...this.getAnalyticsGenericEvent(),
			'eventLabel': `failed - ${document.rejectReason}`
		});

		Broadcaster.fireEvent(EVENTS.FILE_STATUS_UPDATED, document);
	}

	/**
	 * Converstion success after MWH Status Checking
	 */
	onContentStatusEnabled(ev) {
		const document = ev.document[ev.documentId];

		push({
			...this.getAnalyticsGenericEvent(),
			'eventLabel': 'uploaded'
		});

		Broadcaster.fireEvent(EVENTS.FILE_STATUS_UPDATED, document);
	}

	_onGettokensfail() {
		super._onGettokensfail();
		Broadcaster.fireEvent(EVENTS.SYSTEM_ERROR);
	}

	_processTokens(ev) {
		const { data: { token = '' } = '' } = ev;
		this._tokens = [token];
		return !!token;
	}

	_onFail(ev) {
		Broadcaster.fireEvent(EVENTS.UPLOAD_FAILED, ev);
		const contentId = this.tokensToContentIds[ev.formData.token];
		this.MWHStatusChecker.removeIdFromCheckingList(contentId.toString());
	}

	_onGettokensok(ev) {
		super._onGettokensok(ev);
		const { contentId, token } = ev.data;

		if (!token) {
			Broadcaster.fireEvent(EVENTS.SYSTEM_ERROR);
			return;
		}

		let documentIds = {};
		documentIds[contentId] = 'video';

		// Store documentId->uploadtoken pairs, to use in upload success event handler.
		this.tokensToContentIds[token] = contentId;

		this.MWHStatusChecker.addStatusesToCheck(documentIds);
	}

	_onDone(event) {
		const { token } = event.formData;
		const contentId = this.tokensToContentIds[token];

		Broadcaster.fireEvent(EVENTS.FILE_STATUS_CONVERTING, event.files[0].name, contentId);
	}

	_onAlways() {
		Broadcaster.fireEvent(EVENTS.UPLOAD_ENDED);
		super._onAlways();
	}

	/**
	 * Callback before preparation was failed.
	 */
	_onBeforePreparefail(ev) {
		Broadcaster.fireEvent(EVENTS.VALIDATION_FAILED, ev.errors);
	}

	_onAfterPreparefail() {
		this._toggleProgressbar();
	}
}
