import Chaos from '../../lib/chaos/Chaos';
import ChaosObject from '../../lib/chaos/Object';
import { Broadcaster } from '../../lib/chaos/Broadcaster';

/**
 * Displays, and handles global rpogress indicator objects, for ajax calls
 * @TODO Component to add&remove a class?! :)
 * @package    common
 * @subpackage GlobalProgressIndicator
 */

/**
 * Constructing class
 */
export default class GlobalProgressIndicator extends ChaosObject {
	static GLOBALEVENT_SHOW_INDICATOR = 'show-indicator';
	static GLOBALEVENT_HIDE_INDICATOR = 'hide-indicator';
	static instance = undefined;

	get properties() {
		return { ...super.properties,
			showCls : 'show'

		};
	}
	init(el, config) {
		//Register unique global events
		Chaos.addEvents(
			GlobalProgressIndicator.GLOBALEVENT_SHOW_INDICATOR,
			GlobalProgressIndicator.GLOBALEVENT_HIDE_INDICATOR
		);
		//Global events
		Broadcaster.on(GlobalProgressIndicator.GLOBALEVENT_SHOW_INDICATOR, this.showEventHandler, this);
		Broadcaster.on(GlobalProgressIndicator.GLOBALEVENT_HIDE_INDICATOR, this.hideEventHandler, this);

		//Save instance reference
		GlobalProgressIndicator.instance = this;
		// call parent's init
		super.init(el, config);
	}

	/**
	 * Global ajax indicators show event handler
	 */
	showEventHandler() {
		this.show();
	}

	/**
	 * Global ajax indicators hide event handler
	 */
	hideEventHandler() {
		this.hide();
	}

	/**
	 * Displays global ajax indicator
	 */
	show() {
		this.element.addClass(this.showCls);
	}

	/**
	 * Hides global ajax indicator
	 */
	hide() {
		this.element.removeClass(this.showCls);
	}

	/**
	 * Binds all basic event listeners.
	 *
	 * @return void
	 */
	bind() {
		super.bind();
	}

	/**
	 * Unbinds all basic event listeners.
	 *
	 * @return void
	 */
	unbind() {
		super.unbind();
	}
}
