import HTML5UploaderController from './HTML5UploaderController';

export default class SelfInvoiceHTML5UploaderController extends HTML5UploaderController {
	get properties() {
		return { ...super.properties,
			/** @var {String} statusCellSel      'Status' table cell selector */
			statusCellSel : '.statusContent',
			/** @var {String} invoiceCellSel     'Invoice' table cell selector */
			invoiceCellSel : '.tableLinkContentCell',
			/** @var {Array} statusClassArray      Status table cell selector */
			statusClassArray : [
				'individual_acceptedStatus',
				'individual_pendingStatus',
				'company_pendingStatus',
				'company_approvedStatus',
				'company_rejectedStatus'
			]
		};
	}

	/**
	 * Init method.
	 *
	 * @param {Object} el
	 * @param {Object} config
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);
	}

	/**
	 * Callback after upload is done.
	 * @private
	 */
	_onAfterDone(ev) {
		var result = JSON.parse(ev.result);

		var invoiceStatus = result.data.invoiceStatus,
			payoutStatus = result.data.payoutStatus,
			rowEl = this._el.parents('tr'),
			statusCellEl = rowEl.children(this.statusCellSel),
			invoiceCellEl = rowEl.children(this.invoiceCellSel);

		// Remove status classes from cells
		this.statusClassArray.forEach(function(cls) {
			invoiceCellEl.removeClass(cls);
			statusCellEl.removeClass(cls);
		});

		this._el.html(invoiceStatus);
		statusCellEl.html(payoutStatus);
	}

	_onAfterChange() {
		this._toggleText();
	}

	/**
	 * Events pls.
	 */
	bind() {
		super.bind();
	}
}