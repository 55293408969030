import Ext from '../../lib/vendor/ExtCore';
import ChaosObject from '../../lib/chaos/Object';

export default class ChannelOverlayView extends ChaosObject {
	static EVENT_MEDIA_ITEM_CLICK = 'media-item-click';

	get properties() {
		return { ...super.properties,
			/** @var {String} name    Class name of a media item */
			mediaItemCls : 'mediaItemClickBox',
			/** @var {String} name    Class name of a video media play button */
			videoPlayBtnCls : 'js-play',
			/** @var {String} name    Class name of a delete button for the media items */
			mediaDeleteButtonCls : 'delete_media',
			/** @var {String} name    Class name of a rotate button for the media items */
			mediaRotateButtonCls : 'rotate_media',
			/** @var {String}         Selector of the parent media box */
			mediaBoxSel : '.mediaBox',
			/** @var {String}        Class of disabled status on media box */
			disabledCls : 'disabled',
			mediaZoomBtnCls : 'js-zoom'

		};
	}
	init(el, config) {
		super.init(el, config);
		this.addEvents(ChannelOverlayView.EVENT_MEDIA_ITEM_CLICK);
	}

	/**
	 * Fires an event when an item has been clicked.
	 *
	 * @param {Object} ev   Browser event object
	 *
	 * @return void;
	 */
	onMediaItemClick(ev) {
		ev.preventDefault();
		var target = Ext.get(ev.target),
			mediaBox = target.parent(this.mediaBoxSel);

		if (mediaBox.hasClass(this.disabledCls)) {
			return;
		}

		this.fireEvent(ChannelOverlayView.EVENT_MEDIA_ITEM_CLICK, {
			scope  : this,
			itemEl : target
		});
	}

	/**
	 * Binds events
	 */
	bind() {
		super.bind();
		this.element.on('click', this.onMediaItemClick, this, {
			delegate : this.mediaZoomBtnCls.dot()
		});
		this.element.on('click', this.onMediaItemClick, this, {
			delegate : this.videoPlayBtnCls.dot()
		});
	}

	/**
	 * Unbind events
	 */
	unbind() {
		this.autoUnbind();
	}
}
