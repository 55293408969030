/* eslint-disable complexity */
/* eslint-disable max-statements */

import riot from 'riot';

import Util from '../../lib/chaos/Util';
import PH from '../../lib/constant/Phrame';

riot.tag('form-mixin-datatable',
	`<input type="hidden" ref="input" />
	<virtual if="{ parent.input }">
		<select name="fake-{ parent.input.name }-select" onchange="{ onSelectChange }">
			<option selected hidden></option>
			<option each="{ item, i in data }" value="{ item.value }" data-index="{ i }" selected="{ selected: item.selected }" disabled="{ disabled: item.disabled }" data-testid="{ item.title }">
				{ formattedOptionTitle(item.title) }
			</option>
		</select>
	</virtual>
	<div  ref="json" class="${ PH.cls.hide }"><yield></yield></div>
	<scrollable max="170" onmousemove="{ prevent }" onmouseleave="{ unprevent }">
		<ul>
			<li each="{ item, i in parent.data }" scope="{ this }" class="{ hover: item.selected } { disabled: item.disabled }">
				<span onmousedown="{ parent.parent.select }" data-value="{ item.value }" data-index="{ i }">{ item.title }</span>
			</li>
		</ul>
	</scrollable>`,

	function() {
		// Apply mixin
		this.mixin('form');

		// Init variables
		this.data = [];
		let IsPrevented = false,
			JumpCache = '',
			JumpCacheTimer;

		// Construct
		this.on('mount', function() {
			let value = this.parent.getValue();
			this.data = this.refs.json.innerHTML.replace(new RegExp('\\(\\(\\(', 'g'), '{');
			this.data = this.data.replace(new RegExp('\\)\\)\\)', 'g'), '}');
			if (this.data) {
				this.data = JSON.parse(this.data);
			}
			else if (this.parent.opts.datatableItems) {
				//Needs to be able to pass items directly as an object without text markup
				this.data = this.parent.opts.datatableItems;
			}
			else {
				console.warn('Datatable has no parseable data');
				this.data = []; // default to empty dataset
			}
			if (this.refs.json.parentNode) {
				this.refs.json.parentNode.removeChild(this.refs.json);
			}
			this.parent.root.classList.add('ready');
			this.parent.opts.readonly = true;
			this.root.removeAttribute('tabindex'); // To set default behavior for tabbing through input fields
			this.refs.input.name = this.parent.input.name;
			this.parent.root.removeAttribute('disabled'); // IE of course...
			this.parent.input.name = 'fake-' + this.parent.input.name;
			this.parent.getValue = ::this.getValue;
			this.unselectAll();

			if (value) {
				let index = this.getIndexByValue(value);
				if (index + 1) {
					this.setValue(this.getIndexByValue(value));
				}
				else {
					this.parent.input.value = '';
					this.parent.setValue('');
				}
			}
			else if (
				typeof this.parent.opts.disabled !== 'undefined'
				|| typeof this.parent.opts.hidden !== 'undefined'
			) {
				this.setValue(0);
			}
			this.update();
			this.refs.input.blur();
			this.parent.on('focus', ::this.open);
			this.parent.on('blur', ::this.close);
			this.parent.on('keydown', :: this.keydown);

			if (this.parent.opts.disabled === '1') {
				this.root.querySelector('select').setAttribute('disabled', 'disabled');
			}
		});

		/**
		 * Formats a string to a needed Select Option text format.
		 * @param {string} str Option Title
		 * @returns {String} formatted option title
		 */
		this.formattedOptionTitle = function(str) {
			str = Util.decodeEntities(str);
			return str.charAt(0).toUpperCase() + str.slice(1);
		};

		/**
		 * Returns an item index from the this.data object by it's value.
		 * @param {String} value The value of the item.
		 * @returns {Number} Index By Value
		 */
		this.getIndexByValue = function(value) {
			return this.data.findIndex(function(item) {
			// Only 2 = (==) !important
			return item.value == value; // eslint-disable-line
			});
		};

		/**
		 * Returns an item index from the this.data object by it's value.
		 * @param {String} value The value of the item.
		 * @returns {Number} Index of selected
		 */
		this.getIndexBySelected = function() {
			return this.data.findIndex(function(item) {
				return item.selected === true;
			});
		};

		/**
		 * Returns an item from the this.data object by it's value.
		 * @param {String} value The value of the item.
		 * @returns {array} this.data item
		 */
		this.getItemByValue = function(value) {
			return this.data.filter(function(item) {
				return item.value === value;
			}).pop();
		};

		/**
		 * Returns an item from the this.data object by it's index.
		 * @param {Number} index Index of the item.
		 * @returns {*} this.data item
		 */
		this.getItemByIndex = index=> this.data[index];

		/**
		 * Return an item's title from the data object by it's value.
		 * @param {String} value The value of the item.
		 * @returns {String} Title of item
		 */
		this.getTitleByValue = function(value) {
			return this.getItemByValue(value || this.parent.getValue()).title;
		};

		/**
		 * Sets the value of the input.
		 * @param {index} index Item's index in the this.data object.
		 * @return {void}
		 */
		this.setValue = function(index) {
			let item = this.getItemByIndex(index);
			this.parent.input.value = item.title;
			this.refs.input.value = item.value;
			this.setSelected(index);
		};

		this.reload = function(data) {
			this.data = data;
			this.update();
			this.setValue(0);
		};

		this.getValue = function() {
			return this.refs.input.value;
		};

		this.setSelected = function(index) {
			let selectedIndex = this.getIndexBySelected();
			if (selectedIndex + 1) {
				this.data[selectedIndex].selected = false;
			}
			this.data[index].selected = true;
		};

		this.setSelectedByValue = function(value) {
			let index = this.getIndexByValue(value);
			this.setValue(index);
		};

		/**
		 * Select event callback. Coming from the non-native select list.
		 * @param {object} ev Event Object
		 * @return {void}
		 */
		this.select = (ev) => {
			let exIndex = this.getIndexBySelected() || 0,
				index = parseInt(ev.target.getAttribute('data-index'), 10);
			this.unprevent();
			exIndex !== index && this.change(index);
		};

		/**
		 * On Select change event. Coming from the native (mobile) select element.
		 * @param {object} ev Event Object
		 * @return {void}
		 */
		this.onSelectChange = function(ev) {
			let value = ev.target.value,
				index = this.getIndexByValue(value);
			this.change(index);
		};

		/**
		 * Common change event, when the native or the desktop datatable is changing.
		 * Selecting the same value does not a change.
		 * @param {Number} index Index of the selected item
		 * @return {void}
		 */
		this.change = function(index) {
			this.setValue(index);
			this.parent.change && this.parent.change();
			this.parent.trigger('change');
		};

		/**
		 * Goes to the index'th item.
		 * @param {Number} index Index of the item.
		 * @return {void}
		 */
		this.jumpTo = function(index) {
			this.setSelected(index);
			this.getListElements(index).scrollIntoViewIfNeeded();
		};

		/**
		 * Return the list elements (or one element).
		 * @param {Number} index Index of the element you need. Don't specify if you need all.
		 * @returns {*|NodeList} Node or NodeList
		 */
		this.getListElements = function(index) {
			let listElements = this.root.querySelectorAll('li');
			return typeof index !== 'undefined' ? listElements[index] : listElements;
		};

		this.open = function() {
			if (IsPrevented) {return}
			this.root.classList.remove(PH.cls.hide);
		};

		this.close = function() {
			if (!IsPrevented) {
				this.root.classList.add(PH.cls.hide);
			}
			else {
				this.parent.input.focus();
			}
		};

		this.prevent = function() {
			IsPrevented = true;
		};

		this.unprevent = function() {
			IsPrevented = false;
		};

		this.escapeRegex = function(value) {
			return value.replace(/[\-\[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
		};

		this.unselectIndex = function(selectedIndex) {
			if (selectedIndex >= 0) {
				this.data[selectedIndex].selected = false;
			}
		};

		this.unselectAll = function() {
			this.data.map(function(item) {
				item.selected = false;
			});
		};

		this.cleanSelection = function() {
			this.refs.input.value = '';
			this.unselectAll();
			this.update();
		};


		this.keydown = function(ev) {
			// Handle Tab press
			if (ev.keyCode === 9) {
				this.unprevent();
				this.close();
				return;
			}

			ev.preventDefault();
			let selectedIndex = this.getIndexBySelected() || 0;

			// Handle Enter press
			if (ev.keyCode === 13) {
				this.handleEnterPress(selectedIndex);
			}
			// Handler Down-Up press
			else if (ev.keyCode === 40 || ev.keyCode === 38) {
				this.handleUpDownPress(ev, selectedIndex);
			}
			else {
				this.handleLetterPress(ev);
			}
		};

		this.handleEnterPress = function(selectedIndex) {
			this.setValue(selectedIndex);
			this.unprevent();
			this.close();
		};

		this.handleUpDownPress = function(ev, selectedIndex) {
			let newIndex = selectedIndex + (ev.keyCode === 40 ? 1 : -1);

			this.unselectIndex(selectedIndex);
			this.prevent();
			this.close();

			if (newIndex <= -1) {
				newIndex = 0;
			}
			else if (newIndex === this.data.length) {
				newIndex = this.data.length - 1;
			}
			else {
				newIndex = newIndex;
			}

			this.jumpTo(newIndex);
		};

		this.handleLetterPress = function(ev) {
			clearTimeout(JumpCacheTimer);
			JumpCacheTimer = setTimeout(function() {
				JumpCache = '';
			}, 1500);
			JumpCache += String.fromCharCode(ev.keyCode);

			let pattern = '^' + this.escapeRegex(JumpCache),
				regExp = new RegExp(pattern, 'i'),
				index = -1;

			this.data.some(function(item, i) {
				index = i;
				return regExp.test(item.title);
			});

			if (index + 1) {
				this.jumpTo(index);
			}
		};

		// TODO: Make sure that fake field has title from select box instead of raw value
		// There are too many updates of the state.
		// Should be refactored to STRICTLY avoid direct accessing parent like "this.parent.input.value = someValue"
		this.on('updated', () => {
			let selectedIndex = this.getIndexBySelected();
			if (selectedIndex > -1) {
				this.setValue(selectedIndex);
			}
		});
	});
