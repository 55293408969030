import React, { FC, ReactNode, useEffect, useState } from 'react';
import JawsClient, { JawsClientProvider } from '@dh-fe/jaws-client/react';
// eslint-disable-next-line no-restricted-imports
import { applicationConfigService } from 'domains/ApplicationConfig/ApplicationConfig.serviceFactory';
// eslint-disable-next-line no-restricted-imports
import { jawsService } from 'domains/Jaws/Jaws.serviceFactory';

interface JawsProviderProps {
    performerId?: number;
    children: ReactNode;
}

const JawsProvider: FC<JawsProviderProps> = ({ children, performerId }) => {
    const [jawsClient, setJawsClient] = useState<JawsClient | null>(null);
    const [isError, setError] = useState(false);

    useEffect(() => {
        if (!performerId || jawsClient) return;

        applicationConfigService
            .getApplicationConfig()
            .then((applicationConfig) => {
                const client = jawsService.getJawsClient(applicationConfig.common.jaws2Url, performerId);
                if (client) {
                    setJawsClient(client);
                }
            })
            .catch(() => {
                console.log("Couldn't connect to the websocket server.");
                setError(true);
            });
    }, [performerId]);

    if (!jawsClient) return !performerId || isError ? <>{children}</> : null;

    // @ts-ignore
    return <JawsClientProvider client={jawsClient}>{children}</JawsClientProvider>;
};

export default JawsProvider;
