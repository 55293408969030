import Ext from '../../lib/vendor/ExtCore';
import ChaosObject from '../../lib/chaos/Object';

export default class MVCOverlayView extends ChaosObject {
	static EVENT_SHOW = 'overlay-show';
	static EVENT_HIDE = 'overlay-hide';

	get properties() {
		return { ...super.properties,
			/** @var {String}     View name. */
			name : 'MVCOverlayView',
			/** @var {Object}     Overlay background element [Ext.Element] */
			overlayEl : undefined,
			/** @var {Boolean}    True to set empty the element's content after hide */
			autoDestroy : true,
			/** @var {Boolean}    True to overwrite content if a show command called when it is already shown */
			allowOverwrite : false,
			/** @var {String}     ID of the overlay container */
			overlayContainerId : undefined,
			/** @var {String}     ID of the overlay background */
			overlayBackgroundId : 'mvc_overlay_container',
			/** @var {Boolean}    True if an overlay is already shown */
			_isShown : false

		};
	}
	init(el, config) {
		super.init(el, config);
		this.addEvents(
			MVCOverlayView.EVENT_SHOW,
			MVCOverlayView.EVENT_HIDE
		);
		this._overlayContainerEl = Ext.get(this.overlayContainerId);
	}

	/**
	 * Renders to HTML to the DOM
	 *
	 * @method renderContent
	 * @param {Object} params   Params that contains the html content
	 *
	 * @return {Object} scope to chain
	 */
	renderContent(params) {
		this._appendHTMLContent(params);
		return this;
	}

	/**
	 * Appends the given content
	 *
	 * @method _appendHTMLContent
	 * @private
	 *
	 * @param {Object} params   Params that contains the html content
	 *
	 * @return void;
	 */
	_appendHTMLContent(params) {
		var content = params.content;
		if (content) {
			if (!this.isVisible()) {
				Ext.DomHelper.overwrite(this.overlayContainerId, content);
				this.show(params);
			}
			else if (this.allowOverwrite) {
				this._swapContent(params);
			}
		}
	}

	/**
	 * Swaps the content with the new one
	 *
	 * @method _swapContent
	 * @private
	 *
	 * @param {Object} params   Content params
	 *
	 * @return void;
	 */
	_swapContent(params) {
		this._hideContent(function() {
			this.fireEvent(MVCOverlayView.EVENT_HIDE,
				{
					scope             : this,
					isSwapContentHide : true
				});
			params.isSwapContent = true;
			if (!this.isVisible()) {
				Ext.DomHelper.overwrite(this.overlayContainerId, params.content);
				this.show(params);
			}
		});
	}

	/**
	 * Hides the currently displayed content (and keeps the overlay background displayed).
	 *
	 * @method _hideContent
	 *
	 * @return void;
	 */
	_hideContent(callback) {
		this._isShown = false;
		this.element.display('none');
		if (typeof callback === 'function') {
			callback.call(this);
		}
	}

	/**
	 * Hides the currently displayed content (and keeps the overlay background displayed).
	 *
	 * @method _showContent
	 *
	 * @return void;
	 */
	_showContent(params) {
		this.element.display('block');
		this.fireEvent(MVCOverlayView.EVENT_SHOW, { scope : this, params : params });
	}

	/**
	 * Sets a style to the overlay elements.
	 *
	 * @method _setStyle
	 * @private
	 *
	 * @return void;
	 */
	_setStyle() {
		const style = {
			display    : 'block',
			visibility : 'visible'
		};
		this.overlayEl.setStyle(style);
		this._overlayContainerEl.setStyle(style);
	}

	/**
	 * Displays the overlay.
	 *
	 * @method show
	 * @public
	 *
	 * @return void;
	 */
	show(params) {
		if (this.overlayEl && !this.isVisible()) {
			if (!params.isSwapContent) {
				this._setStyle();
			}
			Ext.getBody().addClass('overlay').addClass('no-overscroll');
			this._onOverlayShow();
			this._showContent(params);
		}
	}

	/**
	 * Callback when overlay become shown
	 *
	 * @method _onOverlayShow
	 * @private
	 *
	 * @return void;
	 */
	_onOverlayShow() {
		this._isShown = true;
	}

	/**
	 * Hides the overlay, and removes its content.
	 *
	 * @method hide
	 * @public
	 *
	 * @return void;
	 */
	hide(params) {
		if (this.overlayEl && this.isVisible()) {
			this.overlayEl.display('none');
			this.element.display('none');
			if (this.autoDestroy) {
				this.element.html('');
			}
			this._onOverlayHide();
			this.fireEvent(MVCOverlayView.EVENT_HIDE, {
				scope             : this,
				params            : params,
				isSwapContentHide : false
			});
			if (Ext.getBody().hasClass('overlay')) {
				Ext.getBody().removeClass('overlay');
			}
		}
	}

	/**
	 * Callback when overlay become hidden.
	 *
	 * @method _onOverlayHide
	 * @private
	 *
	 * @return void;
	 */
	_onOverlayHide() {
		this._isShown = false;
		if (this.autoDestroy) {
			this.element.html('');
		}
		Ext.getBody().removeClass('overlay').removeClass('no-overscroll').removeClass('no-overscroll-fixed');
	}

	/**
	 * Return with the visibilty
	 *
	 * @method isVisible
	 * @public
	 *
	 * @return {Boolean}
	 */
	isVisible() {
		return this._isShown;
	}

	/**
	 * Renders and displays an overlay background.
	 *
	 * @method setOverlayBackground
	 * @public
	 *
	 * @return {Object} created element
	 */
	setOverlayBackground() {
		this._overlayBackgroundEl = Ext.get(this.overlayBackgroundId);
		return this._overlayBackgroundEl;
	}
}
