import { Au10tixApi } from 'apiClients/msc-api-gateway/proxy/au10tix/client/au10tix-api';
import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { Au10tixDataMapper } from './Au10tix.dataMapper';
import { Au10tixService } from './Au10tix.service';
import { Au10tixAccessToken, CreateAccessTokenRequest } from './Au10tix.types';

export interface Au10tixServiceInterface {
    createAccessToken: (request: CreateAccessTokenRequest) => Promise<Au10tixAccessToken>;
}

const api = new Au10tixApi(apiGwConfig, apiHost, axiosApiGw);
const dataMapper = new Au10tixDataMapper(api);

export const au10tixService = new Au10tixService(dataMapper);
