import riot from 'riot';
import PH from '../../lib/constant/Phrame';

/* eslint-disable indent */

riot.tag('form-mixin-eye', `
	<i class="msc-icon {icon}" onmousedown="{mousedown}"></i>
	`,

	function (opts) {
        this.mousedown = (ev) => {
            ev.preventDefault();
            if (this.parent.input.type === 'password') {
				this.parent.input.type = 'text';
				this.icon = 'icon-eye-off-outline';
			} else {
				this.parent.input.type = 'password';
				this.icon = 'icon-eye-on-outline';
			}
        };

		this.icon = 'icon-eye-on-outline';
	}
);
