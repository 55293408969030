import { AxiosResponse } from 'axios';

export type FetchResponse<T> = Omit<AxiosResponse<T>, 'config'>;

export enum CurrencyType {
    CREDIT = 'credit',
    COIN = 'coin',
}

export interface ProxyCredentials {
    performerId: number;
    type: AccountType;
}

export type AccountType = 'studio' | 'performer';

export type ActorTypes = 'performer' | 'studio' | 'user';

/* eslint-disable camelcase */
export enum ProfilePictureVersionMediaKeyEnum {
    _4_3_56x42_Jpg = 'profile_picture_crop_4_3_56_42_jpg',
    _4_3_147x110_Jpg = 'profile_picture_crop_4_3_147_110_jpg',
    _4_3_800x600_Jpg = 'profile_picture_crop_4_3_800_600_jpg',
    _9_16_900x1600_Jpg = 'profile_picture_crop_9_16_900_1600_jpg',
    _9_16_504x896_Jpg = 'profile_picture_crop_9_16_504_896_jpg',
    _16_9_1600x900_Jpg = 'profile_picture_crop_16_9_1600_900_jpg',
    _16_9_896x504_Jpg = 'profile_picture_crop_16_9_896_504_jpg',
    _4_3_460x345_Jpg = 'profile_picture_crop_4_3_460_345_jpg',
    _16_9_285x160_Jpg = 'profile_picture_crop_16_9_285_160_jpg',
}
/* eslint-enable camelcase */

export type ProfilePictureCategory = 'glamour' | 'nonExplicit';

export interface ActorIdAndType {
    xActorId: string;
    xActorType: ActorTypes;
}
