import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { MyStoryServiceApi } from 'apiClients/msc-api-gateway/mystoryservice/client/my-story-service-api';
import { jawsService } from 'domains/Jaws/Jaws.serviceFactory';
import { LJStoriesDataMapper } from './LJStories.dataMapper';
import { LJStoriesService } from './LJStories.service';

const apiClient = new MyStoryServiceApi(apiGwConfig, apiHost, axiosApiGw);
const ljStoriesDataMapper = new LJStoriesDataMapper(apiClient);
export const ljStoriesService = new LJStoriesService(ljStoriesDataMapper, jawsService);
