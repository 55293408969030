import $ from 'jquery';
import au10tixSdk from '@dh-fe/au10tix-sdk';
import { getFeatureFlag } from 'utils/featureFlag';
import { FeatureFlagEnum } from 'configs/featureFlags';
import store from 'store/store';
import PH from '../../lib/constant/Phrame';
import { au10tixService } from 'domains/Au10tix/Au10tix.serviceFactory'
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import { push } from '../../component/GoogleAnalyticsService';
import HTML5Uploader from './HTML5Uploader';
import HTML5UploaderController from './HTML5UploaderController';
import Snapshooter from '../Snapshooter/Snapshooter';
import './IdUploader.scss';

export default class IdHTML5UploaderController extends HTML5UploaderController {
	get properties() {
		return { ...super.properties,
			_isUploaded : undefined,
            _au10tix: {
                enabled: false,
                token: undefined,
                tokenValidUntil : undefined,
                instance : undefined,
            },
		};
	}

	/**
	 * Init method.
	 *
	 * @param {Object} el
	 * @param {Object} config
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);

		this._ui = {};
		this._ui.item = this._el.closest('.js-item');
		this._ui.input = this._ui.item.find('input[type=hidden]');
		this._ui.uploaded = this._ui.item.find('.js-uploaded');
		this._ui.img = this._ui.uploaded.find('img');
		this._ui.uploader = this._ui.item.find('.js-uploader');
		this._ui.icon = this._ui.item.find('.js-icon i');
		this._ui.reject = this._ui.item.find('.js-reject');

		this._ui.item.on('click', '.js-remove', this._onRemoveClick.bind(this));
		this._ui.img.on('load', function() {$(this).removeClass(PH.cls.hide)});

		this._isUploaded = !this._ui.uploaded.hasClass(PH.cls.hide);

        const au10tix = window.PortalApi?.registrationAu10tixUpload;
        this._au10tix.enabled = Boolean(au10tix?.token?.length) && Boolean(au10tix?.tokenValidUntil?.length);
        if (this._au10tix.enabled) {
            this._setAu10tixToken(au10tix.token, au10tix.tokenValidUntil);
            this._setupAu10tixInstance(false);
        }
	}

    /**
	 * Stores token and token-expiration metadata for Au10tix.
	 *
	 * @private
	 */
    _setAu10tixToken(token, tokenValidUntil) {
        this._au10tix.token = token;
        this._au10tix.tokenValidUntil = tokenValidUntil;
        this._au10tix.instance = undefined;
    }

    /**
	 * Creates and stores new Au10tix instance if none exists, otherwise returns existing instance.
	 *
	 * @private
	 */
    _setupAu10tixInstance(allowErrorTooltip = false) {
        const { token, instance } = this._au10tix;
        if (instance) return instance;

        try {
            this._au10tix.instance = au10tixSdk(token);
            this._au10tix.instance.loadFaceModels();
            return this._au10tix.instance;
        } catch(e) {
            console.error(e);
            if (allowErrorTooltip) {
                this._ui.item.protipShow({
                    title: this.options.errorMessages[HTML5Uploader.ERROR.GENERAL_RELOAD],
                    icon  : 'alert',
                });
            }
        }
    }

    /**
	 * Refreshes Au10tix token if expired.
	 *
	 * @private
	 */
    async _refreshAu10tixTokenIfExpired() {
        const { token, tokenValidUntil } = this._au10tix;
        if (!token?.length || !tokenValidUntil?.length) return;

        const isPastExpiration = new Date().getTime() > new Date(tokenValidUntil).getTime();
        if (!isPastExpiration) return;

        const { me } = store.getState().performer;
        const createResponse = await au10tixService.createAccessToken({
            actorId: me.id,
            actorType: 'user',
            isForced: !!getFeatureFlag(FeatureFlagEnum.ForceAu10TixIdVerification),
        });
        const newExpiration = new Date();
        newExpiration.setSeconds(newExpiration.getSeconds() + createResponse.expiresIn);
        this._setAu10tixToken(createResponse.accessToken, newExpiration.toISOString());
    }

	/**
	 * It'll show the preview of the uploaded image.
	 *
	 * @private
	 */
	_togglePreview() {
		this.toggleUploader();

		if (this._isUploaded) {
			this._hidePreview();
		}
		else {
			this._showPreview();
		}
		this._isUploaded = !this._isUploaded;
	}

	_hidePreview() {
		this._ui.uploaded.toggleClass(PH.cls.hide, true);
		this._ui.uploader.toggleClass(PH.cls.hide, false);
		this._ui.icon
			.removeClass(PH.icon.join(' '))
			.addClass(PH.cls.protip)
			.addClass(PH.icon.info);
	}

	_showPreview() {
		this._ui.uploaded.toggleClass(PH.cls.hide, false);
		this._ui.uploader.toggleClass(PH.cls.hide, true);
		this._ui.icon
			.protipHide()
			.removeClass(PH.icon.join(' '))
			.removeClass(PH.cls.protip)
			.addClass(PH.icon.ok);

		this._ui.img.addClass(PH.cls.hide);
		this._ui.img.attr('src', this.readerResults[Object.keys(this.readerResults)[0]]);
	}

	/**
	 * Sets the hidden input value.
	 *
	 * @param value
	 * @private
	 */
	_setInput(value) {
		this._ui.input.val(value);
	}

	/**
	 * After input value is changed.
	 *
	 * @private
	 */
	_onAfterChange() {
		this._ui.reject.protipHide().remove();
        this._ui.item.protipHide().removeClass(PH.cls.protip);
	}

	/**
	 * Handler after upload has finished.
	 *
	 * @param ev
	 * @private
	 */
	_onAfterDone(ev) {
		var response = JSON.parse(ev.result);

		if (response.status !== 'ERROR') {
			let rejectedEl = document.querySelector('.js-reject'),
				formEl = document.querySelector('.id-uploader-form');

			this._setInput(response.data.fileId);
            if (!this._au10tix.enabled) {
                this._togglePreview();
                this._setInput(response.data.fileId);
                if (formEl && !rejectedEl) {
                    formEl._tag.trigger(formEl._tag.CONST.FORM_SUBMIT_ENABLE_EVENT);
                    Broadcaster.fireEvent('id-upload-changed');
                }
                return;
            }

            if (formEl && !rejectedEl) {
                this._checkDocumentWithAu10tix();
			}
		}
		else {
			this._addError(HTML5Uploader.ERROR.DEFAULT);
			this._showErrors();
		}
	}

    async _checkDocumentWithAu10tix() {
        // brief delay or otherwise the progress UI does not remove correctly
        await new Promise(resolve => setTimeout(resolve, 1));
        this.hideProgressBar();
        this.showAnalyzing();

        try {
            await this._refreshAu10tixTokenIfExpired();
            const instance = this._setupAu10tixInstance();
            const isFaceAndId = this._ui.input.attr('name') === 'faceAndId';
            const analyzer = isFaceAndId ? instance.analyzeSelfieImage : instance.analyzeDocumentImage;
            const result = await analyzer(this.file);

            const resultStatus = isFaceAndId ? result.response.faceStatus : result.response.idStatus;
            if (resultStatus !== 1) {
                const errorMsgKey = isFaceAndId ? HTML5Uploader.ERROR.NO_FACE_DETECTED : HTML5Uploader.ERROR.NO_ID_DETECTED;
                this._ui.item.protipShow({
                    title: this.options.errorMessages[errorMsgKey],
                    icon  : 'alert',
                });
                this._setInput('');
                return;
            }

            this._togglePreview();
            let formEl = document.querySelector('.id-uploader-form');
            formEl._tag.trigger(formEl._tag.CONST.FORM_SUBMIT_ENABLE_EVENT);
            Broadcaster.fireEvent('id-upload-changed');
        } catch (e) {
            console.error(e);
            this._ui.item.protipShow({
                title: this.options.errorMessages[HTML5Uploader.ERROR.GENERAL_RELOAD],
                icon  : 'alert',
            });
            this._setInput('');
        } finally {
            this.hideAnalyzing();
        }
    }

	/**
	 * Handler when remove link is clicked.
	 *
	 * @private
	 */
	_onRemoveClick(ev) {
		ev.preventDefault();

		this._togglePreview();
		this._setInput('');
		Broadcaster.fireEvent('id-upload-changed');
	}

	_onSnapshooterReady(ev) {
		this.addSingle(ev.dataUri, 'snapshot.jpg', 'image/jpeg');
	}

    _getGAPageLabel() {
        switch (this._ui.input.attr('name')) {
            case 'idPage2':
                return 'id back';
            case 'faceAndId':
                return 'face and id';
            case 'idPage1':
            default:
                return 'id front';
        }
    }

    _onPrepareFail(ev) {
        const errors = ev?.errors || [];
        const hasSizeError = errors.find((error) => {
            return Object.keys(error).find((key) => error[key].startsWith('Media width is too small') || error[key].startsWith('Media height is too small'));
        });
        if (hasSizeError) {
            push({
                event: 'GA - Event - Generic Event',
                eventCategory: 'add new model flow document upload',
                eventLabel: `${this._getGAPageLabel()} minimum resolution not met`,
                eventAction: 'validation failed',
                eventInteraction: true,
            });
        }
    }

	bind() {
		if (this._el.attr('id') === 'snapshotUploader') {
			Broadcaster.on(
				Snapshooter.EVENT_SNAPSHOT_IMAGE_READY,
				this._onSnapshooterReady, this
			);
		}

        this.on(HTML5Uploader.EV.PREPARE_FAIL, this['_onPrepareFail'], this);

		super.bind();
	}
}