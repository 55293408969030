/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../../msc-api-gateway/proxy/engagement/models';
// @ts-ignore
import { InlineResponse200 } from '../../../../msc-api-gateway/proxy/engagement/models';
// @ts-ignore
import { InlineResponse2001 } from '../../../../msc-api-gateway/proxy/engagement/models';
// @ts-ignore
import { InlineResponse2002 } from '../../../../msc-api-gateway/proxy/engagement/models';
// @ts-ignore
import { UnauthorizedError } from '../../../../msc-api-gateway/proxy/engagement/models';
/**
 * EngagementApi - axios parameter creator
 * @export
 */
export const EngagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides the details of emarsys contact.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactDetails: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getContactDetails', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getContactDetails', 'xActorType', xActorType);
            const localVarPath = `/gw/engagement/v1/contact-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides details about the performer\'s or studio\'s Intercom workspace identity verification based on the platform.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomIdentity: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getIntercomIdentity', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getIntercomIdentity', 'xActorType', xActorType);
            const localVarPath = `/gw/engagement/v1/identity/intercom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the details of intercom performer contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIntercomPerformerContactDetails: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'performerId', performerId);
            // verify required parameter 'product' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'product', product);
            const localVarPath = `/gw/engagement/v1/performers/{performerId}/intercom`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the details of intercom studio contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} studioId Numerical MSC studio ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIntercomStudioContactDetails: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            studioId: number,
            product: 'livejasmin' | 'oranum',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getIntercomStudioContactDetails', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getIntercomStudioContactDetails', 'xActorType', xActorType);
            // verify required parameter 'studioId' is not null or undefined
            assertParamExists('getIntercomStudioContactDetails', 'studioId', studioId);
            // verify required parameter 'product' is not null or undefined
            assertParamExists('getIntercomStudioContactDetails', 'product', product);
            const localVarPath = `/gw/engagement/v1/studios/{studioId}/intercom`.replace(
                `{${'studioId'}}`,
                encodeURIComponent(String(studioId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EngagementApi - functional programming interface
 * @export
 */
export const EngagementApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EngagementApiAxiosParamCreator(configuration);
    return {
        /**
         * Provides the details of emarsys contact.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactDetails(xActorId, xActorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides details about the performer\'s or studio\'s Intercom workspace identity verification based on the platform.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntercomIdentity(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntercomIdentity(
                xActorId,
                xActorType,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the details of intercom performer contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getIntercomPerformerContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntercomPerformerContactDetails(
                xActorId,
                xActorType,
                performerId,
                product,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the details of intercom studio contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} studioId Numerical MSC studio ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getIntercomStudioContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            studioId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntercomStudioContactDetails(
                xActorId,
                xActorType,
                studioId,
                product,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EngagementApi - factory interface
 * @export
 */
export const EngagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EngagementApiFp(configuration);
    return {
        /**
         * Provides the details of emarsys contact.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): AxiosPromise<InlineResponse2002> {
            return localVarFp
                .getContactDetails(xActorId, xActorType, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Provides details about the performer\'s or studio\'s Intercom workspace identity verification based on the platform.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomIdentity(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): AxiosPromise<InlineResponse2001> {
            return localVarFp
                .getIntercomIdentity(xActorId, xActorType, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Provides the details of intercom performer contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIntercomPerformerContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .getIntercomPerformerContactDetails(xActorId, xActorType, performerId, product, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Provides the details of intercom studio contact for client integration. Deprecated: use /v1/identity/intercom instead.
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} studioId Numerical MSC studio ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIntercomStudioContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            studioId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .getIntercomStudioContactDetails(xActorId, xActorType, studioId, product, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EngagementApi - interface
 * @export
 * @interface EngagementApi
 */
export interface EngagementApiInterface {
    /**
     * Provides the details of emarsys contact.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementApiInterface
     */
    getContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        options?: any,
    ): AxiosPromise<InlineResponse2002>;

    /**
     * Provides details about the performer\'s or studio\'s Intercom workspace identity verification based on the platform.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementApiInterface
     */
    getIntercomIdentity(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        options?: any,
    ): AxiosPromise<InlineResponse2001>;

    /**
     * Provides the details of intercom performer contact for client integration. Deprecated: use /v1/identity/intercom instead.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EngagementApiInterface
     */
    getIntercomPerformerContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ): AxiosPromise<InlineResponse200>;

    /**
     * Provides the details of intercom studio contact for client integration. Deprecated: use /v1/identity/intercom instead.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} studioId Numerical MSC studio ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EngagementApiInterface
     */
    getIntercomStudioContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        studioId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ): AxiosPromise<InlineResponse200>;
}

/**
 * EngagementApi - object-oriented interface
 * @export
 * @class EngagementApi
 * @extends {BaseAPI}
 */
export class EngagementApi extends BaseAPI implements EngagementApiInterface {
    /**
     * Provides the details of emarsys contact.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementApi
     */
    public getContactDetails(xActorId: string, xActorType: 'performer' | 'studio' | 'user', options?: any) {
        return EngagementApiFp(this.configuration)
            .getContactDetails(xActorId, xActorType, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides details about the performer\'s or studio\'s Intercom workspace identity verification based on the platform.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementApi
     */
    public getIntercomIdentity(xActorId: string, xActorType: 'performer' | 'studio' | 'user', options?: any) {
        return EngagementApiFp(this.configuration)
            .getIntercomIdentity(xActorId, xActorType, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the details of intercom performer contact for client integration. Deprecated: use /v1/identity/intercom instead.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EngagementApi
     */
    public getIntercomPerformerContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ) {
        return EngagementApiFp(this.configuration)
            .getIntercomPerformerContactDetails(xActorId, xActorType, performerId, product, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the details of intercom studio contact for client integration. Deprecated: use /v1/identity/intercom instead.
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} studioId Numerical MSC studio ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EngagementApi
     */
    public getIntercomStudioContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        studioId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ) {
        return EngagementApiFp(this.configuration)
            .getIntercomStudioContactDetails(xActorId, xActorType, studioId, product, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
