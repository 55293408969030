import { TranslatorTextClient } from '@azure/cognitiveservices-translatortext';
import { TranslateInput, TranslationResponseItem } from './Translation.type';
import { mapTranslationResponse } from './Translation.mapper';
import { getAzureTranslationClient } from './Translation.utils';

export class TranslationService {
    private apiClient: TranslatorTextClient;

    private static instance: TranslationService;

    // the `private` modifier makes it impossible to create an instance of this class from outside
    private constructor(subscriptionKey: string) {
        this.apiClient = getAzureTranslationClient(subscriptionKey);
    }

    public static getInstance(subscriptionKey: string): TranslationService {
        if (!TranslationService.instance) {
            TranslationService.instance = new TranslationService(subscriptionKey);
        }

        return TranslationService.instance;
    }

    public translate(translateTo: string[], text: TranslateInput[]): Promise<TranslationResponseItem[]> {
        return new Promise((resolve, reject) => {
            this.apiClient.translator
                .translate(translateTo, text)
                .then((response) => {
                    if (!response.length) {
                        reject(new Error('no response'));
                        return;
                    }

                    try {
                        resolve(mapTranslationResponse(response));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(reject);
        });
    }
}
