import riot from 'riot';
import { Broadcaster } from '../../lib/chaos/Broadcaster';

riot.tag('broadcaster', false,
	function({
		broadcast = (() => { throw 'Required' })(),
		broadcastEvent = 'click',
		preventDefault = true,
		stopPropagation = false
	}) {
		this[`on${broadcastEvent}`] = (ev) => {
			preventDefault && ev.preventDefault();
			stopPropagation && ev.stopPropagation();
			/* webpack-strip-block:removed */
			Broadcaster.fireEvent(broadcast, ev.currentTarget.dataset, ev);
		};
	},
	'onClick={onclick}' // simply add here new events when needed
);