/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InteractionInteraction } from './interaction-interaction';
import { LoyaltyLevel } from './loyalty-level';

/**
 *
 * @export
 * @interface Interaction
 */
export interface Interaction {
    /**
     *
     * @type {number}
     * @memberof Interaction
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Interaction
     */
    displayName?: string;
    /**
     *
     * @type {LoyaltyLevel}
     * @memberof Interaction
     */
    loyaltyLevel?: LoyaltyLevel;
    /**
     *
     * @type {string}
     * @memberof Interaction
     */
    fanclubPackageType?: InteractionFanclubPackageTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof Interaction
     */
    hasProMembership?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof Interaction
     */
    isSubscriber?: boolean;
    /**
     *
     * @type {string}
     * @memberof Interaction
     */
    recommendationType?: InteractionRecommendationTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Interaction
     */
    createdAt?: string;
    /**
     *
     * @type {InteractionInteraction}
     * @memberof Interaction
     */
    interaction?: InteractionInteraction;
}

/**
 * @export
 * @enum {string}
 */
export enum InteractionFanclubPackageTypeEnum {
    Bronze = 'bronze',
    Silver = 'silver',
    Gold = 'gold',
}
/**
 * @export
 * @enum {string}
 */
export enum InteractionRecommendationTypeEnum {
    Interaction = 'interaction',
    Thread = 'thread',
}
