import { Au10tixWebSdkAccessTokenResponseData } from 'apiClients/msc-api-gateway/proxy/au10tix/models';
import { Au10tixAccessToken } from './Au10tix.types';

export const mapAccessTokenResponse = (data: Au10tixWebSdkAccessTokenResponseData): Au10tixAccessToken => {
    const { tokenType, expiresIn, accessToken } = data;
    return {
        tokenType,
        expiresIn,
        accessToken,
    };
};
