import ChaosObject from '../../lib/chaos/Object';

export default class AbstractSuggestionList extends ChaosObject {
	/**
	 * Filter full suggest list by the typed-in keyword
	 * @returns {Array} Filtered suggestion list
	 */
	get properties() {
		return { ...super.properties,
			suggestionPerPage : 15,

			currentPage : 1
		};
	}
	getList(keyword, done) {
		this.currentPage = 1;
		this._prepareList(keyword, done);
	}

	nextPage(keyword, done) {
		this.currentPage++;
		this._prepareList(keyword, done);
	}

	/**
     * @param {string} keyword
     * @param {function} done
     * @protected
     */
	_prepareList(keyword, done) {
		setTimeout(done, 0, null, []);
	}
}