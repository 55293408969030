/* eslint-disable global-require */
import React, { FC, useEffect } from 'react';
import styles from './ChannelOverlay.module.scss';

export enum ChannelOverlayMediaTypes {
    VIDEO = 'video',
    PHOTO = 'photo',
}

export enum ChannelOverlayTypeEnum {
    PAYING = 'paying',
    FREE = 'free',
}

export interface ChannelOverlayProps {
    mediaType?: ChannelOverlayMediaTypes;
    type: string;
    id: string;
    disableNavigation?: boolean;
    onClose: () => void;
}

const ChannelOverlay: FC<ChannelOverlayProps> = ({
    mediaType = ChannelOverlayMediaTypes.VIDEO,
    type,
    id,
    disableNavigation = true,
    onClose,
}) => {
    useEffect(() => {
        // to be fixed in https://jira.doclerholding.com/browse/MSC-8794
        if (process.env.NODE_ENV === 'production') {
            // @ts-ignore
            const Config = require('../../../../src/lib/chaos/Config').default;
            // @ts-ignore
            const LegacyMVCOverlay = require('../../../../src/component/MyContent/MVCOverlay').default;
            !Config.get('legacyMVCOverlay') && Config.set('legacyMVCOverlay', new LegacyMVCOverlay(document.body, {}));
            // @ts-ignore
            const LegacyChannelOverlay = require('../../../../src/component/MyContent/ChannelOverlay').default;
            const channelOverlay = new LegacyChannelOverlay(document.body, { disableNavigation });
            Config.set('channelType', type);
            channelOverlay.openMediaOverlay(mediaType, id, onClose);
        }
    }, []);

    return <div data-testid="wrapper" className={styles.overlay} />;
};

export default ChannelOverlay;
