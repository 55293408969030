import JawsClient from '@dh-fe/jaws-client';
import { JawsToken } from './Jaws.types';

export interface JawsServiceInterface {
    getJawsToken: (performerId: number) => Promise<JawsToken>;
    getJawsClient: (socketUrl: string, performerId: number) => JawsClient | undefined;
    subscribeToEvent: <T>(eventName: string, cb: (data: T) => void) => void;
}

export interface JawsDataMapperInterface {
    getJawsToken: (performerId: number) => Promise<JawsToken>;
}

export class JawsService implements JawsServiceInterface {
    jawsClient: JawsClient | undefined = undefined;

    constructor(private mapper: JawsDataMapperInterface) {}

    public getJawsToken(performerId: number) {
        return new Promise<JawsToken>((resolve, reject) => {
            this.mapper
                .getJawsToken(performerId)
                .then((jawsToken) => {
                    resolve(jawsToken);
                })
                .catch(() => {
                    reject(new Error(`Backend error - couldn't fetch JAWS token`));
                });
        });
    }

    public getJawsClient(socketUrl: string, performerId: number): JawsClient | undefined {
        if (this.jawsClient) {
            return this.jawsClient;
        }

        try {
            this.jawsClient = new JawsClient({
                socketUrl,
                authCallback: () => {
                    return this.getJawsToken(performerId).then((jawsToken) => jawsToken.accessToken);
                },
                hostsName: 'msc',
            });
            this.subscribeToAuthEvents();
            return this.jawsClient;
        } catch (error) {
            /* webpack-strip-block:removed */
        }
    }

    public subscribeToEvent<T>(eventName: string, cb: (data: T) => void) {
        if (!this.jawsClient) throw new Error('JAWS Client is not initialized');
        this.jawsClient.on(eventName, (data: unknown) => {
            cb(data as T);
        });
    }

    private subscribeToAuthEvents() {
        /* webpack-strip-block:removed */
    }
}
