import { TranslationResponseItem } from 'domains/Translation/Translation.type';
import { TranslatedMessage } from './MessengerTranslation.types';

export const mapTranslationResponse = (
    translationResponse: TranslationResponseItem[],
    messageId: number,
): TranslatedMessage => {
    if (translationResponse.length < 1) {
        throw new Error('No translation response');
    }

    return {
        messageId,
        translatedText: translationResponse[0].translations[0].text,
    };
};
