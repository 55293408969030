/* tslint:disable */
/* eslint-disable */
/**
 * My_Story_Service API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface MyStoryItemRequest
 */
export interface MyStoryItemRequest {
    /**
     *
     * @type {string}
     * @memberof MyStoryItemRequest
     */
    type?: MyStoryItemRequestTypeEnum;
    /**
     *
     * @type {string}
     * @memberof MyStoryItemRequest
     */
    privacy?: MyStoryItemRequestPrivacyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum MyStoryItemRequestTypeEnum {
    Image = 'image',
    Video = 'video',
}
/**
 * @export
 * @enum {string}
 */
export enum MyStoryItemRequestPrivacyEnum {
    Free = 'free',
    Premium = 'premium',
}
