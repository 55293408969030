import { useEffect } from 'react';
import useAppSelector from 'store/hooks/useAppSelector';
import useAppDispatch from 'store/hooks/useAppDispatch';
import { fetchNewbieStatus } from 'store/slices/Performer/Performer.thunks';
import { selectMyPerformer } from 'store/slices/Performer/Performer.selectors';

const useNewbies = () => {
    const dispatch = useAppDispatch();
    const myPerformer = useAppSelector(selectMyPerformer);
    const isNewbie = useAppSelector((state) => state.performerNewbieStatus?.isNewbie);
    const newbieRequestStatus = useAppSelector((state) => state.performerNewbieStatus?.status);
    const newbieUntil = useAppSelector((state) => state.performerNewbieStatus?.newbieUntil);
    const isNewbieLoading = newbieRequestStatus === 'initial' || newbieRequestStatus === 'pending';

    useEffect(() => {
        if (myPerformer) dispatch(fetchNewbieStatus(myPerformer.id));
    }, [dispatch, myPerformer]);

    return {
        isNewbie,
        isNewbieLoading,
        newbieUntil,
    };
};

export default useNewbies;
