import { TranslatorTextClient } from '@azure/cognitiveservices-translatortext';
import { ApiKeyCredentials } from '@azure/ms-rest-js';
import { TRANSLATOR_BASE_URL } from './Translation.config';

export const getAzureTranslationClient = (subscriptionKey: string) => {
    const cognitiveServiceCredentials = new ApiKeyCredentials({
        inHeader: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    });

    return new TranslatorTextClient(cognitiveServiceCredentials, TRANSLATOR_BASE_URL);
};
