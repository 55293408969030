/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Level } from './level';

/**
 *
 * @export
 * @interface IncomeGroup
 */
export interface IncomeGroup {
    /**
     *
     * @type {number}
     * @memberof IncomeGroup
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof IncomeGroup
     */
    groupName?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof IncomeGroup
     */
    pretences?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof IncomeGroup
     */
    groupType?: IncomeGroupGroupTypeEnum;
    /**
     *
     * @type {Array<Level>}
     * @memberof IncomeGroup
     */
    levels?: Array<Level> | null;
    /**
     *
     * @type {number}
     * @memberof IncomeGroup
     */
    currentLevelId?: number | null;
    /**
     *
     * @type {number}
     * @memberof IncomeGroup
     */
    groupEarnings?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum IncomeGroupGroupTypeEnum {
    Dependent = 'level-dependent',
    Independent = 'level-independent',
}
