import riot from 'riot';
import SimpleBar from 'simplebar';

import './Scrollable.scss';

// Please handle updates manually for performance reasons
SimpleBar.removeObserver();
riot.tag('scrollable',
	false,
	function(opts) {
		let scrollEl, contentEl,
			showHorizontal = !!opts.showHorizontal;
		const { min = 0, max = 1000, heightOffset = 0, use } = opts;
		const simpleBar = new SimpleBar(this.root, {
			autoHide    : !!opts.autoHide
		});
		this.scrollEl = scrollEl = simpleBar.getScrollElement();
		this.contentEl = contentEl = simpleBar.getContentElement();

		this.on('mount', function () {
			showHorizontal && this.root.classList.add('scrollable--horizontal');
			if (this.parent) {
				this.parent.on('updated', this.doUpdate);
			}
			window.addEventListener('resize', this.onResize);

			// Handling these events manually
			// so it's possible to use riot event handlersonscrollable elements
			scrollEl.addEventListener('mouseenter', this.onMouseEnter);
			scrollEl.addEventListener('mouseleave', this.onMouseLeave);

			// Handlingscrollstuff totriggerevents
			scrollEl.addEventListener('scroll', this.onScroll);
		});

		this.on('unmount', () => {
			window.removeEventListener('resize', this.onResize);
			scrollEl.removeEventListener('mouseenter', this.onMouseEnter);
			scrollEl.removeEventListener('mouseleave', this.onMouseLeave);
			scrollEl.removeEventListener('scroll', this.onScroll);
		});

		this.doUpdate = () => {
			this.setHeight();
			simpleBar.recalculate();
		};

		this.scroll = (dir = 'bottom') => {
			const { scrollHeight } = scrollEl;
			scrollEl.scrollTop = dir === 'bottom'
				? scrollHeight
				: 0;
		};

		this.scrollTop = (val = 0) => {
			scrollEl.scrollTop = val;
		};

		this.setHeight = () => {
			let height;
			if (use === 'parent') {
				let parent = this.root.parentNode || this.parent.root;
				height = parent.offsetHeight + parseInt(heightOffset, 10);
			}
			else if (contentEl) {
				height = contentEl.firstElementChild.offsetHeight;
				height += heightOffset;
			}
			height = height < parseInt(min, 10) ? min : height;
			height = height > parseInt(max, 10) ? max : height;
			this.root.style.height = `${height}px`;
		};

		this.onResize = () => {
			if (this.parent) {
				clearTimeout(this.resizeTimeout);
				this.resizeTimeout = setTimeout(() => this.parent.trigger('updated'), 100);
			}
		};

		this.onScroll = ev => {
			const { scrollTop, scrollHeight, offsetHeight } = scrollEl;

			this.trigger('scroll', ev);

			if (scrollHeight === scrollTop + offsetHeight) {
				this.trigger('scrollend', ev);
			}
			else if (scrollTop === 0) {
				this.trigger('scrollstart', ev);
			}
		};
	});
