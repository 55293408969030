import Ext from '../../lib/vendor/ExtCore';

import overlayLoader from './';

/**
 * Overlay Controller abstract.
 * Handles overlay's functions
 */
export default class OverlayControllerAbstract extends Ext.util.Observable {
	/**
     * Id alapjan peldanyositjuk a megfelelo js osztalyokat
     *
     * @param pageId
     */
	async pageController(overlayId, clickedButton, response, overlayCmp) {
		let controller = await overlayLoader(overlayId);

		// Init overlay controller if exists
		if (controller) {
			this._controller = new controller(overlayCmp.element, {
				overlayCmp : overlayCmp,
				formCmp    : this._form,
				response
			});
		}
		else {
			/* webpack-strip-block:removed */
		}
	}
}
