import riot from 'riot';
import { addMinutes, addHours, setSeconds, setMinutes, setHours, toDate } from 'date-fns';
import dayjs from 'global/dayjsExtended';
import { toISO8601 } from 'utils/datetime';

const getSelectDigits = function(limit = 24, step = 1) {
	let items = [];

	for (let i = 0; i < limit; i += step) {
		let title = i < 10 ? '0' + i : String(i);
		items.push({
			title : title,
			value : i
		});
	}

	return items;
};

const getDuration = function() {
	return [{
		title : Chaos.translate('{minutes} minutes', { minutes : '10' }),
		value : 10
	}, {
		title : Chaos.translate('{minutes} minutes', { minutes : '15' }),
		value : 15
	}, {
		title : Chaos.translate('{minutes} minutes', { minutes : '20' }),
		value : 20
	}, {
		title : Chaos.translate('{minutes} minutes', { minutes : '25' }),
		value : 25
	}, {
		title : Chaos.translate('{minutes} minutes', { minutes : '30' }),
		value : 30
	}];
};

riot.tag('form-time-range',
	`<div class="dh-form__time-range-container">
		<span class="dh-form__time-label">{ _('Start time:') }</span>
		<div class="dh-form__time-selector">
			<form-input type="select" class="dh-form__time-hours" no-validate="true" default-value="{ inputList.hours }" value="{ inputList.hours }" name="hours" ref="hours" top-label="&nbsp;" datatable-items="{ hourItems }"></form-input>
			<form-input type="select" class="dh-form__time-minutes" no-validate="true" default-value="{ inputList.minutes }" value="{ inputList.minutes }" name="minutes" ref="minutes" top-label="&nbsp;" datatable-items="{ minuteItems }"></form-input>
		</div>
		<div class="dh-form__duration-selector">
			<form-input type="select" class="dh-form__time-duration" no-validate="true" default-value="{ inputList.duration }" value="{ inputList.duration }" name="duration" ref="duration" top-label="{ _('Duration:') }" datatable-items="{ durationItems }"></form-input>
		</div>
		<yield></yield>
		<div class="dh-form__time-range-error">
			<form-mixin-error class="{ PH.cls.hide }" data-pt-position="right" data-pt-gravity="right; top-right" hide-icon="true"></form-mixin-error>
		</div>
	</div>`,

	function() {
		let minutesStep = 5;

		// Apply mixins
		this.mixin('form');
		// Init const
		var C = this.CONST;

		this.date = new Date();
		this.hourItems = getSelectDigits(24);
		this.minuteItems = getSelectDigits(60, minutesStep);
		this.durationItems = getDuration();
		this.startDate = false;
		this.endDate = false;

		this.getDefaultValues = function() {
			this.currentMinutes = Number(dayjs(this.date).format('mm'));
			this.date = addMinutes(this.date, minutesStep - this.currentMinutes % minutesStep);
			return {
				hours    : Number(dayjs(addHours(this.date, 1)).format('h')),
				minutes  : Number(dayjs(this.date).format('m')),
				duration : this.durationItems[0].value
			};
		};

		this.inputList = this.getDefaultValues();

		this.on('mount', function() {
			this.scheduleStartTimeTag = this.refs.scheduleStartTime;
			this.scheduleEndTimeTag = this.refs.scheduleEndTime;

			this.scheduleEndTimeTag.on('mount', () => {
				this.setDates();
				this.bindEvents();
			});
		});

		this.checkRangeInput = function(input) {
			this.inputList[input.opts.name] = this.inputList[input.opts.name] !== undefined && Number(input.getValue());

			let isDateNotCompleted = Object.keys(this.inputList).some(name => typeof this.inputList[name] !== 'number');

			if (isDateNotCompleted) {
				return;
			}

			this.setDates();
		};

		this.updateDate = function (dateString) {
			this.date = dayjs(dateString).toDate();
			this.setDates();
			this.hideError();
		};

		this.setDates = function () {
			let formId = this.form && this.form.root.id;

			let startDate = setSeconds(setMinutes(setHours(toDate(this.date), this.inputList.hours), this.inputList.minutes), 0);
			let endDate = addMinutes(toDate(startDate), this.inputList.duration);

			this.startDate = toISO8601(startDate);
			this.endDate = toISO8601(endDate);


			this.scheduleStartTimeTag.setValue(this.startDate);
			this.scheduleEndTimeTag.setValue(this.endDate);

			this.trigger('change', this.startDate, this.endDate, formId);
		};

		/**
		 * Bind events
		 */
		this.bindEvents = function() {
			Object.keys(this.inputList).forEach(input => {
				this.refs[input]
					.on(C.RIOT_ELEMENT_RESET_DONE, this.checkRangeInput.bind(this, this.refs[input]))
					.on('blur', this.checkRangeInput.bind(this, this.refs[input]));
			});

			this.scheduleStartTimeTag.on('mount', () => {
				this.scheduleStartTimeTag.setValue(this.startDate);
			});

			this.scheduleEndTimeTag.on('mount', () => {
				this.scheduleEndTimeTag.setValue(this.endDate);
			});
		};

		this.showError = function(message) {
			this.trigger(C.RIOT_ELEMENT_SHOW_ERROR_EVENT, message);
			Object.keys(this.inputList).forEach(input => {
				this.refs[input].trigger(C.RIOT_ELEMENT_SHOW_HIGHLITE_EVENT);
			});
		};

		this.hideError = function() {
			this.trigger(C.RIOT_ELEMENT_HIDE_ERROR_EVENT);
			Object.keys(this.inputList).forEach(input => {
				this.refs[input].trigger(C.RIOT_ELEMENT_HIDE_HIGHLITE_EVENT);
			});
		};
	}
);
