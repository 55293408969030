import _ from '@dh-fe/translate';
import { AppThunk } from 'store/store';
import { performerService } from 'domains/Performer/Performer.serviceFactory';
// eslint-disable-next-line no-restricted-imports
import { UpdatePerformerPropertiesRequest } from 'apiClients/msc-api-gateway/accountservices/models';
// eslint-disable-next-line no-restricted-imports
import { AudioSpokenLanguageEnum } from 'apiClients/msc-api-gateway/accountservices/models/audio-spoken-language-enum';
import { ljStoriesService } from 'domains/LJStories/LJStories.serviceFactory';
import {
    fetchMyPerformerStarted,
    fetchMyPerformerSucceeded,
    fetchMyPerformerFailed,
    fetchMeSucceeded,
    fetchMeFailed,
    fetchMyPerformerPeriodsSucceeded,
    updatePerformerPropertiesStarted,
    updatePerformerPropertiesSucceeded,
    updatePerformerPropertiesFailed,
    setSaveIndicatorStarted,
    setSaveIndicatorSucceeded,
    setSaveIndicatorFailed,
    clearAllSaveIndicators,
} from './Performer.slice';
import {
    fetchNewbieStatusStarted,
    fetchNewbieStatusSucceeded,
    fetchNewbieStatusFailed,
} from './PerformerNewbieStatus.slice';
import {
    setPerformerTwoWayAudio,
    updatePerformerTwoWayAudioEnabled,
    updatePerformerTwoWayAudioLanguages,
} from './PerformerTwoWayAudio.slice';
import { PerformerLoadingTypes } from './Performer.types';

const errorMessage = _`There was an error in loading data. Please try again later.`;
export const fetchMe = (): AppThunk => (dispatch) => {
    performerService
        .getMe()
        .then((me) => {
            dispatch(fetchMeSucceeded(me));
        })
        .catch(() => {
            dispatch(fetchMeFailed(errorMessage));
        });
};

export const fetchMyPerformer =
    (performerId: number): AppThunk =>
    (dispatch) => {
        dispatch(fetchMyPerformerStarted());
        performerService
            .getMyPerformer(performerId)
            .then((result) => {
                const { performer, twoWayAudio } = result;
                dispatch(fetchMyPerformerSucceeded(performer));
                dispatch(setPerformerTwoWayAudio(twoWayAudio));
            })
            .catch(() => {
                dispatch(fetchMyPerformerFailed(errorMessage));
            });
    };

export const fetchPerformerPeriods = (): AppThunk => (dispatch) => {
    const periods = performerService.getPerformerPeriods();
    dispatch(fetchMyPerformerPeriodsSucceeded(periods));
};

export const updatePerformerProperties =
    (performerId: number, body: UpdatePerformerPropertiesRequest): AppThunk =>
    (dispatch) => {
        dispatch(updatePerformerPropertiesStarted());
        performerService
            .updatePerformer(performerId, body)
            .then(() => {
                dispatch(updatePerformerPropertiesSucceeded(body));
            })
            .catch(() => {
                dispatch(updatePerformerPropertiesFailed(errorMessage));
            });
    };
export const fetchPerformerProperties =
    (performerId: number): AppThunk =>
    (dispatch) => {
        dispatch(updatePerformerPropertiesStarted());
        const properties = performerService.getPerformerProperties(performerId);
        dispatch(updatePerformerPropertiesSucceeded(properties));
    };

export const startPriceLoadingIndicators = (): AppThunk => (dispatch) => {
    const types = [
        PerformerLoadingTypes.Cam2CamPrice,
        PerformerLoadingTypes.MessagePrice,
        PerformerLoadingTypes.PrivateShowPrice,
        PerformerLoadingTypes.SubscriptionPrice,
        PerformerLoadingTypes.TwoWayAudioPrice,
        PerformerLoadingTypes.VideoCallPrice,
        PerformerLoadingTypes.MyStoryPrice,
    ];

    for (const type of types) {
        dispatch(setSaveIndicatorStarted(type));
    }
};

export const stopPriceLoadingIndicators = (): AppThunk => (dispatch) => {
    dispatch(clearAllSaveIndicators());
};

export const updateVideoCallPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.VideoCallPrice;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { videoVoiceCallPrice: price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateMessengerPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.MessagePrice;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { memberMessagePrice: price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updatePrivateShowPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.PrivateShowPrice;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { liveShowChargeAmount: price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateCam2CamPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.Cam2CamPrice;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { cam2camChargeAmount: price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateTwoWayAudioPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.TwoWayAudioPrice;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { audioChargeRateModifier: price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateMyStoryPrice =
    (performerId: number, price: number): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.MyStoryPrice;
        dispatch(setSaveIndicatorStarted(type));
        ljStoriesService
            .updateMyStory(performerId, { price })
            .then(() => {
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateTwoWayAudioLanguages =
    (performerId: number, languages: string[]): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.TwoWayAudioLanguages;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { audioSpokenLanguage: languages as AudioSpokenLanguageEnum[] })
            .then(() => {
                dispatch(updatePerformerTwoWayAudioLanguages(languages));
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const updateTwoWayAudioEnabled =
    (performerId: number, isTwoWayAudioEnabled: boolean): AppThunk =>
    (dispatch) => {
        const type = PerformerLoadingTypes.TwoWayAudioEnabled;
        dispatch(setSaveIndicatorStarted(type));
        performerService
            .updatePerformer(performerId, { isTwoWayAudioEnabled })
            .then(() => {
                dispatch(updatePerformerTwoWayAudioEnabled(isTwoWayAudioEnabled));
                dispatch(setSaveIndicatorSucceeded(type));
            })
            .catch(() => {
                dispatch(setSaveIndicatorFailed({ type, message: errorMessage }));
            });
    };

export const fetchNewbieStatus =
    (performerId: number): AppThunk =>
    (dispatch, getState) => {
        const { status } = getState().performerNewbieStatus;
        if (status !== 'initial') return;

        dispatch(fetchNewbieStatusStarted());
        performerService
            .getNewbieStatus(performerId)
            .then(({ newbieUntil, isNewbie }) => {
                dispatch(
                    fetchNewbieStatusSucceeded({
                        newbieUntil,
                        isNewbie,
                    }),
                );
            })
            .catch(() => {
                dispatch(fetchNewbieStatusFailed(errorMessage));
            });
    };
