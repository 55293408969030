/* tslint:disable */
/* eslint-disable */
/**
 * Account_Services API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Person
 */
export interface Person {
    /**
     *
     * @type {number}
     * @memberof Person
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Person
     */
    zodiacSign?: PersonZodiacSignEnum;
    /**
     *
     * @type {boolean}
     * @memberof Person
     */
    hasBirthday?: boolean;
    /**
     * ISO 3166-1 alpha-2 country code
     * @type {string}
     * @memberof Person
     */
    countryCode?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Person
     */
    isGiftSendingDisabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof PersonDetailsAllOf
     */
    viewType?: PersonViewTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonZodiacSignEnum {
    Capricorn = 'capricorn',
    Aquarius = 'aquarius',
    Pisces = 'pisces',
    Aries = 'aries',
    Taurus = 'taurus',
    Gemini = 'gemini',
    Cancer = 'cancer',
    Leo = 'leo',
    Virgo = 'virgo',
    Libra = 'libra',
    Scorpio = 'scorpio',
    Sagittarius = 'sagittarius',
}

/**
 * @export
 * @enum {string}
 */
export enum PersonViewTypeEnum {
    Single = 'single',
    Model = 'model',
    Studio = 'studio',
    Agency = 'agency',
}
