/* tslint:disable */
/* eslint-disable */
/**
 * My_Story_Service API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StoryItemMediaDimensions } from './story-item-media-dimensions';

/**
 * An upload token describing entity.
 * @export
 * @interface StoryItemMedia
 */
export interface StoryItemMedia {
    /**
     * Numeric unique id of the message.
     * @type {number}
     * @memberof StoryItemMedia
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StoryItemMedia
     */
    status?: StoryItemMediaStatusEnum;
    /**
     *
     * @type {StoryItemMediaDimensions}
     * @memberof StoryItemMedia
     */
    dimensions?: StoryItemMediaDimensions;
    /**
     * The url of the media.
     * @type {string}
     * @memberof StoryItemMedia
     */
    contentUri?: string;
    /**
     * The media key of the media.
     * @type {string}
     * @memberof StoryItemMedia
     */
    mediaKey?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum StoryItemMediaStatusEnum {
    Created = 'created',
    Stored = 'stored',
    Failed = 'failed',
}
