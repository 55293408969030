import Template from '../../lib/chaos/Template';

/**
 * Html5VideoPlayerTemplate
 * -----------------------
 *
 * Store Html5VideoPlayerTemplate Template
 * Set template config params
 *
 */

export default class Html5VideoPlayerTemplate extends Template {
	get properties() {
		return { ...super.properties,
			/** @var {string}   html string */
			tpl : '<video id="{id}" {attributes}><source src="{videoUrl}" type="video/mp4" /></video>' +
				'<div class="video-controls" draggable="false">' +
				'<span class="play-pause-btn play" >' +
				'<i class="msc-icon icon-play-solid"></i>' +
				'<i class="msc-icon icon-pause-solid"></i>' +
				'</span>' +
				'<div class="current-time">00:00</div>' +
				'<div class="progress-container">' +
				'<input class="progress-input" type="range" min="0" max="100" step="0.01" value="0">' +
				'<div class="progress-buffer"></div>' +
				'<div class="progress-current">' +
				'<div class="progress-handle"></div>' +
				'</div>' +
				'</div>' +
				'<div class="remaining-time">00:00</div>' +
				'<div class="volume-controls">' +
				'<div class="volume-control">' +
				'<div class="volume-container">' +
				'<input class="volume-input" type="range" min="0" max="1" step="0.01" value="0">' +
				'<div class="volume-current">' +
				'<div class="volume-handle"></div>' +
				'</div>' +
				'</div>' +
				'</div>' +
				'<span class="volume-btn" >' +
				'<i class="msc-icon icon-volume-high-solid"></i>' +
				'<i class="msc-icon icon-volume-low-solid"></i>' +
				'<i class="msc-icon icon-volume-mute-solid"></i>' +
				'</span>' +
				'</div>' +
				'<span class="video-fillscreen">' +
				'<i class="msc-icon icon-zoom-in-solid"></i>' +
				'<i class="msc-icon icon-zoom-out-solid"></i>' +
				'</span>' +
				'<span class="video-fullscreen">' +
				'<i class="msc-icon icon-screen-full-solid"></i>' +
				'<i class="msc-icon icon-screen-standard-solid"></i>' +
				'</span>' +
				'</div>'
		};
	}
	init() {
		super.init();
	}

	/**
	 * Sets params for template to render.
	 *
	 * @param {Object} data   Apply template params
	 *
	 * @return object;
	 */
	_setTemplateParams(data) {
		return data;
	}
}
