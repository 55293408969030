/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { ApiSuccess } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { BadRequestErrorResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { ChatDiscount } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { ChatDiscountAvailability } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { ErrorResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { GetBeMyFanReferralTokenResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { HasNotesResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { InlineResponse200 } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { InnerApiSuccess } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { InternalServerErrorResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { LanguageCode } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { NotFoundErrorResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { PerformerAggregatedOnlineTimeResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { PerformerNewbieStatusResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { PerformerPricingResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { PerformerUnreadCountsResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { PromoVideos } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { SharedLiveAvailablePerformersResponse } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { UNKNOWN_BASE_TYPE } from '../../../msc-api-gateway/proxy/models';
// @ts-ignore
import { UnauthorizedError } from '../../../msc-api-gateway/proxy/models';
/**
 * PerformersApi - axios parameter creator
 * @export
 */
export const PerformersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkChatDiscountAvailability: async (performerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('checkChatDiscountAvailability', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/chat-discount/availability`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, 'X-API-Key', configuration);

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatDiscount: async (
            performerId: number,
            uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('createChatDiscount', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/chat-discount`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, 'X-API-Key', configuration);

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeMyFanUsernameReferralToken: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getBeMyFanUsernameReferralToken', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getBeMyFanUsernameReferralToken', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getBeMyFanUsernameReferralToken', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/be-my-fan/referral-token`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['default:read'], configuration);

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the details of intercom performer contact for client intergration
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomPerformerContactDetails: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'performerId', performerId);
            // verify required parameter 'product' is not null or undefined
            assertParamExists('getIntercomPerformerContactDetails', 'product', product);
            const localVarPath = `/gw/engagement/v1/performers/{performerId}/intercom`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, 'X-API-Key', configuration);

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the aggregated online time of a performer between two dates
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerAggregatedOnlineTime: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            fromDate: string,
            toDate: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getPerformerAggregatedOnlineTime', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getPerformerAggregatedOnlineTime', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPerformerAggregatedOnlineTime', 'performerId', performerId);
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getPerformerAggregatedOnlineTime', 'fromDate', fromDate);
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getPerformerAggregatedOnlineTime', 'toDate', toDate);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/aggregated-online-time`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*', 'default:read'], configuration);

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] =
                    (fromDate as any) instanceof Date ? (fromDate as any).toISOString().substr(0, 10) : fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] =
                    (toDate as any) instanceof Date ? (toDate as any).toISOString().substr(0, 10) : toDate;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the newbie status for a performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerNewbieStatus: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getPerformerNewbieStatus', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getPerformerNewbieStatus', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPerformerNewbieStatus', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/newbie/status`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the pricing of performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerPricing: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getPerformerPricing', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getPerformerPricing', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPerformerPricing', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/pricing`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*', 'default:read'], configuration);

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get streaming status performers
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerStreamingStatus: async (performerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPerformerStreamingStatus', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/streaming-status`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary For the given performer get unread news, messages and my content channels creation flags
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {LanguageCode} [languageCode] Language code
         * @param {string} [countryCode] Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerUnreadCounts: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            languageCode?: LanguageCode,
            countryCode?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getPerformerUnreadCounts', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getPerformerUnreadCounts', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPerformerUnreadCounts', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/unread-counts`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get promo videos
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromoVideos: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getPromoVideos', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getPromoVideos', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getPromoVideos', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/promo-videos`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*', 'default:read'], configuration);

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of shared live available performers
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [displayName] The display name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedLiveAvailablePerformers: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            displayName?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getSharedLiveAvailablePerformers', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getSharedLiveAvailablePerformers', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getSharedLiveAvailablePerformers', 'performerId', performerId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/shared-live-available-performers`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', [], configuration);

            if (displayName !== undefined) {
                localVarQueryParameter['displayName'] = displayName;
            }

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} memberId Numerical member ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            memberId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet', 'xActorType', xActorType);
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet', 'performerId', performerId);
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet', 'memberId', memberId);
            const localVarPath = `/gw/inner/v2/performers/{performerId}/members/{memberId}/notes`
                .replace(`{${'performerId'}}`, encodeURIComponent(String(performerId)))
                .replace(`{${'memberId'}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['default:read'], configuration);

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PerformersApi - functional programming interface
 * @export
 */
export const PerformersApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PerformersApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkChatDiscountAvailability(
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDiscountAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkChatDiscountAvailability(
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChatDiscount(
            performerId: number,
            uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChatDiscount(
                performerId,
                uNKNOWNBASETYPE,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeMyFanUsernameReferralToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InnerApiSuccess & GetBeMyFanReferralTokenResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBeMyFanUsernameReferralToken(
                xActorId,
                xActorType,
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the details of intercom performer contact for client intergration
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntercomPerformerContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntercomPerformerContactDetails(
                xActorId,
                xActorType,
                performerId,
                product,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the newbie status for a performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformerNewbieStatus(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerApiSuccess & PerformerNewbieStatusResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformerNewbieStatus(
                xActorId,
                xActorType,
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the aggregated online time of a performer between two dates
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformerAggregatedOnlineTime(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            fromDate: string,
            toDate: string,
            options?: any,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InnerApiSuccess & PerformerAggregatedOnlineTimeResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformerAggregatedOnlineTime(
                xActorId,
                xActorType,
                performerId,
                fromDate,
                toDate,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the pricing of performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformerPricing(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerApiSuccess & PerformerPricingResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformerPricing(
                xActorId,
                xActorType,
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get streaming status performers
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformerStreamingStatus(
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccess & object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformerStreamingStatus(performerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary For the given performer get unread news, messages and my content channels creation flags
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {LanguageCode} [languageCode] Language code
         * @param {string} [countryCode] Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformerUnreadCounts(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            languageCode?: LanguageCode,
            countryCode?: string,
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerApiSuccess & PerformerUnreadCountsResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformerUnreadCounts(
                xActorId,
                xActorType,
                performerId,
                languageCode,
                countryCode,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get promo videos
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromoVideos(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerApiSuccess & PromoVideos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromoVideos(
                xActorId,
                xActorType,
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of shared live available performers
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [displayName] The display name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedLiveAvailablePerformers(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            displayName?: string,
            options?: any,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InnerApiSuccess & SharedLiveAvailablePerformersResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedLiveAvailablePerformers(
                xActorId,
                xActorType,
                performerId,
                displayName,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} memberId Numerical member ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            memberId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerApiSuccess & HasNotesResponse>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
                    xActorId,
                    xActorType,
                    performerId,
                    memberId,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PerformersApi - factory interface
 * @export
 */
export const PerformersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerformersApiFp(configuration);
    return {
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkChatDiscountAvailability(performerId: number, options?: any): AxiosPromise<ChatDiscountAvailability> {
            return localVarFp
                .checkChatDiscountAvailability(performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatDiscount(
            performerId: number,
            uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE,
            options?: any,
        ): AxiosPromise<ChatDiscount> {
            return localVarFp
                .createChatDiscount(performerId, uNKNOWNBASETYPE, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeMyFanUsernameReferralToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & GetBeMyFanReferralTokenResponse> {
            return localVarFp
                .getBeMyFanUsernameReferralToken(xActorId, xActorType, performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Provides the details of intercom performer contact for client intergration
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {'livejasmin' | 'oranum'} product The product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomPerformerContactDetails(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            product: 'livejasmin' | 'oranum',
            options?: any,
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .getIntercomPerformerContactDetails(xActorId, xActorType, performerId, product, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the newbie status for a performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerNewbieStatus(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & PerformerNewbieStatusResponse> {
            return localVarFp
                .getPerformerNewbieStatus(xActorId, xActorType, performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the aggregated online time of a performer between two dates
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerAggregatedOnlineTime(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            fromDate: string,
            toDate: string,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & PerformerAggregatedOnlineTimeResponse> {
            return localVarFp
                .getPerformerAggregatedOnlineTime(xActorId, xActorType, performerId, fromDate, toDate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the pricing of performer
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerPricing(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & PerformerPricingResponse> {
            return localVarFp
                .getPerformerPricing(xActorId, xActorType, performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get streaming status performers
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerStreamingStatus(performerId: number, options?: any): AxiosPromise<ApiSuccess & object> {
            return localVarFp
                .getPerformerStreamingStatus(performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary For the given performer get unread news, messages and my content channels creation flags
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {LanguageCode} [languageCode] Language code
         * @param {string} [countryCode] Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformerUnreadCounts(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            languageCode?: LanguageCode,
            countryCode?: string,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & PerformerUnreadCountsResponse> {
            return localVarFp
                .getPerformerUnreadCounts(xActorId, xActorType, performerId, languageCode, countryCode, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get promo videos
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromoVideos(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & PromoVideos> {
            return localVarFp
                .getPromoVideos(xActorId, xActorType, performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of shared live available performers
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [displayName] The display name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedLiveAvailablePerformers(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            displayName?: string,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & SharedLiveAvailablePerformersResponse> {
            return localVarFp
                .getSharedLiveAvailablePerformers(xActorId, xActorType, performerId, displayName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} memberId Numerical member ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            performerId: number,
            memberId: number,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & HasNotesResponse> {
            return localVarFp
                .gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
                    xActorId,
                    xActorType,
                    performerId,
                    memberId,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerformersApi - interface
 * @export
 * @interface PerformersApi
 */
export interface PerformersApiInterface {
    /**
     *
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    checkChatDiscountAvailability(performerId: number, options?: any): AxiosPromise<ChatDiscountAvailability>;

    /**
     *
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    createChatDiscount(
        performerId: number,
        uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE,
        options?: any,
    ): AxiosPromise<ChatDiscount>;

    /**
     *
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getBeMyFanUsernameReferralToken(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & GetBeMyFanReferralTokenResponse>;

    /**
     * Provides the details of intercom performer contact for client intergration
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getIntercomPerformerContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ): AxiosPromise<InlineResponse200>;

    /**
     *
     * @summary Get the newbie status for a performer
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPerformerNewbieStatus(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & PerformerNewbieStatusResponse>;

    /**
     *
     * @summary Get the aggregated online time of a performer between two dates
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} fromDate
     * @param {string} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPerformerAggregatedOnlineTime(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        fromDate: string,
        toDate: string,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & PerformerAggregatedOnlineTimeResponse>;

    /**
     *
     * @summary Get the pricing of performer
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPerformerPricing(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & PerformerPricingResponse>;

    /**
     *
     * @summary Get streaming status performers
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPerformerStreamingStatus(performerId: number, options?: any): AxiosPromise<ApiSuccess & object>;

    /**
     *
     * @summary For the given performer get unread news, messages and my content channels creation flags
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {LanguageCode} [languageCode] Language code
     * @param {string} [countryCode] Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPerformerUnreadCounts(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        languageCode?: LanguageCode,
        countryCode?: string,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & PerformerUnreadCountsResponse>;

    /**
     *
     * @summary Get promo videos
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getPromoVideos(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & PromoVideos>;

    /**
     *
     * @summary Get a list of shared live available performers
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [displayName] The display name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    getSharedLiveAvailablePerformers(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        displayName?: string,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & SharedLiveAvailablePerformersResponse>;

    /**
     *
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} memberId Numerical member ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApiInterface
     */
    gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        memberId: number,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & HasNotesResponse>;
}

/**
 * PerformersApi - object-oriented interface
 * @export
 * @class PerformersApi
 * @extends {BaseAPI}
 */
export class PerformersApi extends BaseAPI implements PerformersApiInterface {
    /**
     *
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public checkChatDiscountAvailability(performerId: number, options?: any) {
        return PerformersApiFp(this.configuration)
            .checkChatDiscountAvailability(performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public createChatDiscount(performerId: number, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any) {
        return PerformersApiFp(this.configuration)
            .createChatDiscount(performerId, uNKNOWNBASETYPE, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getBeMyFanUsernameReferralToken(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getBeMyFanUsernameReferralToken(xActorId, xActorType, performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the details of intercom performer contact for client intergration
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {'livejasmin' | 'oranum'} product The product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getIntercomPerformerContactDetails(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        product: 'livejasmin' | 'oranum',
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getIntercomPerformerContactDetails(xActorId, xActorType, performerId, product, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the newbie status for a performer
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPerformerNewbieStatus(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getPerformerNewbieStatus(xActorId, xActorType, performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the aggregated online time of a performer between two dates
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} fromDate
     * @param {string} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPerformerAggregatedOnlineTime(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        fromDate: string,
        toDate: string,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getPerformerAggregatedOnlineTime(xActorId, xActorType, performerId, fromDate, toDate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the pricing of performer
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPerformerPricing(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getPerformerPricing(xActorId, xActorType, performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get streaming status performers
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPerformerStreamingStatus(performerId: number, options?: any) {
        return PerformersApiFp(this.configuration)
            .getPerformerStreamingStatus(performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary For the given performer get unread news, messages and my content channels creation flags
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {LanguageCode} [languageCode] Language code
     * @param {string} [countryCode] Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPerformerUnreadCounts(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        languageCode?: LanguageCode,
        countryCode?: string,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getPerformerUnreadCounts(xActorId, xActorType, performerId, languageCode, countryCode, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get promo videos
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getPromoVideos(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getPromoVideos(xActorId, xActorType, performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of shared live available performers
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [displayName] The display name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public getSharedLiveAvailablePerformers(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        displayName?: string,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .getSharedLiveAvailablePerformers(xActorId, xActorType, performerId, displayName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} memberId Numerical member ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformersApi
     */
    public gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        performerId: number,
        memberId: number,
        options?: any,
    ) {
        return PerformersApiFp(this.configuration)
            .gwInnerV2PerformersPerformerIdMembersMemberIdNotesGet(xActorId, xActorType, performerId, memberId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
