/* eslint-disable max-statements */

import riot from 'riot';
import $ from 'jquery';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.us';

import { Broadcaster } from '../../lib/chaos/Broadcaster';
import PH from '../../lib/constant/Phrame';

import '../_ToggleOnOff/ToggleOnOff';

// name type value placeholder checked required error mixins autocomplete hint info module captcha captchaUrlTemplate maxlength hidden
riot.tag(
    'form-input',
    `<div class="form-input-container{ addonRight || addonLeft ? ' form-input-container--with-addons' : ''}{ opts.topLabel ? ' form-input-container--labeltop' : ''}">
		<label if="{ opts.topLabel }" for="{ getID() }" class="form-input-label--top">
			<span class="label-text">{ opts.topLabel }</span>
			<div ref="outside" class="form-input-additions form-input-additions--top { PH.cls.hide }">
				<form-mixin-error
					class="{ PH.cls.hide } form-input-additions-mixin"
					pt-position="right"
					pt-gravity="false">
				</form-mixin-error>
				<form-mixin-ok class="{ PH.cls.hide } form-input-additions-mixin"></form-mixin-ok>
			</div>
		</label>
		<form-mixin-captcha if="{ opts.captcha }" captcha="{ opts.captcha }"></form-mixin-captcha>
	
		<input ref="input" if="{ opts.type != 'textarea' && opts.type != 'toggle'}" type="{ opts.type || 'text' }" name="{ opts.name }" value="{ opts.value }" placeholder="{ opts.placeholder }" onblur="{ blur }" onchange="{ change }" onkeydown="{ keydown }" onkeyup="{ keyup }" oninput="{ handleinput }" onfocus="{ focus }" maxlength="{ opts.maxlength }" minlength="{ opts.minlength }" autocomplete="{ this.autocomplete }">
		<textarea ref="textarea" if="{ opts.type == 'textarea'}" name="{ opts.name }" onblur="{ blur }" onchange="{ change }" onkeydown="{ keydown }" onkeyup="{ keyup }" onfocus="{ focus }" maxlength="{ opts.maxlength }" minlength="{ opts.minlength }" placeholder="{ opts.placeholder }"></textarea>
		<div ref="toggle" if="{ opts.type == 'toggle'}" name="{ opts.name }" checked="{ opts.checked }" data-url="{ opts.dataUrl }" data-is="toggle-on-off"></div>
		<form-mixin-charcounter if="{ typeof opts.maxlength !== 'undefined' && (opts.type == 'textarea' || opts.showCounter) }"></form-mixin-charcounter>

		<div if="{ opts.type != 'checkbox' && opts.type != 'radio' && opts.type != 'select' }" ref="yieldArea" class="{ PH.cls.hide }">
			 <yield></yield>
		</div>

		<label if="{ !opts.topLabel && (opts.type == 'checkbox' || opts.type == 'radio') }" for="{ getID() }" class="msc-icon icon-check-solid"></label>
		<label if="{ !opts.topLabel && (opts.type == 'checkbox' || opts.type == 'radio') }" for="{ getID() }" class="{ PH.cls.hide }" ref="yieldLabel">
			<yield></yield>
		</label>

		<div if="{ opts.type == 'select' }" onclick="{ mobileTap }" ref="form-input-mobile-tap"></div>
		<form-mixin-datatable if="{ opts.type == 'select' }" class="{ PH.cls.hide }"><yield></yield></form-mixin-datatable>
		<div ref="inside" class="form-input-additions form-input-additions--inside">
			<form-mixin-loading ref="loading" class="{ PH.cls.hide }"></form-mixin-loading>
			<form-mixin-eye if="{ opts.mixins.includes('eye') }" type="{ opts.type }" ></form-mixin-eye>
			<form-mixin-select if="{ opts.type == 'select' }"></form-mixin-select>
		</div>
		<div ref="outside" class="form-input-additions form-input-additions--outside { PH.cls.hide }" >
			<form-mixin-ok if="{ !opts.topLabel }" class="{ PH.cls.hide }"></form-mixin-ok>
			<form-mixin-error if="{ !opts.topLabel }" class="{ PH.cls.hide }" data-pt-position="{ opts.dataPtPosition }" data-pt-gravity="{ opts.dataPtGravity }" data-pt-classes="{ opts.dataPtClasses }"></form-mixin-error>
			<form-mixin-info if="{ opts.info }" text="{ opts.info }" class="{ opts.name }-info"></form-mixin-info>
			<form-mixin-hint if="{ opts.hint }" text="{ opts.hint }"></form-mixin-hint>
		</div>
		<form-mixin-linked class="{ PH.cls.hide }"></form-mixin-linked>
		<form-mixin-highlight class="{ PH.cls.hide }"></form-mixin-highlight>
	</div>`,

    function (opts) {
        // Construct
        this.mixin('form');
        this.form.registerInput(this);
        this.hasError = false;
        this.PH = PH;
        opts.mixins = opts.mixins || [];
        this.autocomplete = 'off';

        // Init variables
        const C = this.CONST;
        const Rand = Math.floor(Math.random() * 100001);
        const ID = 'form-input-' + Rand;
        let ValidationEnabled = true;
        let Value = opts.value;

        // After DOM is ready
        this.on('mount', function () {
            //disable validation
            if (opts.noValidate) {
                this.disableValidation();
            }
            this.input = this.refs[opts.type] || this.refs.input;
            this.loading = this.refs.loading;
            if (opts.type === 'textarea') {
                this.input.value = this.input.innerHTML = opts.value;
            }
            this.input.id = this.getID();
            this.input.checked = typeof opts.checked !== 'undefined' && opts.checked !== false;
            if (typeof opts.required !== 'undefined') {
                this.getRow().addClass(PH.cls.input.required);
            }
            if (opts.phone) {
                try {
                    new Cleave(this.input, {
                        delimiter: '-',
                        phone: true,
                        phoneRegionCode: 'US',
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            if (this.input.removeAttribute) {
                this.input.removeAttribute('tabindex'); // To set default behavior for tabbing through input fields
            }
            this.setValue(this.input.value);
            this.cleanYields();
            this.handleAttributes();
        });

        /**
         * Adds or removes the element attributes according to specific conditions.
         */
        this.handleAttributes = () => {
            // show the input additions outside (notifications icons...)
            var outside = this.getLast().refs.outside;
            if (Array.isArray(outside)) {
                outside.forEach(function (item) {
                    item.classList.remove(PH.cls.hide);
                });
            } else if (outside) {
                outside.classList.remove(PH.cls.hide);
            }
            if (typeof opts.disabled !== 'undefined') {
                this.input.setAttribute('disabled', 'disabled');
            }
            if (typeof opts.hidden !== 'undefined') {
                this.root.classList.add(PH.cls.hide);
            }
            if (typeof opts.readonly !== 'undefined' || opts.type === 'select') {
                this.input.setAttribute('readonly', '');
            }
        };

        this.getID = () => ID;

        this.cleanYields = () => {
            let { yieldArea, yieldLabel } = this.refs;
            if (yieldArea && yieldArea.innerHTML.trim().length) {
                yieldArea.classList.remove(PH.cls.hide);
                yieldArea.classList.add(PH.cls.display.inlineBlock);
            }
            if (yieldLabel && yieldLabel.innerHTML.trim().length) {
                yieldLabel.classList.remove(PH.cls.hide);
                yieldLabel.classList.add(PH.cls.display.inlineBlock);
            }
        };

        /** Sets the input into a disabled state. */
        this.disable = () => {
            if (this.input.setAttribute) {
                this.input.setAttribute('disabled', 'disabled');
            }
        };

        /**
         * Sets the input into a enabled state.
         */
        this.enable = () => {
            if (this.input.removeAttribute) {
                this.input.removeAttribute('disabled');
            }
        };

        /**
         * Returns the value of the input
         * @return {string} Value of the input
         */
        this.getValue = () => (opts.type === 'checkbox' || opts.type === 'radio' ? this.input.checked : Value);

        /**
         * Sets value.
         * @param val {string} The value of the element.
         */
        this.setValue = (val) => {
            this.value = val;
            if (this.input) {
                this.input.value = Value = val;
            }
        };

        /**
         * Reset Input Value and triggers re-validation
         */
        this.reset = () => {
            if (opts.defaultValue === null || typeof opts.defaultValue === 'undefined') {
                opts.defaultValue = '';
            }

            if (this.input.checked) {
                this.input.checked = '';
            }

            if (opts.type === 'select') {
                var datatable = this.tags['form-mixin-datatable'];
                if (opts.defaultValue !== '') {
                    datatable.setSelectedByValue(opts.defaultValue);
                } else {
                    this.setValue(opts.defaultValue);
                    datatable.cleanSelection();
                }
            } else {
                this.setValue(opts.defaultValue);
            }

            this.trigger(C.RIOT_ELEMENT_HIDE_ERROR_EVENT)
                .trigger(C.RIOT_ELEMENT_HIDE_HIGHLITE_EVENT)
                .trigger(C.RIOT_ELEMENT_HIDE_OK_EVENT)
                .trigger(C.RIOT_ELEMENT_RESET_DONE);
        };

        /**
         * Returns true if every of the module's inputs are filled.
         * @return {Boolean} If module if filled completely or not.
         */
        this.isModuleFilled = () => {
            let isNotEmpty = (input) => input.getValue() !== '';
            let module = this.form.getInputStorage()[opts.module];

            return module.every(isNotEmpty);
        };

        /**
         * Returns the random number generated for this input.
         * @return {Number} Random number
         */
        this.getRand = () => Rand;

        /**
         * Return the form row element for this input.
         * @return {object} JQuery
         */
        this.getRow = () => $(this.root).closest(PH.cls.form.row.dot());

        /**
         * Returns the last element of this input's module.
         * @return {Object} Riot Element
         */
        this.getLast = () => {
            return typeof opts.useAsFirst !== 'undefined'
                ? this.form.getInputStorage()[opts.module][0]
                : this.form.getInputStorage()[opts.module].slice(-1).pop();
        };

        /**
         * Tells if is this field is the last in the module list.
         * @return {Boolean} is the last or not
         */
        this.isLast = () => this.getLast().opts.name === opts.name;

        /**
         * Shows the loading element.
         * @return {void}
         */
        this.showLoading = () => this.loading.show();

        /**
         * Hides the loading element.
         * @return {void}
         */
        this.hideLoading = () => this.loading.hide();

        /**
         * Shows the highlite on the input.
         * @return {void}
         */
        this.showHighlite = () => this.input.classList.add(PH.cls.input.state.error);

        /**
         * Hides the highlite on the input.
         * @return {void}
         */
        this.hideHighlite = () => this.input.classList.remove(PH.cls.input.state.error);

        /**
         * Hides the error tooltip on the input.
         * @return {void}
         */
        this.hideError = () => this.trigger(C.RIOT_ELEMENT_HIDE_ERROR_EVENT);

        /**
         * Reveals the form row.
         * @return {void}
         */
        this.showRow = () => this.getRow().slideDown();

        /**
         * Hides the form row.
         * @return {void}
         */
        this.hideRow = () => {
            this.getLast().trigger(C.RIOT_ELEMENT_HIDE_ERROR_EVENT);
            this.getRow().slideUp();
        };

        /**
         * Enables validation for this input.
         * @return {void}
         */
        this.enableValidation = () => (ValidationEnabled = true);

        /**
         * Enables validation for this input.
         * @return {void}
         */
        this.disableValidation = () => (ValidationEnabled = false);

        /**
         * Checks if validation is enabled for this input.
         * @return {Boolean} Is validation enabled or not
         */
        this.isValidationEnabled = () => ValidationEnabled;

        /**
         * Enables validation for the whole row.
         * @return {void}
         */
        this.enableRowValidation = () => {
            this.getRow()
                .find('[name]')
                .each((index, el) => {
                    el = this.form.getInput(el.name, 0);

                    if (el) {
                        el.enableValidation();
                    }
                });
        };

        /**
         * Disables validation for the whole row.
         * @return {void}
         */
        this.disableRowValidation = () => {
            this.getRow()
                .find('[name]')
                .each((index, el) => {
                    el = this.form.getInput(el.name, 0);

                    if (el) {
                        el.disableValidation();
                    }
                });
        };

        /**
         * On focus callback.
         * @param {object} ev Event Object
         * @return {boolean} true
         */
        this.focus = (ev) => {
            this.trigger('focus', ev);
            this.isFocused = true;
            this.getLast().trigger('mixin-hide-ok');
            setTimeout(() => this.trigger('hide-error'));
            return true;
        };

        /**
         * On change callback.
         * Radio and checkbox aren't have blur event. Use change to call blur.
         * @param {object} ev Event Object
         * @return {void}
         */
        this.change = (ev) => {
            Broadcaster.fireEvent(`change::${opts.name}`, this.input.value);

            if (ev && (ev.target.type === 'checkbox' || ev.target.type === 'radio')) {
                this.trigger('change', ev);
                this.blur(ev);
            }
        };

        /**
         * On blur callback.
         * @param {object} ev Event Object
         * @return {void}
         */
        this.blur = (ev) => {
            if (this.preventBlur) {
                this.input.focus();
                return;
            }
            this.trigger('blur', ev);
            if (this.isFocused) {
                this.trigger('change', ev);
            }
            this.isFocused = false;
            this.setValue(this.input.value);

            if (this.isModuleFilled() || ev.forceAll) {
                this.form.trigger(C.START_VALIDATION_EVENT, opts.module);
            }
        };

        /**
         * Keyup callback.
         * @param {object} ev Event Object
         * @return {void}
         */
        this.keyup = (ev) => {
            this.trigger(this.getValue() === '' ? 'empty-yes' : 'empty-no');
            this.trigger('keyup', ev);
        };

        /**
         * Keydown callback.
         * @param {object} ev Event Object
         * @return {boolean} If noEnter then checks for enter is pressed
         */
        this.keydown = (ev) => {
            if (opts.noEnter && ev.keyCode === 13) {
                ev.preventDefault();
            }

            this.trigger('keydown', ev);
        };

        /**
         * OnInput callback.
         * @param {object} ev Event Object
         */
        this.handleinput = (ev) => {
            if (opts.noInvalidChars) {
                ev.preventDefault();
                const pattern = new RegExp(opts.noInvalidChars, 'gi');
                this.setValue(this.input.value.replace(pattern, ''));
                return false;
            }
        };

        /**
         * Tap on click area if exists (datatable mixin)
         * @return {void}
         */
        this.mobileTap = () => {
            if (typeof opts.disabled === 'undefined') {
                let select = this.root.querySelector('select');
                let e = new MouseEvent('mousedown', { bubbles: true, cancelable: true, view: window });
                select.dispatchEvent(e);
                this.isFocused = true;
            }
        };

        this.showAjaxSuccess = () => {
            if (opts.ajaxfeedback === '1') {
                this.getLast().trigger('mixin-show-ok');
                this.resetFeedbacks();
            }
        };

        this.showAjaxError = ({ errorMessage }) => {
            if (opts.ajaxfeedback === '1') {
                this.trigger(C.RIOT_ELEMENT_SHOW_ERROR_EVENT, errorMessage);
                this.resetFeedbacks();
                this.showHighlite();
            }
        };

        this.submit = () => {
            if (opts.ajaxfeedback === '1') {
                this.resetFeedbacks();
                this.showLoading();
            }
        };

        this.resetFeedbacks = () => {
            this.hideError();
            this.hideHighlite();
            this.hideLoading();
        };

        /**
         * Bind events
         */
        this.on(C.RIOT_ELEMENT_SHOW_HIGHLITE_EVENT, this.showHighlite);
        this.on(C.RIOT_ELEMENT_HIDE_HIGHLITE_EVENT, this.hideHighlite);
        this.form.on(C.FORM_RESET_EVENT, this.reset.bind(this));
        this.form.on(C.FORM_DISABLE_EVENT, this.disable.bind(this));
        this.form.on(C.FORM_ENABLE_EVENT, this.enable.bind(this));
        this.form.on(C.FORM_AJAX_SUCCESS, this.showAjaxSuccess.bind(this));
        this.form.on(C.FORM_AJAX_ERROR, this.showAjaxError.bind(this));
        this.form.on('submit', this.submit.bind(this));
    },
);
