import React, { FC, ComponentType, useEffect, useMemo, ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { globalConfig } from 'configs/globalConfig';
import { fetchMe, fetchMyPerformer } from 'store/slices/Performer/Performer.thunks';
import useEmarsys from 'pages/Layouts/hooks/useEmarsys';
import { OverlayContextProvider } from 'contexts/OverlayContext';
import JawsProvider from 'contexts/JawsClientContext';
import { ThemeContextProvider } from 'contexts/ThemeContext';
import routes from 'configs/routes';
import { messengerTranslationService } from 'domains/Messenger/MessengerTranslation/MessengerTranslation.serviceFactory';
import useAppSelector from 'store/hooks/useAppSelector';
import useAppDispatch from 'store/hooks/useAppDispatch';
import {
    selectIsPromoPeriodActive,
    selectIsPromoPeriodRequestComplete,
} from 'store/slices/PromoPeriodTime/PromoPeriodTime.selectors';
import {
    selectIsExclusiveBadgeStatusActive,
    selectMyPerformerPersons,
    selectSelectedLanguage,
} from 'store/slices/Performer/Performer.selectors';
import useNewbies from 'pages/Dashboard/widgets/Newbies/hooks/useNewbies';
import PreventLandscapeOverlay from 'components/PreventLandscapeOverlay';
import sendGaEvent, { initGa, InitGaProps } from 'utils/ga';

interface Props {
    components: ChaosComponent[];
}

interface ChaosComponent {
    Component: ComponentType;
    componentRoot: Element;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    componentProps?: any;
    /* eslint-enable  @typescript-eslint/no-explicit-any */
}

const App: FC<Props> = ({ components }) => {
    const dispatch = useAppDispatch();
    const { me, myPerformer, errorMessage } = useAppSelector((state) => state.performer);
    const { isNewbie, isNewbieLoading } = useNewbies();
    const language = useAppSelector(selectSelectedLanguage);
    const persons = useAppSelector(selectMyPerformerPersons);
    const isPromoPeriodActive = useAppSelector(selectIsPromoPeriodActive);
    const isPromoPeriodRequestComplete = useAppSelector(selectIsPromoPeriodRequestComplete);
    const isExclusive = useAppSelector(selectIsExclusiveBadgeStatusActive);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const requiresAuth = !components.every((comp) => comp.componentProps?.noAuth);
    const { viewType, accountViewType, isLimitedAccess, applicationEnvironment } = globalConfig;
    const isPerformerView = viewType === 'performer' || viewType === 'single_performer';
    useEmarsys();

    const initGaProps: Partial<InitGaProps> = {
        viewType: accountViewType,
        language,
        isLimitedAccess,
        isTestAccount: (isPerformerView && myPerformer?.isTestAccount) || me?.studio?.isTestAccount || false,
        hasMultiplePerformers: (persons && persons?.length > 1) || false,
        authenticated: Boolean(me?.id),
        trafficType: applicationEnvironment,
        userId: isPerformerView ? myPerformer?.id : me?.studio.id,
    };

    useEffect(() => {
        initGa({
            eventType: 'config',
            ...initGaProps,
        } as InitGaProps);
    }, []);

    useEffect(() => {
        if (!viewType || !me) {
            return;
        }

        initGa({
            eventType: 'set',
            ...initGaProps,
        } as InitGaProps);
    }, [accountViewType, language, isLimitedAccess, persons, me, applicationEnvironment]);

    useEffect(() => {
        if (!isPerformerView || isNewbieLoading || !isPromoPeriodRequestComplete) {
            return;
        }

        sendGaEvent({
            category: 'dashboard page',
            label: 'success',
            action: 'login',
            customProperties: {
                isNewbie,
                isPromoPeriod: isPromoPeriodActive,
                isExclusive,
            },
        });
    }, [isPerformerView, isNewbieLoading, isPromoPeriodRequestComplete, isNewbie, isPromoPeriodActive, isExclusive]);

    useEffect(() => {
        if (components.length > 0 && !me && requiresAuth) {
            dispatch(fetchMe());
        }
    }, [dispatch, components, me]);

    useEffect(() => {
        messengerTranslationService.removeInvalidTranslations(language);
    }, [language]);

    useEffect(() => {
        const { activePerformerId, userType } = window.GlobalConfig;
        const isPerformer = userType === 'performer';
        const performerId = activePerformerId || (isPerformer ? me?.defaultPerformerId : undefined);

        if (!performerId || !me) return;

        if (components.length > 0 && !myPerformer) {
            dispatch(fetchMyPerformer(performerId));
        }
    }, [dispatch, components, myPerformer, me]);

    const reactComponents = useMemo(() => {
        const componentArray: ReactPortal[] = [];
        components.forEach((component) => {
            const { Component, componentRoot, componentProps } = component;
            const key = Component.name;
            const portalComponent = <Component key={key} {...componentProps} />;

            return componentArray.push(ReactDOM.createPortal(portalComponent, componentRoot));
        });
        return componentArray;
    }, [components]);

    if (!requiresAuth)
        return (
            <Sentry.ErrorBoundary>
                <ThemeContextProvider>
                    <PreventLandscapeOverlay />
                    {reactComponents.map((component) => component)}
                </ThemeContextProvider>
            </Sentry.ErrorBoundary>
        );

    if (errorMessage && shouldRedirectToErrorPage()) return goToErrorPage();
    if ((!me || !myPerformer) && isPerformerView) return null;
    if (!me && viewType === 'studio') return null;
    if (!myPerformer && components.length === 0 && viewType !== 'studio') return null;
    return (
        <Sentry.ErrorBoundary>
            <ThemeContextProvider>
                <OverlayContextProvider>
                    <JawsProvider performerId={myPerformer?.id}>
                        <PreventLandscapeOverlay />
                        {reactComponents.map((component) => component)}
                    </JawsProvider>
                </OverlayContextProvider>
            </ThemeContextProvider>
        </Sentry.ErrorBoundary>
    );
};

const shouldRedirectToErrorPage = () => {
    // reason why we need this on the sign up page can be found here: https://jira.doclerholding.com/browse/MSC-14575
    // we only need this until the registration uses legacy PHP
    const routesThatCanSkipErrorRedirect = [routes.signUpSelectAccountType];

    if (routesThatCanSkipErrorRedirect.includes(window.location.pathname)) return false;

    return true;
};

const goToErrorPage = () => {
    window.location.href = routes.error500;
    return null;
};

export default App;
