import { ApplicationConfigApi } from 'apiClients/msc-api-gateway/applicationconfig/client/application-config-api';
import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { ApplicationConfigDataMapper } from './ApplicationConfig.dataMapper';
import { ApplicationConfigService } from './ApplicationConfig.service';
import { ApplicationConfig } from './ApplicationConfig.types';

export interface ApplicationConfigServiceInterface {
    getApplicationConfig: () => Promise<ApplicationConfig>;
}

const applicationConfigApi = new ApplicationConfigApi(apiGwConfig, apiHost, axiosApiGw);
const mapper = new ApplicationConfigDataMapper(applicationConfigApi);

export const applicationConfigService = new ApplicationConfigService(mapper);
