import { ApplicationConfigServiceInterface } from './ApplicationConfig.serviceFactory';
import { ApplicationConfig } from './ApplicationConfig.types';

export interface ApplicationConfigDataMapperInterface {
    getApplicationConfig: () => Promise<ApplicationConfig>;
}

export class ApplicationConfigService implements ApplicationConfigServiceInterface {
    constructor(private mapper: ApplicationConfigDataMapperInterface) {}

    public getApplicationConfig(): Promise<ApplicationConfig> {
        return this.mapper.getApplicationConfig();
    }
}
