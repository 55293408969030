export type Orientation = 'landscape' | 'portrait';

export interface DeviceOrientation {
    isPortrait: boolean;
    isLandscape: boolean;
    orientation: Orientation;
}

export const getOrientation = (): DeviceOrientation => {
    let isLandscape: boolean;
    // Screen orientation is not yet fully supported, so rely on deprecated window.orientation in case of undefined
    if (window.screen?.orientation?.type) {
        isLandscape = window.screen?.orientation?.type.includes('landscape') && window.innerWidth > window.innerHeight;
    } else {
        isLandscape = Math.abs(window.orientation as number) === 90 && window.innerWidth > window.innerHeight;
    }

    return {
        isPortrait: !isLandscape,
        isLandscape,
        orientation: isLandscape ? 'landscape' : 'portrait',
    };
};
