import Ext from '../vendor/ExtCore';
import Chaos from './Chaos';
import { Broadcaster } from './Broadcaster';
import ChaosObject from './Object';

export default class Container extends ChaosObject {
	static EVENT_ADD_COMPONENT = 'add-component';
	static EVENT_REMOVE_COMPONENT = 'remove-component';
	static GLOBAL_COMPONENT_NAME = 'global';

	constructor(el, config) {
			super(el, config);
	}
}

Chaos.extend(Container, ChaosObject, {
	/** @var Object _components     collection of components in key-value pairs */
	_components : undefined,

	/**
	 * Initializes the component
	 **/
	init : function(el, config) {
		this._components = {};

		this.addEvents(
			Container.EVENT_ADD_COMPONENT,     // fired when a component was added to a container
			Container.EVENT_REMOVE_COMPONENT   // fired when a component was removed from a container
		);

		// make shortcut to getComponent
		this.getCmp = this.getComponent;

		// add config.item to _components
		if (config.items instanceof Object) {
			this.addComponents(config.items);
		}
		/* webpack-strip-block:removed */

		/** @TODO: Megoldani, hogy ne irjuk felul a global eventet, hanem ujat adjuk hozza  */
		if (config.globals instanceof Object) {
			for (var globalIndex in config.globals) {
				var globalItem = config.globals[globalIndex];
				this.addGlobalEvent(globalIndex, globalItem);
			}
		}

		Container.superclass.init.call(this, el, config);
	},

	/**
	 * Adds all global events to the container
	 */
	addGlobalEvent : function(eventName, eventHandler) {
		var configObj = {
			component : Broadcaster,
			listeners : {}
		};
		configObj.listeners[eventName] = eventHandler;
		this.addComponent(configObj, Container.GLOBAL_COMPONENT_NAME);
	},

	/**
	 * Adds more new components to the container. ComponentList should be an object,
	 * containing named components like this:
	 * {
	 *     "menu":  <menu descriptor @see addComponent>
	 *     "item2": <item2 descriptor @see addComponent>
	 *     ...
	 * }
	 *
	 * @param Object componentList      see above
	 *
	 * @return undefined
	 */
	addComponents : function(componentList) {
		/* webpack-strip-block:removed */

		for (var itemIndex in componentList) {
			var cmpObj = componentList[itemIndex];

			this.addComponent(cmpObj, itemIndex);
		}
	},

	/**
	 * adds a new Component to the controller
	 *
	 * @param object config    config object (descriptor) for the component. The structure should be like this:
	 *                             {
	 *                                 "component": ref to a component
	 *                                 "listeners": {
	 *                                     "item-click": string|function "onBookItemClick",
	 *                                 }
	 *                             }
	 *
	 * @param string id        Identifier of the component, should be unique in this controller.
	 *
	 * @return undefined
	 */
	addComponent : function(config, id) {
		/* webpack-strip-block:removed */

		// get id - it should be unique in this component
		var id = id || config.component.getId();

		/* webpack-strip-block:removed */

		this._components[id] = config.component;

		this[id] = config.component;

		this.fireEvent(Container.EVENT_ADD_COMPONENT, config.component, config.listeners, this);

		// bind event listeners to the event handlers, one by one
		if (typeof config.listeners === 'object') {
			// define scope, if not set, "this" will be used
			var scope = config.listeners.scope || this || window;

			for (var event in config.listeners) {
				var fn = config.listeners[event];

				// scope is not an event
				if (event != 'scope') {
					if (typeof fn === 'string') {
						/* webpack-strip-block:removed */
						config.component.on(event, this[fn], scope);
					}
					else {
						if (typeof this[fn] === 'undefined') {
							/* webpack-strip-block:removed */
							return;
						}
						config.component.on(event, fn, scope);
					}
				}
			}
		}
	},

	getComponent : function(id) {
		var cmp = this._components[id];
		/* webpack-strip-block:removed */
		return cmp;
	},

	removeComponent : function(name) {
		/* webpack-strip-block:removed */
		delete this[name];
		delete this._components[name];
	},

	bind : function() {
		Container.superclass.bind.call(this);
	},

	unbind : function() {
		Container.superclass.unbind.call(this);
	}
});
