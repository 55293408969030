import riot from 'riot';

import { observer } from './';

riot.tag('triggerer', false, function({
	triggerId,
	triggerEvent = 'click',
	preventDefault = true,
	stopPropagation = true
}) {
	this.isTriggered = false;
	this.observer = observer;
	this[`on${triggerEvent}`] = (ev) => {
		preventDefault && ev.preventDefault();
		stopPropagation && ev.stopPropagation();
		observer.trigger(triggerId, !this.isTriggered);
	};
	this.onTriggerable = (isTriggered = !this.isTriggered) => this.update({ isTriggered });
	this.on('mount', () => observer.on(triggerId, this.onTriggerable));
	this.on('unmount', () => observer.off(triggerId, this.onTriggerable));
}, 'onClick={onclick} triggered={isTriggered}'); // simply add here new events when needed