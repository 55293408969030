import { CreateMyStoryItemRequest } from 'apiClients/msc-api-gateway/mystoryservice/models';
import { CancelTokenSource } from 'axios';
import { JawsEvents, JawsNotification } from 'domains/Jaws/Jaws.types';
import { JawsServiceInterface } from '../Jaws/Jaws.service';
import {
    GetMyStoryUploadTokenEntity,
    GetMyStoryEntity,
    MyStoryTypes,
    MyStoryPrivacy,
    MyStoryItemEntity,
    UpdateMyStoryData,
} from './LJStories.types';

export interface LJStoriesDataMapperInterface {
    uploadStory: (
        file: File,
        privacy: MyStoryPrivacy,
        fileType: MyStoryTypes,
        performerId: number,
        onProgressUpdate: (percentage: number) => void,
        cancelTokenSource?: CancelTokenSource,
    ) => Promise<number>;
    getUploadToken: (performerId: number, params?: CreateMyStoryItemRequest) => Promise<GetMyStoryUploadTokenEntity>;
    getStoryById: (performerId: number, storyId: number) => Promise<MyStoryItemEntity>;
    getMyStory: (performerId: number) => Promise<GetMyStoryEntity>;
    getMyStoryItems: (performerId: number, storiesId?: number[]) => Promise<MyStoryItemEntity[]>;
    updateMyStory: (performerId: number, data: UpdateMyStoryData) => Promise<void>;
    deleteStoryItem: (performerId: number, storyItemId: number) => Promise<number>;
}

export class LJStoriesService {
    constructor(
        private ljStoriesMapper: LJStoriesDataMapperInterface,
        private jawsService: JawsServiceInterface,
    ) {}

    public uploadStory(
        file: File,
        privacy: MyStoryPrivacy,
        fileType: MyStoryTypes,
        performerId: number,
        onProgressUpdate: (percentage: number) => void,
        cancelTokenSource?: CancelTokenSource,
    ): Promise<number> {
        return this.ljStoriesMapper.uploadStory(
            file,
            privacy,
            fileType,
            performerId,
            onProgressUpdate,
            cancelTokenSource,
        );
    }

    public getUploadToken(performerId: number, params: CreateMyStoryItemRequest) {
        return this.ljStoriesMapper.getUploadToken(performerId, params);
    }

    public getStoryById(perfomerId: number, storyId: number) {
        return this.ljStoriesMapper.getStoryById(perfomerId, storyId);
    }

    public getMyStory(performerId: number) {
        return this.ljStoriesMapper.getMyStory(performerId);
    }

    public getMyStoryItems(performerId: number, storiesId?: number[]): Promise<MyStoryItemEntity[]> {
        return this.ljStoriesMapper.getMyStoryItems(performerId, storiesId);
    }

    public updateMyStory(performerId: number, data: UpdateMyStoryData) {
        return this.ljStoriesMapper.updateMyStory(performerId, data);
    }

    public deleteStoryItem(performerId: number, storyItemId: number): Promise<number> {
        return this.ljStoriesMapper.deleteStoryItem(performerId, storyItemId);
    }

    public subscribeToStoryActivation(cb: (notification: JawsNotification) => void) {
        this.jawsService.subscribeToEvent<JawsNotification>(JawsEvents.MY_STORY.MY_STORY_ITEM_ACTIVATED, cb);
    }

    public subscribeToStoryRejected(cb: (notification: JawsNotification) => void) {
        this.jawsService.subscribeToEvent<JawsNotification>(JawsEvents.MY_STORY.MY_STORY_ITEM_REJECTED, cb);
    }

    public subscribeToStoryStored(cb: (notification: JawsNotification) => void) {
        this.jawsService.subscribeToEvent<JawsNotification>(JawsEvents.MY_STORY.MY_STORY_ITEM_STORED, cb);
    }
}
