import Chaos from '../../lib/chaos/Chaos';

import Connection from '../../lib/chaos/Connection';
import AbstractSuggestionList from './AbstractList';
/**
 * Loads all tags from remote source according to keyword
 * @param {string} keyword
 * @param {function} done
 * @private
 */
export default class DynamicSuggestionList extends AbstractSuggestionList {
	get properties() {
		return { ...super.properties,
			/** @property {string}    Service routing for suggestion list ajax request */
			tagSuggestAjaxRoute : 'MusicLibrary/Search'
		};
	}
	_prepareList(keyword, done) {
		Connection.Ajax.request({
			url : Chaos.getUrl(this.tagSuggestAjaxRoute, this.config && this.config.routeParams || {}, {
				searchText : keyword,
				page       : this.currentPage
			}),
			scope : this,
			success(response) {
				var data = response.json.data;
				done(null, data.songs);
			},
			error : done
		});
	}
}
