import riot from 'riot';

import '../_Triggerable';
import styles from './DropDown.scss';

riot.tag('dropdown',
	`<triggerable trigger-id="{ opts.triggerId }">
		<yield />
	</triggerable>`,
	function({ triggerId }) {
		this.on('mount', () => {
			this.tags.triggerable.observer.on(triggerId, this.onTriggerable);
		});

		this.on('unmount', () => {
			try {
				this.tags.triggerable.observer.off(triggerId, this.onTriggerable);
			}
			catch (e) {
				/* webpack-strip-block:removed */
			}
			document.body.removeEventListener('click', this.onBodyClick);
		});

		this.onTriggerable = isTriggered => {
			// Prevents triggering events from improperly removed nodes
			if (!document.body.contains(this.root)) {
				return this.unmount();
			}
			document.body.click(); // Close the rest

			if (isTriggered) {
				document.body.addEventListener('click', this.onBodyClick);
			}
			else {
				document.body.removeEventListener('click', this.onBodyClick);
			}
		};

		this.onBodyClick = () => {
			this.tags.triggerable.observer.trigger(triggerId, false);
		};
	},
	`class="${styles.dropdown}"`
);