import $ from 'jquery';

import PH from '../../lib/constant/Phrame';
import BuildErrorMsg from './BuildErrorMsg';

import HTML5Uploader from './HTML5Uploader';

export default class HTML5UploaderController extends HTML5Uploader {
	get properties() {
		return { ...super.properties,
			/**
			 * Classes
			 *
			 * @type Object
			 */
			ui : {
				progress        : '.uploader5__progress',
				progressBar     : '.uploader5__progress-bar',
				progressBarBar  : '.uploader5__progress-bar .bar',
				text            : '.uploader5__progress-bar span:first',
				percentText     : '.uploader5__progress-bar span:last',
                analyzing       : '.uploader5__analyzing',
				button          : '.uploader5__button',
				buttonLink      : '.uploader5__button a:first',
				hideOnProgress  : '.uploader5__hide-on-progress',
                hideOnAnalyzing : '.uploader5__hide-on-analyzing',
			},

			cls : {
				hide                 : 'hide',
				tooltip              : 'uploader5__tooltip',
				noProgressTransition : 'noProgressTransition',
				dragOver             : 'uploader5--is-drag-over',
				isDragging           : 'uploader5--is-dragging'
			},

			_dragTimeout : undefined,
			_allowDragRemove : true
		};
	}

	constructor(el, config) {
		super(el, config);
		this._el = el.dom ? $(el.dom) : $(el);
		this._fetchElements();
	}

	/**
	 * Init method.
	 *
	 * @param {Object} el     Element of the Plugin. Its a File Input. Ext.Element because the environment is Ext.
	 * @param {Object} config Config object
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);
	}

	/**
	 * Tells if the uploader is visible
	 *
	 * @return boolean
	 */
	isVisible() {
		return this._el.is(':visible');
	}

	/**
	 * Fetches UI elements.
	 *
	 * @param global [boolean] If TRUE, checks items globally in the dom, not just inside the _el
	 * @private
	 */
	_fetchElements(global) {
		const parent = global ? $('body') : this._el;

		$.each(this.ui, function(key, value) {
			this.ui[key] = parent.find(value);
		}.bind(this));
		this.ui.body = $('body');
	}

	/**
	 * Toggles the whole uploader.
	 */
	toggleUploader() {
		this._el.toggleClass(this.cls.hide);
	}

	/**
	 * Toggles the upload button and progressbar visibility.
	 *
	 * @private
	 */
	_toggleProgressbar() {
		this.ui.button
			.add(this.ui.progress)
			.add(this.ui.hideOnProgress)
			.toggleClass(this.cls.hide);
	}

	hideProgressBar() {
		this.ui.button.removeClass(this.cls.hide);
		this.ui.progress.addClass(this.cls.hide)
	}

	showProgressBar() {
		this.ui.button.addClass(this.cls.hide);
		this.ui.progress.removeClass(this.cls.hide)
	}

    showAnalyzing() {
		this.ui.button
			.add(this.ui.analyzing)
			.add(this.ui.hideOnAnalyzing)
			.addClass(this.cls.hide);
        this.ui.analyzing.removeClass(this.cls.hide);
    }

    hideAnalyzing() {
        this.ui.button
            .add(this.ui.analyzing)
            .add(this.ui.hideOnAnalyzing)
            .removeClass(this.cls.hide);
        this.ui.analyzing.addClass(this.cls.hide);
    }

	/**
	 * Toggles the visible test on the progressbar.
	 *
	 * @private
	 */
	_toggleText() {
		if (!this._textOnce) {
			this.ui.text.toggleClass(this.cls.hide);
			this._textOnce = true;
		}
	}

	/**
	 * Prepare fail handler. In case of validation error.
	 *
	 * @private
	 */
	_onAfterPreparefail() {
		this._toggleProgressbar();
		this._showErrors();
	}

	getErrorTooltipConfig(defaultConfig) {
		return defaultConfig;
	}

	/**
	 * Shows error tooltip from generated messages or the text provided in the parameter.
	 *
	 * @param text [string]
	 * @private
	 */
	_showErrors(text) {
		this.ui.buttonLink.protipShow(this.getErrorTooltipConfig({
			title   : text || BuildErrorMsg(this._errors),
			classes : this.cls.tooltip + ' ' + PH.cls.protipCommonClose,
			gravity : true
		}));
	}

	/**
	 * Hides error tooltip.
	 */
	hideErrors() {
		this.ui.buttonLink.protipHide();
	}

	/**
	 * Callback when input field changes.
	 *
	 * @private
	 */
	_onChange() {
		this.hideErrors();
		this._toggleProgressbar();
		this.ui.progressBarBar.width(0);
	}

	/**
	 * Callback when upload has finished.
	 *
	 * @private
	 */
	_onAlways() {
		this._toggleProgressbar();
		this._textOnce = false;
		this._toggleText();
		this._textOnce = false;
		this.ui.progressBarBar.css('width', '').addClass(this.cls.noProgressTransition);

		if (this._errors.length) {
			this._showErrors();
		}
	}

	/**
	 * Callback fro upload progress.
	 *
	 * @param ev {Object} Event object
	 * @private
	 */
	_onProgress(ev) {
		var percent = parseInt(ev.loaded / ev.total * 100, 10).percent();
		this.ui.progressBarBar.width(percent).removeClass(this.cls.noProgressTransition);
		this.ui.percentText.text(percent);
	}

	/**
	 * On Token getting fail
	 * @private
	 */
	_onGettokensfail() {
		this._onAlways();
	}

	/**
	 * Callback when uploading has started.
	 *
	 * @private
	 */
	_onSend() {
		this._toggleText();
	}

	/**
	 * On drop handler
	 *
	 * @overrides
	 * @param ev
	 * @private
	 */
	_onDrop(ev) {
		super._onDrop(ev);
		if (this.options.dropZone) {
			this.options.dropZone.removeClass(this.cls.dragOver);
		}
	}

	/**
	 * Drag enter callback if Drag&Drop enabled
	 *
	 * @param ev {Object} Event object
	 * @private
	 */
	_onDragover(ev) {
		ev.originalEv.preventDefault();
		if (this.options.dropZone) {
			clearTimeout(this._dragLeaveTimeout);
			this.options.dropZone.addClass(this.cls.dragOver);
		}
	}

	/**
	 * Drag leave callback if Drag&Drop enabled
	 *
	 * @param ev {Object} Event object
	 * @private
	 */
	_onDragleave(ev) {
		ev.originalEv.preventDefault();
		if (this.options.dropZone) {
			this._dragLeaveTimeout = setTimeout(function () {
				this.options.dropZone.removeClass(this.cls.dragOver);
			}.bind(this), 50);
		}
	}

	/**
	 * Attaches events.
	 */
	bind() {
		super.bind();

		$(document).bind('dragover', function (ev) {
			ev.preventDefault();
			this.ui.body.addClass(this.cls.isDragging);
			clearTimeout(this._dragTimeout);
		}.bind(this));

		$(document).bind('drop dragleave', function (ev) {
			ev.preventDefault();
			this._dragTimeout = setTimeout(function() {
				this.ui.body.removeClass(this.cls.isDragging);
			}.bind(this), 50);
		}.bind(this));
	}

	/**
	 * Removes events.
	 */
	unbind() {
		super.unbind();

		$(document).off('drop dragover dragleave');
	}
}