import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { UIPromoPeriodTimes } from './PromoPeriodTime.types';

const _getStatus = (state: RootState) => state.promoPeriodTime.status;
const _getTimes = (state: RootState): UIPromoPeriodTimes | undefined => state.promoPeriodTime.times;

export const selectIsPromoPeriodRequestComplete = createSelector(
    [_getStatus],
    (status) => status === 'resolved' || status === 'rejected',
);
export const selectIsPromoPeriodActive = createSelector(
    [_getStatus, _getTimes],
    (status, times) => !!(status === 'resolved' && times?.free?.length && times?.private?.length),
);

export const selectPromoPeriodTimes = createSelector(
    (state: RootState) => state.promoPeriodTime,
    (promoPeriodTime): UIPromoPeriodTimes | undefined => promoPeriodTime.times,
);
