import React from 'react';
import _ from '@dh-fe/translate';
import { isMobileOnly } from 'utils/device-detect';
import { useOrientation } from 'hooks/useOrientation';
import Icon, { IconList } from 'components/Icon';
import styles from './PreventLandscapeOverlay.module.scss';

const PreventLandscapeOverlay = () => {
    const { isLandscape } = useOrientation();
    const isMobileLandscape = isMobileOnly() && isLandscape;

    if (isMobileLandscape) {
        return (
            <div className={styles.root}>
                <Icon className={styles.icon} icon={IconList.mobileRotationSolid} size="4rem" />
                <h1>{_`Rotate your screen`}</h1>
                <p>{_`Please rotate back your screen. This site supports only portrait mode.`}</p>
            </div>
        );
    }

    return null;
};

export default PreventLandscapeOverlay;
