/* tslint:disable */
/* eslint-disable */
/**
 * Account_Services API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { BadRequestErrorResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { BadRequestWithSpamDetectedErrorResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { ErrorResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { NotFoundErrorResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PatchVideoCallRequest } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerDetailsListPagedResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerDetailsListResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerDetailsResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerPatchOperation } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerPrivateChatLog } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerPromoPeriodTimeDetails } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerRating } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PerformerStatusesEnum } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { PreconditionFailedResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { StreamPerformerResponse } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { UpdatePerformerPropertiesRequest } from '../../../msc-api-gateway/accountservices/models';
// @ts-ignore
import { UserDetailsResponse } from '../../../msc-api-gateway/accountservices/models';
/**
 * AccountServicesApi - axios parameter creator
 * @export
 */
export const AccountServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
         * @summary Get the current User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1MeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:read'], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns a list of performers who has the same userId. Returns a single performer if screenName filter provided (exact match)
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {string} [filterDisplayName] Performer Display Name
         * @param {string} [filterPerformerScreenNameLike] Part of performer screen name.
         * @param {string} [filterPerformerDisplayNameLike] Part of performer display name.
         * @param {Array<PerformerStatusesEnum>} [filterStatuses] An array of the statuses for the filter.
         * @param {number} [pageLimit] The maximum number of result to get.
         * @param {string} [pageCursor] The cursor key of the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersGet: async (
            filterScreenName?: string,
            filterDisplayName?: string,
            filterPerformerScreenNameLike?: string,
            filterPerformerDisplayNameLike?: string,
            filterStatuses?: Array<PerformerStatusesEnum>,
            pageLimit?: number,
            pageCursor?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/me/performers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:read'], configuration);

            if (filterScreenName !== undefined) {
                localVarQueryParameter['filter[screenName]'] = filterScreenName;
            }

            if (filterDisplayName !== undefined) {
                localVarQueryParameter['filter[displayName]'] = filterDisplayName;
            }

            if (filterPerformerScreenNameLike !== undefined) {
                localVarQueryParameter['filter[performerScreenNameLike]'] = filterPerformerScreenNameLike;
            }

            if (filterPerformerDisplayNameLike !== undefined) {
                localVarQueryParameter['filter[performerDisplayNameLike]'] = filterPerformerDisplayNameLike;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter[statuses][]'] = filterStatuses;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page[cursor]'] = pageCursor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns performer private chat logs between the performer and the member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} memberNick Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdChatLogsGet: async (
            performerId: number,
            memberNick: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdChatLogsGet', 'performerId', performerId);
            // verify required parameter 'memberNick' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdChatLogsGet', 'memberNick', memberNick);
            const localVarPath = `/v1/me/performers/{performerId}/chat-logs`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['chat-logs:read'], configuration);

            if (memberNick !== undefined) {
                localVarQueryParameter['memberNick'] = memberNick;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the given performer, but only if the given user is an actor in that performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdGet: async (
            performerId: number,
            extendWithNewbieStatus?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdGet', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:read'], configuration);

            if (extendWithNewbieStatus !== undefined) {
                localVarQueryParameter['extendWithNewbieStatus'] = extendWithNewbieStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update a performer: used to block/unblock a member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {PerformerPatchOperation} [performerPatchOperation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPatch: async (
            performerId: number,
            extendWithNewbieStatus?: string,
            performerPatchOperation?: PerformerPatchOperation,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdPatch', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['blacklist:write'], configuration);

            if (extendWithNewbieStatus !== undefined) {
                localVarQueryParameter['extendWithNewbieStatus'] = extendWithNewbieStatus;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                performerPatchOperation,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns details about promo period time of performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPromoPeriodTimeGet: async (
            performerId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdPromoPeriodTimeGet', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/promo-period-time`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:read'], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Each **key** must be the **name** of a property.  Each **value** must be the **value** of the property.  Possible keys:   * isBirthdayVisible     * used to set birthday visibility   * liveShowChargeAmount     * used to set price of live show   * cam2camChargeAmount     * used to set price of cam2cam   * audioChargeRateModifier     * used to set price of two-way audio   * videoVoiceCallPrice     * used to set price of video call   * memberMessagePrice     * used to set price of member message   * countryBan     * used to ban countries/states of member or guest   * aboutMe     * used to set the about me section   * nextOnlineSession     * used to set the next online session for the performer   * isSubscriptionEnabled     * used to set the performer profile to subscription based (must be true)   * subscriptionPackageId     * used to set the subscription package   * isTwoWayAudioEnabled     * used to toggle two way audio   * audioSpokenLanguage     * used to set spoken languages   * isTwoWayAudioEnabled     * used to toggle two way audio   * referralConsentStatus     * used to set the referral consent status   * isMobileLiveDisabled     * used to turn off/on mobile live   * isReadReceiptsEnabled     * used to turn off/on read receipts
         * @summary Update performer properties
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdatePerformerPropertiesRequest} [updatePerformerPropertiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPropertiesPatch: async (
            performerId: number,
            updatePerformerPropertiesRequest?: UpdatePerformerPropertiesRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdPropertiesPatch', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/properties`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:write'], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updatePerformerPropertiesRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns performer rating. If memberNick passed returns member rating as well.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [memberNick] Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdRatingGet: async (
            performerId: number,
            memberNick?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdRatingGet', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/rating`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:read'], configuration);

            if (memberNick !== undefined) {
                localVarQueryParameter['memberNick'] = memberNick;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set Video Call availability status for the given performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {PatchVideoCallRequest} [patchVideoCallRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdVideoCallPatch: async (
            performerId: number,
            patchVideoCallRequest?: PatchVideoCallRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdVideoCallPatch', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/video-call`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['profile:write'], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchVideoCallRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Searches for a performer based on the provided filter
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformersGet: async (filterScreenName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/performers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterScreenName !== undefined) {
                localVarQueryParameter['filter[screenName]'] = filterScreenName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the given performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformersPerformerIdGet: async (performerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1PerformersPerformerIdGet', 'performerId', performerId);
            const localVarPath = `/v1/performers/{performerId}`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AccountServicesApi - functional programming interface
 * @export
 */
export const AccountServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountServicesApiAxiosParamCreator(configuration);
    return {
        /**
         * Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
         * @summary Get the current User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1MeGet(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Returns a list of performers who has the same userId. Returns a single performer if screenName filter provided (exact match)
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {string} [filterDisplayName] Performer Display Name
         * @param {string} [filterPerformerScreenNameLike] Part of performer screen name.
         * @param {string} [filterPerformerDisplayNameLike] Part of performer display name.
         * @param {Array<PerformerStatusesEnum>} [filterStatuses] An array of the statuses for the filter.
         * @param {number} [pageLimit] The maximum number of result to get.
         * @param {string} [pageCursor] The cursor key of the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersGet(
            filterScreenName?: string,
            filterDisplayName?: string,
            filterPerformerScreenNameLike?: string,
            filterPerformerDisplayNameLike?: string,
            filterStatuses?: Array<PerformerStatusesEnum>,
            pageLimit?: number,
            pageCursor?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformerDetailsListPagedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersGet(
                filterScreenName,
                filterDisplayName,
                filterPerformerScreenNameLike,
                filterPerformerDisplayNameLike,
                filterStatuses,
                pageLimit,
                pageCursor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Returns performer private chat logs between the performer and the member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} memberNick Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdChatLogsGet(
            performerId: number,
            memberNick: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformerPrivateChatLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdChatLogsGet(
                performerId,
                memberNick,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the given performer, but only if the given user is an actor in that performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdGet(
            performerId: number,
            extendWithNewbieStatus?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamPerformerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdGet(
                performerId,
                extendWithNewbieStatus,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update a performer: used to block/unblock a member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {PerformerPatchOperation} [performerPatchOperation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdPatch(
            performerId: number,
            extendWithNewbieStatus?: string,
            performerPatchOperation?: PerformerPatchOperation,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdPatch(
                performerId,
                extendWithNewbieStatus,
                performerPatchOperation,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Returns details about promo period time of performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdPromoPeriodTimeGet(
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformerPromoPeriodTimeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdPromoPeriodTimeGet(
                performerId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Each **key** must be the **name** of a property.  Each **value** must be the **value** of the property.  Possible keys:   * isBirthdayVisible     * used to set birthday visibility   * liveShowChargeAmount     * used to set price of live show   * cam2camChargeAmount     * used to set price of cam2cam   * audioChargeRateModifier     * used to set price of two-way audio   * videoVoiceCallPrice     * used to set price of video call   * memberMessagePrice     * used to set price of member message   * countryBan     * used to ban countries/states of member or guest   * aboutMe     * used to set the about me section   * nextOnlineSession     * used to set the next online session for the performer   * isSubscriptionEnabled     * used to set the performer profile to subscription based (must be true)   * subscriptionPackageId     * used to set the subscription package   * isTwoWayAudioEnabled     * used to toggle two way audio   * audioSpokenLanguage     * used to set spoken languages   * isTwoWayAudioEnabled     * used to toggle two way audio   * referralConsentStatus     * used to set the referral consent status   * isMobileLiveDisabled     * used to turn off/on mobile live   * isReadReceiptsEnabled     * used to turn off/on read receipts
         * @summary Update performer properties
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdatePerformerPropertiesRequest} [updatePerformerPropertiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdPropertiesPatch(
            performerId: number,
            updatePerformerPropertiesRequest?: UpdatePerformerPropertiesRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdPropertiesPatch(
                performerId,
                updatePerformerPropertiesRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Returns performer rating. If memberNick passed returns member rating as well.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [memberNick] Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdRatingGet(
            performerId: number,
            memberNick?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformerRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdRatingGet(
                performerId,
                memberNick,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Set Video Call availability status for the given performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {PatchVideoCallRequest} [patchVideoCallRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdVideoCallPatch(
            performerId: number,
            patchVideoCallRequest?: PatchVideoCallRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdVideoCallPatch(
                performerId,
                patchVideoCallRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Searches for a performer based on the provided filter
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PerformersGet(
            filterScreenName?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformerDetailsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PerformersGet(filterScreenName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the given performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PerformersPerformerIdGet(
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformerDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PerformersPerformerIdGet(performerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AccountServicesApi - factory interface
 * @export
 */
export const AccountServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = AccountServicesApiFp(configuration);
    return {
        /**
         * Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
         * @summary Get the current User
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1MeGet(options?: any): AxiosPromise<UserDetailsResponse> {
            return localVarFp.v1MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns a list of performers who has the same userId. Returns a single performer if screenName filter provided (exact match)
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {string} [filterDisplayName] Performer Display Name
         * @param {string} [filterPerformerScreenNameLike] Part of performer screen name.
         * @param {string} [filterPerformerDisplayNameLike] Part of performer display name.
         * @param {Array<PerformerStatusesEnum>} [filterStatuses] An array of the statuses for the filter.
         * @param {number} [pageLimit] The maximum number of result to get.
         * @param {string} [pageCursor] The cursor key of the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersGet(
            filterScreenName?: string,
            filterDisplayName?: string,
            filterPerformerScreenNameLike?: string,
            filterPerformerDisplayNameLike?: string,
            filterStatuses?: Array<PerformerStatusesEnum>,
            pageLimit?: number,
            pageCursor?: string,
            options?: any,
        ): AxiosPromise<PerformerDetailsListPagedResponse> {
            return localVarFp
                .v1MePerformersGet(
                    filterScreenName,
                    filterDisplayName,
                    filterPerformerScreenNameLike,
                    filterPerformerDisplayNameLike,
                    filterStatuses,
                    pageLimit,
                    pageCursor,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns performer private chat logs between the performer and the member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} memberNick Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdChatLogsGet(
            performerId: number,
            memberNick: string,
            options?: any,
        ): AxiosPromise<Array<PerformerPrivateChatLog>> {
            return localVarFp
                .v1MePerformersPerformerIdChatLogsGet(performerId, memberNick, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the given performer, but only if the given user is an actor in that performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdGet(
            performerId: number,
            extendWithNewbieStatus?: string,
            options?: any,
        ): AxiosPromise<StreamPerformerResponse> {
            return localVarFp
                .v1MePerformersPerformerIdGet(performerId, extendWithNewbieStatus, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update a performer: used to block/unblock a member.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
         * @param {PerformerPatchOperation} [performerPatchOperation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPatch(
            performerId: number,
            extendWithNewbieStatus?: string,
            performerPatchOperation?: PerformerPatchOperation,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .v1MePerformersPerformerIdPatch(performerId, extendWithNewbieStatus, performerPatchOperation, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns details about promo period time of performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPromoPeriodTimeGet(
            performerId: number,
            options?: any,
        ): AxiosPromise<PerformerPromoPeriodTimeDetails> {
            return localVarFp
                .v1MePerformersPerformerIdPromoPeriodTimeGet(performerId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Each **key** must be the **name** of a property.  Each **value** must be the **value** of the property.  Possible keys:   * isBirthdayVisible     * used to set birthday visibility   * liveShowChargeAmount     * used to set price of live show   * cam2camChargeAmount     * used to set price of cam2cam   * audioChargeRateModifier     * used to set price of two-way audio   * videoVoiceCallPrice     * used to set price of video call   * memberMessagePrice     * used to set price of member message   * countryBan     * used to ban countries/states of member or guest   * aboutMe     * used to set the about me section   * nextOnlineSession     * used to set the next online session for the performer   * isSubscriptionEnabled     * used to set the performer profile to subscription based (must be true)   * subscriptionPackageId     * used to set the subscription package   * isTwoWayAudioEnabled     * used to toggle two way audio   * audioSpokenLanguage     * used to set spoken languages   * isTwoWayAudioEnabled     * used to toggle two way audio   * referralConsentStatus     * used to set the referral consent status   * isMobileLiveDisabled     * used to turn off/on mobile live   * isReadReceiptsEnabled     * used to turn off/on read receipts
         * @summary Update performer properties
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdatePerformerPropertiesRequest} [updatePerformerPropertiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdPropertiesPatch(
            performerId: number,
            updatePerformerPropertiesRequest?: UpdatePerformerPropertiesRequest,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .v1MePerformersPerformerIdPropertiesPatch(performerId, updatePerformerPropertiesRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns performer rating. If memberNick passed returns member rating as well.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [memberNick] Nickname of Member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdRatingGet(
            performerId: number,
            memberNick?: string,
            options?: any,
        ): AxiosPromise<PerformerRating> {
            return localVarFp
                .v1MePerformersPerformerIdRatingGet(performerId, memberNick, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set Video Call availability status for the given performer.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {PatchVideoCallRequest} [patchVideoCallRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdVideoCallPatch(
            performerId: number,
            patchVideoCallRequest?: PatchVideoCallRequest,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .v1MePerformersPerformerIdVideoCallPatch(performerId, patchVideoCallRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Searches for a performer based on the provided filter
         * @param {string} [filterScreenName] Performer Screen Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformersGet(filterScreenName?: string, options?: any): AxiosPromise<PerformerDetailsListResponse> {
            return localVarFp.v1PerformersGet(filterScreenName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the given performer
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformersPerformerIdGet(performerId: number, options?: any): AxiosPromise<PerformerDetailsResponse> {
            return localVarFp
                .v1PerformersPerformerIdGet(performerId, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountServicesApi - interface
 * @export
 * @interface AccountServicesApi
 */
export interface AccountServicesApiInterface {
    /**
     * Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
     * @summary Get the current User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MeGet(options?: any): AxiosPromise<UserDetailsResponse>;

    /**
     *
     * @summary Returns a list of performers who has the same userId. Returns a single performer if screenName filter provided (exact match)
     * @param {string} [filterScreenName] Performer Screen Name
     * @param {string} [filterDisplayName] Performer Display Name
     * @param {string} [filterPerformerScreenNameLike] Part of performer screen name.
     * @param {string} [filterPerformerDisplayNameLike] Part of performer display name.
     * @param {Array<PerformerStatusesEnum>} [filterStatuses] An array of the statuses for the filter.
     * @param {number} [pageLimit] The maximum number of result to get.
     * @param {string} [pageCursor] The cursor key of the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersGet(
        filterScreenName?: string,
        filterDisplayName?: string,
        filterPerformerScreenNameLike?: string,
        filterPerformerDisplayNameLike?: string,
        filterStatuses?: Array<PerformerStatusesEnum>,
        pageLimit?: number,
        pageCursor?: string,
        options?: any,
    ): AxiosPromise<PerformerDetailsListPagedResponse>;

    /**
     *
     * @summary Returns performer private chat logs between the performer and the member.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} memberNick Nickname of Member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdChatLogsGet(
        performerId: number,
        memberNick: string,
        options?: any,
    ): AxiosPromise<Array<PerformerPrivateChatLog>>;

    /**
     *
     * @summary Get the given performer, but only if the given user is an actor in that performer
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdGet(
        performerId: number,
        extendWithNewbieStatus?: string,
        options?: any,
    ): AxiosPromise<StreamPerformerResponse>;

    /**
     *
     * @summary Update a performer: used to block/unblock a member.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
     * @param {PerformerPatchOperation} [performerPatchOperation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdPatch(
        performerId: number,
        extendWithNewbieStatus?: string,
        performerPatchOperation?: PerformerPatchOperation,
        options?: any,
    ): AxiosPromise<void>;

    /**
     *
     * @summary Returns details about promo period time of performer.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdPromoPeriodTimeGet(
        performerId: number,
        options?: any,
    ): AxiosPromise<PerformerPromoPeriodTimeDetails>;

    /**
     * Each **key** must be the **name** of a property.  Each **value** must be the **value** of the property.  Possible keys:   * isBirthdayVisible     * used to set birthday visibility   * liveShowChargeAmount     * used to set price of live show   * cam2camChargeAmount     * used to set price of cam2cam   * audioChargeRateModifier     * used to set price of two-way audio   * videoVoiceCallPrice     * used to set price of video call   * memberMessagePrice     * used to set price of member message   * countryBan     * used to ban countries/states of member or guest   * aboutMe     * used to set the about me section   * nextOnlineSession     * used to set the next online session for the performer   * isSubscriptionEnabled     * used to set the performer profile to subscription based (must be true)   * subscriptionPackageId     * used to set the subscription package   * isTwoWayAudioEnabled     * used to toggle two way audio   * audioSpokenLanguage     * used to set spoken languages   * isTwoWayAudioEnabled     * used to toggle two way audio   * referralConsentStatus     * used to set the referral consent status   * isMobileLiveDisabled     * used to turn off/on mobile live   * isReadReceiptsEnabled     * used to turn off/on read receipts
     * @summary Update performer properties
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UpdatePerformerPropertiesRequest} [updatePerformerPropertiesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdPropertiesPatch(
        performerId: number,
        updatePerformerPropertiesRequest?: UpdatePerformerPropertiesRequest,
        options?: any,
    ): AxiosPromise<void>;

    /**
     *
     * @summary Returns performer rating. If memberNick passed returns member rating as well.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [memberNick] Nickname of Member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdRatingGet(
        performerId: number,
        memberNick?: string,
        options?: any,
    ): AxiosPromise<PerformerRating>;

    /**
     *
     * @summary Set Video Call availability status for the given performer.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {PatchVideoCallRequest} [patchVideoCallRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1MePerformersPerformerIdVideoCallPatch(
        performerId: number,
        patchVideoCallRequest?: PatchVideoCallRequest,
        options?: any,
    ): AxiosPromise<void>;

    /**
     *
     * @summary Searches for a performer based on the provided filter
     * @param {string} [filterScreenName] Performer Screen Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1PerformersGet(filterScreenName?: string, options?: any): AxiosPromise<PerformerDetailsListResponse>;

    /**
     *
     * @summary Get the given performer
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApiInterface
     */
    v1PerformersPerformerIdGet(performerId: number, options?: any): AxiosPromise<PerformerDetailsResponse>;
}

/**
 * AccountServicesApi - object-oriented interface
 * @export
 * @class AccountServicesApi
 * @extends {BaseAPI}
 */
export class AccountServicesApi extends BaseAPI implements AccountServicesApiInterface {
    /**
     * Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
     * @summary Get the current User
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MeGet(options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1MeGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns a list of performers who has the same userId. Returns a single performer if screenName filter provided (exact match)
     * @param {string} [filterScreenName] Performer Screen Name
     * @param {string} [filterDisplayName] Performer Display Name
     * @param {string} [filterPerformerScreenNameLike] Part of performer screen name.
     * @param {string} [filterPerformerDisplayNameLike] Part of performer display name.
     * @param {Array<PerformerStatusesEnum>} [filterStatuses] An array of the statuses for the filter.
     * @param {number} [pageLimit] The maximum number of result to get.
     * @param {string} [pageCursor] The cursor key of the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersGet(
        filterScreenName?: string,
        filterDisplayName?: string,
        filterPerformerScreenNameLike?: string,
        filterPerformerDisplayNameLike?: string,
        filterStatuses?: Array<PerformerStatusesEnum>,
        pageLimit?: number,
        pageCursor?: string,
        options?: any,
    ) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersGet(
                filterScreenName,
                filterDisplayName,
                filterPerformerScreenNameLike,
                filterPerformerDisplayNameLike,
                filterStatuses,
                pageLimit,
                pageCursor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns performer private chat logs between the performer and the member.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} memberNick Nickname of Member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdChatLogsGet(performerId: number, memberNick: string, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdChatLogsGet(performerId, memberNick, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the given performer, but only if the given user is an actor in that performer
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdGet(performerId: number, extendWithNewbieStatus?: string, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdGet(performerId, extendWithNewbieStatus, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update a performer: used to block/unblock a member.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [extendWithNewbieStatus] Extend the response with performer newbie status
     * @param {PerformerPatchOperation} [performerPatchOperation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdPatch(
        performerId: number,
        extendWithNewbieStatus?: string,
        performerPatchOperation?: PerformerPatchOperation,
        options?: any,
    ) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdPatch(performerId, extendWithNewbieStatus, performerPatchOperation, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns details about promo period time of performer.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdPromoPeriodTimeGet(performerId: number, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdPromoPeriodTimeGet(performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Each **key** must be the **name** of a property.  Each **value** must be the **value** of the property.  Possible keys:   * isBirthdayVisible     * used to set birthday visibility   * liveShowChargeAmount     * used to set price of live show   * cam2camChargeAmount     * used to set price of cam2cam   * audioChargeRateModifier     * used to set price of two-way audio   * videoVoiceCallPrice     * used to set price of video call   * memberMessagePrice     * used to set price of member message   * countryBan     * used to ban countries/states of member or guest   * aboutMe     * used to set the about me section   * nextOnlineSession     * used to set the next online session for the performer   * isSubscriptionEnabled     * used to set the performer profile to subscription based (must be true)   * subscriptionPackageId     * used to set the subscription package   * isTwoWayAudioEnabled     * used to toggle two way audio   * audioSpokenLanguage     * used to set spoken languages   * isTwoWayAudioEnabled     * used to toggle two way audio   * referralConsentStatus     * used to set the referral consent status   * isMobileLiveDisabled     * used to turn off/on mobile live   * isReadReceiptsEnabled     * used to turn off/on read receipts
     * @summary Update performer properties
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UpdatePerformerPropertiesRequest} [updatePerformerPropertiesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdPropertiesPatch(
        performerId: number,
        updatePerformerPropertiesRequest?: UpdatePerformerPropertiesRequest,
        options?: any,
    ) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdPropertiesPatch(performerId, updatePerformerPropertiesRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns performer rating. If memberNick passed returns member rating as well.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [memberNick] Nickname of Member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdRatingGet(performerId: number, memberNick?: string, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdRatingGet(performerId, memberNick, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Set Video Call availability status for the given performer.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {PatchVideoCallRequest} [patchVideoCallRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1MePerformersPerformerIdVideoCallPatch(
        performerId: number,
        patchVideoCallRequest?: PatchVideoCallRequest,
        options?: any,
    ) {
        return AccountServicesApiFp(this.configuration)
            .v1MePerformersPerformerIdVideoCallPatch(performerId, patchVideoCallRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Searches for a performer based on the provided filter
     * @param {string} [filterScreenName] Performer Screen Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1PerformersGet(filterScreenName?: string, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1PerformersGet(filterScreenName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the given performer
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountServicesApi
     */
    public v1PerformersPerformerIdGet(performerId: number, options?: any) {
        return AccountServicesApiFp(this.configuration)
            .v1PerformersPerformerIdGet(performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
