import {
    UpdatePerformerPropertiesRequest,
    UpdatePerformerPropertiesRequestReferralConsentStatusEnum,
    Category,
    Person,
    PersonZodiacSignEnum,
    PerformerSubscription,
    PerformerAllOfLiveShow,
    PerformerAllOfTwoWayAudio,
    ProfileProfilePictureMediaKeyEnum,
    ProfileProfilePictures,
    PerformerAllOfBattle,
    PerformerPropertiesExclusiveBadgeStatus,
    SelectedProfilePictureMediaKeyEnum,
} from 'apiClients/msc-api-gateway/accountservices/models';
import { AccountServicesApiInterface } from 'apiClients/msc-api-gateway/accountservices/client/account-services-api';
import { PerformersApiInterface } from 'apiClients/msc-api-gateway/proxy/client/performers-api';
import { RegistrationApiInterface } from 'apiClients/msc-api-gateway/proxy/registration/client/registration-api';
import { PerformerPricing, CreditType } from 'apiClients/msc-api-gateway/proxy/models';
import { globalConfig } from 'configs/globalConfig';
import { CurrencyType, ProfilePictureVersionMediaKeyEnum } from '../Common.types';
import {
    PerformerEntity,
    PerformerEntityStatus,
    PerformerCategory,
    PerformerPerson,
    MeEntity,
    PerformerPricing as UIPerformerPricing,
    PerformerSubscription as MappedPerformerSubscription,
    PerformerLiveShow,
    PerformerProfilePicture,
    PerformerTwoWayAudio,
    NewbieStatus,
    BattleAccess,
    ExclusiveBadgeAccess,
    ExclusiveBadgeStatusEnum,
} from './Performer.types';
import { PerformerDataMapperInterface } from './Performer.service';
import { mapGetMeResponse } from './Performer.mapper';

export class PerformerDataMapper implements PerformerDataMapperInterface {
    currencies = {
        [CreditType.Credit]: CurrencyType.CREDIT,
        [CreditType.Coin]: CurrencyType.COIN,
    };

    constructor(
        private accountServicesApiClient: AccountServicesApiInterface,
        private registrationApiClient: RegistrationApiInterface,
        private proxyApiClient: PerformersApiInterface,
    ) {}

    public getMe(): Promise<MeEntity> {
        return new Promise<MeEntity>((resolve, reject) => {
            this.registrationApiClient
                .gwInnerV2MeGet()
                .then((me) => {
                    if (me.data.data) {
                        resolve(mapGetMeResponse(me.data.data));
                    } else {
                        reject(new Error('no response'));
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getMyPerformer(id: number): Promise<{ performer: PerformerEntity; twoWayAudio: PerformerTwoWayAudio }> {
        return new Promise<{ performer: PerformerEntity; twoWayAudio: PerformerTwoWayAudio }>((resolve, reject) =>
            this.accountServicesApiClient
                .v1MePerformersPerformerIdGet(id)
                .then((performer) => {
                    if (performer.data.data) {
                        const {
                            id,
                            screenName,
                            displayName,
                            status,
                            category,
                            isBirthdayVisible,
                            isSelected,
                            persons,
                            subscription,
                            createdAt,
                            liveShow,
                            hasInvalidDisplayName,
                            profilePictures,
                            twoWayAudio,
                            isActionsRequestDisabled,
                            isTestReadingFinished,
                            isTestAccount,
                            isInvisible,
                            battle,
                            exclusiveBadgeStatus,
                        } = performer.data.data;

                        const hasReachedEarning = this.performerHasReachedEarning();
                        const isExclusivePartner = this.performerIsExclusivePartner();
                        const performerCategory = this.mapPerformerCategory(category);
                        const performerPersons = this.mapPersons(persons);
                        const migrationDetails = this.getMigrationDetails();
                        const displayNameDetails = this.getDisplayNameDetails();
                        const performerStatus = status || PerformerEntityStatus.NEW;
                        const performerSubscription = this.mapSubscription(subscription);
                        const performerLiveShow = this.mapLiveShow(liveShow);
                        const performerProfilePictures = this.mapProfilePictures(profilePictures);
                        const performerTwoWayAudio = this.mapTwoWayAudio(twoWayAudio);
                        const isPerformerActionsRequestDisabled = !!isActionsRequestDisabled;
                        const performerBattleAccess = this.mapBattleAccess(battle);
                        const performerExclusiveBadgeAccess = this.mapExclusiveBadgeAccess(exclusiveBadgeStatus);

                        const mappedPerformer: PerformerEntity = {
                            id: id as number,
                            screenName: screenName as string,
                            displayName: displayName as string,
                            status: performerStatus as PerformerEntityStatus,
                            category: performerCategory,
                            isBirthdayVisible: isBirthdayVisible as boolean,
                            isSelected: isSelected as boolean,
                            persons: performerPersons,
                            hasReachedEarning,
                            isExclusivePartner,
                            migration: migrationDetails,
                            displayNameDetails,
                            subscription: performerSubscription,
                            createdAt: createdAt as string,
                            liveShow: performerLiveShow,
                            hasInvalidDisplayName: hasInvalidDisplayName as boolean,
                            profilePictures: performerProfilePictures,
                            isActionsRequestDisabled: isPerformerActionsRequestDisabled,
                            isTestReadingFinished: isTestReadingFinished || false,
                            isInvisible: isInvisible || false,
                            isTestAccount: isTestAccount || false,
                            battle: performerBattleAccess,
                            exclusiveBadgeStatus: performerExclusiveBadgeAccess,
                        };
                        resolve({ performer: mappedPerformer, twoWayAudio: performerTwoWayAudio });
                    } else {
                        reject(new Error('no response'));
                    }
                })
                .catch((error) => reject(error)),
        );
    }

    public getPerformerPricing(id: number): Promise<UIPerformerPricing> {
        return new Promise<UIPerformerPricing>((resolve, reject) => {
            this.proxyApiClient
                .getPerformerPricing(`${id}`, 'performer', id)
                .then((pricing) => {
                    const mappedPricing = this.mapPricing(pricing.data.data);

                    if (mappedPricing) {
                        resolve(mappedPricing);
                        return;
                    }

                    reject(new Error('no response'));
                })
                .catch((error) => reject(error));
        });
    }

    public getPerformerPeriods() {
        return globalConfig.periods;
    }

    public getPerformerProperties(_id: number) {
        return {
            referralConsentStatus:
                `${globalConfig.referralConsentStatusId}` as UpdatePerformerPropertiesRequestReferralConsentStatusEnum,
        };
    }

    public updatePerformerProperties(id: number, body: UpdatePerformerPropertiesRequest): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.accountServicesApiClient
                .v1MePerformersPerformerIdPropertiesPatch(id, body)
                .then(() => {
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    public getNewbieStatus(id: number): Promise<NewbieStatus> {
        return new Promise((resolve, reject) => {
            this.proxyApiClient
                .getPerformerNewbieStatus(`${id}`, 'performer', id)
                .then(({ data }) => {
                    const { isNewbie, newbieUntil } = data.data;
                    resolve({
                        isNewbie,
                        newbieUntil,
                    });
                })
                .catch(() => {
                    reject();
                });
        });
    }

    private mapPerformerCategory(category: Category | undefined): PerformerCategory {
        if (!category) {
            return {
                id: 0,
                name: '',
                label: '',
                personCount: 0,
            };
        }

        return {
            id: category.id as number,
            name: category.name as string,
            label: category.label as string,
            personCount: category.personCount as number,
        };
    }

    private mapTwoWayAudio(twoWayAudio?: PerformerAllOfTwoWayAudio): PerformerTwoWayAudio {
        if (!twoWayAudio) return { spokenLanguages: [], isEnabled: false };

        const { spokenLanguages, isEnabled } = twoWayAudio;
        return {
            spokenLanguages: spokenLanguages || [],
            isEnabled: isEnabled || false,
        };
    }

    private performerHasReachedEarning(): boolean {
        if (!window?.PortalApi?.homeDashboard) {
            return false;
        }

        return window?.PortalApi?.homeDashboard.performer.hasReachedEarning;
    }

    private performerIsExclusivePartner(): boolean {
        if (!window?.PortalApi?.homeDashboard) {
            return false;
        }

        return window?.PortalApi?.homeDashboard.performer.isExclusivePartner;
    }

    private mapPersons(persons: Person[] | undefined): PerformerPerson[] {
        if (!persons || !persons[0]) {
            return [
                {
                    id: 0,
                    zodiacSign: PersonZodiacSignEnum.Aquarius,
                    hasBirthday: false,
                },
            ];
        }

        const mappedPersons = persons.map((person) => {
            const { id, zodiacSign, hasBirthday } = person;
            return {
                id: id as number,
                zodiacSign: zodiacSign as PersonZodiacSignEnum,
                hasBirthday: hasBirthday as boolean,
            };
        });

        return mappedPersons;
    }

    private getDisplayNameDetails() {
        if (!window?.PortalApi?.homeDashboard) {
            return {
                isDisplayNameChangePending: false,
                isDisplayNameChangeForced: false,
                newDisplayName: '',
                isDisplayNameFeatureFlagEnabled: false,
            };
        }

        return {
            isDisplayNameChangePending: window?.PortalApi?.homeDashboard?.isDisplayNameChangePending,
            isDisplayNameChangeForced: window?.PortalApi?.homeDashboard?.isDisplayNameChangeForced,
            newDisplayName: window?.PortalApi?.homeDashboard?.newDisplayName,
            isDisplayNameFeatureFlagEnabled: window?.PortalApi?.homeDashboard?.isDisplayNameFeatureFlagEnabled,
        };
    }

    private getMigrationDetails() {
        if (!window?.PortalApi?.homeDashboard) {
            return {
                isMigrationFinished: false,
            };
        }

        return {
            isMigrationFinished: window?.PortalApi?.homeDashboard?.isMigrationFinished,
        };
    }

    private mapPricing(pricing?: PerformerPricing): UIPerformerPricing | undefined {
        if (!pricing) return;

        const {
            creditType,
            liveShow,
            twoWayAudio,
            cam2cam,
            videoCall,
            message,
            mediaMessage,
            storyHighlight,
            myStory,
        } = pricing;

        return {
            creditType: creditType?.label ? this.currencies[creditType.label] : CurrencyType.CREDIT,
            liveShow: {
                price: liveShow?.price ? liveShow.price : 0,
                options: liveShow?.options ? liveShow.options : [],
            },
            twoWayAudio: {
                price: twoWayAudio?.price ? twoWayAudio.price : 0,
                options: twoWayAudio?.options ? twoWayAudio.options : [],
            },
            cam2Cam: { price: cam2cam?.price ? cam2cam.price : 0, options: cam2cam?.options ? cam2cam.options : [] },
            videoCall: {
                price: videoCall?.price ? videoCall.price : 0,
                options: videoCall?.options ? videoCall.options : [],
            },
            message: {
                price: message?.price ? message.price : 0,
                options: message?.options ? message.options : [],
            },
            mediaMessage: {
                video: {
                    defaultPrice: this.getValueAndThrowIfUndefined(mediaMessage?.video?.defaultPrice),
                    options: this.getValueAndThrowIfUndefined(mediaMessage?.video?.options),
                    titleRequiredThreshold: this.getValueAndThrowIfUndefined(
                        mediaMessage?.video?.titleRequiredThreshold,
                    ),
                },
                image: {
                    defaultPrice: this.getValueAndThrowIfUndefined(mediaMessage?.image?.defaultPrice),
                    options: this.getValueAndThrowIfUndefined(mediaMessage?.image?.options),
                },
            },
            storyHighlight: {
                video: {
                    defaultPrice: this.getValueAndThrowIfUndefined(storyHighlight?.video?.defaultPrice),
                    options: this.getValueAndThrowIfUndefined(storyHighlight?.video?.options),
                },
                image: {
                    defaultPrice: this.getValueAndThrowIfUndefined(storyHighlight?.image?.defaultPrice),
                    options: this.getValueAndThrowIfUndefined(storyHighlight?.image?.options),
                },
                text: {
                    defaultPrice: this.getValueAndThrowIfUndefined(storyHighlight?.text?.defaultPrice),
                    options: this.getValueAndThrowIfUndefined(storyHighlight?.text?.options),
                },
            },
            myStory: {
                price: myStory?.price ? myStory.price : 0,
                options: myStory?.options ? myStory.options : [],
            },
        };
    }

    private mapSubscription(subscription?: PerformerSubscription): MappedPerformerSubscription | undefined {
        if (!subscription || subscription.isSubscriptionEnabled === undefined) return;

        const { isSubscriptionEnabled, subscriptionPackageId } = subscription;

        return { isSubscriptionEnabled, subscriptionPackageId };
    }

    private getValueAndThrowIfUndefined = <T>(value: T | undefined): Required<T> => {
        if (typeof value === 'undefined') {
            throw new Error('Recieved undefined value while parsing server response');
        }
        return value as Required<T>;
    };

    private mapLiveShow(liveShow: PerformerAllOfLiveShow | undefined): PerformerLiveShow {
        if (!liveShow) {
            return {
                isOnline: false,
                price: 0,
            };
        }

        return {
            isOnline: liveShow.isOnline as boolean,
            price: liveShow.price as number,
        };
    }

    private mapProfilePictures(profilePictures: ProfileProfilePictures | undefined): PerformerProfilePicture[] {
        if (!profilePictures || !profilePictures.glamour) {
            return [];
        }

        return profilePictures.glamour.map((glamourPicture) => {
            const { mediaKey, url } = glamourPicture;
            return {
                mediaKey: this.mapProfilePictureMediaKey(mediaKey),
                url: url as string,
            };
        });
    }

    private mapProfilePictureMediaKey(
        key: ProfileProfilePictureMediaKeyEnum | undefined,
    ): ProfilePictureVersionMediaKeyEnum {
        if (!key) {
            throw new Error('BE error: profile picture media key not provided.');
        }

        const dictionary: Record<ProfileProfilePictureMediaKeyEnum, ProfilePictureVersionMediaKeyEnum> = {
            [ProfileProfilePictureMediaKeyEnum._1691600900Jpg]: ProfilePictureVersionMediaKeyEnum._16_9_1600x900_Jpg,
            [ProfileProfilePictureMediaKeyEnum._169896504Jpg]: ProfilePictureVersionMediaKeyEnum._16_9_896x504_Jpg,
            [ProfileProfilePictureMediaKeyEnum._43147110Jpg]: ProfilePictureVersionMediaKeyEnum._4_3_147x110_Jpg,
            [ProfileProfilePictureMediaKeyEnum._43800600Jpg]: ProfilePictureVersionMediaKeyEnum._4_3_800x600_Jpg,
            [ProfileProfilePictureMediaKeyEnum._916504896Jpg]: ProfilePictureVersionMediaKeyEnum._9_16_504x896_Jpg,
            [ProfileProfilePictureMediaKeyEnum._9169001600Jpg]: ProfilePictureVersionMediaKeyEnum._9_16_900x1600_Jpg,
        };

        return dictionary[key];
    }

    private mapSelectedProfilePictureMediaKey(
        key: SelectedProfilePictureMediaKeyEnum | undefined,
    ): ProfilePictureVersionMediaKeyEnum {
        if (!key) {
            throw new Error('BE error: profile picture media key not provided.');
        }

        const dictionary: Record<SelectedProfilePictureMediaKeyEnum, ProfilePictureVersionMediaKeyEnum> = {
            [SelectedProfilePictureMediaKeyEnum._1691600900Jpg]: ProfilePictureVersionMediaKeyEnum._16_9_1600x900_Jpg,
            [SelectedProfilePictureMediaKeyEnum._169896504Jpg]: ProfilePictureVersionMediaKeyEnum._16_9_896x504_Jpg,
            [SelectedProfilePictureMediaKeyEnum._43147110Jpg]: ProfilePictureVersionMediaKeyEnum._4_3_147x110_Jpg,
            [SelectedProfilePictureMediaKeyEnum._43800600Jpg]: ProfilePictureVersionMediaKeyEnum._4_3_800x600_Jpg,
            [SelectedProfilePictureMediaKeyEnum._916504896Jpg]: ProfilePictureVersionMediaKeyEnum._9_16_504x896_Jpg,
            [SelectedProfilePictureMediaKeyEnum._9169001600Jpg]: ProfilePictureVersionMediaKeyEnum._9_16_900x1600_Jpg,
        };

        return dictionary[key];
    }

    private mapBattleAccess(access?: PerformerAllOfBattle): BattleAccess {
        return {
            isAvailable: Boolean(access?.isAvailable),
        };
    }

    private mapExclusiveBadgeAccess(
        exclusiveBadgeAccess?: PerformerPropertiesExclusiveBadgeStatus,
    ): ExclusiveBadgeAccess {
        return {
            id: exclusiveBadgeAccess?.id as unknown as ExclusiveBadgeStatusEnum,
            label: exclusiveBadgeAccess?.label,
        };
    }
}
