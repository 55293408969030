/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CommentInteractionMetaData } from './comment-interaction-meta-data';
import { LikeInteractionMetaData } from './like-interaction-meta-data';
import { SpendingInteractionMetaData } from './spending-interaction-meta-data';

/**
 *
 * @export
 * @interface InteractionInteraction
 */
export interface InteractionInteraction {
    /**
     *
     * @type {string}
     * @memberof InteractionInteraction
     */
    type?: InteractionInteractionTypeEnum;
    /**
     * Meta data of the interactions
     * @type {CommentInteractionMetaData | LikeInteractionMetaData | SpendingInteractionMetaData}
     * @memberof InteractionInteraction
     */
    metaData?: CommentInteractionMetaData | LikeInteractionMetaData | SpendingInteractionMetaData | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InteractionInteractionTypeEnum {
    MemberSpent = 'memberSpent',
    AddedToFavorites = 'addedToFavorites',
    ContentLiked = 'contentLiked',
    ContentCommented = 'contentCommented',
    MessageReceived = 'messageReceived',
    FreePeek = 'freePeek',
}
