import riot from 'riot';

import styles from './ToggleOnOff.scss';

const DEBOUNCE_TIMEOUT = 500;
let uniqueId = 0;

riot.tag('toggle-on-off',
	`<div class="${ styles.wrapper }">
		<input ref="input" id="toggle-on-off-{ uniqueId }" type="checkbox" class="${ styles.input }" onchange="{ onChange }" name="{ opts.name }" checked="{ opts.checked }" disabled="{ disabled }">
		<label class="toggle-on-off ${ styles.switch } { 'disabled' : disabled }" for="toggle-on-off-{ uniqueId }">
			<span class="${ styles.title } ${ styles.on }">{ opts.textOn ? _(opts.textOn) : 'On' }</span>
			<span class="${ styles.title } ${ styles.off }">{ opts.textOff ? _(opts.textOff) : 'Off' }</span>
		</label>
	</div>`,

	function() {
		let onceTimer = -1;
		this.debounceTimeout = DEBOUNCE_TIMEOUT;

		this.styles = styles;
		this.uniqueId = uniqueId++;
		this.disabled = this.parent ? this.parent.opts.isDisabled : this.opts.disabled;

		this.on('mount', function() {
			this.lastState = this.refs.input.checked;
		});

		this.onChange = function(forceTrigger = false) {
			const isOn = this.refs.input.checked;

			if (!isOn) {
				this.trigger('off', isOn, this);
			}
			else {
				this.trigger('on', isOn, this);
			}
			this.trigger('change', isOn, this);
			clearTimeout(onceTimer);
			onceTimer = setTimeout(() => {
				if (forceTrigger || !this.lastState === isOn) {
					this.lastState = isOn;
					this.trigger('change-once', isOn, this);
				}
			}, this.debounceTimeout);
			return true;
		};

		this.toggle = (isSilent = false) => {
			this.refs.input.checked = this.lastState = !this.refs.input.checked;
			if (!isSilent) {
				this.onChange(true);
			}
		};

		this.isOn = function() {
			return this.refs.input.checked;
		};

		this.disable = function() {
			this.refs.input.disabled = true;
		};

		this.enable = function() {
			this.refs.input.removeAttribute('disabled');
			this.refs.input.disabled = false;
		};
	});
