import { GetAppConfigResponse } from 'apiClients/msc-api-gateway/applicationconfig/models';
import { ApplicationConfig } from './ApplicationConfig.types';

const mapApplicationConfig = (response: GetAppConfigResponse): ApplicationConfig => {
    const { jaws: jawsConfig, jaws2: jaws2Config, csc } = response.data.common;

    return {
        common: {
            // @ts-ignore
            jawsUrl: `${jawsConfig.uris?.default}/notifications` || '',
            // @ts-ignore
            jaws2Url: `${jaws2Config.uris?.default}` || '',
            cscUrl: csc.url,
        },
    };
};

export default {
    mapApplicationConfig,
};
