/* tslint:disable */
/* eslint-disable */
/**
 * Account_Services API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ProfileProfilePicture
 */
export interface ProfileProfilePicture {
    /**
     *
     * @type {string}
     * @memberof ProfileProfilePicture
     */
    mediaKey?: ProfileProfilePictureMediaKeyEnum;
    /**
     *
     * @type {string}
     * @memberof ProfileProfilePicture
     */
    url?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ProfileProfilePictureMediaKeyEnum {
    _43147110Jpg = 'profile_picture_crop_4_3_147_110_jpg',
    _43800600Jpg = 'profile_picture_crop_4_3_800_600_jpg',
    _9169001600Jpg = 'profile_picture_crop_9_16_900_1600_jpg',
    _916504896Jpg = 'profile_picture_crop_9_16_504_896_jpg',
    _1691600900Jpg = 'profile_picture_crop_16_9_1600_900_jpg',
    _169896504Jpg = 'profile_picture_crop_16_9_896_504_jpg',
}
