import Ext from '../vendor/ExtCore';
import Chaos from './Chaos';

/**
 * AJAX handler class wich creates and manages XHR requests.
 *
 * Wraps and extends the basic Ext-Core AJAX functionality.
 *
 * @package    Chaos
 * @subpackage Core
 *
 * Extra config options:
 *
 * @param {String}   type    type of the expected respose [html|json]
 * @param {Function} error   function to be called when response was unsuccessful (success: false)
 *
 * @event {beforeajaxrequest}
 * @param Connection conn            Connection object, which called the request
 * @param Object           options         options of the current request
 * @param Object           requestData     parameters of the current request (has some overlappign with options)
 *
 */
export default class Connection extends Ext.data.Connection {
	/** @const string TYPE_JSON    ajax call type, json */
	static TYPE_JSON = 'json';
	/** @const string TYPE_HTML    ajax call type, html */
	static TYPE_HTML = 'html';

	get properties() {
		return Object.assign({}, {
			/** @var {Object} requests   Stores the requests which are currently in progress. */
			requests : undefined,

			/** @var {Object} commands   The registered remote commands. */
			commands : undefined,

			/** @var {Object} defaultOptions    default request options */
			defaultOptions : {
				type     : 'json',
				success  : Chaos.emptyFn,
				error    : Chaos.emptyFn,
				failure  : Chaos.emptyFn,
				callback : Chaos.emptyFn,
				method   : 'GET'
			}
		});
	}

	constructor(config) {
		super(config);
		if (this.properties) {
			Object.keys(this.properties).forEach((propertyKey) => {
				this[propertyKey] = this.properties[propertyKey];
			});
		}
		this.init();
	}
	/**
	 * Initializes the AJAX global event listeners.
	 *
	 * @return void
	 */
	init() {
		// init objects
		this.requests = {};
		this.commands = {};

		// nothing to do
		this.on('requestcomplete', this.onRequestComplete, this);

		this.addEvents({
			beforeajaxrequest : true,
			callback          : true,
			success           : true,
			error             : true,
			failure           : true,
			abort             : true,
			abortall          : true,
			remove            : true,
			registerCommand   : true,
			unregisterCommand : true
		});
	}

	onRequestComplete(conn, response, options) {}

	onRequestException(conn, response, options) {}

	/**
	 * Sends a request to the server defined by config
	 *
	 * @param {Object} options     The parameter to the request call
	 * @param {Number} timeout     Timeout for the request in milliseconds
	 *
	 * @return undefined
	 */
	request(options, timeout) {
		Ext.applyIf(options, this.defaultOptions);

		// save xhr object and config callbacks
		var requestData = {
			success  : options.success,
			callback : options.callback,
			error    : options.error,
			failure  : options.failure,
			scope    : options.scope
		};

		// overwrite original callbacks
		options.callback = this.onCallback;
		options.error = this.onError;
		options.success = this.onSuccess;
		options.failure = this.onFailure;
		// save scope for further usage
		requestData.scope = options.scope;
		// overwrite scope to this.
		options.scope = this;

		this.fireEvent('beforeajaxrequest', this, options, requestData);

		// call superclass request to do request
		var rqObj = super.request(options);

		if (timeout) {
			if (Ext.isIE11) {
				rqObj.conn.open(options.method, options.url);
			}
			rqObj.conn.timeout = timeout;
		}

		//
		requestData.xhr = rqObj;
		// save data
		this.requests[rqObj.tId] = requestData;

		return rqObj;
	}

	/**
	 * for calling callback functions without checking its existence.
	 * arguments can be applied for arg parameter (without convering it to array)
	 *
	 * @param {Function}      callback    function to call
	 * @param {Array||Object} args        arguments of the callback function
	 * @param {Object}        scope
	 *
	 * @return mixed    result of the callback function or undefined
	 */
	_fireCallback(callback, args, scope) {
		if (Chaos.isFunction(callback)) {
			if (typeof args === 'object' && !Chaos.isArray(args)) {
				args = Array.prototype.slice(args);
			}
			return callback.apply(scope, args);
		}
		return undefined;
	}

	/**
	 * callback
	 *
	 * @param {String} response
	 * @param {Object} options
	 *
	 * @return undefined
	 */
	onCallback(response, options) {
		// call the original
		if (typeof options.callback === 'function') {
			options.callback.call(options.scope, response, options);
		}

		// fire callback event
		var requestData = this.requests[response.tId];
		this.fireEvent('callback', this, response, options, requestData);
	}

	onError(response, options) {
		if (typeof options.error === 'function') {
			options.error.call(options.scope, response, options);
		}

		// fire error event
		var requestData = this.requests[response.tId];
		this.fireEvent('error', this, response, options, requestData);
	}

	/**
	 * callback on success
	 *
	 * @param {String} response
	 * @param {Object} options
	 *
	 * @return undefined
	 */
	onSuccess(response, options) {
		// get request data
		var requestData = this.requests[response.tId];
		// if not found, throw exception
		if (typeof requestData === 'undefined') {
			throw new Error('request error: requestData not found');
		}

		switch (options.type.toLowerCase()) {
			case Connection.TYPE_JSON:
				// try to decode json
				try {
					response.json = Ext.decode(response.responseText, true);
				}
				catch (e) {
					response.json = {};
					// if fails, call failure method
					this._fireCallback(requestData.error, [response, options, requestData], requestData.scope);
					// do not execute further commands, return void
					return;
				}

				// check if success parameter is falsy
				var success =
					!((response.json.success === 0 ||
					response.json.success === false ||
					response.json.success === '0'));

				// call the appropriate event callback (success or error)
				// if false returned, onSuccess function will abort, doing no more action
				if (success == true) {
					var callbackResult = this._fireCallback(requestData.success, [response, options, requestData], requestData.scope);
				}
				else {
					var callbackResult = this._fireCallback(requestData.error, [response, options, requestData], requestData.scope);
				}

				// if result of the callback is not false, check for redirecting and commands
				if (callbackResult !== false) {
					// Handling redirects.
					if (typeof response.redirectUrl === 'string') {
						document.location = data.redirectUrl;
					}

					// Handling remote commands.
					this._fireCommand(response);
				}
				else {
					return false;
				}

				break;
			case Connection.TYPE_HTML:
				this._fireCallback(requestData.success, [response, options, requestData], requestData.scope);
				return;

				break;
			default:
				this._fireCallback(requestData.failure, [response, options, requestData], requestData.scope);
				return;
				break;
		}

		this.fireEvent('success', this, response, options, requestData);

		this.remove(response.tId);
	}

	/**
	 * Fires each commands from response
	 *
	 * @param {Object} response   Response
	 */
	_fireCommand(response) {
		if (typeof response.json.command === 'object') {
			// there can be more than one command in a response, iterate through them
			for (var commandName in response.json.command) {
				// if command exists in the command list, execute it!
				if (typeof this.commands[commandName] === 'object') {
					this._fireCallback(
						this.commands[commandName].fn,
						response.json.command[commandName] || {},
						this.commands[commandName].scope || this || window
					);
				}
			}
		}
	}

	/**
	 * callback on failure
	 *
	 * @param {String} response
	 * @param {Object} options
	 *
	 * @return undefined
	 */
	onFailure(response, options) {
		var requestData = this.requests[response.tId];
		// call the original
		this._fireCallback(requestData.failure, [response, options, requestData], requestData.scope);

		this.fireEvent('failure', this, response, options, requestData);
	}

	/**
	 * returns the started request
	 *
	 */
	getRequest(tId) {
		return this.requests[tId];
	}

	/**
	 * returns the actually running requests' count.
	 */
	getRunningReqsCount() {
		return Object.keys(this.requests).length;
	}

	/**
	 * Aborts any outstanding request.
	 *
	 * @param {Number|Object} tId  transaction id, defaults to the last transaction.
	 *
	 * @return undefined
	 */
	abort(tId) {
		if (tId instanceof Object) {
			tId = tId.tId;
		}
		// abort only if request exists
		if (typeof this.requests[tId] !== 'undefined') {
			// abort request
			this.requests[tId].xhr.conn.abort();
			// remove from list
			this.remove(tId);
		}
	}

	/**
	 * Aborts all outstanding request.
	 *
	 * @return undefined
	 */
	abortAll() {
		this.fireEvent('abortall', this);

		for (var tId in this.requests) {
			this.abort(tId);
		}
	}

	remove(tId) {
		this.fireEvent('remove', this, tId);

		delete this.requests[tId];
	}

	/**
	 * Registers a remote command.
	 *
	 * @param {String}   name    The name of the command.
	 * @param {Function} fn      The callback function.
	 * @param {Object}   scope   The scope of the function.
	 *
	 * @return void
	 */
	registerCommand(name, fn, scope) {
		this.fireEvent('registerCommand', this, name, fn, scope);

		this.commands[name] = {
			fn    : fn,
			scope : scope
		};
	}

	/**
	 * Unregisters a remote command.
	 *
	 * @param {String} name    The name of the command.
	 *
	 * @return void
	 */
	unregisterCommand(name) {
		this.fireEvent('unregisterCommand', this, name, fn, scope);

		if (this.commands[name]) {
			delete this.commands[name];
		}
	}
}

Connection.Ajax = new Connection();