import { useState, useEffect, useMemo } from 'react';
import { getOrientation, DeviceOrientation } from 'utils/device-detect/device-orientation';
import { debounce } from 'throttle-debounce';

export const useOrientation = (): DeviceOrientation => {
    const [state, setState] = useState<DeviceOrientation>(() => getOrientation());

    const handleOrientationChange = () => {
        const newOrientation = getOrientation();
        setState(newOrientation);
    };

    const debouncedHandleOrientationChange = useMemo(() => debounce(200, handleOrientationChange), []);

    useEffect(() => {
        debouncedHandleOrientationChange();
        window.addEventListener('resize', debouncedHandleOrientationChange, false);

        return () => {
            window.removeEventListener('resize', debouncedHandleOrientationChange, false);
        };
    }, [debouncedHandleOrientationChange]);

    return state;
};
