import { Storage } from './index';

const api: Storage = {
    get(key) {
        return new Promise((resolve, reject) => {
            try {
                resolve(localStorage.getItem(key) || '');
            } catch (e) {
                reject(e);
            }
        });
    },
    set(key, value) {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    },
    remove(key) {
        return new Promise((resolve, reject) => {
            try {
                localStorage.removeItem(key);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    },
};
export default api;
