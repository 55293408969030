/* tslint:disable */
/* eslint-disable */
/**
 * My_Story_Service API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { BadRequestErrorResponse } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { CreateMyStoryItemRequest } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { MyStory } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { MyStoryItemListResponse } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { MyStoryItemResponse } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { NotFoundErrorResponse } from '../../../msc-api-gateway/mystoryservice/models';
// @ts-ignore
import { UpdateMyStoryRequest } from '../../../msc-api-gateway/mystoryservice/models';
/**
 * MyStoryServiceApi - axios parameter creator
 * @export
 */
export const MyStoryServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStory: async (performerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('getMyStory', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*', 'story:read'], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdateMyStoryRequest} [updateMyStoryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyStory: async (
            performerId: number,
            updateMyStoryRequest?: UpdateMyStoryRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('updateMyStory', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*', 'story:write'], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateMyStoryRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of story items in the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {Array<'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'>} [filterItemStatuses] An array of the Item statuses for the filter.
         * @param {Array<number>} [filterItemIds] An array of the Item ids for the filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsGet: async (
            performerId: number,
            filterItemStatuses?: Array<
                | 'created'
                | 'rejected'
                | 'activated'
                | 'deleted'
                | 'expired'
                | 'waiting_for_approval'
                | 'validation_failed'
            >,
            filterItemIds?: Array<number>,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsGet', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story/items`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['story:write'], configuration);

            if (filterItemStatuses) {
                localVarQueryParameter['filter[itemStatuses][]'] = filterItemStatuses.join(COLLECTION_FORMATS.csv);
            }

            if (filterItemIds) {
                localVarQueryParameter['filter[itemIds][]'] = filterItemIds.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a new story item into the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [xFeatureToggles] Semicolon separated list of feature toggles
         * @param {CreateMyStoryItemRequest} [createMyStoryItemRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsPost: async (
            performerId: number,
            xFeatureToggles?: string,
            createMyStoryItemRequest?: CreateMyStoryItemRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsPost', 'performerId', performerId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story/items`.replace(
                `{${'performerId'}}`,
                encodeURIComponent(String(performerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['story:write'], configuration);

            if (xFeatureToggles !== undefined && xFeatureToggles !== null) {
                localVarHeaderParameter['X-Feature-Toggles'] = String(xFeatureToggles);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createMyStoryItemRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete: async (
            performerId: number,
            storyItemId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete', 'performerId', performerId);
            // verify required parameter 'storyItemId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete', 'storyItemId', storyItemId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story/items/{storyItemId}`
                .replace(`{${'performerId'}}`, encodeURIComponent(String(performerId)))
                .replace(`{${'storyItemId'}}`, encodeURIComponent(String(storyItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['story:write'], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an activated story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet: async (
            performerId: number,
            storyItemId: number,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'performerId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet', 'performerId', performerId);
            // verify required parameter 'storyItemId' is not null or undefined
            assertParamExists('v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet', 'storyItemId', storyItemId);
            const localVarPath = `/v1/me/performers/{performerId}/my-story/items/{storyItemId}`
                .replace(`{${'performerId'}}`, encodeURIComponent(String(performerId)))
                .replace(`{${'storyItemId'}}`, encodeURIComponent(String(storyItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['story:read'], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MyStoryServiceApi - functional programming interface
 * @export
 */
export const MyStoryServiceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MyStoryServiceApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyStory(
            performerId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyStory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyStory(performerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdateMyStoryRequest} [updateMyStoryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyStory(
            performerId: number,
            updateMyStoryRequest?: UpdateMyStoryRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyStory(
                performerId,
                updateMyStoryRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of story items in the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {Array<'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'>} [filterItemStatuses] An array of the Item statuses for the filter.
         * @param {Array<number>} [filterItemIds] An array of the Item ids for the filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdMyStoryItemsGet(
            performerId: number,
            filterItemStatuses?: Array<
                | 'created'
                | 'rejected'
                | 'activated'
                | 'deleted'
                | 'expired'
                | 'waiting_for_approval'
                | 'validation_failed'
            >,
            filterItemIds?: Array<number>,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyStoryItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdMyStoryItemsGet(
                performerId,
                filterItemStatuses,
                filterItemIds,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create a new story item into the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [xFeatureToggles] Semicolon separated list of feature toggles
         * @param {CreateMyStoryItemRequest} [createMyStoryItemRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdMyStoryItemsPost(
            performerId: number,
            xFeatureToggles?: string,
            createMyStoryItemRequest?: CreateMyStoryItemRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyStoryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MePerformersPerformerIdMyStoryItemsPost(
                performerId,
                xFeatureToggles,
                createMyStoryItemRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete a story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(
            performerId: number,
            storyItemId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(
                    performerId,
                    storyItemId,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get an activated story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(
            performerId: number,
            storyItemId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyStoryItemResponse>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(
                    performerId,
                    storyItemId,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MyStoryServiceApi - factory interface
 * @export
 */
export const MyStoryServiceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = MyStoryServiceApiFp(configuration);
    return {
        /**
         *
         * @summary Get my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStory(performerId: number, options?: any): AxiosPromise<MyStory> {
            return localVarFp.getMyStory(performerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update my story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {UpdateMyStoryRequest} [updateMyStoryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyStory(
            performerId: number,
            updateMyStoryRequest?: UpdateMyStoryRequest,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .updateMyStory(performerId, updateMyStoryRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of story items in the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {Array<'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'>} [filterItemStatuses] An array of the Item statuses for the filter.
         * @param {Array<number>} [filterItemIds] An array of the Item ids for the filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsGet(
            performerId: number,
            filterItemStatuses?: Array<
                | 'created'
                | 'rejected'
                | 'activated'
                | 'deleted'
                | 'expired'
                | 'waiting_for_approval'
                | 'validation_failed'
            >,
            filterItemIds?: Array<number>,
            options?: any,
        ): AxiosPromise<MyStoryItemListResponse> {
            return localVarFp
                .v1MePerformersPerformerIdMyStoryItemsGet(performerId, filterItemStatuses, filterItemIds, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create a new story item into the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {string} [xFeatureToggles] Semicolon separated list of feature toggles
         * @param {CreateMyStoryItemRequest} [createMyStoryItemRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsPost(
            performerId: number,
            xFeatureToggles?: string,
            createMyStoryItemRequest?: CreateMyStoryItemRequest,
            options?: any,
        ): AxiosPromise<MyStoryItemResponse> {
            return localVarFp
                .v1MePerformersPerformerIdMyStoryItemsPost(
                    performerId,
                    xFeatureToggles,
                    createMyStoryItemRequest,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete a story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(
            performerId: number,
            storyItemId: number,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(performerId, storyItemId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get an activated story item from the performer story.
         * @param {number} performerId Numerical MSC Performer ID
         * @param {number} storyItemId Numerical ID of a Story Item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(
            performerId: number,
            storyItemId: number,
            options?: any,
        ): AxiosPromise<MyStoryItemResponse> {
            return localVarFp
                .v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(performerId, storyItemId, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyStoryServiceApi - interface
 * @export
 * @interface MyStoryServiceApi
 */
export interface MyStoryServiceApiInterface {
    /**
     *
     * @summary Get my story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    getMyStory(performerId: number, options?: any): AxiosPromise<MyStory>;

    /**
     *
     * @summary Update my story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UpdateMyStoryRequest} [updateMyStoryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    updateMyStory(performerId: number, updateMyStoryRequest?: UpdateMyStoryRequest, options?: any): AxiosPromise<void>;

    /**
     *
     * @summary Get a list of story items in the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {Array<'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'>} [filterItemStatuses] An array of the Item statuses for the filter.
     * @param {Array<number>} [filterItemIds] An array of the Item ids for the filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    v1MePerformersPerformerIdMyStoryItemsGet(
        performerId: number,
        filterItemStatuses?: Array<
            'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'
        >,
        filterItemIds?: Array<number>,
        options?: any,
    ): AxiosPromise<MyStoryItemListResponse>;

    /**
     *
     * @summary Create a new story item into the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [xFeatureToggles] Semicolon separated list of feature toggles
     * @param {CreateMyStoryItemRequest} [createMyStoryItemRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    v1MePerformersPerformerIdMyStoryItemsPost(
        performerId: number,
        xFeatureToggles?: string,
        createMyStoryItemRequest?: CreateMyStoryItemRequest,
        options?: any,
    ): AxiosPromise<MyStoryItemResponse>;

    /**
     *
     * @summary Delete a story item from the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} storyItemId Numerical ID of a Story Item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(
        performerId: number,
        storyItemId: number,
        options?: any,
    ): AxiosPromise<void>;

    /**
     *
     * @summary Get an activated story item from the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} storyItemId Numerical ID of a Story Item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApiInterface
     */
    v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(
        performerId: number,
        storyItemId: number,
        options?: any,
    ): AxiosPromise<MyStoryItemResponse>;
}

/**
 * MyStoryServiceApi - object-oriented interface
 * @export
 * @class MyStoryServiceApi
 * @extends {BaseAPI}
 */
export class MyStoryServiceApi extends BaseAPI implements MyStoryServiceApiInterface {
    /**
     *
     * @summary Get my story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public getMyStory(performerId: number, options?: any) {
        return MyStoryServiceApiFp(this.configuration)
            .getMyStory(performerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update my story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {UpdateMyStoryRequest} [updateMyStoryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public updateMyStory(performerId: number, updateMyStoryRequest?: UpdateMyStoryRequest, options?: any) {
        return MyStoryServiceApiFp(this.configuration)
            .updateMyStory(performerId, updateMyStoryRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of story items in the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {Array<'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'>} [filterItemStatuses] An array of the Item statuses for the filter.
     * @param {Array<number>} [filterItemIds] An array of the Item ids for the filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public v1MePerformersPerformerIdMyStoryItemsGet(
        performerId: number,
        filterItemStatuses?: Array<
            'created' | 'rejected' | 'activated' | 'deleted' | 'expired' | 'waiting_for_approval' | 'validation_failed'
        >,
        filterItemIds?: Array<number>,
        options?: any,
    ) {
        return MyStoryServiceApiFp(this.configuration)
            .v1MePerformersPerformerIdMyStoryItemsGet(performerId, filterItemStatuses, filterItemIds, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create a new story item into the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {string} [xFeatureToggles] Semicolon separated list of feature toggles
     * @param {CreateMyStoryItemRequest} [createMyStoryItemRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public v1MePerformersPerformerIdMyStoryItemsPost(
        performerId: number,
        xFeatureToggles?: string,
        createMyStoryItemRequest?: CreateMyStoryItemRequest,
        options?: any,
    ) {
        return MyStoryServiceApiFp(this.configuration)
            .v1MePerformersPerformerIdMyStoryItemsPost(performerId, xFeatureToggles, createMyStoryItemRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete a story item from the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} storyItemId Numerical ID of a Story Item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(
        performerId: number,
        storyItemId: number,
        options?: any,
    ) {
        return MyStoryServiceApiFp(this.configuration)
            .v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(performerId, storyItemId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get an activated story item from the performer story.
     * @param {number} performerId Numerical MSC Performer ID
     * @param {number} storyItemId Numerical ID of a Story Item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyStoryServiceApi
     */
    public v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(
        performerId: number,
        storyItemId: number,
        options?: any,
    ) {
        return MyStoryServiceApiFp(this.configuration)
            .v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(performerId, storyItemId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
