import { ApplicationConfigApiInterface } from 'apiClients/msc-api-gateway/applicationconfig/client/application-config-api';
import { ApplicationConfigDataMapperInterface } from './ApplicationConfig.service';
import { ApplicationConfig } from './ApplicationConfig.types';
import mapper from './ApplicationConfig.mapper';

export class ApplicationConfigDataMapper implements ApplicationConfigDataMapperInterface {
    constructor(private applicationConfigApi: ApplicationConfigApiInterface) {}

    public getApplicationConfig(): Promise<ApplicationConfig> {
        return new Promise((resolve, reject) => {
            this.applicationConfigApi
                .v1AppConfigPlatformGet('web')
                .then((response) => {
                    resolve(mapper.mapApplicationConfig(response.data));
                })
                .catch(reject);
        });
    }
}
