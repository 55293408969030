/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum LoyaltyLevelLiveJasmin {
    NotInLoyalty = 'Not in loyalty',
    Crush = 'Crush',
    Lover = 'Lover',
    Sweetheart = 'Sweetheart',
    Lancelot = 'Lancelot',
    Romeo = 'Romeo',
    Casanova = 'Casanova',
    Eros = 'Eros',
}
