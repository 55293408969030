import riot from 'riot';

import { required, observer } from './';

riot.tag('triggerable', false, function({ triggerId = required('TriggerID missing') }) {
	this.isTriggered = false;
	this.observer = observer;
	this.onTriggerable = (isTriggered = !this.isTriggered) => this.update({ isTriggered });
	this.on('mount', () => observer.on(triggerId, this.onTriggerable));
	this.on('unmount', () => observer.off(triggerId, this.onTriggerable));
}, 'triggered={isTriggered}');