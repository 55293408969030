import dayjs from 'dayjs';
import { TranslationService } from 'domains/Translation/Translation.service';
import storage from 'utils/storage/localStorage';
import { captureException } from 'utils/sentry';
import { MessengerTranslationServiceInterface } from './MessengerTranslation.serviceFactory';
import {
    CachedTranslationMessage,
    CachedTranslationThread,
    MessengerTranslationCache,
    TranslatedMessage,
} from './MessengerTranslation.types';
import {
    MAXIMUM_TRANSLATED_MESSAGES_PER_THREAD,
    STORAGE_KEY,
    TRANSLATION_CACHE_EXPIRATION_IN_DAYS,
} from './MessengerTranslation.config';

export interface MessengerTranslationDataMapperInterface {
    getSubscriptionKey(actorId: string, actorType: string): Promise<string>;
    setApiClient(apiClient: TranslationService): void;
    translate(messageId: number, translateTo: string, text: string): Promise<TranslatedMessage>;
}

export class MessengerTranslationService implements MessengerTranslationServiceInterface {
    constructor(private mapper: MessengerTranslationDataMapperInterface) {}

    public getSubscriptionKey(actorId: string, actorType: string): Promise<string> {
        return this.mapper.getSubscriptionKey(actorId, actorType);
    }

    public initialize(subscriptionKey: string): void {
        const translationApiClient = TranslationService.getInstance(subscriptionKey);
        this.mapper.setApiClient(translationApiClient);
    }

    public translate(messageId: number, translateTo: string, text: string): Promise<TranslatedMessage> {
        return this.mapper.translate(messageId, translateTo, text);
    }

    public async persistTranslatedMessage(
        language: string,
        threadId: number,
        messageId: number,
        originalText: string,
        translatedText: string,
    ): Promise<void> {
        try {
            const threadTranslatedMessages = await this.getOrCreateThreadInTranslationCache(language, threadId);
            const cache = await this.getAllTranslatedMessages();

            const newTranslatedMessage: CachedTranslationMessage = {
                messageId,
                originalText,
                translatedText,
            };

            if (threadTranslatedMessages.translations.length >= MAXIMUM_TRANSLATED_MESSAGES_PER_THREAD) {
                threadTranslatedMessages.translations.shift();
            }

            threadTranslatedMessages.translations.push(newTranslatedMessage);
            cache[threadId] = threadTranslatedMessages;
            this.updateStorage(cache);
        } catch (e) {
            console.error(e);
            captureException(new Error(`[MessengerTranslation] Failed to persist translated message: ${e.message}`));
        }
    }

    public async updateThreadLastOpenedDate(threadId: number): Promise<void> {
        try {
            const cache = await this.getAllTranslatedMessages();

            if (cache[threadId]) {
                cache[threadId].lastOpenedAt = new Date().toISOString();
                this.updateStorage(cache);
            }
        } catch (e) {
            console.error(e);
            captureException(
                new Error(`[MessengerTranslation] Failed to update thread last opened date: ${e.message}`),
            );
        }
    }

    public async getTranslatedMessages(threadId: number): Promise<CachedTranslationMessage[]> {
        try {
            const cache = await this.getAllTranslatedMessages();

            if (!cache[threadId]) {
                return [];
            }

            return cache[threadId].translations;
        } catch (e) {
            console.error(e);
            captureException(new Error(`[MessengerTranslation] Failed to get translated messages: ${e.message}`));
            return [];
        }
    }

    public async getTranslatedMessage(
        threadId: number,
        messageId: number,
    ): Promise<CachedTranslationMessage | undefined> {
        try {
            const cache = await this.getAllTranslatedMessages();

            if (!cache[threadId]) {
                return undefined;
            }

            return cache[threadId].translations.find((message) => message.messageId === messageId) || undefined;
        } catch (e) {
            console.error(e);
            captureException(new Error(`[MessengerTranslation] Failed to get translated message: ${e.message}`));
            return undefined;
        }
    }

    public async removeInvalidTranslations(language: string): Promise<void> {
        try {
            const cache = await this.getAllTranslatedMessages();

            const expiredThreadIds = this.findThreadsWithExpiredTranslations(cache);
            const threadsWithDifferentLanguage = this.findThreadsWithDifferentLanguage(cache, language);

            for (const threadId of [...expiredThreadIds, ...threadsWithDifferentLanguage]) {
                if (cache[threadId]) {
                    delete cache[threadId];
                }
            }

            this.updateStorage(cache);
        } catch (e) {
            console.error(e);
            captureException(new Error(`[MessengerTranslation] Failed to remove invalid translations: ${e.message}`));
        }
    }

    private async getOrCreateThreadInTranslationCache(
        language: string,
        threadId: number,
    ): Promise<CachedTranslationThread> {
        try {
            const cache = await this.getAllTranslatedMessages();

            if (!cache[threadId]) {
                return this.createThreadInTranslationCache(cache, language, threadId);
            }

            return cache[threadId];
        } catch (e) {
            throw new Error(`[MessengerTranslation] Failed to get or create thread in translation cache: ${e.message}`);
        }
    }

    private async createThreadInTranslationCache(
        cache: MessengerTranslationCache,
        language: string,
        threadId: number,
    ): Promise<CachedTranslationThread> {
        const newThread = {
            language,
            threadId,
            lastOpenedAt: new Date().toISOString(),
            translations: [],
        };

        const updatedCache = { ...cache, [threadId]: newThread };

        this.updateStorage(updatedCache);

        return newThread;
    }

    private async getAllTranslatedMessages(): Promise<MessengerTranslationCache> {
        try {
            const cache = await storage.get(STORAGE_KEY);

            if (!cache) {
                this.updateStorage({});
                return {};
            }

            return JSON.parse(cache);
        } catch (e) {
            throw new Error(`[MessengerTranslation] Failed to get translated messages from storage: ${e.message}`);
        }
    }

    private updateStorage(cache: MessengerTranslationCache): void {
        storage.set(STORAGE_KEY, cache);
    }

    private findThreadsWithExpiredTranslations(cache: MessengerTranslationCache): string[] {
        const threadIds = Object.keys(cache);
        const now = dayjs();

        return threadIds.filter((threadId) => {
            const threadLastOpenedAt = dayjs(cache[threadId].lastOpenedAt);
            const diff = now.diff(threadLastOpenedAt, 'days', true);
            return diff > TRANSLATION_CACHE_EXPIRATION_IN_DAYS;
        });
    }

    private findThreadsWithDifferentLanguage(cache: MessengerTranslationCache, language: string): string[] {
        const threadIds = Object.keys(cache);

        return threadIds.filter((threadId) => {
            return cache[threadId].language !== language;
        });
    }
}
