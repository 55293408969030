/** getQueryParamsForArray('filter[highlightIds]', [100]) => { filter[highlightIds][0]: '100 } */
export const getQueryParamsForArray = <T extends { toString: () => string }>(
    key: string,
    data: T[] = [],
): { [key: string]: string } => {
    return data.reduce<{ [key: string]: string }>((query, value, i) => {
        // eslint-disable-next-line no-param-reassign
        query[`${key}[${i}]`] = value.toString();
        return query;
    }, {});
};
