import { SyncStorage } from 'utils/storage';

type DataId = string | number;
export interface DataCacherInterface {
    check<T>(id: DataId): T | undefined;
    persist<T>(id: DataId, data: T): void;
    update<T>(id: DataId, body: T): void;
    process<T>(id: DataId, fetch: DataFetcher<T>): Promise<T>;
}

interface Values<T> {
    [id: string]: ItemValue<T>;
}

interface ItemValue<T> {
    persistedAt: number;
    data: T;
}
type DataFetcher<P> = () => Promise<P>;

class DataCacher implements DataCacherInterface {
    constructor(
        private storage: SyncStorage,
        private key: string,
        private ttl = 60000,
    ) {}

    public check<T>(id: DataId) {
        const { storage, key, ttl } = this;
        const values = storage.get<Values<T>>(key);
        if (!values) return;
        const value = values[id];
        if (!value) return;
        const { persistedAt, data } = value;
        if (Date.now() - persistedAt > ttl) {
            delete values[key];
            storage.set(key, values);
            return;
        }
        return data;
    }

    public persist<T>(id: DataId, data: T) {
        const { storage, key } = this;
        const values = storage.get<Values<T>>(key) || {};
        const persistedAt = Date.now();
        values[id] = { persistedAt, data };
        storage.set(key, values);
    }

    public async process<T>(id: DataId, fetch: DataFetcher<T>) {
        const cachedData = this.check<T>(id);
        if (cachedData) return cachedData;
        try {
            const mappedData = await fetch();
            this.persist(id, mappedData);
            return mappedData;
        } catch (e) {
            throw new Error(e);
        }
    }

    public update<T>(id: DataId, body: T) {
        const { storage, key } = this;
        const values = storage.get<Values<T>>(key);
        if (!values) return;
        const value = values[id];
        if (!value) return;
        const persistedAt = Date.now();
        const data = { ...value.data, ...body };
        values[id] = { persistedAt, data };
        storage.set(key, values);
    }
}
export default DataCacher;
