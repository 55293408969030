import Ext from '../../lib/vendor/ExtCore';
import DOMPurify from 'dompurify';
import HTML5MultiUploaderController from './HTML5MultiUploaderController';
import UploaderView from '../MyContent/UploaderView';
import Util from '../../lib/chaos/Util';
import { Broadcaster } from '../../lib/chaos/Broadcaster';

export default class ChannelHTML5UploaderController extends HTML5MultiUploaderController {
	static EVENT_UPLOAD_COMPLETE = 'upload-complete';
	static EVENT_ALL_UPLOAD_COMPLETE = 'upload-all-complete';
	static EVENT_UPLOADED_MEDIA_DISPLAYED = 'uploaded-media-displayed';
	static EVENT_ON_MEDIA_TEMPLATE_READY = 'on-media-template-ready';
	static EVENT_CONVERTED_FILE_FAILED = 'on-converted-file-failed';

	get properties() {
		return { ...super.properties,
			/** @var {String}    Component name */
			name : 'ChannelHTML5UploaderController',
			/** @var {String}    CSS class name of a box containing the uploaded files */
			mediaBoxCls : 'mediaBox',
			/** @var {String}    Attribute name containing the file's token for DomQueries */
			dataIdAttributeName : 'data-document-id',
			/** @var {String}    Videos folder ID's */
			videosFolderId : 'videos_folder',
			/** @var {String}    Active folder cls */
			activeFolderCls : 'active_folder',
			/** @var {String}    Uploader block cls */
			uploaderBlockCls : 'actionBlock',
			/** @var {String}    the name of the photo media type - can be found in the data-media-type attr */
			photoMediaType : 'photo',
			/** @var {String}    the name of the video media type - can be found in the data-media-type attr */
			videoMediaType : 'video',
			/** @var {Bool}      True if token required for send to MWH */
			isTokenRequired : true
		};
	}

	/**
	 * Standard initializer
	 *
	 * @param {Object|String} el
	 * @param {Object} config
	 */
	init(el, config) {
		this.addEvents(
			ChannelHTML5UploaderController.EVENT_UPLOAD_COMPLETE,
			ChannelHTML5UploaderController.EVENT_ALL_UPLOAD_COMPLETE,
			ChannelHTML5UploaderController.EVENT_UPLOADED_MEDIA_DISPLAYED,
			ChannelHTML5UploaderController.EVENT_ON_MEDIA_TEMPLATE_READY
		);
		this.cls = Object.assign({}, this.cls, {
			progressBar   : 'progressContainer',
			statusPercent : 'loading_percent'
		});
		this.config = config;
		this.UploaderView = this.config.items.UploaderView.component;

		super.init(el, config);
		if (!this.getAllowedUploadCount()) {
			this.disableInput();
		}
	}

	/**
	 * Polyfills the old this.element (because now it is the uploader, before it was the active tab content)
	 *
	 * @returns {*|string|DOMImplementation|Node|File|HTMLElement}
	 */
	getElement() {
		return Ext.select('.commonTabsContent.active').item(0);
	}

	/**
	 * Recognizes if the current folder is a "photo" or "video" folder.
	 *
	 * @method getFolderType
	 * @public
	 *
	 * @return {String} "photo" or "video"
	 */
	getFolderType() {
		return this.getElement().dom.id === this.videosFolderId ? this.videoMediaType : this.photoMediaType;
	}

	/**
	 * Gets the current uploader block
	 *
	 * @method getUploaderBlockEl
	 * @public
	 *
	 * @return {Object} UploaderBlockEl
	 */
	getUploaderBlockEl() {
		var contentEl = this.getElement();
		if (contentEl.dom.id !== this.videosFolderId) {
			return contentEl.select(this.activeFolderCls.dot() + ' ' + this.uploaderBlockCls.dot()).item(0);
		}

		return contentEl.select(this.uploaderBlockCls.dot()).item(0);
	}

	/**
	 * Return current folder ID
	 *
	 * @returns {string|undefined}
	 */
	getCurrentFolderId() {
		var el = this.getElement().select('.active_folder').item(0);
		return el ? el.dom.id.split('_')[1] : undefined;
	}

	/**
	 * Returns the element to show the common (all) errors on.
	 *
	 * @overrrides HTML5MultiUploaderController
	 */
	getCommonErrorEl() {
		return this.element.jq().closest('.boxInner');
	}

	/**
	 * Returns the already uploaded count in a folder.
	 *
	 * @overrides HTML5Uploader
	 * @returns {Number}
	 */
	getUploadedCount() {
		return parseInt(this.getElement().jq().find('.contentCount span').text(), 10) || 0;
	}

	/**
	 * Creates enabled media box
	 *
	 * @method createEnabledMediaBox
	 * @param {object} ev   Json object
	 *
	 * @return void;
	 */
	createEnabledMediaBox(ev) {
		var documentId = ev.documentId,
			post = ev.document[documentId].post,
			uploadedMediaBox = this._getMediaBoxByDocumentId(documentId),
			convertingBlockEl =
				this.getElement().select('[' + this.dataIdAttributeName + '=' + documentId + ']').item(0);

		if (convertingBlockEl) {
			Ext.DomHelper.insertAfter(convertingBlockEl, post);
		}
		uploadedMediaBox.remove();
		this.fireEvent(ChannelHTML5UploaderController.EVENT_UPLOADED_MEDIA_DISPLAYED, {
			scope : this,
			type  : this.type
		});
	}

	/**
	 * Converted image failed. Called by addMediaComponent.
	 *
	 * @param {Object} params   Event object
	 * @method onGetConvertedFileFailed
	 * @public
	 *
	 * @return void
	 */
	onGetConvertedFileFailed(params) {
		if (params.documentId) {
			var documentId = params.documentId,
				failedMediaEl = this._getMediaBoxByDocumentId(documentId),
				newFailedMediaBoxEl;
			if (failedMediaEl.getCount()) {
				newFailedMediaBoxEl = this.UploaderView.createFailedMediaBox(failedMediaEl.item(0), {
					documentId  : documentId,
					errorReason : params.errorReason,
					fileName    : DOMPurify.sanitize(params.fileName)
				});
			}
			this.fireEvent(ChannelHTML5UploaderController.EVENT_CONVERTED_FILE_FAILED, {
				scope   : this,
				boxEl   : newFailedMediaBoxEl,
				message : params.errorReason
			});
		}
	}

	/**
	 * Runs on change.
	 *
	 * @param {Object} ev   {Object}
	 */
	_onBeforeChange(ev) {
		this.UploaderView.setUploadingClass(this.getElement(), UploaderView.UPLOADING_STATE_COMMAND_ADD);
		this.options.formData.folderId = this.getCurrentFolderId();
		super._onBeforeChange(ev);
	}

	/**
	 * After successfully got the tokens.
	 *
	 * @param param
	 * @private
	 */
	_onAfterGettokensok(param) {
		var i = this.pluginDatas.length;
		param.data.documents.reverse().forEach(function(doc) {
			var fileName = Util.escapeHTML(this.pluginDatas[--i].files[0].name);
			this.UploaderView.createUploadingMediaBox({
				documentId : doc.mongoId,
				fileIndex  : '000',
				fileName   : DOMPurify.sanitize(fileName),
				mediaType  : param.uploaderInstance.mediaType
			});
		}.bind(this));
	}

	/**
	 * Runs on upload complete.
	 *
	 * @method onUploadComplete
	 * @public
	 *
	 * @param {Object} params Store the image token
	 *
	 * return void;
	 */
	_onAfterDone(ev) {
		var fileName = ev.files[0].name;
		var el = this.getItemByFileName(fileName);
		var response;
		var isFailed;
		var errors = this.getSortedErrors()[fileName];

		try {
			response = JSON.parse(ev.result);
		}
		catch (e) {
			/* webpack-strip-block:removed */
		}

		isFailed = !(response && response.status === 'OK');

		if (!isFailed) {
			var loadingMediaBox = this.UploaderView.getLoadingMediaBox({
				documentId : ev.formData.mongo,
				fileIndex  : 0,
				fileName   : DOMPurify.sanitize(fileName)
			});
			el.after(loadingMediaBox);
			el.remove();

			this.fireEvent(ChannelHTML5UploaderController.EVENT_UPLOAD_COMPLETE, {
				scope      : this,
				params     : ev,
				documentId : ev.formData.mongo,
				token      : ev.formData.token,
				type       : this.type,
				fileIndex  : 0
			});
		}
		else if (errors) {
			this.UploaderView.createFailedMediaBox(Ext.get(el.get(0)), {
				errorReason : this.buildErrorMsg(errors),
				fileName    : DOMPurify.sanitize(fileName)
			});
		}
	}

	/**
	 * When suddenly upload is aborted. Ex. when navigation happens.
	 *
	 * @private
	 */
	_onStop() {
		this._onAllDone();
	}

	/**
	 * After every upload has finished.
	 *
	 * @overrides HTML5MultiUploaderController
	 * @private
	 */
	_onAllDone() {
		this.fireEvent(ChannelHTML5UploaderController.EVENT_ALL_UPLOAD_COMPLETE);
		this.UploaderView.setUploadingClass(this.getElement(), UploaderView.UPLOADING_STATE_COMMAND_REMOVE);
		super._onAllDone();
	}

	/**
	 * Callback if prepare failed.
	 *
	 * @overrides HTML5MultiUploaderController
	 * @private
	 */
	_onAfterPreparefail() {
		if (!this.pluginDatas.length) {
			this._onAllDone();
		}
	}

	/**
	 * @overrides HTML5MultiUploaderController
	 * @private
	 */
	_onAdd() {}

	/**
	 * Tries to get a media box from DOM by the given document id
	 *
	 * @method _getMediaBoxByDocumentId
	 * @private
	 * @param {String} documentId   Description
	 *
	 * @return {Object}
	 */
	_getMediaBoxByDocumentId(documentId) {
		return Ext.select('.commonTabsContent.active')
			.item(0)
			.select(this.mediaBoxCls.dot() + '[' + this.dataIdAttributeName + '=' + documentId + ']');
	}

	/**
	 * Forwards the UploaderView event to the component
	 *
	 * @method onMediaTemplateReady
	 * @public
	 *
	 * @return void
	 */
	_onMediaTemplateReady(ev) {
		var	mediaType = ev.mediaType,
			currentFolderMediaType = this.getFolderType();

		if (!mediaType || !currentFolderMediaType || mediaType === currentFolderMediaType) {
			this.UploaderView.insertContentAfter(this.getUploaderBlockEl(), ev.mediaTemplate);
			this.fireEvent(ChannelHTML5UploaderController.EVENT_ON_MEDIA_TEMPLATE_READY, ev);
		}
	}

	isVisible() {
		return true;
	}

	/**
	 * Checks the uploader if its uploading or not.
	 * Then adds/removes uploading class accordingly.
	 */
	updateUploaderStatus() {
		var command = this.isUploading() ?
			UploaderView.UPLOADING_STATE_COMMAND_ADD : UploaderView.UPLOADING_STATE_COMMAND_REMOVE;
		this.UploaderView.setUploadingClass(this.getElement(), command);
	}

	/**
	 * If this gets true as the first parameter, then the uploader will get token with the isPromoVideo param.
	 *
	 * @param {Object} params
	 * @private
	 */
	_setPromoVideoState(params) {
		this.promoVideoEnabled = params.isPromoVideo ? 1 : 0;
		this.videoPrice = params.price;
	}

	/**
	 *
	 * @return {*|string}
	 */
	extendGetTokenUrl() {
		let extendedUrl = Util.updateQueryString(
			'attributes[isPromoVideo]',
			this.promoVideoEnabled || 0,
			this.options.tokenUrl
		);
		return Util.updateQueryString(
			'attributes[price]',
			this.videoPrice,
			extendedUrl
		);
	}

	/**
	 * Bind events.
	 * @overrides HTML5Uploader
	 */
	bind() {
		super.bind();
		this.UploaderView.on(UploaderView.EVENT_MEDIA_TEMPLATE_READY, this._onMediaTemplateReady, this);
		this.UploaderView.on(UploaderView.EVENT_PROMOTED_VIDEO_SELECTED, this._setPromoVideoState, this);
		this.UploaderView.on(UploaderView.EVENT_PREMIUM_VIDEO_SELECTED, this._setPromoVideoState, this);

		Broadcaster.clearListeners(UploaderView.GLOBALEVENT_UPDATE_UPLOADER_STATUS);
		Broadcaster.on(UploaderView.GLOBALEVENT_UPDATE_UPLOADER_STATUS, this.updateUploaderStatus, this);
	}
}