	
// This value is from XRegExp library to support the "\p{Latin}" script replacement. Copying this here to keep
// existing functionality unchanged, instead of using the JS-compatible "\p{L}" script (which has different
// character rules).
const LATIN_SCRIPT = {
    pattern: '\p{Latin}',
    value: 'A-Za-z\xAA\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u02E0-\u02E4\u1D00-\u1D25\u1D2C-\u1D5C\u1D62-\u1D65\u1D6B-\u1D77\u1D79-\u1DBE\u1E00-\u1EFF\u2071\u207F\u2090-\u209C\u212A\u212B\u2132\u214E\u2160-\u2188\u2C60-\u2C7F\uA722-\uA787\uA78B-\uA7CA\uA7D0\uA7D1\uA7D3\uA7D5-\uA7D9\uA7F2-\uA7FF\uAB30-\uAB5A\uAB5C-\uAB64\uAB66-\uAB69\uFB00-\uFB06\uFF21-\uFF3A\uFF41-\uFF5A',
};

const CYRILLIC_SCRIPT = {
    pattern: '\p{Cyrillic}',
    value: 'а-я'
};

export const replacePhpRegexScripts = (pattern) => {
    // PHP has a {Latin} regex script for latin character matching. Substituting that here
    // with a replacement value taken from the XRegExp library we used to use.
    let finalPattern = pattern;
    if (finalPattern?.length && finalPattern.includes(LATIN_SCRIPT.pattern)) {
        finalPattern = finalPattern.replaceAll(LATIN_SCRIPT.pattern, LATIN_SCRIPT.value);
    }
    if (finalPattern?.length && finalPattern.includes(CYRILLIC_SCRIPT.pattern)) {
        finalPattern = finalPattern.replaceAll(CYRILLIC_SCRIPT.pattern, CYRILLIC_SCRIPT.value);
    }
    return finalPattern;
};
