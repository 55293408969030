/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-depth */

import dayjs from 'global/dayjsExtended';
import { differenceInDays, addDays, subDays } from 'date-fns';
import CONST from '../../lib/constant/Constants';
import Ext from '../../lib/vendor/ExtCore';
import ChaosObject from '../../lib/chaos/Object';
import Config from '../../lib/chaos/Config';
import Connection from '../../lib/chaos/Connection';
import { beginningOfDayFromDateString } from '../../../msc_modules/src/utils/datetime';

import { InitRiot } from '../App/App';

import './RangeSlider.scss';

/**
 * RangeSlider
 *
 * Improve lehetosegek:
 * - a this.rangeBeginLeft automatikusan legyen megallapitva vagy korrigalva legyen css-el
 * - Folyamatos csuszka modban a csuszka left css erteket irja ki az input hiddenbe. Ezt lehetne allithatova tenni.
 * - Folyamatos csuszka mod nincsen kiprobalva !!!
 * - mouseup korrekcio -t tesztelni
 */

export default class RangeSlider extends ChaosObject {
    // Custom Event
    static ON_SAVE = 'on-save';

    get properties() {
        return {
            ...super.properties,
            /** @var {Boolean} steppedSlide				 Ha true, akkor lepesrol lepesre lehet csusztatni, ha false akkor folyamatos csuszas van */
            steppedSlide: true,
            /** @var {Number} sliderMinBeginValue	     A csuszka min ertek alapbeallitasa */
            sliderMinBeginValue: 0,
            /** @var {Number} sliderMaxBeginValue	     A csuszka max ertek alapbeallitasa */
            sliderMaxBeginValue: 1,
            /** @var {Number} sliderElementAmount	     A csuszka elemeinek a szama */
            sliderElementAmount: 1,
            /** @var {String} tickerSel	     			 A csuszkak kozos szelektora */
            tickerSel: 'periodSliderLineEnd',
            /** @var {String} sliderTrackId	     		 A csuszkak hattere, azaz a csuszka-palya ID-je */
            sliderTrackCls: 'periodSliderBg',
            /** @var {Number} sliderTrackWidth     		 A csuszka teljes szelessege */
            sliderTrackWidth: 0,
            /** @var {String} visibleDayCls              A harmadik sor listaelemeinek a csuszkahoz tartozo show class-a */
            visibleDayCls: 'visibleDay',
            /** @var {String} selectedId	     	     A kijeloles jelzo sav ID-je */
            sliderLineCls: 'periodSliderLine',
            /** @var {String} inputMinSel	     	     A Min erteket tarolo input mezo szelektora */
            inputMinSel: 'input[name=min]',
            /** @var {String} inputMaxSel	     	     A Max erteket tarolo input mezo szelektora */
            inputMaxSel: 'input[name=max]',
            /** @var {String} saveRoute	     	         A csusztatast koveto ajax keres Route-ja */
            saveRoute: 'BroadcasterCenter/GetSummary',
            /** @var {String} enableIntegratedSave	     Hasznaljuk-e a beepitett save metodust, vagy sajatot akarunk irni */
            enableIntegratedSave: true,
            /** @var {Boolean} noSave	                 Mentes teljes kikapcsolasa, csak a hidden mezok ertekeit frissiti */
            noSave: false,
            /** @var {String} mouseUpCheckInterval	     Ennyi millisecundum-onkent ellenorizze, hogy felengedtuk-e az egergombot */
            mouseUpCheckInterval: 3000,
            /** @var {String} periodStartTextCls	     A periodus kezdo szoveg dobozanak classa */
            periodStartTextCls: '.periodStartText',
            /** @var {String} periodEndTextCls	         A periodus vege szoveg dobozanak classa */
            periodEndTextCls: '.periodEndText',
            /** @var {Number} sliderFirstElement	     A slider elso elemenek erteke */
            sliderFirstElement: 1,
            /** @var {String} saveUrl	                 Ajaxos elmentes eseten a cim ahova az adatot kuldi a slider */
            saveUrl: undefined,
            /** @var {Bool} isOverlayRefreshEnabled      Overlayes ujratoltes uj ertek eseten*/
            isOverlayRefreshEnabled: false,
            /** @var {Bool} isPageRefreshEnabled	     Oldal ujratoltes uj ertek eseten*/
            isPageRefreshEnabled: false,
            /** @var {Obj} integratedSaveCallbackFn	     Ajax valaszt koveto helyi mukodesek megvalositasara callback*/
            integratedSaveCallbackFn: undefined,
            /** @var {String} bodyLoadingCls             Az egesz body-n mukodo loading cursort eloidezo class */
            bodyLoadingCls: 'loading',
            /** @var {String} lineRoundedCls             A csuszkavonal elejenek kerekseget allito class */
            lineRoundedCls: 'rnded',
            /** @var {String} overlayContainerId         Oeverlay container id */
            overlayContainerId: 'overlayContainer',
            /** @var {String} pageContainerId            Page container Id */
            pageContainerId: 'pageContainer',

            /** --- PRIVATES --- **/
            /** @var {Boolean} _slidingInProgress	    Ebben taroljuk, hogy eppen csusztatas alatt van-e a slider vagy nem */
            _slidingInProgress: false,
            /** @var {Object} _selectedEl               A kijeloles jelzo sav */
            _selectedEl: undefined,
            /** @var {Object} _sliderEl                 A csuszka */
            _sliderEl: undefined,
            /** @var {Object} _inputMinEl               A Min erteket tarolo input mezo */
            _inputMinEl: undefined,
            /** @var {Object} _inputMaxEl               A Max erteket tarolo input mezo */
            _inputMaxEl: undefined,
            /** @var {Object} _mouseDown                Azt tarolja , hogy le van-e nyomva az egergomb vagy nem */
            _mouseDown: false,
            /** @var {Object} _tickerElement	        A csuszkakat tartalmazo objektum */
            _tickerElements: undefined,
            /** @var {Number} _maxSliderPos	            Letarolja a max slider left helyzetet */
            _maxSliderPos: 0,
            /** @var {Number} _minSliderPos	            Letarolja a min slider left helyzetet */
            _minSliderPos: 0,
            /** @var {Number} _stepWidth				Ez egy lepes szelessege  */
            _stepWidth: undefined,
            /** @var {string} _dataPeriod               A stathoz tartozo ido periodus (2 hetes) select ID-ja */
            _dataPeriod: 'input[name=period]',
            /** @var {string} _dataYear                 A stathoz tartozo ev select ID-ja */
            _dataYear: 'input[name=periodYear]',
            /** @var {Number} _dayFrom                  A kezdonap */
            _dayFrom: undefined,
            /** @var {Number} _dayTo:                   Az utolso nap */
            _dayTo: undefined,
            /** @var {Date} _dateFrom
			_dayFrom : undefined,
			/** @var {Date} _dateTo:
			_dayTo : undefined,
			/** @var {Number} _month                    A honap */
            _month: undefined,
            /** @var {Number} _year                     Az ev */
            _year: undefined,
            /** @var {Object} _periodElements           data-elements attributumban el tudsz helyezni egy JSON objecktumot, hogy a masodik sorban konkretan milyen elemek legyenek*/
            _periodElements: undefined,
            /** @var {Array}                            HammerJS Object Array */
            _hammers: [],
            /** @var {Object} _overlayContainerEl       Overlay Container */
            _overlayContainerEl: undefined,
            /** @var {Object} _pageContainerEl          Page Container */
            _pageContainerEl: undefined,
            /** @var number half of the selector dot size */
            halfDotSize: 13,
        };
    }
    init(el, config) {
        this._overlayContainerEl = Ext.get(this.overlayContainerId);
        this._pageContainerEl = Ext.get(this.pageContainerId);
        // Get after form-inputs are inited
        setTimeout(
            function () {
                this._dataYearEl = Ext.get(document.querySelector(this._dataYear));
                this._dataPeriodEl = Ext.get(document.querySelector(this._dataPeriod));
            }.bind(this),
        );

        const fromDate = beginningOfDayFromDateString(this.element.dom.getAttribute('data-from'));
        const toDate = beginningOfDayFromDateString(this.element.dom.getAttribute('data-to'));

        this._dateFrom = fromDate;
        this._dateTo = toDate;
        this._dayFrom = fromDate.getDate();
        this._dayTo = toDate.getDate();
        this._month = fromDate.getMonth();
        this._year = fromDate.getFullYear();
        this._periodElements = this.element.dom.getAttribute('data-elements');

        this.initSliderComponent();

        super.init(el, config);
    }

    /**
     * A csusztatas kezdetekor initeljuk a csusztatashoz szukseges valtozokat
     *
     * @param {EventObject} e event
     */
    initVars(e) {
        // Most eppen ezt az elemet csusztatjuk.
        this._sliderEl = Ext.get(e.target);
        this._sliderElPageOffset = e.center.x;
        // Ha a max csuszkat hasznalom akkor true, ha a min-t akkor false
        this._sliderElLeft = Ext.get(e.target).getLeft(true);
    }

    /**
     * Az egermozgas soran lefuto esemeny. A slider huzgalasat szolgalja.
     *
     * @param {EventObject} e event
     */
    onMouseMove(e) {
        e.preventDefault();
        // Drag kozben ne jelolgessen ki semmit
        document.onselectstart = function () {
            return false;
        };

        if (!this._sliderEl) {
            return false;
        }
        // Az uj css 'left' ertek kiszamolasa
        var newLeft = this._sliderElLeft + (this._sliderElPageOffset - e.center.x) * -1;

        //Mozoghat a csuszka, ha az ervenyes tartomanyon belul van
        if (newLeft >= 0 && newLeft <= this.sliderTrackWidth) {
            //Beallitom a csuszka uj helyzetet
            //Ha leptetes van beallitva
            if (this.steppedSlide) {
                var positionMultiplier = Math.round(newLeft / this._stepWidth);
                var selectedDay = addDays(this._dateFrom, positionMultiplier);
                var dayOfWeek = dayjs(selectedDay).format('ddd');
                this._sliderEl.dom.innerHTML = `<span class="sliderTooltip"><strong>${dayjs(selectedDay).format(
                    'D',
                )}</strong> ${dayOfWeek}</span>`;
                this._sliderEl.setStyle('left', positionMultiplier * this._stepWidth - this.halfDotSize + 'px');
            }
            // Ha folyamatos csusztatas van beallitva
            else {
                this._sliderEl.setStyle('left', newLeft + 'px');
            }

            //Megkeresem a helyzet alapjan hogy milyen left erteket vett fel a 2 csuszka
            this._maxSliderPos = 0;
            this._minSliderPos = 0;
            for (let i = 0; i < this._tickerElements.elements.length; i++) {
                //Elso talalat legyen a min ertek
                if (i === 0) {
                    this._minSliderPos = this._tickerElements.item(i).getLeft(true);
                } else if (this._minSliderPos <= this._tickerElements.item(i).getLeft(true)) {
                    this._maxSliderPos = this._tickerElements.item(i).getLeft(true);
                }
                //Ha a masodik talalat kevesebb, az eddigi min maxxa valik, es az uj ertek lesz a min.
                else {
                    this._maxSliderPos = this._minSliderPos;
                    this._minSliderPos = this._tickerElements.item(i).getLeft(true);
                }
            }
            //Frissitem az inputba beirt ertekeket
            //TODO: optimalizalni kellene, hogy ne minden pixelen fusson le
            //this.refreshRange();

            //Beallitom a csik hosszat es leftjet
            if (!this.noRange) {
                this._selectedLineEl.dom.style.width = this._maxSliderPos - this._minSliderPos + 'px';
                this._selectedLineEl.setStyle('left', this._minSliderPos + 'px');
            } else {
                // 5 pix korrekcio szukseges
                this._selectedLineEl.dom.style.width = this._minSliderPos + 5 + 'px';
            }
        }
    }

    /**
     * Az egergomb elengedeset kovetoen levesszuk a csuszkat mukodteto mousemove esemenyt
     */
    onBodyMouseUp() {
        if (this._slidingInProgress) {
            this._sliderEl = undefined;

            Ext.getBody().removeClass('noselect');

            if (this._overlayContainerEl) {
                this._overlayContainerEl.removeClass('noselect');
            }

            if (this._pageContainerEl) {
                this._pageContainerEl.removeClass('noselect');
            }

            document.onselectstart = null;

            this._slidingInProgress = false;

            const min =
                    this._minSliderPos === 0
                        ? 0
                        : Math.round((this._minSliderPos + this.halfDotSize) / this._stepWidth),
                max =
                    this._maxSliderPos === 0
                        ? 1
                        : Math.round((this._maxSliderPos + this.halfDotSize) / this._stepWidth) + 1;

            const startDate = dayjs(addDays(this._dateFrom, min)).format('D'),
                endDate = dayjs(subDays(this._dateTo, this.sliderElementAmount - max)).format('D'),
                currentStartDate = parseInt(this._inputMinEl.dom.value, 10).toString(),
                currentEndDate = parseInt(this._inputMaxEl.dom.value, 10).toString();

            if (startDate !== currentStartDate || endDate !== currentEndDate) {
                this.saveState(startDate, endDate);
            }
        }
    }

    /**
     * Az csuszkan torteno egergomb lenyomasat kovetoen lefuto metodus
     *
     * @param {EventObject} e event
     * @param {DomElement} t target
     */
    onMouseDown(e, t) {
        this.initVars(e, t);
        this._slidingInProgress = true;
        Ext.getBody().addClass('noselect');
        //Moz select fix
        if (this._overlayContainerEl) {
            this._overlayContainerEl.addClass('noselect');
        }
        if (this._pageContainerEl) {
            this._pageContainerEl.addClass('noselect');
        }
    }

    /**
     * Csuszka aktualis allasanak elkuldese AJAX-szal, ha be van kapcsolva a beepitett mentes.
     * Ha nincs bekapcsolva a beepitett mentes, akkor kivulrol egy esemenyen keresztul meg lehet
     * adni sajat save callback-et.
     *
     * @param {Number} min
     * @param {Number} max
     */
    saveState(min, max) {
        var newUrl = this.saveUrl.split('?'),
            fullPeriod = this._dataPeriodEl.dom.value;

        if (this.noSave) {
            return;
        }
        //ujratoltes overlay meghivassal
        if (this.isOverlayRefreshEnabled) {
            this.overlayComponent = Config.get('overlayComponent');
            this.overlayComponent.getOverlay(newUrl[0] + '?period=' + fullPeriod + '&fromDay=' + min + '&toDay=' + max);
        }
        //ujratoltes a tartalomba
        if (this.isPageRefreshEnabled) {
            // Kurzor toltesre allitasa
            Ext.getBody().addClass(this.bodyLoadingCls);
            // Unbindelunk, hogy mentes kozben ne tortenhessen semmi
            this.setSliderEnable(false);

            //Kuldd el az ajaxot
            Connection.Ajax.request({
                type: CONST.TYPE_JSON,
                url: newUrl[0] + '?period=' + fullPeriod + '&fromDay=' + min + '&toDay=' + max,
                scope: this,
                success: this.saveSucessCallback,
                error: this.saveErrorCallback,
                failure: this.saveErrorCallback,
                method: CONST.GET,
                synchron: true,
            });
        }

        // A beepitett ajax mentes.
        if (this.enableIntegratedSave) {
            Connection.AjaxComponent.request({
                type: CONST.TYPE_JSON,
                scope: this,
                url: this.saveUrl,
                params: {
                    min: min,
                    max: max,
                },
                method: CONST.GET,
                synchron: true,
                success(response) {
                    this.saveSucessCallback(response);
                },
                error() {
                    /* webpack-strip-block:removed */
                },
                failure() {
                    /* webpack-strip-block:removed */
                },
            });
        }
    }

    /**
     * A sikeres mentest kovetoen lefuto callback
     */
    saveSucessCallback(response) {
        Ext.getBody().removeClass(this.bodyLoadingCls);
        this.setSliderEnable(true);
        if (this.integratedSaveCallbackFn) {
            this.integratedSaveCallbackFn.call(null, response);
        }
        // Menteskor esemeny
        this.fireEvent(RangeSlider.ON_SAVE, this);
    }

    /**
     * A sikertelen mentes kovetoen lefuto callback
     */
    saveErrorCallback() {
        /* webpack-strip-block:removed */
        Ext.getBody().removeClass(this.bodyLoadingCls);
        this.setSliderEnable(true);
    }

    /**
     * Beallitja valtozoba , hogy az egergomb le van-e nyomva vagy nincs
     */
    setMouseDown(ev, target, options) {
        if (options.up) {
            this._mouseDown = false;
        } else {
            this._mouseDown = true;
        }
    }

    /**
     * Torli a slider JS generalt reszeit
     */
    destroy() {
        Ext.get(this.id).select('ul').remove();
        Ext.get(this.id).select('div').remove();
    }

    /**
     * A kert idointervallum megvaltozasara  ujrapeldanyositja a slidert
     */
    onPeriodChange() {
        //esemenyek levetele
        this.unbind();
        //rangeSlider elemeinek torlese
        this.destroy();
        //uj letrehozas
        this.initSliderComponent();
        //esemenyek rakotese
        this.bind();
    }

    /**
     *  Peldanyositja a slider komponenst a megfelelo datum adatokkal
     *
     */
    initSliderComponent() {
        InitRiot();

        //Peldanyositsd a valtozokat
        this.sliderElementAmount = differenceInDays(this._dateTo, this._dateFrom) + 1;
        this.sliderFirstElement = parseInt(this._dayFrom, 10);
        this.firstDayNumber = this._dateFrom.getDay();
        this._inputMinEl = this.element.select(this.inputMinSel).item(0);
        this._inputMaxEl = this.element.select(this.inputMaxSel).item(0);
        const selectedDayFromNumber =
            (this._inputMinEl && parseInt(this._inputMinEl.dom.value, 10).toString()) ||
            parseInt(this._dayFrom, 10).toString();
        const selectedDayToNumber =
            (this._inputMaxEl && parseInt(this._inputMaxEl.dom.value, 10).toString()) ||
            parseInt(this._dayTo, 10).toString();
        let selectedDayFromIndex = 0,
            selectedDayToIndex = 0,
            selectedDayFrom = null,
            selectedDayTo = null;

        //Slider felepitese
        //A slider kezdo es vegpont ertekenek korrigalasa, hogy 1-tol induljon, mert konnyebb vele szamolni
        this.sliderMinBeginValue = this.sliderMinBeginValue - this.sliderFirstElement + 1;
        this.sliderMaxBeginValue = this.sliderMaxBeginValue - this.sliderFirstElement + 1;
        //Megallapitom egy lepes szelesseget.
        this._stepWidth = this.sliderTrackWidth / (this.sliderElementAmount - 1);

        //Korlatozd a kiirhato elemek szamat, hogy ne csusszon egymasra
        var daysObject = Config.get('sliderObj.days') || {},
            days = [
                daysObject.sun,
                daysObject.mon,
                daysObject.tue,
                daysObject.wed,
                daysObject.thu,
                daysObject.fri,
                daysObject.sat,
            ],
            periodDays = [],
            periodDayNames = [],
            periodElements = Ext.decode(this._periodElements),
            caption;

        // Annyi szam es nev lista elemet epitek amennyi a kert elemek szama
        for (let i = 0; i < this.sliderElementAmount; i++) {
            const day = addDays(this._dateFrom, i);
            const dayNumber = dayjs(addDays(this._dateFrom, i)).format('D');
            caption = periodElements ? periodElements[0][i] : dayNumber;
            periodDays[i] = { tag: 'li', html: '<span>&nbsp;</span>' + caption };

            if (dayNumber === selectedDayFromNumber) {
                selectedDayFromIndex = i;
                selectedDayFrom = day;
            }
            if (dayNumber === selectedDayToNumber) {
                selectedDayToIndex = i;
                selectedDayTo = day;
            }

            if (dayNumber !== selectedDayFromNumber && dayNumber !== selectedDayToNumber) {
                periodDayNames[i] = { tag: 'li', html: days[(i + this.firstDayNumber) % 7] };
            } else {
                periodDayNames[i] = { tag: 'li', html: days[(i + this.firstDayNumber) % 7], cls: 'visibleDay' };
            }
        }
        //Slider elemeinek objektumkent letrehozasa
        var contentArray = [
            { tag: 'div', cls: this.sliderTrackCls },
            { tag: 'div', cls: this.sliderLineCls },
            { tag: 'div', cls: this.tickerSel },
        ];

        if (!this.noRange) {
            contentArray.push({ tag: 'div', cls: this.tickerSel });
        }

        //DOM elemek letrehozasa
        for (let i = 0; i < contentArray.length; i++) {
            this.element.createChild(contentArray[i]);
        }

        this._selectedLineEl = this.element.select('.' + this.sliderLineCls).item(0);
        this._tickerElements = this.element.select('.' + this.tickerSel);

        if (this.noRange) {
            this._selectedLineEl.addClass(this.lineRoundedCls);
        }

        //Beallitom a slider szelesseget
        this.element.setWidth(this.sliderTrackWidth);
        this.element
            .select('.' + this.sliderTrackCls)
            .item(0)
            .setWidth(this.sliderTrackWidth);

        // Ha leptetos csuszkat akarunk
        if (this.steppedSlide) {
            // Pozicionalom a csikot
            var beginPosition = this._stepWidth * selectedDayFromIndex,
                endPosition = this._stepWidth * selectedDayToIndex;
            if (!this.noRange) {
                this._selectedLineEl.setWidth(endPosition - beginPosition);
            } else {
                this._selectedLineEl.setWidth(beginPosition);
            }

            if (!this.noRange) {
                this._selectedLineEl.setLeft(beginPosition);
            } else {
                this._selectedLineEl.setLeft(0);
            }
            //Pozicionalom a csuszka 2 elemet
            this._tickerElements.item(0).setLeft(beginPosition - this.halfDotSize);

            var weekDayFrom = dayjs(selectedDayFrom).format('ddd');
            this._tickerElements.item(0).dom.innerHTML = `<span class="sliderTooltip"><strong>${dayjs(
                selectedDayFrom,
            ).format('D')}</strong> ${weekDayFrom}</span>`;

            this._tickerElements.item(1).setLeft(endPosition - this.halfDotSize);
            var weekDayTo = dayjs(selectedDayTo).format('ddd');
            this._tickerElements.item(1).dom.innerHTML = `<span class="sliderTooltip"><strong>${dayjs(
                selectedDayTo,
            ).format('D')}</strong> ${weekDayTo}</span>`;
        } else {
            // @TODO: Ha nem leptetos slidert hasznalunk, akkor is pozicionalni kell a csuszkakat
        }
        //A csuszka kezdete-vege szovegek pozicionalasa
        var periodStartTextEl = this.element.select(this.periodStartTextCls).item(0);
        if (periodStartTextEl) {
            periodStartTextEl.setRight(this.sliderTrackWidth);
            periodStartTextEl.setStyle('display', 'inline-block');
        }

        var periodEndTextEl = this.element.select(this.periodEndTextCls).item(0);
        if (periodEndTextEl) {
            periodEndTextEl.setLeft(this.sliderTrackWidth);
            periodEndTextEl.setStyle('display', 'inline-block');
        }

        // Custom Event
        this.addEvents(RangeSlider.ON_SAVE);
    }

    /**
     * U can turn the slider tickers functionality on or off
     *
     * @param {Boolean} enable Enable if true, disable if false
     */
    setSliderEnable(enable) {
        if (!enable) {
            enable = true;
        }

        this._hammers.forEach(function (hammer) {
            hammer.get('pan').set({ enable: enable });
        });
    }

    /**
     * Event Binding
     */
    bind() {
        super.bind();

        this._tickerElements.each(
            function (ticker, tickers, i) {
                this._hammers[i] = new Hammer(ticker.dom)
                    .on('panstart', this.onMouseDown.bind(this))
                    .on('panleft panright', this.onMouseMove.bind(this))
                    .on('panend', this.onBodyMouseUp.bind(this));

                this._hammers[i].get('pan').set({ threshold: 0 });
            }.bind(this),
        );

        //Ha van a sliderhez select
        if (this._dataYearEl && this._dataPeriodEl) {
            // Az ev select valtoztatasahoz tartozo esemeny
            this._dataYearEl.on('change', this.onPeriodChange, this);
            // Az idoperiodus select valtoztatasahoz tartozo esemeny
            this._dataPeriodEl.on('change', this.onPeriodChange, this);
        }
    }

    unbind() {
        super.unbind();

        this._hammers.forEach(function (hammer) {
            hammer.destroy();
        });

        //Ha van a sliderhez select
        if (this._dataYearEl && this._dataPeriodEl) {
            // Az ev select valtoztatasahoz tartozo esemeny
            this._dataYearEl.un('change', this.onPeriodChange, this);
            // Az idoperiodus select valtoztatasahoz tartozo esemeny
            this._dataPeriodEl.un('change', this.onPeriodChange, this);
        }
    }
}
