import $ from 'jquery';

import Chaos from '../../lib/chaos/Chaos';
import { replacePhpRegexScripts } from './Form.utils';

import InputHighlight from './InputHighlight';

/**
 * @constructor
 */
export default function ValidatorJS(rule, value, field, form) {
	this.Deferred = $.Deferred();
	this.Rule = rule;
	this.Value = value;
	this.Field = field;
	this.FieldName = field.opts.name;
	this.Scenario = field.scenario;
	this.Form = form;

	let handlers = {
		mandatory          : this.validateMandatory,
		regexp             : this.validateRegexp,
		date               : this.validateDateGregorian,
		age                : this.validateDateInterval,
		date_interval      : this.validateDateInterval, // eslint-disable-line
		date_time_from_now : this.validateDateTimeFromNow, // eslint-disable-line
		interval           : this.validateInterval,
		ajax               : this.validateAjax,
		spam               : this.validateSpam,
		match              : this.validateMatch
	};

	this.Handler = handlers[this.Rule.rule_type]
		? handlers[this.Rule.rule_type]
		: () => true;
	this.Handler.call(this);

	return this.Deferred;
}

ValidatorJS.prototype = {

	validateSpam() {
		if (!this.isSameValueProvided()) {
			let obj = {};
			obj.text = this.Value;

			if (typeof this.Scenario === 'object') {
				Object.assign(obj, this.Scenario);
			}
			this.sendAjax(obj);
		}
		else {
			this.showValidationError();
		}
	},

	sendAjax(obj) {
		$.post(this.Rule.rule, obj, function(response) {
			if (typeof response.data.highlight !== 'undefined' && !!response.data.highlight === true) {
				InputHighlight.set(this.Field.getID(), new Array(response.data.spamExpression));
			}
			this.Field.lastValidationValue = this.Value;
			this.Rule.isHighlighted = response.data.highlight;
			this.Rule.requestParams = response.requestParams;
			this.Rule.error_message = // eslint-disable-line
				this.Field.lastValidationErrorMessage = response.errorCode
					? Chaos.translate('Please wait and try again')
					: response.data.errorReason;
			this.handleResult(this.Field.lastValidationResult = response.data && response.data.result);
		}.bind(this));
	},

	isSameValueProvided() {
		return this.Field.lastValidationValue === this.Value;
	},

	showValidationError() {
		this.Rule.error_message = this.Field.lastValidationErrorMessage; // eslint-disable-line
		this.handleResult(this.Field.lastValidationResult);
	},

	/**
	 * Converts PHP style regex to JS usable regexp.
	 * @param {String} regexp Regexp comes from backend
	 * @return {Object} Converted JS compatible regexp
	 */
	convertRegexp(regexp) {
		let result = { pattern : regexp, modifier : '' },
			pattern 	= regexp.toString(),
			delimiter 	= regexp.charAt(0);

		if (pattern.indexOf(delimiter) === 0) {
			let first = 0,
				last = pattern.lastIndexOf(delimiter);
			result.pattern = pattern.substring(first + 1, last);
			// 'u' modifier causes bugs in XRegExp, thus we replace it out.
			result.modifier = pattern.substring(last + 1).replace('u', '');

            // Replace any JS-incompatible scripts (e.g. {Latin})
            result.pattern = replacePhpRegexScripts(result.pattern);
		}
		return result;
	},

	validateMandatory() {
		// We need different validation for checkboxes and radios.
		// If any of them are true, then mandatory rule is passes.
		if (this.Rule.rule && (this.Field.input.type === 'checkbox' || this.Field.input.type === 'radio')) {
			let isOk = false;
			this.Form.getInput(this.FieldName, false).forEach(function(input) {
				if (input.getValue()) {
					isOk = true;
				}
			});
			this.handleResult(isOk);
			return;
		}
		if (this.Rule.rule) {
			this.Value = this.Value.trim();
			this.handleResult(!!this.Value.length || !!this.Value);
		}
		// If rule is not true, validation is not needed, so return true
		this.handleResult(true);
	},

	validateRegexp() {
		if (this.Value.length === 0) {
			this.handleResult(true);
			return;
		}

		// Remove new line characters from the value
		this.Value = this.Value.replace(/(\r\n|\n|\r)/gm, '');

		// If there is no rule but 'true' value in it, and we have a template
		// We shall validate the Value against the template only
		if (this.Rule.template && this.Rule.rule === true) {
			// Create regex which matches to {key}s in template string
			let bracesRegex = new RegExp('(\\{.*?\\})', 'gi');
			// replace braces {key}s with string variable regex
			let matchRegex = this.Rule.template.replace(bracesRegex, '(.{1,})');
			let matchXRegexp = new RegExp(matchRegex, 'gi');
			this.handleResult(this.Value.match(matchXRegexp));
			return;
		}
		else if (this.Rule.regexpList) {
			let byValue = this.Form.getInput(this.Rule.regexpListDependencyFieldName, 0).getValue();
			this.Rule.rule = this.Rule.regexpList[byValue] || this.Rule.rule;
		}
		let regexp = this.convertRegexp(this.Rule.rule);
		regexp = new RegExp(regexp.pattern, regexp.modifier);
		let result = regexp.test(this.Value);
		// Ask backend devs about this -.-
		if (this.Rule.highlight) {
			result = !result;
		}
		this.handleResult(result);
	},

	validateMatch() {
		let result = this.Rule.rule !== this.Value.trim();
		this.handleResult(result);
	},

	validateDateInterval() {
		if (!this.validateDate()) {
			this.handleResult(false);
		}
		let dateTimeStamp = this.generateDateObject(this.Value).getTime() / 1000;
		let min = this.Rule.rule.min || -9999999999;
		let max = this.Rule.rule.max || 2147483648;
		this.handleResult(dateTimeStamp >= min && dateTimeStamp <= max);
	},

	/**
	 * Validates date + time from now in ISO format: YYYY-MM-DDTHH:mm:ss+0000.
	 * It uses the actual time instead of minimum rule.
	 */
	validateDateTimeFromNow() {
		let dateTimeStamp = new Date(this.Value) / 1000,
			dateTimeNow = Date.now() / 1000;
		if (!dateTimeStamp) {
			this.handleResult(false);
		}
		else {
			this.handleResult(dateTimeStamp >= dateTimeNow);
		}
	},

	/**
	 * Tells if our string matches the date pattern.
	 * @returns {boolean}
	 */
	validateInterval() {
		let min = this.Rule.rule.min || -9999999999;
		let max = this.Rule.rule.max || 2147483648;
		this.handleResult(this.Value >= min && this.Value <= max);
	},

	validateDate() {
		let datePat = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/,
			matchArray = this.Value.match(datePat);
		return matchArray !== null;
	},

	/**
	 * Validates if date is a Gregorian date while accounting for leap year.
	 */
	validateDateGregorian() {
		if (!this.validateDate()) {
			this.handleResult(false);
			return false;
		}

		let date = this._parseDate(this.Value);
		let dateObj = new Date(date.month + '/' + date.day + '/' + date.year);
		this.handleResult(!(dateObj && parseInt(dateObj.getDate(), 10) !== parseInt(date.day, 10)));
	},

	/**
	 * Date parser, returns split date data with leap year detection.
	 *
	 * @param {String} dateString   The date string to parse.
	 *
	 * @returns {{year: Number, month: number, day: Number, isLeap: boolean}}
	 * @private
	 */
	_parseDate(dateString) {
		let datePat = /^(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})$/,
			date = dateString.match(datePat),
			year = parseInt(date[1], 10);

		return {
			year   : year,
			month  : parseInt(date[2], 10),
			day    : parseInt(date[3], 10),
			isLeap : new Date(year, 1, 29).getMonth() === 1
		};
	},

	/**
	 * Validates value through an ajax request.
	 */
	validateAjax() {
		if (!this.isSameValueProvided()) {
			// Don't send ajax request if field is empty and not mandatory
			if (this.Value === '' && this.Field.opts.isMandatory === '') {
				this.handleResult(true);
				return;
			}

			let obj = {};
			obj[this.FieldName] = this.Value;

			if (typeof this.Scenario === 'object') {
				Object.assign(obj, this.Scenario);
			}
			this.sendAjax(obj);
		}
		else {
			this.showValidationError();
		}
	},

	/**
	 * Generates a sate object from a date string.
	 * @param dateStr Date string. Formats: YEAR-MONTH-DAY
	 * @returns {Date}
	 */
	generateDateObject(dateStr) {
		let dateArr = dateStr.split('-'),
			y = dateArr[0] || 0,
			m = dateArr[1] - 1 || 0,
			d = dateArr[2] || 0;
		return new Date(y, m, d);
	},

	/**
	 * Returns the error message from the validator.
	 * @returns {*}
	 */
	getError() {
		return this.Rule.error_message; // eslint-disable-line
	},

	/**
	 * Handles the result and resolves/rejects the defferred object.
	 * @param result
	 */
	handleResult(result) {
		this.Deferred[result ? 'resolve' : 'reject'](this, this.getError());
	}

};