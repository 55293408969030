import riot from 'riot';
import $ from 'jquery';
import PH from '../../lib/constant/Phrame';
import Config from '../../lib/chaos/Config';
import './Form.scss';

riot.tag('form-mixin-error',
	`<i ref="icon"
		class="{ !opts.hideIcon ? 'msc-icon icon-warning-circular-solid' : '' } protip ph-cursor-pointer"
		data-pt-title="{ opts.text }"
		data-pt-trigger="{ isDesktop ? 'click2' : 'click'}">
	</i>`,

	function(opts) {
		// Apply mixins
		this.mixin('form');
		this.isDesktop = Config.get('device').isDesktop;

		// Init variables
		let C = this.CONST;
		let errorBuffer = [];
		let errorBufferTimeout;

		this.on('mount', function() {
			if (this.parent.opts.error) {
				this.parent.getLast().trigger(C.RIOT_ELEMENT_SHOW_ERROR_EVENT, this.parent.opts.error);
			}
		});

		this.hide = function() {
			this.root.classList.add(PH.cls.hide);
			$(this.refs.icon).protipHide();
		};

		this.show = function(msg) {
			clearTimeout(errorBufferTimeout);
			var $form = $(this.form.root);

			if (typeof msg !== 'string') {
				errorBuffer = errorBuffer.concat(msg);
			}
			else {
				errorBuffer.push(msg);
			}

			if ($form.length > 0) {
				errorBufferTimeout = setTimeout(() => {
					this.root.classList.remove(PH.cls.hide);
					errorBuffer = this.removeDuplicates(errorBuffer);
					let title = this.form.buildErrorMessage($.unique(errorBuffer))
						+ '<i class="msc-icon icon-close-solid protip-close"></i>';
					$(this.refs.icon).protipShow({
						title,
						classes  : `ph-form-tooltip-error-list protip-common-close ${opts.dataPtClasses}`,
						mixin    : 'css-no-transition',
						width    : 300,
						position : opts.dataPtPosition || 'right',
						gravity  : opts.dataPtGravity || true,
						// if we don't set the form as target, the target will be the icon itself
						// that target would be too small and the toolptip will be small and with a blurry effect
						target   : $form
					});
					errorBuffer.length = 0;
				});
			}
		};

		this.removeDuplicates = function (messageArray) {
			if (!messageArray || !messageArray.length) {
				return [];
			}

			let uniqueMessages = {};

			return messageArray.filter(item => {
				if (uniqueMessages[item]) {
					return false;
				}

				uniqueMessages[item] = item;
				return true;
			});
		};

		this.parent.on(C.RIOT_ELEMENT_SHOW_ERROR_EVENT, this.show.bind(this));
		this.parent.on(C.RIOT_ELEMENT_HIDE_ERROR_EVENT, this.hide.bind(this));
	});
