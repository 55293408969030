/* tslint:disable */
/* eslint-disable */
/**
 * My_Story_Service API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MusicRecognition } from './music-recognition';
import { StoryItemMedia } from './story-item-media';
import { StoryItemViewCount } from './story-item-view-count';
import { StoryStatusReason } from './story-status-reason';

/**
 * A story item in My Story.
 * @export
 * @interface MyStoryItem
 */
export interface MyStoryItem {
    /**
     * Numeric unique id of the story item.
     * @type {number}
     * @memberof MyStoryItem
     */
    id?: number;
    /**
     * The status of the story item.
     * @type {string}
     * @memberof MyStoryItem
     */
    status?: string;
    /**
     *
     * @type {StoryStatusReason}
     * @memberof MyStoryItem
     */
    statusReason?: StoryStatusReason | null;
    /**
     * The type of the story item.
     * @type {string}
     * @memberof MyStoryItem
     */
    type?: MyStoryItemTypeEnum;
    /**
     * The privacy setting of the story item.
     * @type {string}
     * @memberof MyStoryItem
     */
    privacy?: MyStoryItemPrivacyEnum;
    /**
     * The duration in seconds of the story item.
     * @type {number}
     * @memberof MyStoryItem
     */
    duration?: number;
    /**
     * The URI to use to upload content attached to the story item.
     * @type {string}
     * @memberof MyStoryItem
     */
    uploadUrl?: string | null;
    /**
     * The number of seconds left before the story item expires.
     * @type {number}
     * @memberof MyStoryItem
     */
    expiresIn?: number;
    /**
     * The medias attached to the story item.
     * @type {Array<StoryItemMedia>}
     * @memberof MyStoryItem
     */
    media?: Array<StoryItemMedia>;
    /**
     *
     * @type {MusicRecognition}
     * @memberof MyStoryItem
     */
    musicRecognition?: MusicRecognition;
    /**
     *
     * @type {StoryItemViewCount}
     * @memberof MyStoryItem
     */
    viewCount?: StoryItemViewCount;
}

/**
 * @export
 * @enum {string}
 */
export enum MyStoryItemTypeEnum {
    Image = 'image',
    Video = 'video',
}
/**
 * @export
 * @enum {string}
 */
export enum MyStoryItemPrivacyEnum {
    Free = 'free',
    Premium = 'premium',
}
