import { ActorTypes } from 'domains/Common.types';
import { EmarsysEntity } from './Emarsys.types';

export interface EmarsysDataMapperInterface {
    getEmarsysContactDetails: (xActorId: string, xActorType: ActorTypes) => Promise<EmarsysEntity>;
}

export class EmarsysService {
    constructor(private emarsysMapper: EmarsysDataMapperInterface) {}

    public getEmarsysContactDetails(xActorId: string, xActorType: ActorTypes): Promise<EmarsysEntity> {
        return this.emarsysMapper.getEmarsysContactDetails(xActorId, xActorType);
    }
}
