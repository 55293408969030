/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { Au10tixWebSdkAccessTokenResponse } from '../../../../msc-api-gateway/proxy/au10tix/models';
// @ts-ignore
import { CreateAu10tixWebSdkAccessTokenRequest } from '../../../../msc-api-gateway/proxy/au10tix/models';
// @ts-ignore
import { ErrorResponse } from '../../../../msc-api-gateway/proxy/au10tix/models';
// @ts-ignore
import { InnerApiSuccess } from '../../../../msc-api-gateway/proxy/au10tix/models';
// @ts-ignore
import { UnauthorizedError } from '../../../../msc-api-gateway/proxy/au10tix/models';
/**
 * Au10tixApi - axios parameter creator
 * @export
 */
export const Au10tixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates WEB SDK access token for Au10tix WEB SDK API
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {CreateAu10tixWebSdkAccessTokenRequest} [createAu10tixWebSdkAccessTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAu10tixWebSdkAccessToken: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            createAu10tixWebSdkAccessTokenRequest?: CreateAu10tixWebSdkAccessTokenRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('createAu10tixWebSdkAccessToken', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('createAu10tixWebSdkAccessToken', 'xActorType', xActorType);
            const localVarPath = `/gw/inner/v2/external-partners/au10tix/tokens/web-sdk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createAu10tixWebSdkAccessTokenRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * Au10tixApi - functional programming interface
 * @export
 */
export const Au10tixApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = Au10tixApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates WEB SDK access token for Au10tix WEB SDK API
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {CreateAu10tixWebSdkAccessTokenRequest} [createAu10tixWebSdkAccessTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAu10tixWebSdkAccessToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            createAu10tixWebSdkAccessTokenRequest?: CreateAu10tixWebSdkAccessTokenRequest,
            options?: any,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InnerApiSuccess & Au10tixWebSdkAccessTokenResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAu10tixWebSdkAccessToken(
                xActorId,
                xActorType,
                createAu10tixWebSdkAccessTokenRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * Au10tixApi - factory interface
 * @export
 */
export const Au10tixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Au10tixApiFp(configuration);
    return {
        /**
         * Creates WEB SDK access token for Au10tix WEB SDK API
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {CreateAu10tixWebSdkAccessTokenRequest} [createAu10tixWebSdkAccessTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAu10tixWebSdkAccessToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            createAu10tixWebSdkAccessTokenRequest?: CreateAu10tixWebSdkAccessTokenRequest,
            options?: any,
        ): AxiosPromise<InnerApiSuccess & Au10tixWebSdkAccessTokenResponse> {
            return localVarFp
                .createAu10tixWebSdkAccessToken(xActorId, xActorType, createAu10tixWebSdkAccessTokenRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Au10tixApi - interface
 * @export
 * @interface Au10tixApi
 */
export interface Au10tixApiInterface {
    /**
     * Creates WEB SDK access token for Au10tix WEB SDK API
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {CreateAu10tixWebSdkAccessTokenRequest} [createAu10tixWebSdkAccessTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Au10tixApiInterface
     */
    createAu10tixWebSdkAccessToken(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        createAu10tixWebSdkAccessTokenRequest?: CreateAu10tixWebSdkAccessTokenRequest,
        options?: any,
    ): AxiosPromise<InnerApiSuccess & Au10tixWebSdkAccessTokenResponse>;
}

/**
 * Au10tixApi - object-oriented interface
 * @export
 * @class Au10tixApi
 * @extends {BaseAPI}
 */
export class Au10tixApi extends BaseAPI implements Au10tixApiInterface {
    /**
     * Creates WEB SDK access token for Au10tix WEB SDK API
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {CreateAu10tixWebSdkAccessTokenRequest} [createAu10tixWebSdkAccessTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Au10tixApi
     */
    public createAu10tixWebSdkAccessToken(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        createAu10tixWebSdkAccessTokenRequest?: CreateAu10tixWebSdkAccessTokenRequest,
        options?: any,
    ) {
        return Au10tixApiFp(this.configuration)
            .createAu10tixWebSdkAccessToken(xActorId, xActorType, createAu10tixWebSdkAccessTokenRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
