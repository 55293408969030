/**
 * Adds px extension to a number
 *
 * @return string
 */
if (!Number.prototype.px) {
	Number.prototype.px = function () {
		return this + 'px';
	};
}

/**
 * Adds % extension to a number
 *
 * @return string
 */
if (!Number.prototype.percent) {
	Number.prototype.percent = function () {
		return this + '%';
	};
}

/**
 *
 * @param c
 * @param d
 * @param t
 * @return {string}
 */
if (!Number.prototype.formatMoney) {
	Number.prototype.formatMoney = function(c = 2, d = '.', t = ',') {
		let n = this, s, i, j;
		c = isNaN(c = Math.abs(c)) ? 2 : c;
		d = d === undefined ? '.' : d;
		t = t === undefined ? ',' : t;
		s = n < 0 ? '-' : '';
		i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c), 10));
		j = (j = i.length) > 3 ? j % 3 : 0;
		return '$' + s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
			(c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
	};
}

/**
 *
 * @param decimals
 * @return {number}
 */
if (!Number.prototype.toPercent) {
	Number.prototype.toPercent = function(decimals = 1) {
		return (this * 100).toFixed(decimals);
	};
}

/**
 *
 * @param decimals
 * @return {string}
 */
if (!Number.prototype.toTrend) {
	Number.prototype.toTrend = function(decimals = 1) {
		let n = this,
			s = n > 1 ? '+' : '';
		return s + ((n - 1) * 100).toFixed(decimals) + '%';
	};
}