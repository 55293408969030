import HTML5Uploader from './HTML5Uploader';
import HTML5UploaderController from './HTML5UploaderController';

export default class SnapshotHTML5UploaderController extends HTML5UploaderController {
	/**
	 * Init method.
	 *
	 * @param {Object} el
	 * @param {Object} config
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);
	}

	_onAfterChange() {

	}

	_onAfterPrepareok() {

	}

	_onAfterDone(ev) {
		var response = JSON.parse(ev.result);
		if (!response.status !== 'ERROR') {
			this._addError(HTML5Uploader.ERROR.IMAGE_MIN_WIDTH.DEFAULT);
			this._showErrors();
		}
	}

	_onAfterPreparefail() {
		this._toggleProgressbar();
		this._showErrors();
	}
}
