/* eslint-disable complexity */
/* eslint-disable max-depth */
import Ext from '../../lib/vendor/ExtCore';
import Chaos from '../../lib/chaos/Chaos';
import ChaosObject from '../../lib/chaos/Object';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import CONST from '../../lib/constant/Constants';

import OverlayComponent from '../Overlay/Overlay';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import GlobalProgressIndicator from '../ProgressIndicator/GlobalProgressIndicator';
import Ajax from '../Ajax/Ajax';

/**
 *
 * DataSender : used to transfer data on overlays
 *
 */
export default class DataSender extends ChaosObject {
	get properties() {
		return { ...super.properties,
			submitBtnEl : undefined,

			loadParams : undefined,

			params : undefined,

			postUrl : undefined,

			evalValue : undefined,

			callbackFn : undefined,

			errorCallbackFn : undefined,

			callbackScope : undefined,

			overlayComponent : undefined,

			preventHideOverlay : true,

			synchron : true,

			/** @var {Object}     If a loaderContainerEl, we dont use global progress indicator while the ajax request, but local in it. */
			loaderContainerEl : undefined

		};
	}
	init(el, config) {
		this._inputElements = this.element.select('input[type=text], input[type=password]');

		super.init(el, config);
	}

	/**
	 * A Form.js-bol jovo form submit globalis esemenyre akaszkodo esemenykezelo
	 *
	 * @param {Object} options event es scope
	 */
	onFormSubmit(options) {
		let ev = options.ev,
			target = ev ? ev.target : undefined,
			scope = options.scope,
			delay = scope && scope.setDelay;

		if (ev) {
			ev.preventDefault();
			ev.stopPropagation();
		}

		if (this.overlayComponent) {
			this.overlayComponent._clickedElement = target;
		}

		if (delay) {
			this.sync = true;
			this.showPreloader();
			setTimeout(this.dataSender.bind(this), delay);
		}
		else {
			this.dataSender();
		}
	}

	/**
	 * collect values from enabled form elements
	 *
	 * @returns Object
	 */
	collectFormData() {
		let findElementsToSend = this.element.select('input, select, textarea'),
			dataObj = {};

		for (let i = 0; i < findElementsToSend.getCount(); i++) {
			if (findElementsToSend.item(i).hasClass('disabled')) {
				continue;
			}

			switch (findElementsToSend.item(i).dom.nodeName) {
				case 'SELECT':
					if (findElementsToSend.item(i).select('option[selected]').item(0) !== null) {
						dataObj[findElementsToSend.item(i).dom.name] = findElementsToSend.item(i).select('option[selected]').item(0).dom.value; // eslint-disable-line
					}
					break;

				case 'INPUT':
					switch (findElementsToSend.item(i).dom.type) {
						case 'radio':
						case 'checkbox':
							if (findElementsToSend.item(i).dom.checked) {
								dataObj[findElementsToSend.item(i).dom.name] = findElementsToSend.item(i).dom.value;
							}
							break;
						default:
							let element = findElementsToSend.item(i).dom;
							if (element.name.indexOf('[]') !== -1) {
								if (!Array.isArray(dataObj[element.name])) {
									dataObj[element.name] = [];
								}
								dataObj[element.name].push(element.value);
							}
							else {
								dataObj[element.name] = element.value;
							}
							break;
					}
					break;

				case 'TEXTAREA':
					dataObj[findElementsToSend.item(i).dom.name] = findElementsToSend.item(i).dom.value;
					break;

				default:
					/* webpack-strip-block:removed */
					break;
			}
		}

		return dataObj;
	}

	/**
	 * Calls save method with collected params for ajax request.
	 */
	dataSender() {
		this.save(this.params || this.collectFormData());
	}

	showPreloader() {
		let isSync = this.loaderContainerEl ? false : this.sync;

		// If we dont use synchron request, so we want to use a local progress indicator instead of global.
		if (!isSync && this.loaderContainerEl) {
			// Show local preloader
			Broadcaster.fireEvent(ProgressIndicator.GLOBALEVENT_ADD_LOCAL_INDICATOR, {
				element : this.loaderContainerEl
			});
		}
		else if (this.sync) {
			// Show global preloader
			Chaos.fireEvent(GlobalProgressIndicator.GLOBALEVENT_SHOW_INDICATOR);
		}
	}

	/**
	 *
	 * @param params
	 */
	save(params) {
		this.showPreloader();
		Ajax.request({
			type              : CONST.TYPE_JSON,
			url               : this.postUrl,
			params            : params,
			scope             : this,
			success           : this.ajaxSuccessHandler,
			error             : this.ajaxErrorHandler,
			failure           : this.ajaxErrorHandler,
			method            : CONST.POST,
			preventLoaderHide : this.preventHideOverlay
		});
	}

	ajaxSuccessHandler(response, request) {
		// Remove local progress indicator if we have !
		if (this.loaderContainerEl) {
			Broadcaster.fireEvent(ProgressIndicator.GLOBALEVENT_REMOVE_LOCAL_INDICATOR, {
				element : this.loaderContainerEl
			});
		}
		// If CallbackFn set, we call it.
		if (this.callbackFn) {
			if (!this.callbackScope) {
				/* webpack-strip-block:removed */
			}
			this.callbackFn.call(this.callbackScope, response);
		}

		// Stop if overlayCmp not set
		if (!this.overlayComponent) {
			return;
		}

		// Default success handler for overlays
		response = JSON.parse(response.responseText).data;

		if (response.done || response.refreshPage) {
			window.location.href = response.redirectUrl;
		}
		else if (response.overlay) {
			/*
			 Ha a valaszban rogton jon az oveerlay tartalom, akkor nem kerdezunk le megegyszer
			 hanem egybol atadjuk a tartalmat megjelenitesre
			 */
			this.overlayComponent.createPopup(response);
		}
		else if (response.refreshPage === false) {
			this.overlayComponent.closePopupEventHandler();
			this.overlayComponent.fireEvent(OverlayComponent.AFTER_CLOSE_OVERLAY, response, this.element.dom, request);
		}
		else if (response.length !== 0) {
			// Fire an event about closing the overlay
			this.overlayComponent.fireEvent(OverlayComponent.CLOSE_OVERLAY, this);
			this.overlayComponent.getOverlay(response.forwardUrl);
		}
	}

	ajaxErrorHandler(response) {
		/* webpack-strip-block:removed */
		// Remove local progress indicator if we have !
		if (this.loaderContainerEl) {
			Broadcaster.fireEvent(ProgressIndicator.GLOBALEVENT_REMOVE_LOCAL_INDICATOR, {
				element : this.loaderContainerEl
			});
		}

		if (this.errorCallbackFn) {
			if (!this.callbackScope) {
				/* webpack-strip-block:removed */
			}
			this.errorCallbackFn.call(this.callbackScope, response);
		}
	}

	/**
	 * 	Toroljuk a globalis esemenyek kozul a form-submit-eket.
	 * 	.un-al ez valami megmagyarazhatatlan okbol nem mukodik.
	 */
	detachFormSubmitEvent() {
		let formSubmitEvents = Broadcaster.events['form-submit'];
		if (formSubmitEvents) {
			for (let i in formSubmitEvents.listeners) {
				if (formSubmitEvents.listeners[i].fn === this.onFormSubmit) {
					formSubmitEvents.listeners.splice(i, 1);
				}
			}
		}
	}

	/**
	 * On input keydown, in ie8 we trigger a form send
	 * @param {EventObject} ev
	 */
	onInputKeydown(ev) {
		if (ev.keyCode === 13 && Ext.isIE8) {
			ev.preventDefault();
			this.dataSender();
		}
	}

	bind() {
		// Globalitas miatt elobb toroljuk az esemenyt.
		this.detachFormSubmitEvent();
		Broadcaster.on('form-submit', this.onFormSubmit, this);

		this._inputElements.on('keydown', this.onInputKeydown, this);

		super.bind();
	}

	unbind() {
		this.detachFormSubmitEvent();
		Broadcaster.un('form-submit', this.onFormSubmit, this);

		this._inputElements.un('keydown', this.onInputKeydown, this);

		super.unbind();
	}
}
