import anime from 'animejs';

import Ext from '../../lib/vendor/ExtCore';
import Chaos from '../../lib/chaos/Chaos';
import ChaosObject from '../../lib/chaos/Object';
import Util from '../../lib/chaos/Util';

/**
 * View of CommentManagerView
 */
export default class CommentManagerView extends ChaosObject {
	static EVENT_APPEND_CONTENT_DONE = 'on-append-content-done';
	static EVENT_REPLACE_CONTENT_DONE = 'on-replace-content-done';
	static EVENT_REMOVE_CONTENT_DONE = 'on-remove-content-done';
	static EVENT_REPLACE_COMMENTS_DONE = 'on-replace-comments-done';
	static EVENT_COMMENT_COLLAPSE_ANIMATION_DONE = 'on-comment-collapse-animation-done';
	static EVENT_SCROLL_CONTENT_AFTER_REMOVE = 'on-scroll-content-after-remove';
	static EVENT_COMMENT_REMOVE_SLIDE_LEFT_DONE = 'on-comment-remove-slide-left-done';
	static EVENT_FAILED_COMMENT_SKIN_READY = 'on-failed-comment-skin-ready';
	static EVENT_COMMENT_REFRESH_DONE = 'on-comment-refresh-done';

	get properties() {
		return { ...super.properties,
			/** @var {String}   View name. */
			name : 'CommentManagerView',
			/** @var {String}   Each of the comment elements are inside the block with this cls */
			commentBlockCls : 'comment_block',
			/** @var {String}   Reply Container Selector */
			replyContainerSel : '.reply_container'

		};
	}
	init(el, config) {
		super.init(el, config);
		this.addEvents(
			CommentManagerView.EVENT_APPEND_CONTENT_DONE,
			CommentManagerView.EVENT_REPLACE_CONTENT_DONE,
			CommentManagerView.EVENT_REMOVE_CONTENT_DONE,
			CommentManagerView.EVENT_REPLACE_COMMENTS_DONE,
			CommentManagerView.EVENT_COMMENT_COLLAPSE_ANIMATION_DONE,
			CommentManagerView.EVENT_SCROLL_CONTENT_AFTER_REMOVE,
			CommentManagerView.EVENT_COMMENT_REMOVE_SLIDE_LEFT_DONE,
			CommentManagerView.EVENT_COMMENT_REFRESH_DONE
		);
		this._commentTemplate = new Ext.Template(
			'<div class="{commentBlockCls} no_events {isSelf}" data-type-id="{commentId}" ">' +
				'<span class="name">{commentOwner}</span>' +
				'<a class="trash">' +
				'<i class="msc-icon icon-trash-outline icon-trash"></i>' +
				'</a>' +
				'<span class="timestamp">{timestamp}</span>' +
				'<p class="comment_text">{content}</p>' +
				'<div class="remove_comment">' +
				'<div class="remove_wrap">' +
				'<div class="remove_cell">' +
				'<a class="button remove" size="s">' + Chaos.translate('Delete') + '</a>' +
				'<a class="cancel">' + Chaos.translate('Cancel') + '</a>' +
				'</div>' +
				'</div>' +
				'</div>' +
				'</div>'
		);
		this.commentFormEl = this.element.select(this.replyContainerSel).item(0);
	}

	/**
	 * Animate Comment Collapsing with decreasing the height
	 *
	 * @method animateCommentRemove     *
	 * @param animationParams Contents the animation parameters
	 *
	 * @return void
	 */
	animateCommentRemove(animationParams) {
		const { element } = animationParams;

		element.setStyle('overflow', 'hidden');
		element.setStyle('border', '0');

		anime({
			targets       : element.dom,
			duration      : 400,
			easing        : 'linear',
			height        : 0,
			paddingTop    : 0,
			paddingBottom : 0,
			complete      : () => this.fireEvent(CommentManagerView.EVENT_COMMENT_COLLAPSE_ANIMATION_DONE)
		});
	}

	/**
	 * Appends content
	 *
	 * @method appendContent
	 * @param {Object} parent       [Ext.element] Where to append the content
	 * @param {Object} content      Content to append
	 * @param {Boolean} isLast      True if it appends the last element
	 *
	 * @return {Object} scope chain
	 */
	appendContent(parent, content, isLast) {
		Ext.DomHelper.append(parent, content);
		if (isLast) {
			this.fireEvent(CommentManagerView.EVENT_COMMENT_REFRESH_DONE);
		}
		return this;
	}

	/**
	 * Slide back to hide the delete buttons

	 * @method slideBackForRemoveButtons
	 * @param {Object}  element     [Ext.element]
	 *
	 * @return {Object} scope chain
	 */
	slideBackForRemoveButtons(element) {
		anime({
			targets  : element.dom,
			duration : 400,
			left     : 0,
			easing   : 'linear'
		});
		return this;
	}

	/**
	 * Slide left to show the delete buttons
	 *
	 * @method slideLeftForRemoveButtons
	 *
	 * @param {Object} element              [Ext.element]
	 * @param {Number} slidePanelWidth      slidePanelWidth
	 *
	 * @return {Object} scope chain
	 */
	slideLeftForRemoveButtons(element, slidePanelWidth) {
		anime({
			targets  : element.dom,
			duration : 400,
			left     : slidePanelWidth * -1,
			easing   : 'linear'
		});

		return this;
	}

	/**
	 * Refresh comment content
	 *
	 * @method refreshComments
	 * @param {Object}  containerEl     Container [Ext.element]
	 * @param {Object}  detailsObj      Details for new content
	 *
	 * @return void;
	 */
	refreshComments(containerEl, detailsObj) {
		var comments = detailsObj.content;
		if (containerEl) {
			containerEl.item(0).dom.innerHTML = this.forgeTemplates(comments);
		}
	}

	/**
	 * Makes a piece of html fragment of
	 * all comment blocks
	 *
	 * @method forgeTemplates
	 * @param {Array}   commentsArray   Comments Array
	 *
	 * @return {object} html fragment
	 */
	forgeTemplates(commentsArray) {
		var output = '';
		for (var i = 0; i < commentsArray.length; i++) {
			var commentData = {
				commentBlockCls : this.commentBlockCls,
				commentId       : commentsArray[i].id,
				commentOwner    : Util.escapeHTML(commentsArray[i].owner),
				timestamp       : commentsArray[i].createdAt,
				content         : Util.escapeHTML(commentsArray[i].content),
				isSelf          : commentsArray[i].is_performer ? 'self' : ''
			};
			output += this._commentTemplate.apply(commentData);
		}
		return output;
	}

	/**
	 * Shows a tooltip on the input element with a message and an alert.
	 *
	 * @param {String} message Tooltip message
	 *
	 * @return void;
	 */
	showInputTooltip(message) {
		this.commentFormEl.jq().protipShow({
			title   : message,
			icon    : 'alert',
			trigger : 'sticky',
			gravity : 'left',
			target  : true
		});
	}

	/**
	 * Hides the input tooltip
	 *
	 * @return void;
	 */
	hideInputTooltip() {
		this.commentFormEl.jq().protipHide();
	}

	/**
	 * AnimateComment Delete Slide left
	 *
	 * @param animateParams
	 *
	 * @return {Object} scope chain
	 */
	commentRemoveSlideLeft(animateParams) {
		const { basicLeftPos, slideDistance, visibleControlPanel } = animateParams;
		anime({
			targets  : visibleControlPanel.dom,
			duration : 400,
			left     : [basicLeftPos, slideDistance * -1],
			easing   : 'linear',
			complete : () => {
				this.fireEvent(CommentManagerView.EVENT_COMMENT_REMOVE_SLIDE_LEFT_DONE, {
					element : visibleControlPanel
				});
			}
		});

		return this;
	}

	/**
	 * Places the last comment softly
	 *
	 * @method smoothAppend
	 * @param {Object} scrollable       [Ext.element] Scrollable content element
	 * @param {Object} parent           [Ext.element] Parent element of scrollable element
	 * @param {Object} content          Ext items content to append
	 *
	 * @return {Object} scope chain
	 */
	smoothAppend(scrollable, parent, content) {
		var fakeWrapper = '<div class="comment_wrapper fake"></div>';
		var commentContent = Ext.select('.comment_content').item(0);
		fakeWrapper = Ext.DomHelper.append(commentContent, fakeWrapper, true);
		var commentBlock = Ext.DomHelper.append(fakeWrapper, content, true);
		var fakeElHeight = commentBlock.getHeight();
		var scrollableHeight = scrollable.getHeight();
		var commentWrapperHeight = parent.getHeight();
		commentBlock.hide();

		parent.setStyle('z-index', '2000');

		anime({
			targets  : parent.dom,
			duration : 400,
			bottom   : [0, fakeElHeight],
			easing   : 'linear',
			complete : () => {
				Ext.DomHelper.append(scrollable, content);
				parent.setStyle({ bottom : 0 + 'px' });
				if (scrollableHeight + fakeElHeight < commentWrapperHeight) {
					scrollable.setStyle({ bottom : 0 + 'px' });
				}
				else {
					scrollable.setStyle({ bottom : 'auto' });
				}
				fakeWrapper.remove();
				this.fireEvent(CommentManagerView.EVENT_APPEND_CONTENT_DONE);
			}
		});

		anime({
			targets  : fakeWrapper.dom,
			duration : 400,
			height   : [0, fakeElHeight],
			easing   : 'linear'
		});

		return this;
	}

	/**
	 * Indicate the failed comment field with
	 * icon and error message
	 *
	 * @return {Object} this        Scope to chain
	 */
	markFailedComment() {
		this.fireEvent(CommentManagerView.EVENT_FAILED_COMMENT_SKIN_READY);
		return this;
	}

	/**
	 * Show comments loader
	 *
	 * @method showCommentsLoader
	 *
	 * @return void
	 */
	hideCommentsLoader(loaderEl, mainEls) {
		mainEls.setStyle('display', 'block');
		loaderEl.setStyle('display', 'none');
	}

	/**
	 * Changes an icon class
	 *
	 * @method changeIcon
	 *
	 * @param {Object} iconElement      [Ext.element] Icon element
	 * @param {String} iconClsFrom      Icon class we would like to remove
	 * @param {String} iconClsTo        Icon class we would like to add
	 *
	 * @return void;
	 */
	changeIcon(iconElement, iconClsFrom, iconClsTo) {
		if (iconElement) {
			iconElement.removeClass(iconClsFrom);
			iconElement.addClass(iconClsTo);
		}
	}

	/**
	 * Adjust Comment Box Height by JS to the picture size
	 *
	 * @method adjustCommentWrapper
	 *
	 * @param {Object} wrapper  [Ext.element] Wrapper element
	 * @param {Number} height   Height value
	 *
	 * @return {Object} scope chain
	 */
	adjustCommentWrapper(wrapper, height) {
		wrapper.setHeight(height);
		return this;
	}

	/**
	 * Removes the selected element
	 *
	 * @method removeContent
	 * @param {Object} element  [Ext.element]
	 *
	 * @return {Object} scope chain
	 */
	removeContent(element) {
		element.remove();
		this.fireEvent(CommentManagerView.EVENT_REMOVE_CONTENT_DONE);
		return this;
	}

	/**
	 * Removes content from the selected element
	 *
	 * @method makeEmpty
	 *
	 * @return {Object} scope chain
	 */
	makeEmpty(element) {
		element.dom.innerHTML = '';
		return this;
	}

	bind() {
		Ext.getBody().on('click', this.hideInputTooltip, this);
		Ext.fly(window).on('keydown', this.hideInputTooltip, this);
	}

	unbind() {
		setTimeout(function() {
			Ext.getBody().un('click', this.hideInputTooltip, this);
			Ext.fly(window).un('keydown', this.hideInputTooltip, this);
		}.bind(this), 0);
	}
}
