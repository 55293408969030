/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CallInfoMessageContent } from './call-info-message-content';
import { ExternalContentMessageContent } from './external-content-message-content';
import { GiftMessageContent } from './gift-message-content';
import { OfflineSurpriseMessageContent } from './offline-surprise-message-content';
import { StoryReplyMessageContent } from './story-reply-message-content';
import { TextMessageContent } from './text-message-content';
import { TextMessageWithMediaContent } from './text-message-with-media-content';

/**
 * Message is a unit of information exchanged between Participants.
 * @export
 * @interface Message
 */
export interface Message {
    /**
     *
     * @type {number}
     * @memberof Message
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    sender: MessageSenderEnum;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    type: MessageTypeEnum;
    /**
     * Plain text representation of the message, used for backward compatibility purposes. If the client cannot recognize particular message type, it should treat the message as text using this data.
     * @type {string}
     * @memberof Message
     */
    text: string;
    /**
     *
     * @type {TextMessageWithMediaContent | CallInfoMessageContent | StoryReplyMessageContent | OfflineSurpriseMessageContent | GiftMessageContent | ExternalContentMessageContent | TextMessageContent}
     * @memberof Message
     */
    content:
        | TextMessageWithMediaContent
        | CallInfoMessageContent
        | StoryReplyMessageContent
        | OfflineSurpriseMessageContent
        | GiftMessageContent
        | ExternalContentMessageContent
        | TextMessageContent;
    /**
     * Timestamp.
     * @type {string}
     * @memberof Message
     */
    createdAt: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageSenderEnum {
    Member = 'member',
    Performer = 'performer',
}
/**
 * @export
 * @enum {string}
 */
export enum MessageTypeEnum {
    Text = 'text',
    TextWithMedia = 'text-with-media',
    CallInfo = 'call-info',
    OfflineSurprise = 'offline-surprise',
    StoryReply = 'story-reply',
    Gift = 'gift',
    ExternalContent = 'external-content',
}
