import Ext from '../../lib/vendor/ExtCore';
import Chaos from '../../lib/chaos/Chaos';
import ChaosObject from '../../lib/chaos/Object';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import OverlayController from '../Overlay/OverlayController';

/**
 * UploaderView
 */
export default class UploaderView extends ChaosObject {
	static EVENT_FLASH_UPLOADER_EMBED_READY = 'flash-uploader-embed-ready';
	static EVENT_MEDIA_TEMPLATE_READY = 'media-template-ready';
	static GLOBALEVENT_UPDATE_UPLOADER_STATUS = 'update-uploader-status';

	static EVENT_PROMOTED_VIDEO_SELECTED = 'promoted-premium-video-selected';
	static EVENT_PREMIUM_VIDEO_SELECTED = 'premium-video-selected';

	static UPLOADING_STATE_COMMAND_ADD = 'add';
	static UPLOADING_STATE_COMMAND_REMOVE = 'remove';

	get properties() {
		return { ...super.properties,
			/** @var {String}     CSS selector of the progressbar */
			progressBarSel : '#progress_bar span',
			/** @var {String}     uploaderButtonId */
			uploaderButtonId : 'add_photos_button',
			/** @var {String}     Uploading class */
			uploadingCls : 'uploading',
			/** @var {Number}     Duration of animations */
			animateDuration : 0.15,
			/** @var {Object}     Main container element of the media boxes [Ext.Element] */
			mediaBoxContainerEl : undefined,
			/** @var {Object}     Template object for a loading media box [Ext.Template] */
			loadingMediaBoxTpl : undefined,
			/** @var {Object}     Template object for a failed media box [Ext.Template] */
			failedMediaBoxTpl : undefined,
			/** @var {Object}     Template object for an media box that already loaded [Ext.Template] */
			mediaBoxTpl : undefined,
			/** @var {Object}     Template object for an uploader box [Ext.Template] */
			uploaderBoxTpl : undefined,
			/** @var {SWF Object} The uploader flash object */
			uploaderObject : undefined,
			/** @var {String} The id of the uploader flash object */
			uploaderObjectId : 'multiUpload',
			/** @var {String}    uploader button link cls */
			uploaderLink : '.uploader-link',
			/** @var {String}    uploader input field elm */
			uploadInputField : 'input',
			/** @var {String}    The url of the Mark a Promo Overlay */
			promoteOverlayRoute : 'MyContentDocumentPromotionAction/PromoVideoOverlay',
			/** @var {String}    The url of the Mark a Promo Overlay */
			overlayBtnCls : 'overlayBtn',
			/** @var {String}    Selector of the uploader block */
			actionBlockSel : '.actionBlock'

		};
	}
	init(el, config) {
		super.init(el, config);
		this.addEvents(
			UploaderView.EVENT_FLASH_UPLOADER_EMBED_READY,
			UploaderView.EVENT_MEDIA_TEMPLATE_READY,
			UploaderView.EVENT_PROMOTED_VIDEO_SELECTED,
			UploaderView.EVENT_PREMIUM_VIDEO_SELECTED
		);
	}

	/**
	 * Returns the uploader instance type.
	 * @return {string} video or photo
	 * @return void
	 */
	getUploaderType() {
		var actionBlock = this.element.dom.querySelector(this.actionBlockSel);
		return actionBlock.dataset.type;
	}

	/**
	 * Returns if the uploader is a video uploader or not.
	 * @return {boolean}
	 */
	isVideo() {
		return this.getUploaderType() === 'video';
	}

	/**
	 * Reset to the default progress bar
	 *
	 * @method resetProgressBar
	 * @public
	 *
	 * @return {Object}   scope to chain
	 */
	resetProgressBar() {
		this.setProgressBarEl().setWidth(0);
		return this;
	}

	/**
	 * Appends a html fragment to a given element
	 *
	 * @method appendContent
	 *
	 * @return {Object} scope
	 */
	insertContentAfter(parentElement, htmlFragment) {
		Ext.DomHelper.insertAfter(parentElement, htmlFragment);
		return this;
	}

	/**
	 * Adds and removes uploading class,
	 * while there is an uploading item in the folder
	 *
	 * @method setUploadingClass
	 * @param {Object} parentElement   [Ext.element]
	 * @param {String} action          UploaderView.UPLOADING_STATE_COMMAND_REMOVE
	 *                                 UploaderView.UPLOADING_STATE_COMMAND_ADD
	 *
	 * @return void;
	 */
	setUploadingClass(parentElement, action) {
		switch (action) {
			case UploaderView.UPLOADING_STATE_COMMAND_REMOVE:
				parentElement.removeClass(this.uploadingCls);
				break;
			case UploaderView.UPLOADING_STATE_COMMAND_ADD:
				parentElement.addClass(this.uploadingCls);
				break;
			default:
		}
	}

	/**
	 * Gets and returns a progress bar element [Ext.Element]
	 *
	 * @method getProgressBarEl
	 * @public
	 *
	 * @return {Object}
	 */
	getProgressBarEl() {
		if (!(this._progressBarEl instanceof Ext.Element)) {
			this._progressBarEl = this.element.select(this.progressBarSel).item(0);
		}
		return this._progressBarEl;
	}

	/**
	 * Sets the progress bar element [Ext.Element]
	 *
	 * @method getProgressBarEl
	 * @public
	 *
	 * @return {Object}
	 */
	setProgressBarEl() {
		this._progressBarEl = this.element.select(this.progressBarSel).item(0);
		return this._progressBarEl;
	}

	/**
	 * Refreshes the progress bar according to the given param value.
	 *
	 * @method _refreshProgressBar
	 * @public
	 *
	 * @param {Number} percent   StaterefreshProgressBar of the upload process in percent.
	 *
	 * @return {Object}   scope to chain
	 */
	refreshProgressBar(percent) {
		if (percent <= 100) {
			this.getProgressBarEl().setWidth(percent + '%');
		}
		return this;
	}

	/**
	 * Refreshes file index number while multiupload process is in progress.
	 *
	 * @method refreshFileIndex
	 * @public
	 *
	 * @param {Number} allFilesCount      All uploading files number
	 * @param {Number} currentFileIndex   Current uploading file's index
	 *
	 * @return void;
	 */
	refreshFileIndex(allFilesCount, currentFileIndex) {
		Ext.select('.uploader_file_counter').item(0).html(allFilesCount + '/' + currentFileIndex); //@todo: debug only
	}

	/**
	 * Creates a block from a template that will show a loading media box
	 *
	 * @method createLoadingMediaBo
	 * @public
	 *
	 * @return {Object} this scope to chain
	 */
	createLoadingMediaBox(tplConfig) {
		if (this.loadingMediaBoxTpl) {
			var mediaTemplate = this.loadingMediaBoxTpl.applyTemplate(tplConfig);
			this.fireEvent(
				UploaderView.EVENT_MEDIA_TEMPLATE_READY, {
					mediaTemplate : mediaTemplate, fileIndex     : tplConfig.fileIndex
				}
			);
		}
		return this;
	}

	/**
	 *
	 * Creates a block from a template that will show a loading media box
	 *
	 * @method createLoadingMediaBox
	 * @public
	 *
	 * @return {Object} html Fragment
	 */
	getLoadingMediaBox(tplConfig) {
		if (this.loadingMediaBoxTpl) {
			return this.loadingMediaBoxTpl.applyTemplate(tplConfig);
		}
	}

	/**
	 * Returns an uploading bock
	 *
	 * @method createLoadingMediaBox
	 * @public
	 *
	 * @return {Object} this scope to chain
	 */
	createUploadingMediaBox(tplConfig) {
		if (this.uploadingMediaBoxTpl) {
			this.fireEvent(UploaderView.EVENT_MEDIA_TEMPLATE_READY, {
				mediaTemplate : this.uploadingMediaBoxTpl.applyTemplate(tplConfig),
				fileIndex     : tplConfig.fileIndex,
				fileName      : tplConfig.fileName,
				mediaType     : tplConfig.mediaType
			});
		}
		return this;
	}

	/**
	 * Creates a box that show a failed image upload box
	 *
	 * @method createFailedMediaBox
	 * @public
	 *
	 * @param {Object} loadingBoxEl   Loading box element that will be replaced with an uploaded media box
	 * @param {Object} tplConfig      Template config
	 *
	 * @return {Object}
	 */
	createFailedMediaBox(loadingBoxEl, tplConfig) {
		var insertedEl;
		if (this.failedMediaBoxTpl) {
			if (!loadingBoxEl) {
				return Ext.DomHelper.insertAfter(
					this.element.select('.actionBlock').item(0),
					this.failedMediaBoxTpl.applyTemplate(tplConfig)
				);
			}
			insertedEl = Ext.DomHelper.insertAfter(
				loadingBoxEl,
				this.failedMediaBoxTpl.applyTemplate(tplConfig)
			);
			loadingBoxEl.remove();

			return insertedEl;
		}
	}

	/**
	 * Insert a failed media box after the uploader element
	 *
	 * @method insertFailedMediaBox
	 * @param {Object}    beforeElement    [Ext.element]
	 * @param {Object}    insertedElement [Ext.element]
	 * @param {Object}    tplConfig        Template config
	 *
	 * @return void;
	 */
	insertFailedMediaBox(beforeElement, insertedElement, tplConfig) {
		Ext.DomHelper.insertAfter(beforeElement, this.failedMediaBoxTpl.applyTemplate(tplConfig));
	}

	/**
	 * Triggers the OS' upload browser opening.
	 * @private
	 * @return void;
	 */
	_triggerPremiumVideoUpload() {
		this._removePromoVideoOverlay();
		this.element.dom.querySelector(this.uploadInputField).click();
		this._addPromoVideoOverlay();
	}

	/**
	 * Removes the promo video overlay link to the upload block.
	 * @private
	 * @return void;
	 */
	_removePromoVideoOverlay() {
		var uploaderLinkEl = this.element.dom.querySelector(this.uploaderLink);

		uploaderLinkEl && uploaderLinkEl.classList.remove(this.overlayBtnCls);
		uploaderLinkEl && uploaderLinkEl.removeAttribute('href');
	}

	/**
	 * Adds the promo video overlay link to the upload block.
	 * @private
	 * @return void;
	 */
	_addPromoVideoOverlay() {
		if (this.isVideo()) {
			var uploaderLinkEl = this.element.dom.querySelector(this.uploaderLink);
			uploaderLinkEl.classList.add(this.overlayBtnCls);
			uploaderLinkEl.setAttribute('href', Chaos.getUrl(this.promoteOverlayRoute));
		}
	}

	/**
	 * Event handler, if the Promo type selected after uploading premium video.
	 * @return void;
	 */
	onPromoVideoTypeSelected(price) {
		if (this.isVideo()) {
			this.fireEvent(UploaderView.EVENT_PROMOTED_VIDEO_SELECTED, { isPromoVideo: true, price });
			this._triggerPremiumVideoUpload();
		}
	}

	/**
	 * Event handler, if the regular Premium video is selected after premium uploading video,
	 * or the overlay is closed.
	 * @return void;
	 */
	onPremiumVideoTypeSelected(price) {
		this.fireEvent(UploaderView.EVENT_PREMIUM_VIDEO_SELECTED, { isPromoVideo: false, price });
		this._triggerPremiumVideoUpload();
	}

	bind() {
		Broadcaster.on(
			OverlayController.PROMO_VIDEO_TYPE_SELECTED,
			this.onPromoVideoTypeSelected,
			this
		);
		Broadcaster.on(
			OverlayController.PREMIUM_VIDEO_TYPE_SELECTED,
			this.onPremiumVideoTypeSelected,
			this
		);
	}
}
