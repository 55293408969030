/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AddModelFlow } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject1 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject10 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject11 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject12 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject14 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject15 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject17 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject18 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject2 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject3 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject4 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject5 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject6 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject7 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject8 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineObject9 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse200 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2001 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse20010 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2004 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2005 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2006 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2007 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2008 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse2009 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse204 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse400 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse4001 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse403 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { InlineResponse409 } from '../../../../msc-api-gateway/proxy/registration/models';
// @ts-ignore
import { RegistrationFlow } from '../../../../msc-api-gateway/proxy/registration/models';
/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Confirm an email of a user
         * @param {number} userId User ID
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (
            userId: number,
            inlineObject6?: InlineObject6,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('confirmEmail', 'userId', userId);
            const localVarPath = `/public-gw/inner/v2/users/{userId}/email-confirmation-request`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of states
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {string} countryCode Country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2CountriesCountryCodeStatesGet: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            countryCode: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('gwInnerV2CountriesCountryCodeStatesGet', 'product', product);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2CountriesCountryCodeStatesGet', 'acceptLanguage', acceptLanguage);
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('gwInnerV2CountriesCountryCodeStatesGet', 'countryCode', countryCode);
            const localVarPath = `/gw/inner/v2/countries/{countryCode}/states`.replace(
                `{${'countryCode'}}`,
                encodeURIComponent(String(countryCode)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of countries
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2CountriesGet: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('gwInnerV2CountriesGet', 'product', product);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2CountriesGet', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of ethnicities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2EthnicitiesGet: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('gwInnerV2EthnicitiesGet', 'product', product);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2EthnicitiesGet', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/ethnicities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2MeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gw/inner/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of nationalities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2NationalitiesGet: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('gwInnerV2NationalitiesGet', 'product', product);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2NationalitiesGet', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/nationalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject7} [inlineObject7]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationAccountTypeFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject7?: InlineObject7,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationAccountTypeFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationAccountTypeFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationAccountTypeFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/account-type/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject7, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of categories and subcategories
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationCategoriesGet: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('gwInnerV2RegistrationCategoriesGet', 'product', product);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationCategoriesGet', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject9} [inlineObject9]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject9?: InlineObject9,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists(
                'gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut',
                'acceptLanguage',
                acceptLanguage,
            );
            const localVarPath = `/gw/inner/v2/registration/choose-display-name-and-category/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject9, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject8} [inlineObject8]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationChooseExistingPersonFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject8?: InlineObject8,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationChooseExistingPersonFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationChooseExistingPersonFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationChooseExistingPersonFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/choose-existing-person/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject12} [inlineObject12]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationDocumentDetailsFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject12?: InlineObject12,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationDocumentDetailsFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationDocumentDetailsFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationDocumentDetailsFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/document-details/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject12, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationEnumsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gw/inner/v2/registration/enums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow details
         * @param {string} flowId Flow Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationFlowIdGet: async (flowId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationFlowIdGet', 'flowId', flowId);
            const localVarPath = `/gw/inner/v2/registration/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get image uploaded during registration
         * @param {string} flowId Flow Id
         * @param {'front' | 'back' | 'snapshot'} type type of the document
         * @param {string} expiration Expiration Date
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet: async (
            flowId: string,
            type: 'front' | 'back' | 'snapshot',
            expiration: string,
            signature: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet', 'flowId', flowId);
            // verify required parameter 'type' is not null or undefined
            assertParamExists('gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet', 'type', type);
            // verify required parameter 'expiration' is not null or undefined
            assertParamExists(
                'gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet',
                'expiration',
                expiration,
            );
            // verify required parameter 'signature' is not null or undefined
            assertParamExists(
                'gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet',
                'signature',
                signature,
            );
            const localVarPath = `/gw/inner/v2/registration/{flowId}/id-documents/{type}/{expiration}/{signature}`
                .replace(`{${'flowId'}}`, encodeURIComponent(String(flowId)))
                .replace(`{${'type'}}`, encodeURIComponent(String(type)))
                .replace(`{${'expiration'}}`, encodeURIComponent(String(expiration)))
                .replace(`{${'signature'}}`, encodeURIComponent(String(signature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Trigger registration flow migration from Portal to SPA
         * @param {string} acceptLanguage Language
         * @param {InlineObject15} [inlineObject15]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationMigrateFlowPost: async (
            acceptLanguage: string,
            inlineObject15?: InlineObject15,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationMigrateFlowPost', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/migrate-flow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject15, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject14} [inlineObject14]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject14?: InlineObject14,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/oranum-personal-details/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject14, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject11} [inlineObject11]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationPersonalDataFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject11?: InlineObject11,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDataFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDataFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDataFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/personal-data/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject11, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject10} [inlineObject10]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationPersonalDetailsFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject10?: InlineObject10,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDetailsFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDetailsFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationPersonalDetailsFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/gw/inner/v2/registration/personal-details/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {any} idFront Image file
         * @param {any} faceAndId Image file
         * @param {any} [idBack] Image file
         * @param {boolean} [isConsentGiven]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationUploadDocumentsFlowIdPost: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            idFront: any,
            faceAndId: any,
            idBack?: any,
            isConsentGiven?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationUploadDocumentsFlowIdPost', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('gwInnerV2RegistrationUploadDocumentsFlowIdPost', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('gwInnerV2RegistrationUploadDocumentsFlowIdPost', 'acceptLanguage', acceptLanguage);
            // verify required parameter 'idFront' is not null or undefined
            assertParamExists('gwInnerV2RegistrationUploadDocumentsFlowIdPost', 'idFront', idFront);
            // verify required parameter 'faceAndId' is not null or undefined
            assertParamExists('gwInnerV2RegistrationUploadDocumentsFlowIdPost', 'faceAndId', faceAndId);
            const localVarPath = `/gw/inner/v2/registration/upload-documents/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (idFront !== undefined) {
                localVarFormParams.append('idFront', idFront as any);
            }

            if (idBack !== undefined) {
                localVarFormParams.append('idBack', idBack as any);
            }

            if (faceAndId !== undefined) {
                localVarFormParams.append('faceAndId', faceAndId as any);
            }

            if (isConsentGiven !== undefined) {
                localVarFormParams.append('isConsentGiven', isConsentGiven as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks is user started a new registration flow or legacy
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserUsingNewRegistration: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('isUserUsingNewRegistration', 'userId', userId);
            const localVarPath = `/gw/inner/v2/users/{userId}/registration`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a display name is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesDisplayNameAvailabilityPost: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject2?: InlineObject2,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('publicGwInnerV2IdentitiesDisplayNameAvailabilityPost', 'product', product);
            const localVarPath = `/public-gw/inner/v2/identities/display-name-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a email is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesEmailAvailabilityPost: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject1?: InlineObject1,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('publicGwInnerV2IdentitiesEmailAvailabilityPost', 'product', product);
            const localVarPath = `/public-gw/inner/v2/identities/email-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a refferal is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesReferredByAvailabilityPost: async (
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject3?: InlineObject3,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('publicGwInnerV2IdentitiesReferredByAvailabilityPost', 'product', product);
            const localVarPath = `/public-gw/inner/v2/identities/referred-by-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (product !== undefined && product !== null) {
                localVarHeaderParameter['Product'] = String(product);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2RegistrationCreateAccountFlowIdPut: async (
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject5?: InlineObject5,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('publicGwInnerV2RegistrationCreateAccountFlowIdPut', 'flowId', flowId);
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('publicGwInnerV2RegistrationCreateAccountFlowIdPut', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('publicGwInnerV2RegistrationCreateAccountFlowIdPut', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/public-gw/inner/v2/registration/create-account/{flowId}`.replace(
                `{${'flowId'}}`,
                encodeURIComponent(String(flowId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registration flow
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2RegistrationPost: async (
            contentType: string,
            acceptLanguage: string,
            inlineObject4?: InlineObject4,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('publicGwInnerV2RegistrationPost', 'contentType', contentType);
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('publicGwInnerV2RegistrationPost', 'acceptLanguage', acceptLanguage);
            const localVarPath = `/public-gw/inner/v2/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request email change
         * @param {number} userId User ID
         * @param {InlineObject18} [inlineObject18]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailChange: async (
            userId: number,
            inlineObject18?: InlineObject18,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestEmailChange', 'userId', userId);
            const localVarPath = `/gw/inner/v2/users/{userId}/email-change-request`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject18, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request an email confirmation
         * @param {number} userId User ID
         * @param {InlineObject17} [inlineObject17]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailConfirmation: async (
            userId: number,
            inlineObject17?: InlineObject17,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestEmailConfirmation', 'userId', userId);
            const localVarPath = `/gw/inner/v2/users/{userId}/email-confirmation-request`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject17, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationApiAxiosParamCreator(configuration);
    return {
        /**
         * Confirm an email of a user
         * @param {number} userId User ID
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(
            userId: number,
            inlineObject6?: InlineObject6,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse204>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(userId, inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get list of states
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {string} countryCode Country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2CountriesCountryCodeStatesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            countryCode: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2CountriesCountryCodeStatesGet(
                product,
                acceptLanguage,
                countryCode,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get list of countries
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2CountriesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2CountriesGet(
                product,
                acceptLanguage,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get list of ethnicities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2EthnicitiesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2EthnicitiesGet(
                product,
                acceptLanguage,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2MeGet(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get list of nationalities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2NationalitiesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2NationalitiesGet(
                product,
                acceptLanguage,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject7} [inlineObject7]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationAccountTypeFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject7?: InlineObject7,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationAccountTypeFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject7,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get list of categories and subcategories
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationCategoriesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationCategoriesGet(
                product,
                acceptLanguage,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject9} [inlineObject9]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject9?: InlineObject9,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject9,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject8} [inlineObject8]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject8?: InlineObject8,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddModelFlow>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject8,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject12} [inlineObject12]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationDocumentDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject12?: InlineObject12,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationDocumentDetailsFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject12,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationEnumsGet(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationEnumsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow details
         * @param {string} flowId Flow Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationFlowIdGet(
            flowId: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationFlowIdGet(flowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get image uploaded during registration
         * @param {string} flowId Flow Id
         * @param {'front' | 'back' | 'snapshot'} type type of the document
         * @param {string} expiration Expiration Date
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
            flowId: string,
            type: 'front' | 'back' | 'snapshot',
            expiration: string,
            signature: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
                    flowId,
                    type,
                    expiration,
                    signature,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Trigger registration flow migration from Portal to SPA
         * @param {string} acceptLanguage Language
         * @param {InlineObject15} [inlineObject15]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationMigrateFlowPost(
            acceptLanguage: string,
            inlineObject15?: InlineObject15,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationMigrateFlowPost(
                acceptLanguage,
                inlineObject15,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject14} [inlineObject14]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject14?: InlineObject14,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject14,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject11} [inlineObject11]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationPersonalDataFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject11?: InlineObject11,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationPersonalDataFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject11,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject10} [inlineObject10]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationPersonalDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject10?: InlineObject10,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationPersonalDetailsFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject10,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {any} idFront Image file
         * @param {any} faceAndId Image file
         * @param {any} [idBack] Image file
         * @param {boolean} [isConsentGiven]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwInnerV2RegistrationUploadDocumentsFlowIdPost(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            idFront: any,
            faceAndId: any,
            idBack?: any,
            isConsentGiven?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwInnerV2RegistrationUploadDocumentsFlowIdPost(
                flowId,
                contentType,
                acceptLanguage,
                idFront,
                faceAndId,
                idBack,
                isConsentGiven,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks is user started a new registration flow or legacy
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUserUsingNewRegistration(
            userId: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUserUsingNewRegistration(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if a display name is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject2?: InlineObject2,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(
                    product,
                    inlineObject2,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if a email is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGwInnerV2IdentitiesEmailAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject1?: InlineObject1,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGwInnerV2IdentitiesEmailAvailabilityPost(
                product,
                inlineObject1,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if a refferal is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGwInnerV2IdentitiesReferredByAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject3?: InlineObject3,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.publicGwInnerV2IdentitiesReferredByAvailabilityPost(
                    product,
                    inlineObject3,
                    options,
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGwInnerV2RegistrationCreateAccountFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject5?: InlineObject5,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGwInnerV2RegistrationCreateAccountFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject5,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registration flow
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGwInnerV2RegistrationPost(
            contentType: string,
            acceptLanguage: string,
            inlineObject4?: InlineObject4,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFlow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGwInnerV2RegistrationPost(
                contentType,
                acceptLanguage,
                inlineObject4,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request email change
         * @param {number} userId User ID
         * @param {InlineObject18} [inlineObject18]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestEmailChange(
            userId: number,
            inlineObject18?: InlineObject18,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestEmailChange(
                userId,
                inlineObject18,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request an email confirmation
         * @param {number} userId User ID
         * @param {InlineObject17} [inlineObject17]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestEmailConfirmation(
            userId: number,
            inlineObject17?: InlineObject17,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestEmailConfirmation(
                userId,
                inlineObject17,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = RegistrationApiFp(configuration);
    return {
        /**
         * Confirm an email of a user
         * @param {number} userId User ID
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(userId: number, inlineObject6?: InlineObject6, options?: any): AxiosPromise<InlineResponse204> {
            return localVarFp.confirmEmail(userId, inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get list of states
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {string} countryCode Country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2CountriesCountryCodeStatesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            countryCode: string,
            options?: any,
        ): AxiosPromise<InlineResponse2007> {
            return localVarFp
                .gwInnerV2CountriesCountryCodeStatesGet(product, acceptLanguage, countryCode, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get list of countries
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2CountriesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): AxiosPromise<InlineResponse2006> {
            return localVarFp
                .gwInnerV2CountriesGet(product, acceptLanguage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get list of ethnicities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2EthnicitiesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): AxiosPromise<InlineResponse2004> {
            return localVarFp
                .gwInnerV2EthnicitiesGet(product, acceptLanguage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * User details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2MeGet(options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.gwInnerV2MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get list of nationalities
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2NationalitiesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): AxiosPromise<InlineResponse2005> {
            return localVarFp
                .gwInnerV2NationalitiesGet(product, acceptLanguage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject7} [inlineObject7]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationAccountTypeFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject7?: InlineObject7,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationAccountTypeFlowIdPut(flowId, contentType, acceptLanguage, inlineObject7, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get list of categories and subcategories
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
         * @param {string} acceptLanguage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationCategoriesGet(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            acceptLanguage: string,
            options?: any,
        ): AxiosPromise<InlineResponse2009> {
            return localVarFp
                .gwInnerV2RegistrationCategoriesGet(product, acceptLanguage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject9} [inlineObject9]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject9?: InlineObject9,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject9,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject8} [inlineObject8]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject8?: InlineObject8,
            options?: any,
        ): AxiosPromise<AddModelFlow> {
            return localVarFp
                .gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject8,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject12} [inlineObject12]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationDocumentDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject12?: InlineObject12,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationDocumentDetailsFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject12,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationEnumsGet(options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.gwInnerV2RegistrationEnumsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Registration flow details
         * @param {string} flowId Flow Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationFlowIdGet(flowId: string, options?: any): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationFlowIdGet(flowId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Get image uploaded during registration
         * @param {string} flowId Flow Id
         * @param {'front' | 'back' | 'snapshot'} type type of the document
         * @param {string} expiration Expiration Date
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
            flowId: string,
            type: 'front' | 'back' | 'snapshot',
            expiration: string,
            signature: string,
            options?: any,
        ): AxiosPromise<any> {
            return localVarFp
                .gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
                    flowId,
                    type,
                    expiration,
                    signature,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Trigger registration flow migration from Portal to SPA
         * @param {string} acceptLanguage Language
         * @param {InlineObject15} [inlineObject15]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationMigrateFlowPost(
            acceptLanguage: string,
            inlineObject15?: InlineObject15,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationMigrateFlowPost(acceptLanguage, inlineObject15, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject14} [inlineObject14]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject14?: InlineObject14,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject14,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject11} [inlineObject11]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationPersonalDataFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject11?: InlineObject11,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationPersonalDataFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject11,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject10} [inlineObject10]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationPersonalDetailsFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject10?: InlineObject10,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationPersonalDetailsFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject10,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {any} idFront Image file
         * @param {any} faceAndId Image file
         * @param {any} [idBack] Image file
         * @param {boolean} [isConsentGiven]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwInnerV2RegistrationUploadDocumentsFlowIdPost(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            idFront: any,
            faceAndId: any,
            idBack?: any,
            isConsentGiven?: boolean,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .gwInnerV2RegistrationUploadDocumentsFlowIdPost(
                    flowId,
                    contentType,
                    acceptLanguage,
                    idFront,
                    faceAndId,
                    idBack,
                    isConsentGiven,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Checks is user started a new registration flow or legacy
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserUsingNewRegistration(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.isUserUsingNewRegistration(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if a display name is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject2?: InlineObject2,
            options?: any,
        ): AxiosPromise<InlineResponse2001> {
            return localVarFp
                .publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(product, inlineObject2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Check if a email is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesEmailAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject1?: InlineObject1,
            options?: any,
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .publicGwInnerV2IdentitiesEmailAvailabilityPost(product, inlineObject1, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Check if a refferal is available
         * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2IdentitiesReferredByAvailabilityPost(
            product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
            inlineObject3?: InlineObject3,
            options?: any,
        ): AxiosPromise<InlineResponse200> {
            return localVarFp
                .publicGwInnerV2IdentitiesReferredByAvailabilityPost(product, inlineObject3, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} flowId Flow Id
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2RegistrationCreateAccountFlowIdPut(
            flowId: string,
            contentType: string,
            acceptLanguage: string,
            inlineObject5?: InlineObject5,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .publicGwInnerV2RegistrationCreateAccountFlowIdPut(
                    flowId,
                    contentType,
                    acceptLanguage,
                    inlineObject5,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Registration flow
         * @param {string} contentType Content Type
         * @param {string} acceptLanguage Language
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGwInnerV2RegistrationPost(
            contentType: string,
            acceptLanguage: string,
            inlineObject4?: InlineObject4,
            options?: any,
        ): AxiosPromise<RegistrationFlow> {
            return localVarFp
                .publicGwInnerV2RegistrationPost(contentType, acceptLanguage, inlineObject4, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Request email change
         * @param {number} userId User ID
         * @param {InlineObject18} [inlineObject18]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailChange(userId: number, inlineObject18?: InlineObject18, options?: any): AxiosPromise<void> {
            return localVarFp
                .requestEmailChange(userId, inlineObject18, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Request an email confirmation
         * @param {number} userId User ID
         * @param {InlineObject17} [inlineObject17]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailConfirmation(userId: number, inlineObject17?: InlineObject17, options?: any): AxiosPromise<void> {
            return localVarFp
                .requestEmailConfirmation(userId, inlineObject17, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationApi - interface
 * @export
 * @interface RegistrationApi
 */
export interface RegistrationApiInterface {
    /**
     * Confirm an email of a user
     * @param {number} userId User ID
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    confirmEmail(userId: number, inlineObject6?: InlineObject6, options?: any): AxiosPromise<InlineResponse204>;

    /**
     *
     * @summary Get list of states
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {string} countryCode Country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2CountriesCountryCodeStatesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        countryCode: string,
        options?: any,
    ): AxiosPromise<InlineResponse2007>;

    /**
     *
     * @summary Get list of countries
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2CountriesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ): AxiosPromise<InlineResponse2006>;

    /**
     *
     * @summary Get list of ethnicities
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2EthnicitiesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ): AxiosPromise<InlineResponse2004>;

    /**
     * User details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2MeGet(options?: any): AxiosPromise<InlineResponse2008>;

    /**
     *
     * @summary Get list of nationalities
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2NationalitiesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ): AxiosPromise<InlineResponse2005>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationAccountTypeFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject7?: InlineObject7,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     *
     * @summary Get list of categories and subcategories
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationCategoriesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ): AxiosPromise<InlineResponse2009>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject9?: InlineObject9,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject8?: InlineObject8,
        options?: any,
    ): AxiosPromise<AddModelFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject12} [inlineObject12]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationDocumentDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject12?: InlineObject12,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationEnumsGet(options?: any): AxiosPromise<InlineResponse20010>;

    /**
     * Registration flow details
     * @param {string} flowId Flow Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationFlowIdGet(flowId: string, options?: any): AxiosPromise<RegistrationFlow>;

    /**
     * Get image uploaded during registration
     * @param {string} flowId Flow Id
     * @param {'front' | 'back' | 'snapshot'} type type of the document
     * @param {string} expiration Expiration Date
     * @param {string} signature Signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
        flowId: string,
        type: 'front' | 'back' | 'snapshot',
        expiration: string,
        signature: string,
        options?: any,
    ): AxiosPromise<any>;

    /**
     * Trigger registration flow migration from Portal to SPA
     * @param {string} acceptLanguage Language
     * @param {InlineObject15} [inlineObject15]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationMigrateFlowPost(
        acceptLanguage: string,
        inlineObject15?: InlineObject15,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject14} [inlineObject14]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject14?: InlineObject14,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject11} [inlineObject11]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationPersonalDataFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject11?: InlineObject11,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject10} [inlineObject10]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationPersonalDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject10?: InlineObject10,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {any} idFront Image file
     * @param {any} faceAndId Image file
     * @param {any} [idBack] Image file
     * @param {boolean} [isConsentGiven]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    gwInnerV2RegistrationUploadDocumentsFlowIdPost(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        idFront: any,
        faceAndId: any,
        idBack?: any,
        isConsentGiven?: boolean,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Checks is user started a new registration flow or legacy
     * @param {number} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    isUserUsingNewRegistration(userId: number, options?: any): AxiosPromise<void>;

    /**
     * Check if a display name is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject2?: InlineObject2,
        options?: any,
    ): AxiosPromise<InlineResponse2001>;

    /**
     * Check if a email is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    publicGwInnerV2IdentitiesEmailAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject1?: InlineObject1,
        options?: any,
    ): AxiosPromise<InlineResponse200>;

    /**
     * Check if a refferal is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    publicGwInnerV2IdentitiesReferredByAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject3?: InlineObject3,
        options?: any,
    ): AxiosPromise<InlineResponse200>;

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    publicGwInnerV2RegistrationCreateAccountFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject5?: InlineObject5,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Registration flow
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    publicGwInnerV2RegistrationPost(
        contentType: string,
        acceptLanguage: string,
        inlineObject4?: InlineObject4,
        options?: any,
    ): AxiosPromise<RegistrationFlow>;

    /**
     * Request email change
     * @param {number} userId User ID
     * @param {InlineObject18} [inlineObject18]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    requestEmailChange(userId: number, inlineObject18?: InlineObject18, options?: any): AxiosPromise<void>;

    /**
     * Request an email confirmation
     * @param {number} userId User ID
     * @param {InlineObject17} [inlineObject17]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApiInterface
     */
    requestEmailConfirmation(userId: number, inlineObject17?: InlineObject17, options?: any): AxiosPromise<void>;
}

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI implements RegistrationApiInterface {
    /**
     * Confirm an email of a user
     * @param {number} userId User ID
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public confirmEmail(userId: number, inlineObject6?: InlineObject6, options?: any) {
        return RegistrationApiFp(this.configuration)
            .confirmEmail(userId, inlineObject6, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get list of states
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {string} countryCode Country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2CountriesCountryCodeStatesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        countryCode: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2CountriesCountryCodeStatesGet(product, acceptLanguage, countryCode, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get list of countries
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2CountriesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2CountriesGet(product, acceptLanguage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get list of ethnicities
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2EthnicitiesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2EthnicitiesGet(product, acceptLanguage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * User details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2MeGet(options?: any) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2MeGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get list of nationalities
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2NationalitiesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2NationalitiesGet(product, acceptLanguage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationAccountTypeFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject7?: InlineObject7,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationAccountTypeFlowIdPut(flowId, contentType, acceptLanguage, inlineObject7, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get list of categories and subcategories
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Site of origin
     * @param {string} acceptLanguage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationCategoriesGet(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        acceptLanguage: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationCategoriesGet(product, acceptLanguage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject9?: InlineObject9,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationChooseDisplayNameAndCategoryFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject9,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject8?: InlineObject8,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationChooseExistingPersonFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject8,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject12} [inlineObject12]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationDocumentDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject12?: InlineObject12,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationDocumentDetailsFlowIdPut(flowId, contentType, acceptLanguage, inlineObject12, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationEnumsGet(options?: any) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationEnumsGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow details
     * @param {string} flowId Flow Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationFlowIdGet(flowId: string, options?: any) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationFlowIdGet(flowId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get image uploaded during registration
     * @param {string} flowId Flow Id
     * @param {'front' | 'back' | 'snapshot'} type type of the document
     * @param {string} expiration Expiration Date
     * @param {string} signature Signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
        flowId: string,
        type: 'front' | 'back' | 'snapshot',
        expiration: string,
        signature: string,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationFlowIdIdDocumentsTypeExpirationSignatureGet(
                flowId,
                type,
                expiration,
                signature,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Trigger registration flow migration from Portal to SPA
     * @param {string} acceptLanguage Language
     * @param {InlineObject15} [inlineObject15]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationMigrateFlowPost(
        acceptLanguage: string,
        inlineObject15?: InlineObject15,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationMigrateFlowPost(acceptLanguage, inlineObject15, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject14} [inlineObject14]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject14?: InlineObject14,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationOranumPersonalDetailsFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject14,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject11} [inlineObject11]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationPersonalDataFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject11?: InlineObject11,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationPersonalDataFlowIdPut(flowId, contentType, acceptLanguage, inlineObject11, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject10} [inlineObject10]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationPersonalDetailsFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject10?: InlineObject10,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationPersonalDetailsFlowIdPut(flowId, contentType, acceptLanguage, inlineObject10, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {any} idFront Image file
     * @param {any} faceAndId Image file
     * @param {any} [idBack] Image file
     * @param {boolean} [isConsentGiven]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public gwInnerV2RegistrationUploadDocumentsFlowIdPost(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        idFront: any,
        faceAndId: any,
        idBack?: any,
        isConsentGiven?: boolean,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .gwInnerV2RegistrationUploadDocumentsFlowIdPost(
                flowId,
                contentType,
                acceptLanguage,
                idFront,
                faceAndId,
                idBack,
                isConsentGiven,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks is user started a new registration flow or legacy
     * @param {number} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public isUserUsingNewRegistration(userId: number, options?: any) {
        return RegistrationApiFp(this.configuration)
            .isUserUsingNewRegistration(userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if a display name is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject2?: InlineObject2,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .publicGwInnerV2IdentitiesDisplayNameAvailabilityPost(product, inlineObject2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if a email is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public publicGwInnerV2IdentitiesEmailAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject1?: InlineObject1,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .publicGwInnerV2IdentitiesEmailAvailabilityPost(product, inlineObject1, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if a refferal is available
     * @param {'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler'} product Product
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public publicGwInnerV2IdentitiesReferredByAvailabilityPost(
        product: 'livejasmin' | 'bimbim' | 'oranum' | 'jasmin' | 'docler',
        inlineObject3?: InlineObject3,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .publicGwInnerV2IdentitiesReferredByAvailabilityPost(product, inlineObject3, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} flowId Flow Id
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public publicGwInnerV2RegistrationCreateAccountFlowIdPut(
        flowId: string,
        contentType: string,
        acceptLanguage: string,
        inlineObject5?: InlineObject5,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .publicGwInnerV2RegistrationCreateAccountFlowIdPut(
                flowId,
                contentType,
                acceptLanguage,
                inlineObject5,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registration flow
     * @param {string} contentType Content Type
     * @param {string} acceptLanguage Language
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public publicGwInnerV2RegistrationPost(
        contentType: string,
        acceptLanguage: string,
        inlineObject4?: InlineObject4,
        options?: any,
    ) {
        return RegistrationApiFp(this.configuration)
            .publicGwInnerV2RegistrationPost(contentType, acceptLanguage, inlineObject4, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request email change
     * @param {number} userId User ID
     * @param {InlineObject18} [inlineObject18]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public requestEmailChange(userId: number, inlineObject18?: InlineObject18, options?: any) {
        return RegistrationApiFp(this.configuration)
            .requestEmailChange(userId, inlineObject18, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request an email confirmation
     * @param {number} userId User ID
     * @param {InlineObject17} [inlineObject17]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public requestEmailConfirmation(userId: number, inlineObject17?: InlineObject17, options?: any) {
        return RegistrationApiFp(this.configuration)
            .requestEmailConfirmation(userId, inlineObject17, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
