import $ from 'jquery';

import Ext from '../../lib/vendor/ExtCore';
import Chaos from '../../lib/chaos/Chaos';
import Controller from '../../lib/chaos/Controller';
import VideoPlayer from '../Video/VideoPlayer';

const MOBILE_SIZE = 700;

/**
 * ContentViewerController
 *
 * Loads new content to the overlay according which
 * arrow you click (prev||next)
 *
 * @How it works
 *
 *  The navigation button is an <a> tag
 *  ContentViewer is calling the url located in the clicked <a> element's 'href' attribute for next photo
 *  Arrow or navigation Button must have this class: 'content_viewer_navigation'
 *
 */
export default class ContentViewerController extends Controller {
	static EVENT_PHOTO_BLOCK_REPLACED = 'photo-block-replaced';
	static EVENT_GET_PHOTO_BLOCK = 'get-photo-block';
	static EVENT_TURN_ON_COMMENTS = 'turn-on-comments';
	static EVENT_JOIN_CHANNEL_BUTTON_CLICK = 'join-channel-button-click';
	static EVENT_IS_EXCLUSIVE_CONTENT = 'is-exclusive-content';
	static EVENT_COMMENT_BOX_RESIZE = 'comment-box-resize';

	get properties() {
		return { ...super.properties,
			/** @var {String}           Photo Viewer Navigation cls */
			navigationCls : 'content_viewer_navigation',
			/** @var {String}           Photo Viewer Disabled Navigation cls */
			navigationDisabledCls: 'disabled',
			/** @var {String}           Folder info block in the viewer */
			folderInfoCls : 'folder_info',
			/** @var {String}           Overlay Comment container cls */
			overlayContainerId : 'mvc_overlay_container',
			/** @var {String}           Id of overlay photo viewer next button */
			overlayContentViewerNextBtnId : 'channel_next_media',
			/** @var {String}           Id of overlay photo viewer next button */
			overlayContentViewerPrevBtnId : 'channel_prev_media',
			/** @var {String}           Data-comment-id selector */
			dataCommentIdSel : '[data-comment-id]',
			/** @var String                 Flash player id for videos on overlay  */
			videoContainerId : 'channel_video_container',
			/** @var {String}           Data-comment-folder name selector */
			dataFolderNameSel : '[data-folder-name]',
			/** @var {String}           Comment content cls */
			commentContentCls : 'scrollContent',
			/** @var {String}           Frame of any content that should be responsive */
			frameCls : 'frame',
			/** @var {String}           Content container cls */
			contentContainerCls : 'content_container',
			/** @var {String}           Comment content cls */
			commentWrapperCls : 'comment_wrapper',
			/** @var {String}           Comment content cls */
			commentInnerCls : 'comment_inner',
			/** @var {String}           Comment content selector */
			commentContentSel : '.comment_content',
			/** @var {String}           Note content element selector */
			noteContentSel: '.note_content',
			/** @var {String}           Overlay comment container cls */
			overlayCommentCtnCls : 'overlay_comment_container',
			/** @var {String}           If comments should appear in full screen the overlayCommentCtn should have it */
			fitScreenCls : 'fitscreen',
			/** @var {String}           Selector for picture overlay. */
			pictureCls : 'picture_comment_container',
			/** @var {String}           Selector for note overlay. */
			noteCls : 'note_comment_container',
			/** @var {String}           Selector for note content item. */
			noteSel : '.note_content',
			/** @var {Number}           Height of the comment title element. */
			commentTitleHeight : 56,
			/** @var {Number}           Height of the comment input element. */
			commentInputHeight : 60,
			/** @var {Number}           If the comments appear in full screen, it gives the padding from the window */
			overlayTopPadding : 20,
			/** @var {Number}           Overlay side padding */
			overlaySidePadding : 10,
			/** @var {String}           For tracking resize progress */
			isResizing : false,
			/** @var {String}           If key navigation is enabled */
			isKeyNavigationEnabled : true,
			/** @var {String}           Content loading progress */
			isLoading : false,
			/** @var {String}           Join channel button css class */
			joinChannelButtonClass : 'join_channel',
			/** @var {String}           Data Attribute name of the Exclusive content */
			exclusiveContentDataAttribute : 'data-exclusive',
			/** @var {Number}           Limit for image preloading (number of images) */
			preloadLimit : 1,
			/** @var {String}           Url for heash resolve */
			hashResolveUrl : 'MyContentGalleryUrlHashResolver/Get',
			/** @var {String}           Url for get comment details */
			getCommentDetailsUrl : 'MyContentComment/GetList',
			/** @var {Object}           Delayed task for comments loading */
			getCommentTask : null,
			/** @var {Object}           Fit screen element */
			fitScreenEl : '',
			/** @var {Object}           Comment inner element */
			commentInnerEl : '',
			/** @var {Object}           Content container element */
			contentContainerEl : '',
			/** @var {Object}           Comment wrapper element */
			commentWrapperElement : '',
			/** @var {Object}           Turn off back&forth navigation on the contentviewer */
			disableNavigation: false,

			/** Ajax routes **/

			/** @var {String}           Url for get overlay content */
			photoBlockUrl : 'MyContentPostDetails/Index',
			/** @var {String}           route for next content */
			nextContentRoute : 'MyContentNextPostDetails/Get',
			/** @var {String}           route for prev content */
			prevContentRoute : 'MyContentPreviousPostDetails/Get',

			/** @var {String}           Current comment id */
			_currentPhotoId : undefined,
			/** @var {String}           Current photo album name */
			_currentPhotoAlbumName : undefined,
			/** @var {String}           Url for next content */
			_nextContentUrl : undefined,
			/** @var {String}           Url for prev content */
			_prevContentUrl : undefined,
			/** @var {Array}            Array of images to preload */
			_preloadedImageArray : [],
			/** @var {Number}           Counter for array of images to prleoad */
			_currentContentIndex : 0,
			/** @var {Object}           Details object of currently opened album */
			_albumDetails : {},
			/** @var {Object}           Urls of resolved images */
			_resolvedImagesArray : {},
			/** @var {Number}           Index of last preloaded image object */
			_lastPreloadedImageIndex : 0,
			/** @var {Number}           Index of first preloaded image object */
			_firstPreloadedImageIndex : 0,
			/** @var {Bool}             True if all images has been preloaded */
			_allImagesLoaded : false

		};
	}
	init(el, config) {
		Chaos.addEvents(
			ContentViewerController.EVENT_PHOTO_BLOCK_REPLACED,
			ContentViewerController.EVENT_GET_PHOTO_BLOCK,
			ContentViewerController.EVENT_TURN_ON_COMMENTS,
			ContentViewerController.EVENT_JOIN_CHANNEL_BUTTON_CLICK,
			ContentViewerController.EVENT_IS_EXCLUSIVE_CONTENT,
			ContentViewerController.EVENT_COMMENT_BOX_RESIZE
		);

		this.bodyEl = Ext.getBody();
		this.overlayContainerEl = Ext.get(this.overlayContainerId);

		super.init(el, config);
	}

	/**
	 * When the first overlay arrived
	 * it sets the url-s and binds events for
	 * navigation arrows
	 *
	 * @method bindSliderEvents
	 *
	 * @return void;
	 */
	setNavigation() {
		this.navigationItems = this.element.select('.' + this.navigationCls);

		if (!this.disableNavigation) {
			this.overlayContentViewerNextBtnEl = Ext.get(this.overlayContentViewerNextBtnId);
			this.overlayContentViewerPrevBtnEl = Ext.get(this.overlayContentViewerPrevBtnId);

			if (!this.navigationItems.item(0)) {
				return;
			}
			this.bindNavigation();
		}
		else {
			this.folderInfoEl = this.element.select('.' + this.folderInfoCls);
			this.folderInfoEl.elements.length && this.folderInfoEl.item(0).addClass(this.navigationDisabledCls);
			this.navigationItems.each(el => {
				el.addClass(this.navigationDisabledCls);
			});
		}
	}

	/**
	 * Binds navigation events
	 *
	 * @method bindNavigation
	 *
	 * @return void;
	 */
	bindNavigation() {
		this.navigationItems.on('click', this.onNavigationClick, this);
		if (this.isKeyNavigationEnabled) {
			this.bodyEl.un('keydown', this.onKeyPress, this);
			this.bodyEl.on('keydown', this.onKeyPress, this);
		}
	}

	/**
	 * Blocks navigation with keyboard
	 *
	 * @method blockNavigationByKeyboard
	 *
	 * @return void;
	 */
	blockNavigationByKeyboard() {
		this.isKeyNavigationEnabled = false;
	}

	/**
	 * Enable navigation with keyboard
	 *
	 * @method enableNavigationByKeyboard
	 *
	 * @return void;
	 */
	enableNavigationByKeyboard() {
		this.isKeyNavigationEnabled = true;
	}

	/**
	 * Turn on comments if comment block ready
	 *
	 * @method onTurnOnComments
	 *
	 * @return void;
	 */
	onTurnOnComments(commentDetails) {
		this.fireEvent(ContentViewerController.EVENT_TURN_ON_COMMENTS, commentDetails);
	}

	/**
	 * Handle navigation with keyboard
	 *
	 * @method onKeyPress
	 * @param {Object} ev   Event object
	 *
	 * @return void;
	 */
	onKeyPress(ev) {
		if (this.isKeyNavigationEnabled) {
			switch (ev.keyCode) {
				case 39:
					this.showNext();
					break;
				case 37:
					this.showPrev();
					break;
				default:
					return;
			}
		}
	}

	/**
	 * Show next gallery item.
	 *
	 * @method showNext
	 *
	 * @return void;
	 */
	showNext() {
		if (this.overlayContentViewerNextBtnEl) {
			this.overlayContentViewerNextBtnEl.dom.click();
		}
	}

	/**
	 * Show previous gallery item.
	 *
	 * @method showPrev
	 *
	 * @return void;
	 */
	showPrev() {
		if (this.overlayContentViewerPrevBtnEl) {
			this.overlayContentViewerPrevBtnEl.dom.click();
		}
	}

	/**
	 * Returns whether the content is a picture, or not.
	 *
	 * @return {Boolean}
	 */
	isPicture() {
		if (!this.overlayCommentCtnEls) {
			return false;
		}
		return this.overlayCommentCtnEls.item(0).hasClass(this.pictureCls);
	}

	/**
	 * Returns whether the content is a note or not.
	 *
	 * @return {Boolean}
	 */
	isNote() {
		if (!this.overlayCommentCtnEls) {
			return false;
		}

		return this.overlayCommentCtnEls.item(0).hasClass(this.noteCls);
	}

	/**
	 * Returns whether the content is a video or not.
	 * @return {Boolean}
	 */
	isVideo() {
		return !!Ext.fly(this.videoContainerId);
	}

	/**
	 * Collect every elements belong to
	 * photo viewer
	 *
	 * @method getContentViewerElements
	 *
	 * @return void;
	 */
	getContentViewerElements() {
		this.commentContentEl = this.overlayContainerEl.select('.' + this.commentContentCls);
		this.overlayCommentCtnEls = this.overlayContainerEl.select('.' + this.overlayCommentCtnCls);
		this.frameEl = this.overlayContainerEl.select('.' + this.frameCls);
		this.fitScreenEl = this.element.select('.' + this.fitScreenCls).item(0);
		this.commentInnerEl = this.element.select('.' + this.commentInnerCls).item(0);
		this.contentContainerEl = this.element.select('.' + this.contentContainerCls).item(0);
		this.commentWrapperElement = this.element.select('.' + this.commentWrapperCls).item(0);
		this.commentContentElement = this.element.select(this.commentContentSel).item(0);

		if (this.frameEl.item(0) && this.isPicture()) {
			this.photoEl = this.frameEl.item(0).select('img').item(0);
			this.photoEl.un('load', this.setFrameSize, this);
			this.photoEl.on('load', this.setFrameSize, this);
		}
		else if (this.isNote()) {
			this._noteEl = this.frameEl.item(0).select(this.noteSel).item(0);
		}
		else if (this.isVideo()) {
			this._videoPlayer = new VideoPlayer().init({
				element : $('#' + this.videoContainerId)
			});
		}
	}

	/**
	 * Set every necessary things for the carousel
	 * when you open it first time.
	 *
	 * - elements, waiting for image pre-load,
	 * - navigation bindings, frame and comment box
	 *
	 * @method setupCarousel
	 * @param {Object} param    Json object
	 *
	 * @return void;
	 */
	setupCarousel(param) {
		this.getContentViewerElements();
		this.fillContentArrays(param);
		this.updateLikes();
		this.setNavigation();
		this.startFrameResize();
		this.startCommentBoxResize();
		this.isNote() && this.ContentViewerView.updateNoteTextSizeAttr(this._noteEl);
	}

	/**
	 * Get the current content index when overlay opens.
	 *
	 * @method getCurrentContentIndex
	 * @param contentArray
	 *
	 * @return {Number}
	 */
	getCurrentContentIndex(contentArray) {
		var currentContentIndex,
			currentDocumentId = this.overlayCommentCtnEls.item(0).dom.getAttribute('data-comment-id');

		for (let doc of contentArray.containedDocuments || []) {
			if (doc.id === currentDocumentId) {
				currentContentIndex = doc.folder_index;
				return currentContentIndex;
			}
		}

		return -1;
	}

	/**
	 * Symmetrically sorting images
	 * @Thank you for this comment, now I understand what does this do.
	 * @method symmetricalSorting
	 * @param resolvedImages   Resolved image uris
	 *
	 * @return Array() sortedImages
	 */
	symmetricalSorting(resolvedImages) {
		var evenCounter,
			oddCounter,
			sortedImages = [],
			objectLength = resolvedImages.length,
			i = 0,
			preloadLimit = 25;

		evenCounter = oddCounter = this._currentContentIndex;
		preloadLimit = preloadLimit * 2 + 1 > objectLength ? objectLength : preloadLimit * 2 + 1;
		for (i; i < preloadLimit; i++) {
			if (i % 2 === 0) {
				if (evenCounter > objectLength - 1) {
					evenCounter = 0;
				}
				sortedImages[i] = resolvedImages[evenCounter];
				this._lastPreloadedImageIndex = evenCounter;
				evenCounter++;
			}
			else {
				oddCounter--;
				if (oddCounter < 0) {
					oddCounter = objectLength - 1;
				}
				sortedImages[i] = resolvedImages[oddCounter];
				this._firstPreloadedImageIndex = oddCounter;
			}
		}
		return sortedImages;
	}

	/**
	 * Preload images
	 *
	 * @method preloadImages
	 * @param resolvedImages   Resolved image uris
	 *
	 * @return void;
	 */
	preloadImages(resolvedImages) {
		if (typeof resolvedImages === 'object' && resolvedImages.length) {
			var index;
			for (let resolved of Object.keys(resolvedImages)) {
				index = this._contentArray.indexOf(resolvedImages[resolved].hash);
				if (index !== -1) {
					this._contentArray[index] = resolvedImages[resolved].url;
				}
			}
		}

		var sortedImages = this.symmetricalSorting(this._contentArray),
			imageSrc = [], i = 0,
			tempArray = [],
			tempImageObj = null;

		for (i; i < sortedImages.length; i++) {
			imageSrc[i] = sortedImages[i];
			tempImageObj = new window.Image();
			tempImageObj.src = imageSrc[i];
			tempArray[i] = tempImageObj;
			tempImageObj = null;
		}

		//Restore original order of array
		this.restoreOrderAfterPreload(this._contentArray, tempArray);
	}

	/**
	 * Restore original order of array after symmetrical sorting and preload.
	 *
	 * @method restoreOrderAfterPreload
	 *
	 * @param originalArray   Original array
	 * @param sortedArray     Sorted array
	 */
	restoreOrderAfterPreload(originalArray, sortedArray) {
		var j = 0, k = 0;

		this._preloadedImageArray = [];
		for (j; j < originalArray.length; j++) {
			for (k; k < sortedArray.length; k++) {
				if (originalArray[j] === sortedArray[k].src) {
					this._preloadedImageArray[j] = sortedArray[k];
				}
			}
			k = 0;
		}
	}

	/**
	 * Update likes number in the DOM
	 */
	updateLikes() {
		this._currentContentIndex = this.getCurrentContentIndex(this._albumDetails);

		// If there is no index, the likes number is directly in the param object, not in the containedDocuments (videos)
		var firstContentLikes = this._currentContentIndex >= 0 ?
			this._albumDetails.containedDocuments[this._currentContentIndex].likes :
			this._albumDetails.likes || 0;

		if (this.frameEl.item(0)) {
			this.ContentViewerView.updateLikes(this.frameEl.item(0), firstContentLikes);
		}
	}
	/* eslint-disable complexity */
	/**
	 * Fills content array for the carousel.
	 */
	fillContentArrays(param) {
		if (!param) {
			return;
		}

		let contentIds = [], url;

		//Reset global elements
		this._preloadedImageArray = [];
		this._albumDetails = {};
		this._currentContentIndex = 0;
		this._lastPreloadedImageIndex = 0;
		this._firstPreloadedImageIndex = 0;
		this._allImagesLoaded = false;
		this._resolvedImagesArray = [];
		this._currentPhotoId = undefined;
		this._contentArray = [];
		this._albumDetails = param;
		this._currentContentIndex = this.getCurrentContentIndex(param);

		if (!param.containedDocuments) {
			return;
		}

		for (var i = 0; i < param.containedDocuments.length; i++) {
			var containedDoc = param.containedDocuments[i];

			if ('hash' in containedDoc) {
				contentIds.push(containedDoc.id);
			}

			var content = containedDoc.hash || containedDoc.contentUrl || containedDoc.content;
			this._contentArray.push(content);
		}

		if (contentIds.length) {
			url = Chaos.getUrl(
				this.hashResolveUrl,
				{ performerNick : Chaos.getMeta('performerName') },
				{ contentIds }
			);
			this.ContentViewerModel.getResolvedImageUris(url);
		}
		else if (this.isPicture()) {
			this.preloadImages([]);
		}
	}
	/* eslint-enable complexity */

	/**
	 * standByUntilPictureArrive
	 *
	 * @method standByUntilPictureArrive
	 * @param {Object}  detailsObj  Json data for new content
	 * @return void;
	 */
	standByUntilPictureArrive() {
		if (this.overlayCommentCtnEls.item(0).hasClass(this.fitScreenCls)) {
			this.startFrameResize();
		}
	}

	/**
	 * Binds resize event for comment box
	 *
	 * @method startCommentBoxResize
	 *
	 * @return void;
	 */
	startCommentBoxResize() {
		this.setCommentBoxSize();
		this.fireEvent(ContentViewerController.EVENT_COMMENT_BOX_RESIZE);
		Ext.fly(window).un('resize', this.setCommentBoxSize, this);
		Ext.fly(window).on('resize', this.setCommentBoxSize, this);
	}

	/**
	 * Calculates the correct comment box size
	 *
	 * @method setCommentBoxSize
	 *
	 * @return void;
	 */
	setCommentBoxSize() {
		let innerWrapperElement = this.commentInnerEl,
			commentWrapperElement = this.commentWrapperElement,
			height;

		if (innerWrapperElement && commentWrapperElement) {
			let noteContentEl = this.element.select(this.noteContentSel).item(0);

			if (noteContentEl && this.commentContentElement && window.innerWidth <= MOBILE_SIZE) {
				height = window.innerHeight - noteContentEl.getHeight() - this.commentTitleHeight - this.commentInputHeight;
				this.commentContentElement.setStyle('height', height + 'px');
			}
			else {
				let wrapperHeight = window.innerWidth <= MOBILE_SIZE ? window.innerHeight : innerWrapperElement.getHeight();
				height = wrapperHeight - (this.commentTitleHeight + this.commentInputHeight);
			}
			commentWrapperElement.setHeight(height);
		}
	}

	/**
	 * Binds resize event for photo frame
	 *
	 * @method startFrameResize
	 *
	 * @return void;
	 */
	startFrameResize() {
		Ext.fly(window).on('resize', this.setFrameSize, this);
	}

	/**
	 * Calculates the correct frame size
	 *
	 * @method setFrameSize
	 *
	 * @return void;
	 */
	setFrameSize() {
		var fitScreenEl = this.fitScreenEl,
			commentInnerEl = this.commentInnerEl,
			contentContainerEl = this.contentContainerEl,
			embedObject,
			widthRatio = window.innerWidth <= MOBILE_SIZE ? 1 : 0.95,
			heightRatio = window.innerWidth <= MOBILE_SIZE ? 1 : 0.9;
		if (window.innerWidth <= MOBILE_SIZE) commentInnerEl.setStyle('width', '0');
		if (fitScreenEl) {
			embedObject = fitScreenEl.select('object, embed');
			embedObject.setStyle({
				width  : window.innerWidth * widthRatio - commentInnerEl.getWidth() + 'px',
				height : window.innerHeight * heightRatio + 'px'
			});
		}

		if (fitScreenEl && fitScreenEl.hasClass(this.pictureCls) && this.photoEl) {
			var photoMaxHeight = window.innerHeight * heightRatio,
				photoMaxWidth = window.innerWidth <= MOBILE_SIZE ? window.innerWidth : window.innerWidth * widthRatio - commentInnerEl.getWidth(),
				width = Math.min(fitScreenEl.getWidth() / window.innerWidth * 100, widthRatio * 100),
				height = Math.min(fitScreenEl.getHeight() / window.innerHeight * 100, heightRatio * 100),
				contentMinHeight = Math.floor(window.innerHeight * (height / 100));

			this.photoEl.setStyle({
				maxWidth  : photoMaxWidth + 'px',
				maxHeight : photoMaxHeight + 'px'
			});

			contentContainerEl.setStyle({
				minWidth  : window.innerWidth <= MOBILE_SIZE ? width + '%' : 'auto',
				height : this.photoEl.getHeight() + 'px',
				top : window.innerWidth <= MOBILE_SIZE ? window.innerHeight / 2 - this.photoEl.getHeight() / 2 + 'px' : 0
			});

			// we need to set it separately because the above setStyle might change its height
			this.photoEl.setStyle({
				top : Math.max((contentMinHeight - this.photoEl.getHeight()) / 2, 0) + 'px'
			});

			// resize commentbox after image is loaded
			if (window.innerWidth <= MOBILE_SIZE) commentInnerEl.setStyle('width', '100%');
			this.startCommentBoxResize();
		}
	}

	/**
	 * Gets navigation url-s for prev and next content
	 *
	 * @method setNavigationUrls
	 *
	 * @return void;
	 */
	getNavigationUrls() {
		var paramObj = {
			currentDocumentId : this._currentPhotoId,
			folder            : this._currentPhotoAlbumName
		};
		this._nextContentUrl = Chaos.getUrl(this.nextContentRoute, paramObj);
		this._prevContentUrl = Chaos.getUrl(this.prevContentRoute, paramObj);
	}

	/**
	 * onNavigationClick
	 *
	 * @method onNavigationClick
	 * @param {Object} ev   Event object
	 *
	 * return void;
	 */
	onNavigationClick(ev) {
		ev.preventDefault();
		var target = Ext.get(ev.target),
			direction = target.dom.tagName.toLocaleLowerCase() === 'a' ? target.dom.getAttribute('data-direction') :
				target.findParent('a').getAttribute('data-direction');

		if (direction === 'next') {
			this.isPicture() && this.preloadNextImage();
			this._currentContentIndex++;
			if (this._currentContentIndex === this._contentArray.length) {
				this._currentContentIndex = 0;
			}
		}
		else {
			this.isPicture() && this.preloadPrevImage();
			this._currentContentIndex--;
			if (this._currentContentIndex < 0) {
				this._currentContentIndex = this._contentArray.length - 1;
			}
		}

		this.refreshContent();
	}

	/**
	 * Preloads next image.
	 *
	 * @method preloadNextImage
	 *
	 * @return void
	 */
	preloadNextImage() {
		if (!this._allImagesLoaded) {
			if (this._lastPreloadedImageIndex !== this._firstPreloadedImageIndex) {
				this._lastPreloadedImageIndex++;
				this._lastPreloadedImageIndex =
					this._lastPreloadedImageIndex > this._contentArray.length - 1 ? 0 :
						this._lastPreloadedImageIndex;
				this._preloadedImageArray[this._lastPreloadedImageIndex] = new Image();
				this._preloadedImageArray[this._lastPreloadedImageIndex].src =
					this._contentArray[this._lastPreloadedImageIndex];
			}
			else {
				this._allImagesLoaded = true;
			}
		}
	}

	/**
	 * Preloads prev image.
	 *
	 * @method preloadPrevImage
	 *
	 * @return void
	 */
	preloadPrevImage() {
		if (!this._allImagesLoaded) {
			if (this._lastPreloadedImageIndex !== this._firstPreloadedImageIndex) {
				this._firstPreloadedImageIndex--;
				this._firstPreloadedImageIndex =
					this._firstPreloadedImageIndex < 0 ? this._contentArray.length - 1 :
						this._firstPreloadedImageIndex;
				this._preloadedImageArray[this._firstPreloadedImageIndex] = new Image();
				this._preloadedImageArray[this._firstPreloadedImageIndex].src =
					this._contentArray[this._firstPreloadedImageIndex];
			}
			else {
				this._allImagesLoaded = true;
			}
		}
	}

	/**
	 * Details for next content passed by ContentViewerModel
	 *
	 * @method refreshContent
	 * @param {Object}  detailsObj  Json data for new content
	 *
	 * @return void;
	 */
	refreshContent() {
		var currentDocumentObj = this._albumDetails.containedDocuments[this._currentContentIndex];

		if (this.frameEl.item(0)) {
			this.ContentViewerView.updateLikes(this.frameEl.item(0), currentDocumentObj.likes);
			this.ContentViewerView.refreshContentBlock(this.frameEl.item(0), currentDocumentObj);
			this.isPicture() && this.refreshImage();
			this.isNote() && this.refreshNote();
		}
		if (this.commentContentEl.item(0)) {
			if (!(this.getCommentTask instanceof Ext.util.DelayedTask)) {
				this.getCommentTask = new Ext.util.DelayedTask(function() {
					this.delayedGetCommentDetails();
				}, this);
			}
			this.getCommentTask.delay(200);
		}
		if (this.isNote()) {
			this.ContentViewerView.updateNoteTextSizeAttr(this._noteEl);
		}

		this.ContentViewerView.showCommentsLoader();
		this.ContentViewerView.updateCommentId(this.overlayCommentCtnEls.item(0), currentDocumentObj.id);
		this.setFrameSize();
	}

	/**
	 * Refreshes the image in the content if this is a picture viewer
	 */
	refreshImage() {
		this.ContentViewerView.changeImageSrcAttribute(
			this.photoEl,
			this._preloadedImageArray[this._currentContentIndex]
		);
		this.photoEl = this.frameEl.item(0).select('img').item(0);
	}

	/**
	 * Refreshes the note text if this is a note viewer
	 */
	refreshNote() {
		this._noteEl.select('span').item(0).dom.innerHTML = this._contentArray[this._currentContentIndex];
	}

	/**
	 * Delayed get comment details from model
	 *
	 * @method delayedGetCommentDetails
	 *
	 * @return void;
	 */
	delayedGetCommentDetails() {
		var currentDocumentObj = this._albumDetails.containedDocuments[this._currentContentIndex],
			url = Chaos.getUrl(this.getCommentDetailsUrl, { parentId : currentDocumentObj.id, lastItemTimeStamp : 0 });
		this.ContentViewerModel.getCommentDetails(url);
	}

	/**
	 *
	 */
	refreshPhotoId(id) {
		this._currentPhotoId = id;
		this.getNavigationUrls();
	}

	onCommentLikeContainerClick() {
		this.overlayCommentCtnEls.addClass('showCommentOverlay');
	}

	onCommentLikeCloseClick(ev) {
		ev.preventDefault();
		this.overlayCommentCtnEls.removeClass('showCommentOverlay');
	}

	/**
	 * Binds the initial event handlers
	 */
	bind() {
		super.bind();
		this.overlayContainerEl.on('click', this.onCommentLikeContainerClick, this, {
			delegate : '.comment_like_container'
		});
		this.overlayContainerEl.on('click', this.onCommentLikeCloseClick, this, {
			delegate : '.commentCloseBtn'
		});
	}

	/**
	 * Unbinds all event handlers
	 */
	unbind() {
		if (this._videoPlayer) {
			this._videoPlayer.remove();
			delete this._videoPlayer;
		}
		super.unbind();
		if (this.navigationItems) {
			this.navigationItems.un('click', this.onNavigationClick, this);
		}
		this.overlayContainerEl.un('click', this.onCommentLikeContainerClick, this, {
			delegate : '.comment_like_container'
		});
		this.overlayContainerEl.un('click', this.onCommentLikeCloseClick, this, {
			delegate : '.commentCloseBtn'
		});
		this.bodyEl.un('keydown', this.onKeyPress, this);
	}
}