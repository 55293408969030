import HTML5UploaderController from './HTML5UploaderController';
import HTML5MultiUploaderController from './HTML5MultiUploaderController';
import AttachmentHTML5MultiUploaderControllerMWH from './AttachmentHTML5MultiUploaderControllerMWH';
import ChannelHTML5UploaderController from './Channel-HTML5UploaderController';
import IdHTML5UploaderController from './Id-HTML5UploaderController';
import IdSimpleHTML5UploaderController from './IdSimple-HTML5UploaderController';
import SelfInvoiceHTML5UploaderController from './SelfInvoice-HTML5UploaderController';
import SnapshotHTML5UploaderController from './Snapshot-HTML5UploaderController';
import PromotionToolsHTML5UploaderController from './PromotionToolsHTML5UploaderController';
import '../TagManager/TagManager';

export default {
    HTML5UploaderController,
    HTML5MultiUploaderController,
    AttachmentHTML5MultiUploaderControllerMWH,
    ChannelHTML5UploaderController,
    IdHTML5UploaderController,
    IdSimpleHTML5UploaderController,
    SelfInvoiceHTML5UploaderController,
    SnapshotHTML5UploaderController,
    PromotionToolsHTML5UploaderController,
};
