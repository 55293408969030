import { EngagementApiInterface } from 'apiClients/msc-api-gateway/proxy/engagement/client/engagement-api';
import { ActorTypes } from 'domains/Common.types';
import { EmarsysEntity } from './Emarsys.types';
import { EmarsysDataMapperInterface } from './Emarsys.service';

export class EmarsysDataMapper implements EmarsysDataMapperInterface {
    constructor(private apiClient: EngagementApiInterface) {}

    public getEmarsysContactDetails(xActorId: string, xActorType: ActorTypes): Promise<EmarsysEntity> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .getContactDetails(xActorId, xActorType)
                .then((response) => {
                    const { data } = response.data;
                    if (!data || !data.id) {
                        reject(new Error('Missing response data'));
                        return;
                    }
                    resolve({ id: data.id });
                })
                .catch(reject);
        });
    }
}
