import Ext from '../vendor/ExtCore';

/**
 * General template creator.
 * @constructor
 *
 * @param {object} config   Configurables
 */
export default class ChaosTemplate extends Ext.util.Observable {
	get properties() {
		return Object.assign({}, {
			/** @var {string} tpl    Template string or HTML fragment */
			tpl          : '',
			/** @var {boolean} validateData   True if data validation is needed immediately before rendering template */
			validateData : true,

			/** PRIVATES */

			/** @var {Object}        Generated template string [instance of Ext.Template] */
			_tpl : undefined
		});
	}

	constructor(config) {
		super(config);
		if (this.properties) {
			Object.keys(this.properties).forEach((propertyKey) => {
				this[propertyKey] = this.properties[propertyKey];
			});
		}
		this.init();
	}

	/**
	 * Initializer
	 *
	 * @return void;
	 */
	init() {}

	/**
	 * Return with the a template string
	 *
	 * @return string;
	 */
	getTemplate() {
		return this._tpl;
	}

	/**
	 * Sets params for template to render.
	 *
	 * @param {Object} data   Apply template params
	 *
	 * @return object|string;
	 */
	_setTemplateParams(data) {
		if (!data || data === {} || data === null) {
			return '';
		}
		return {
			id : data.id
		};
	}

	/**
	 * Creates a new Ext.Template, and applies params to it.
	 *
	 * @param {Object} data   Apply template params
	 *
	 * @return string;
	 */
	render(data) {
		this._tpl = new Ext.Template(this.tpl);
		if (this.validateData) {
			this._validateData(data);
		}
		return this._tpl.applyTemplate(this._setTemplateParams(data));
	}

	/**
	 * Validates given data.
	 *
	 * @param {Object} data   Apply template params
	 *
	 * @return void
	 */
	_validateData(data) {
		if (typeof data === 'undefined' || data === {} || data === null) {
			throw 'Invalid data for template applying: ' + data;
		}
	}
}