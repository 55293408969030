import ChaosObject from '../../lib/chaos/Object';

export default class ChannelOverlayModel extends ChaosObject {
	/**
	 * Init
	 *
	 * @param {Element} el      Context element
	 * @param {Object} config   Config object of this controller
	 */
	init(el, config) {
		super.init(el, config);
	}
}
