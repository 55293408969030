/* tslint:disable */
/* eslint-disable */
/**
 * Application_config API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../msc-api-gateway/applicationconfig/models';
// @ts-ignore
import { GetAppConfigResponse } from '../../../msc-api-gateway/applicationconfig/models';
/**
 * ApplicationConfigApi - axios parameter creator
 * @export
 */
export const ApplicationConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get application config
         * @param {'ios' | 'android' | 'web'} platform Platform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AppConfigPlatformGet: async (
            platform: 'ios' | 'android' | 'web',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('v1AppConfigPlatformGet', 'platform', platform);
            const localVarPath = `/v1/app-config/{platform}`.replace(
                `{${'platform'}}`,
                encodeURIComponent(String(platform)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ApplicationConfigApi - functional programming interface
 * @export
 */
export const ApplicationConfigApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationConfigApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get application config
         * @param {'ios' | 'android' | 'web'} platform Platform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AppConfigPlatformGet(
            platform: 'ios' | 'android' | 'web',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AppConfigPlatformGet(platform, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ApplicationConfigApi - factory interface
 * @export
 */
export const ApplicationConfigApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ApplicationConfigApiFp(configuration);
    return {
        /**
         *
         * @summary Get application config
         * @param {'ios' | 'android' | 'web'} platform Platform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AppConfigPlatformGet(platform: 'ios' | 'android' | 'web', options?: any): AxiosPromise<GetAppConfigResponse> {
            return localVarFp.v1AppConfigPlatformGet(platform, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationConfigApi - interface
 * @export
 * @interface ApplicationConfigApi
 */
export interface ApplicationConfigApiInterface {
    /**
     *
     * @summary Get application config
     * @param {'ios' | 'android' | 'web'} platform Platform name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationConfigApiInterface
     */
    v1AppConfigPlatformGet(platform: 'ios' | 'android' | 'web', options?: any): AxiosPromise<GetAppConfigResponse>;
}

/**
 * ApplicationConfigApi - object-oriented interface
 * @export
 * @class ApplicationConfigApi
 * @extends {BaseAPI}
 */
export class ApplicationConfigApi extends BaseAPI implements ApplicationConfigApiInterface {
    /**
     *
     * @summary Get application config
     * @param {'ios' | 'android' | 'web'} platform Platform name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationConfigApi
     */
    public v1AppConfigPlatformGet(platform: 'ios' | 'android' | 'web', options?: any) {
        return ApplicationConfigApiFp(this.configuration)
            .v1AppConfigPlatformGet(platform, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
