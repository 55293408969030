import InitMSCRiot from '../../lib/InitMSCRiot';
import { getReactComponent } from './get-react-component';

import './App.scss';
// Commonly used TODO: Move to where it's used
import '../Tabs/Tabs.scss';
import '../ProgressBar/ProgressBar.scss';

// Commons/plugins/vendors  TODO: Move to where it's used
import $ from 'jquery';
import 'protip';
import riot from 'riot';
import Application from '../../lib/chaos/Application';
import Chaos from '../../lib/chaos/Chaos';
import I18n from '../../lib/chaos/I18n';
import Router from '../../lib/chaos/Router';
import Config from '../../lib/chaos/Config';
import PH from '../../lib/constant/Phrame';
import IsMobile from '../../lib/chaos/IsMobile';

import Overlay from '../Overlay/Overlay';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import GlobalProgressIndicator from '../ProgressIndicator/GlobalProgressIndicator';
import Helptip from '../Helptip/Helptip';
import ConfirmOverlay from '../Overlay/Confirm/Confirm';
import layoutLoader from '../Layout';
import pageLoader from '../Page';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import store from 'store/store';
import EntryPoint from 'msc_modules/src/App';
import { getEnv } from 'msc_modules/src/configs/environments';
import { Environment } from 'msc_modules/src/configs/globalConfig';

// Common tags  TODO: Move to where it's used
import '../_Form/FormTag';

// BE based footer scripts will use this to store it's stuff
window.riot = riot; // Sry folks!

export default class App {
	i18n = undefined;

	config = undefined;

	static Instance = undefined;

	constructor(config) {
		this.config = config;
		this.enableJs();
        this.enableSentry();
		this.setConfig();
		this.setLanguage();
		this.setRiotMixins();
		this.initComponents();
		this.setApplication();
		this.setRouter();
		this.setReactChaosBridge();
		this.runApplication();
		this.setHandleLogout();
		App.Instance = this;
		try {
			navigator.serviceWorker.addEventListener('message', event => {
				if (event.data.url) {
					window.location = `/${Config.get('language')}${event.data.url}`;
				}
			});
		} catch (e) {
			console.log(e);
		}
	}

	enableJs() {
		document.body.classList.remove('no-script');
	}

    enableSentry() {
        const isProd = getEnv() === Environment.PROD;

        Sentry.init({
            dsn: SENTRY_DSN,
            enabled: isProd,
            maxBreadcrumbs: 25,
            attachStacktrace: true,
            environment: process.env.NODE_ENV,
            sampleRate: isProd ? 0.2 : 1.0,
            tracesSampleRate: isProd ? 0.01 : 0.5,
            integrations: [new BrowserTracing()],
            release: SENTRY_RELEASE,
						ignoreErrors: [
							// Random plugins/extensions
							'top.GLOBALS',
							// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
							'originalCreateNotification',
							'canvas.contentDocument',
							'MyApp_RemoveAllHighlights',
							'http://tt.epicplay.com',
							"Can't find variable: ZiteReader",
							'jigsaw is not defined',
							'ComboSearch is not defined',
							'http://loading.retry.widdit.com/',
							'atomicFindClose',
							// Facebook borked
							'fb_xd_fragment',
							// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
							// reduce this. (thanks @acdha)
							// See http://stackoverflow.com/questions/4113268
							'bmi_SafeAddOnload',
							'EBCallBackMessageReceived',
							// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
							'conduitPage',
							/Loading \w*\s?chunk \d+ failed/,
							'Request aborted',
							'authentication failed',
							'AbortError: The operation was aborted',
							'AbortError: Share canceled',
							'Abort due to cancellation of share',
							'An error occurred. Please try again',
							'captured as promise rejection',
							'teads_tracking_events_sent.push is not a function',
						],
						denyUrls: [
							// Chrome extensions
							/extensions\//i,
							/^chrome:\/\//i,
							/^chrome-extension:\/\//i,
						],
        });
    }

	setApplication() {
		this.application = Application.getInstance();
	}

	/* eslint-disable indent */
	async runApplication() {
		// Interval is only needed on dev because of inline CSS.
		/* webpack-strip-block:removed */
			this.initPhrame();
			this.application.preload({
				layoutLoader,
				pageLoader
			})
				.then(App.InitRiot)
				.then(() => $.protip({
					defaults: {
						scheme: 'black',
						size: 'normal',
						skin: 'msc',
						gravity: 'right;left;bottom;top;...'
					},
					forceMinWidth: true,
					offset: 2
				}))
				.then(() => this.application.run());
			/* webpack-strip-block:removed */
	}
	/* eslint-enable indent */

	async setLanguage() {
		const { language } = this.config;
		this.i18n = I18n.getInstance();
		this.i18n.setLanguage(language);
		this.i18n.setTranslationTable(
			language,
			window.translations[language] || {}
		);
	}

	async setRouter() {
		this.application.setRouter(
			new Router(this.config.routings, this.i18n)
		);
	}

	async getReactComponents(componentSelector = '.react-root') {
		const reactRoots = document.querySelectorAll(componentSelector);
		const components = [];
		for (let i = 0; i < reactRoots.length; i++) {
			const componentRoot = reactRoots[i];
			const {
				reactComponent: componentName,
				reactComponentPath,
				...componentProps
			} = componentRoot.dataset;
			const componentPath = `${reactComponentPath}/${componentName}`;
			const Component = (await getReactComponent(componentPath)).default;
			if (Component === undefined) {
				throw new Error(`Unable to import ${componentPath}. Check if it has a default export.`);
			}

			components.push({
				Component,
				componentRoot,
				componentProps
			});
		}

		return components;
	}

	async setReactChaosBridge(componentSelector) {
		const components = await this.getReactComponents(componentSelector);
		const bridgeRoot = document.querySelector('.react-chaos-bridge');
		if (bridgeRoot) {
            const root = createRoot(bridgeRoot);
			root.render(
				<Provider store={store}>
					<EntryPoint components={components} />
				</Provider>
			);
		}
	}

	setConfig(configObj = this.config) {
		for (const k of Object.keys(configObj)) {
			Config.set(k, configObj[k]);
		}
	}

	async setRiotMixins() {
		// Else riot would bind to his own context
		Chaos.tr.bind = function () { return Chaos.tr; };

		riot.mixin({
			_: Chaos.tr,
			IsMobile
		});
	}

	async setHandleLogout() {
		window.handleLogout = function () {
			this.localStorage.removeItem('authToken');
		};
	}

	initPhrame() {
		PH.screen = PH._getScreenData();
	}

	async initComponents() {
		Config.set('helpTipComponent', Helptip.getInstance(document.body, {}));
		Config.set(
			'globalProgressIndicator',
			new GlobalProgressIndicator(document.querySelector('#ajaxIndicatorContainer'), {})
		);
		Config.set('overlayComponent', new Overlay(document.body, {}));
		Config.set('confirmComponent', new ConfirmOverlay(document.body, {}));
		Config.set('localProgressIndicator', new ProgressIndicator(document.body, {}));
	}

	static InitRiot = InitMSCRiot;
}

window.AppClass = App;

export { InitMSCRiot as InitRiot };
