export interface GetMyStoryUploadTokenEntity {
    id: number;
    status: string;
    mediaType: MyStoryTypes;
    privacy: string;
    duration: number;
    uploadUrl: string;
    expiresIn: number;
    isConverted: boolean;
    media: StoryItemMedia[];
}

export interface GetMyStoryEntity {
    price: number;
}

export interface MyStoryItemEntity {
    id: number;
    status: MyStoryStatus;
    statusReason: StoryStatusReasonEntity | null;
    mediaType: MyStoryTypes;
    privacy: MyStoryPrivacy;
    duration: number;
    uploadUrl: string;
    expiresIn: number;
    media: StoryItemMedia[];
    isConverted: boolean;
    previewSrc: string | undefined;
    viewCount: number;
}

export interface StoryItemMedia {
    id: number;
    width: number;
    height: number;
    contentUri: string;
    status: StoryItemMediaStatus;
    mediaKey: string;
}

export type StoryItemMediaStatus = 'created' | 'stored' | 'failed';

export interface StoryStatusReasonEntity {
    title: string;
    description: string;
}

export interface UpdateMyStoryData {
    price: number;
}

export enum MyStoryStatus {
    CREATED = 'created',
    ACTIVATED = 'activated',
    REJECTED = 'rejected',
    WAITING_FOR_APPROVAL = 'waiting_for_approval',
}

export enum MyStoryTypes {
    IMAGE = 'image',
    VIDEO = 'video',
}

export enum MyStoryPrivacy {
    FREE = 'free',
    PREMIUM = 'premium',
}
