import { Au10tixServiceInterface } from './Au10tix.serviceFactory';
import { Au10tixAccessToken, CreateAccessTokenRequest } from './Au10tix.types';

export interface Au10tixDataMapperInterface {
    createAccessToken: (request: CreateAccessTokenRequest) => Promise<Au10tixAccessToken>;
}
export class Au10tixService implements Au10tixServiceInterface {
    constructor(private mapper: Au10tixDataMapperInterface) {}

    public createAccessToken(request: CreateAccessTokenRequest): Promise<Au10tixAccessToken> {
        return this.mapper.createAccessToken(request);
    }
}
