import {
    InlineResponse2008Data,
    UserDetailsPerson,
    UserDetailsStudio,
} from 'apiClients/msc-api-gateway/proxy/registration/models';
import { MeEntity, MePersonEntity, StudioEntity } from './Performer.types';

export const mapGetMeResponse = (response?: InlineResponse2008Data): MeEntity => {
    if (!response || !response.studio || !response.person)
        throw new Error('Missing required response elements from /me request');

    const { id, email, studio, person, defaultPerformerId } = response;
    return {
        id: id as number,
        email,
        studio: mapStudio(studio),
        person: mapPerson(person),
        defaultPerformerId,
    };
};

const mapStudio = (studio?: UserDetailsStudio): StudioEntity => {
    if (!studio) {
        return {
            ownerUserId: 0,
            id: 0,
            name: '',
            isCertified: undefined,
            certificationFlag: 0,
            isTestAccount: false,
        };
    }

    const { id, ownerUserId, name, isCertified, certificationFlag, isTestAccount } = studio;

    // Generated client has optional "id" and "ownerUserId" fields, but our type is required.
    if (!id) console.warn('Missing studio id; using default');
    if (!ownerUserId) console.warn('Missing studio ownerUserId; using default');

    return {
        id: id || 0,
        ownerUserId: ownerUserId || 0,
        name,
        isCertified,
        certificationFlag,
        isTestAccount,
    };
};

const mapPerson = (person: UserDetailsPerson): MePersonEntity => {
    const { viewType, firstName } = person;
    return {
        isGiftSendingDisabled: !window.GlobalConfig.isGiftSendingEnabled,
        viewType,
        firstName,
    };
};
