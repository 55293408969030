import axios, { CancelTokenSource } from 'axios';
import {
    MyStoryItem,
    CreateMyStoryItemRequest,
    StoryItemMedia as APIStoryItemMedia,
    StoryItemMediaStatusEnum,
    MyStoryItemRequestPrivacyEnum,
    MyStoryItemRequestTypeEnum,
} from 'apiClients/msc-api-gateway/mystoryservice/models';
import { StoryStatusReason } from 'apiClients/msc-api-gateway/highlight/models';
import { MyStoryServiceApiInterface } from 'apiClients/msc-api-gateway/mystoryservice/client/my-story-service-api';
import { MyStoryItemListResponse } from 'apiClients/msc-api-gateway/mystoryservice/models/my-story-item-list-response';
import {
    MyStoryItemTypeEnum,
    MyStoryItemPrivacyEnum,
} from 'apiClients/msc-api-gateway/mystoryservice/models/my-story-item';
import { getQueryParamsForArray } from 'utils/apiClient';
import { getFeatureFlag } from 'utils/featureFlag';
import { FeatureFlagEnum } from 'configs/featureFlags';
import { LJStoriesDataMapperInterface } from './LJStories.service';
import {
    GetMyStoryUploadTokenEntity,
    MyStoryStatus,
    MyStoryTypes,
    MyStoryPrivacy,
    GetMyStoryEntity,
    MyStoryItemEntity,
    StoryItemMedia,
    StoryItemMediaStatus,
    UpdateMyStoryData,
} from './LJStories.types';

export class LJStoriesDataMapper implements LJStoriesDataMapperInterface {
    private disableStoryAutoApprovalForTestAccounts?: boolean;

    constructor(private apiClient: MyStoryServiceApiInterface) {
        this.disableStoryAutoApprovalForTestAccounts = getFeatureFlag(
            FeatureFlagEnum.DisableStoryAutoApprovalForTestAccounts,
        )
            ? true
            : undefined;
    }

    public getUploadToken(
        performerId: number,
        params?: CreateMyStoryItemRequest,
    ): Promise<GetMyStoryUploadTokenEntity> {
        const featureToggles: string[] = [];

        if (this.disableStoryAutoApprovalForTestAccounts)
            featureToggles.push('disableStoryAutoApprovalForTestAccounts=1');

        return new Promise((resolve, reject) => {
            this.apiClient
                .v1MePerformersPerformerIdMyStoryItemsPost(performerId, featureToggles.join('; '), params)
                .then((response) => {
                    const { data } = response.data;
                    if (!data) {
                        reject();
                        return;
                    }
                    resolve(this.mapResponseToStoryItem(data));
                })
                .catch(() => {
                    reject();
                });
        });
    }

    private calculateProgress = (loaded: number, total: number) => {
        return Math.round((loaded * 100) / total);
    };

    public uploadStory(
        file: File,
        // eslint-disable-next-line default-param-last
        privacy: MyStoryPrivacy = MyStoryPrivacy.FREE,
        fileType: MyStoryTypes,
        performerId: number,
        onProgressUpdate: (percentage: number) => void,
        cancelTokenSource?: CancelTokenSource,
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            const createStoryParams: CreateMyStoryItemRequest = {
                data: {
                    type: this.mediaTypeAppToAPIMap[fileType],
                    privacy: this.privacyAppToAPIMap[privacy],
                },
            };

            this.getUploadToken(performerId, createStoryParams)
                .then((story) => {
                    const formData = new FormData();
                    formData.append('Filedata', file);

                    axios({
                        method: 'post',
                        url: story.uploadUrl,
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: (ev: { loaded: number; total?: number }) => {
                            if (ev.total) onProgressUpdate(this.calculateProgress(ev.loaded, ev.total));
                        },
                        cancelToken: cancelTokenSource?.token,
                    }).then(() => resolve(story.id));
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public getStoryById(performerId: number, storyId: number): Promise<MyStoryItemEntity> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .v1MePerformersPerformerIdMyStoryItemsStoryItemIdGet(performerId, storyId)
                .then((response) => {
                    const { data } = response.data;
                    if (!data) {
                        reject();
                        return;
                    }
                    resolve(this.mapResponseToStoryItem(data));
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public getMyStory(performerId: number): Promise<GetMyStoryEntity> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .getMyStory(performerId)
                .then((response) => {
                    resolve({
                        price: response.data.price || 0,
                    });
                })
                .catch(reject);
        });
    }

    public getMyStoryItems(performerId: number, storiesId?: number[]): Promise<MyStoryItemEntity[]> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .v1MePerformersPerformerIdMyStoryItemsGet(performerId, undefined, undefined, {
                    query: {
                        ...getQueryParamsForArray('filter[itemStatuses]', [
                            'rejected',
                            'activated',
                            'created',
                            'waiting_for_approval',
                        ]),
                        ...getQueryParamsForArray('filter[itemIds]', storiesId),
                    },
                })
                .then((data) => {
                    resolve(this.mapMyStoryItems(data.data));
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    public updateMyStory(performerId: number, data: UpdateMyStoryData): Promise<void> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .updateMyStory(performerId, data)
                .then(() => resolve())
                .catch(reject);
        });
    }

    public deleteStoryItem(performerId: number, storyItemId: number): Promise<number> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .v1MePerformersPerformerIdMyStoryItemsStoryItemIdDelete(performerId, storyItemId)
                .then(() => {
                    resolve(storyItemId);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    private mapResponseToStoryItem(storyItem: MyStoryItem): MyStoryItemEntity {
        const mediaType: MyStoryTypes = storyItem.type ? this.storyTypeAPIToAppMap[storyItem.type] : MyStoryTypes.IMAGE;
        return {
            id: storyItem?.id || 0,
            status: storyItem.status ? this.storyStatusMap[storyItem.status] : MyStoryStatus.CREATED,
            statusReason: storyItem.statusReason ? this.mapStatusReason(storyItem.statusReason) : null,
            mediaType,
            privacy: storyItem?.privacy ? this.storyPrivacyAPIToAppMap[storyItem.privacy] : MyStoryPrivacy.FREE,
            duration: storyItem?.duration || 0,
            uploadUrl: storyItem?.uploadUrl || '',
            expiresIn: storyItem?.expiresIn || 0,
            isConverted: storyItem?.media ? storyItem?.media?.length > 0 : false,
            media: storyItem.media ? this.mapStoryMedia(mediaType, storyItem.media) : [],
            previewSrc: storyItem?.media ? this.getPreviewSrc(storyItem.media) : '',
            viewCount: storyItem?.viewCount?.member || 0,
        };
    }

    private mapMyStoryItems(data: MyStoryItemListResponse): MyStoryItemEntity[] {
        const { data: storyItems } = data;
        if (!storyItems) throw new Error('Backend error - missing data');

        return storyItems.map((item) => {
            const { id, status, type, privacy, duration, expiresIn } = item;

            if (!type || !status || !privacy || id === undefined || duration === undefined || expiresIn === undefined) {
                throw new Error('Backend error - missing property');
            }

            return this.mapResponseToStoryItem(item);
        });
    }

    private mapStatus = (status?: StoryItemMediaStatusEnum): StoryItemMediaStatus => {
        switch (status) {
            case StoryItemMediaStatusEnum.Failed:
                return 'failed';
            case StoryItemMediaStatusEnum.Stored:
                return 'stored';
            default: {
                return 'created';
            }
        }
    };

    private mapStoryMedia(mediaType: MyStoryTypes, mediaList: APIStoryItemMedia[]): StoryItemMedia[] {
        const list = mediaList.map((media) => {
            const { id, dimensions, contentUri, status, mediaKey } = media;

            if (id === undefined || contentUri === undefined) {
                throw new Error('Backend error - missing property');
            }

            return {
                id,
                width: dimensions?.width || 0,
                height: dimensions?.height || 0,
                contentUri,
                status: this.mapStatus(status),
                mediaKey: mediaKey || '',
            };
        });
        // For video stories, sort the media list so that videos are first.
        if (mediaType === MyStoryTypes.VIDEO) {
            const videoExtension = '.mp4';
            return list.sort((a, b) => {
                if (a.contentUri.endsWith(videoExtension) && !b.contentUri.endsWith(videoExtension)) return -1;
                if (b.contentUri.endsWith(videoExtension) && !a.contentUri.endsWith(videoExtension)) return 1;
                return 0;
            });
        }
        return list;
    }

    private getPreviewSrc = (media?: APIStoryItemMedia[]) => {
        if (!media) return undefined;
        return media.find(
            (mediaItem) =>
                mediaItem.mediaKey === 'preview_free_252x448_jpg' ||
                mediaItem.mediaKey === 'preview_secured_252x448_jpg' ||
                mediaItem.mediaKey === 'free_252x448_jpg' ||
                mediaItem.mediaKey === 'secured_252x448_jpg',
        )?.contentUri;
    };

    private mapStatusReason(reason: StoryStatusReason) {
        return {
            title: reason.title || '',
            description: reason.description || '',
        };
    }

    private storyStatusMap: { [key: string]: MyStoryStatus } = {
        created: MyStoryStatus.CREATED,
        activated: MyStoryStatus.ACTIVATED,
        rejected: MyStoryStatus.REJECTED,
        waiting_for_approval: MyStoryStatus.WAITING_FOR_APPROVAL,
    };

    private storyPrivacyAPIToAppMap: { [key in MyStoryItemPrivacyEnum]: MyStoryPrivacy } = {
        [MyStoryItemPrivacyEnum.Free]: MyStoryPrivacy.FREE,
        [MyStoryItemPrivacyEnum.Premium]: MyStoryPrivacy.PREMIUM,
    };

    private storyTypeAPIToAppMap: { [key in MyStoryItemTypeEnum]: MyStoryTypes } = {
        [MyStoryItemTypeEnum.Image]: MyStoryTypes.IMAGE,
        [MyStoryItemTypeEnum.Video]: MyStoryTypes.VIDEO,
    };

    private privacyAppToAPIMap: { [key in MyStoryPrivacy]: MyStoryItemRequestPrivacyEnum } = {
        [MyStoryPrivacy.FREE]: MyStoryItemRequestPrivacyEnum.Free,
        [MyStoryPrivacy.PREMIUM]: MyStoryItemRequestPrivacyEnum.Premium,
    };

    private mediaTypeAppToAPIMap: { [key in MyStoryTypes]: MyStoryItemRequestTypeEnum } = {
        [MyStoryTypes.IMAGE]: MyStoryItemRequestTypeEnum.Image,
        [MyStoryTypes.VIDEO]: MyStoryItemRequestTypeEnum.Video,
    };
}
