/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Performercenter type
 * @export
 * @enum {string}
 */

export enum ExternalEntityTypeEnum {
    Studio = 'studio',
    Performer = 'performer',
}
