class ChaosI18n {
	properties = {
		DEFAULT_LANGUAGE : 'en',
		language: 'en',
		translationTables : {
			en : {}
		}
	};

	getInstance() {
		return this;
	}

	/**
	 * Sets the language
	 * @param {String} language     Selected language
	 */
	setLanguage (language) {
		this.properties.language = language;
	}

	/**
	 * Retrieves the current language.
	 *
	 * @return {String} The current language.
	 */
	getLanguage () {
		return this.properties.language;
	}

	setTranslationTable (language, translationTable) {
		this.properties.translationTables[language] = {
			...this.properties.translationTables[language],
			...translationTable
		};
	}

	getCurrentLanguage (language) {
		if (typeof language !== 'string' || typeof language === 'string' && language.length == 0) {
			language = this.properties.language;
		}
		if (typeof language !== 'string'
			|| typeof language === 'string' && language.length == 0
			|| typeof this.properties.translationTables[language] !== 'object'
		) {
			language = this.properties.DEFAULT_LANGUAGE;
		}
		return language;
	}

	getTranslation (text, params, language) {
		let result = text;
		let key = text;
		language = this.getCurrentLanguage(language);
		if (typeof this.properties.translationTables[language] === 'object') {
			if (typeof this.properties.translationTables[language][key] === 'string') {
				result = this.properties.translationTables[language][key];
			}
		}

		if (typeof params === 'object') {
			result = this.replacePlaceholders(result, params);
		}

		return result;
	}

	replacePlaceholders (text, params) {
		for (const prop in params) {
			if (typeof params[prop] === 'string' || typeof params[prop] === 'number') {
				let placeholder = '{' + prop + '}';
				text = text.replace(placeholder, params[prop]);
			}
		}

		return text;
	}

}

export default new ChaosI18n();