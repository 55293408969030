import { DataCacherInterface } from 'utils/dataCacher';
import { UpdatePerformerPropertiesRequest } from 'apiClients/msc-api-gateway/accountservices/models';
import {
    PerformerEntity,
    MeEntity,
    PerformerPeriod,
    PerformerPricing,
    NewbieStatus,
    PerformerTwoWayAudio,
} from './Performer.types';

export interface PerformerDataMapperInterface {
    getMe(): Promise<MeEntity>;
    getMyPerformer(id: number): Promise<{ performer: PerformerEntity; twoWayAudio: PerformerTwoWayAudio }>;
    getPerformerPricing(id: number): Promise<PerformerPricing>;
    getPerformerPeriods(): Array<PerformerPeriod>;
    getPerformerProperties(id: number): UpdatePerformerPropertiesRequest;
    updatePerformerProperties(id: number, body: UpdatePerformerPropertiesRequest): Promise<void>;
    getNewbieStatus(id: number): Promise<NewbieStatus>;
}
export class PerformerService {
    constructor(
        private mapper: PerformerDataMapperInterface,
        private cacher: DataCacherInterface,
    ) {}

    public getMe() {
        return this.mapper.getMe();
        //return this.cacher.process<MeEntity>('me', () => this.mapper.getMe());
    }

    public getMyPerformer(id: number) {
        return this.mapper.getMyPerformer(id);
        // return this.cacher.process<PerformerEntity>(id, () => this.mapper.getMyPerformer(id));
    }

    public getPerformerPricing(id: number) {
        return this.mapper.getPerformerPricing(id);
    }

    public getPerformerPeriods() {
        return this.mapper.getPerformerPeriods();
    }

    public getPerformerProperties(id: number) {
        return this.mapper.getPerformerProperties(id);
    }

    public updatePerformer(id: number, body: UpdatePerformerPropertiesRequest) {
        this.cacher.update<UpdatePerformerPropertiesRequest>(id, body);
        return this.mapper.updatePerformerProperties(id, body);
    }

    public getNewbieStatus(id: number) {
        return this.mapper.getNewbieStatus(id);
    }
}
