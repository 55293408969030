import anime from 'animejs';
import _ from '@dh-fe/translate';
import Ext from '../../lib/vendor/ExtCore';
import CONST from '../../lib/constant/Constants';
import Chaos from '../../lib/chaos/Chaos';
import Config from '../../lib/chaos/Config';
import ChaosObject from '../../lib/chaos/Object';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import GlobalProgressIndicator from '../ProgressIndicator/GlobalProgressIndicator';
import '../_DropDown/DropDown';
import '../_Broadcaster/Broadcaster';
import { InitRiot } from '../App/App';

export default class MediaManagerView extends ChaosObject {
	static EVENT_INPUT_VALUE_CHANGE = 'input-value-change';
	static EVENT_INPUT_ENTER_PRESSED = 'input-enter-pressed';
	static EVENT_MEDIA_REMOVE_DONE = 'media-remove-done';
	static EVENT_MEDIA_ROTATE = 'media-rotate';
	static EVENT_MEDIA_DELETE_CONFIRMED = 'media-delete-confirmed';
	static EVENT_MEDIA_DELETE_OVERLAY_CONFIRMED = 'media-delete-overlay-confirmed';

	static EVENT = {
		CHANGE_TITLE : 'mycontent::change-title',
		SET_PRICE    : 'mycontent::set-price',
		ROTATE_LEFT  : 'mycontent::rotate-left',
		ROTATE_RIGHT : 'mycontent::rotate-right',
		DELETE       : 'mycontent::delete'
	};

	get properties() {
		return { ...super.properties,
			/** @var {String} mediaItemCls           Class name of a media item */
			mediaItemCls : 'mediaBox',
			/** @var {String} mediaDeleteButtonCls   Class name of delete button */
			mediaDeleteButtonCls : 'js-delete',
			/** @var {String} confirmDeleteCls        Class name of delete confirm overlay items */
			confirmDeleteCls : 'media-ask-delete',
			/** @var {String} mediaDeleteConfirmCls  Class name of delete confirm button */
			mediaDeleteConfirmCls : 'delete_confirm',
			/** @var {String} mediaDeleteCancelCls   Class name of cancel button */
			mediaDeleteCancelCls : 'js-delete-cancel',
			/** @var {String} mediaInputCls          Class name of title input */
			mediaInputCls : 'title_input',
			/** @var {String} editMediaTitleButton   Class name of title input */
			editMediaTitleButton : 'js-edit',
			/** @var {String} mediaRotateButtonCls   Class name of a rotate button */
			mediaRotateButtonCls : 'js-rotate',
			/** @var {String} mediaOpenButtonCls     Class name of the open button */
			mediaOpenButtonCls : 'js-open',
			/** @var {String}                        Rotating class for image under rotation */
			rotatingCls : 'rotating',
			/** @var {String}                        Hide cls */
			hideCls : 'ph-hide',
			/** @var {Number}                        Rotation degree */
			rotationDegree : 90,
			/** @var {Number}                        image Rotation Delay. */
			imageRotationDelay : 1000,
			/** @var {Array}                         rotation items on AJAX. */
			_rotationItems : [],
			/** @var {Array}                         rotation items without AJAX. */
			_rotationParams : [],
			/** @var {Array} _editedImages           rotated images array */
			_editedImages : [],
			/** @var {String} _lastInputValue        The last value of the title input */
			_lastInputValue : '',
			/** @var {String} mediaBox               Box of rotated image */
			mediaBox : undefined,
			/** @var {String} folderNameCls          Class name of folder title */
			folderNameCls : 'folderName',
			/** @var {String} lastClickedId          ID of last clicked media item */
			lastClickedId : undefined,
			/** @var {Number} rotationCssValue       Rotation value of image for css rotation */
			rotationCssValue : 0,
			/** @var {Bool} _sentAjaxRequest         True if we sent the ajax request */
			_sentAjaxRequest : false,
			/** @var {Bool} _sentDelayedAjaxRequest  True if we sent delayed ajax request */
			_sentDelayedAjaxRequest : false,
			/** @var {Object} mediaType              Object of media types */
			mediaType : {
				photo  : 'photo',
				video  : 'video',
				note   : 'note',
				folder : 'folder'
			}
		};
	}
	init(el, config) {
		super.init(el, config);
		this.addEvents(
			MediaManagerView.EVENT_INPUT_ENTER_PRESSED,
			MediaManagerView.EVENT_MEDIA_REMOVE_DONE,
			MediaManagerView.EVENT_MEDIA_ROTATE,
			MediaManagerView.EVENT_INPUT_VALUE_CHANGE,
			MediaManagerView.EVENT_MEDIA_DELETE_CONFIRMED,
			MediaManagerView.EVENT_MEDIA_DELETE_OVERLAY_CONFIRMED
		);
		// Make browsers support focusin/out bubbling!
		this._polyfillFocusBubbling();
		this._channelType = Config.get('channelType');
	}

	/**
	 * Pure magic! Implements focusin/focusout event as bubbling in unsupported browsers
	 *
	 */
	_polyfillFocusBubbling() {
		var w = window,
			d = w.document;

		var addPolyfill = function addPolyfill(e) {
			var type = e.type === 'focus' ? 'focusin' : 'focusout';
			var event = new CustomEvent(type, { bubbles : true, cancelable : false });
			event.c1Generated = true;
			e.target.dispatchEvent(event);
		};

		var removePolyfill = function removePolyfill(e) {
			if (!e.c1Generated) { // focus after focusin, so chrome will the first time trigger two times focusin
				if (d.removeEventListener) {
					d.removeEventListener('focus', addPolyfill, true);
					d.removeEventListener('blur', addPolyfill, true);
					d.removeEventListener('focusin', removePolyfill, true);
					d.removeEventListener('focusout', removePolyfill, true);
				}
				else { // Because of ie8
					d.detachEvent('onfocus', addPolyfill);
					d.detachEvent('onblur', addPolyfill);
					d.detachEvent('onfocusin', removePolyfill);
					d.detachEvent('onfocusout', removePolyfill);
				}
			}
			setTimeout(function() {
				if (d.removeEventListener) {
					d.removeEventListener('focusin', removePolyfill, true);
					d.removeEventListener('focusout', removePolyfill, true);
				}
				else {
					d.detachEvent('onfocusin', removePolyfill);
					d.detachEvent('onfocusout', removePolyfill);
				}
			}, 0);
		};

		if (w.onfocusin === undefined) {
			if (d.addEventListener) {
				d.addEventListener('focus', addPolyfill, true);
				d.addEventListener('blur', addPolyfill, true);
				d.addEventListener('focusin', removePolyfill, true);
				d.addEventListener('focusout', removePolyfill, true);
			}
			else { // Because of ie8
				d.attachEvent('onfocus', addPolyfill);
				d.attachEvent('onblur', addPolyfill);
				d.attachEvent('onfocusin', removePolyfill);
				d.attachEvent('onfocusout', removePolyfill);
			}
		}
	}

	/**
	 * Get the media item of the clicked element
	 *
	 * @method _getContentParent
	 * @param {Object} target   Clicked element
	 *
	 * @return {Object}
	 */
	_getContentParent(target) {
		return target.parent('.' + this.mediaItemCls) || target;
	}

	/**
	 * Get media id from MediaBox data-id attribute
	 *
	 * @method _getMediaId
	 *
	 * @param {Object} targetEl   Clicked element
	 *
	 * @return mediaId
	 */
	_getMediaId(targetEl) {
		return this._getContentParent(targetEl).data('id');
	}

	/**
	 * Get media type from MediaBox data-media-type attribute
	 *
	 * @method _getMediaType
	 *
	 * @param {Object} targetEl   Clicked element
	 *
	 * @return mediaType
	 */
	_getMediaType(targetEl) {
		return this._getContentParent(targetEl).data('media-type');
	}

	/**
	 * Get media content type from MediaBox data-type attribute
	 *
	 * @method _getContentType
	 *
	 * @param {Object} targetEl   Clicked element
	 *
	 * @return contentType
	 */
	_getContentType(targetEl) {
		return this._getContentParent(targetEl).data('type');
	}

	/**
	 * Get media type from MediaBox data-title attribute
	 *
	 * @method _getMediaTitle
	 *
	 * @param {Object} targetEl   Clicked element
	 *
	 * @return mediaTitle
	 */
	_getMediaTitle(targetEl) {
		return this._getContentParent(targetEl).data('title');
	}

	/**
	 * Collect title params for onInputBlur method
	 *
	 * @method _getTitleParams
	 *
	 * @param {Object} targetEl   Clicked element
	 *
	 * @return {Object}
	 */
	_getTitleParams(targetEl) {
		var params = {};

		params.id = this._getMediaId(targetEl);
		params.title = targetEl.getValue();

		if (this._getContentType(targetEl) !== this.mediaType.folder) {
			params.channelType = this._channelType;
		}
		return params;
	}

	/**
	 * Focus on Input event handler
	 *
	 * @method onInputFocus
	 * @param {Object} ev   Event object
	 *
	 * @return void
	 */
	onInputFocus(ev) {
		var inputEl = Ext.get(ev.target);
		this._activateInput(inputEl);
	}

	/** Input keypress event handler
	 *
	 * @method onInputKeyPress
	 * @param {Object} ev       Event object
	 *
	 * @return void
	 */
	onInputKeyPress(ev) {
		var targetEl = Ext.get(ev.target);

		if (ev.getCharCode() === CONST.keyCode.ESCAPE) {
			this._restoreInputValue(ev);
			this._deactivateInput(ev);
		}
		else if (ev.getCharCode() === CONST.keyCode.ENTER) {
			this.fireTitleChangeEvent(targetEl, MediaManagerView.EVENT_INPUT_ENTER_PRESSED);
		}
	}

	/**
	 * Blur on Input event handler
	 *
	 * @method onInputBlur
	 * @param {Object} ev   Event object
	 *
	 * @return void
	 */
	onInputBlur(ev) {
		var targetEl = Ext.get(ev.target),
			parentEl = this._getContentParent(targetEl);
		parentEl.removeClass('focus');
		parentEl.jq().protipHide();
		if (this._lastInputValue !== targetEl.getValue() && targetEl.getValue() !== '') {
			this.fireTitleChangeEvent(targetEl, MediaManagerView.EVENT_INPUT_VALUE_CHANGE);
		}
	}

	/**
	 * Fires an event that can indicate title save.
	 *
	 * @param {Object} targetEl target element of the action (input)
	 * @param {String} event Event name
	 *
	 * @return void
	 */
	fireTitleChangeEvent(targetEl, event) {
		var parentEl = this._getContentParent(targetEl);
		this.fireEvent(event, {
			scope     : this,
			params    : this._getTitleParams(targetEl),
			mediaType : this._getMediaType(targetEl),
			type      : this._getContentType(targetEl),
			id        : this._getMediaId(targetEl),
			parentEl  : parentEl,
			action    : parentEl.data('action'),
			inputEl   : targetEl
		});
	}

	/**
	 * Activate input and its mediaBox. Get the last input value of input.
	 *
	 * @method _activateInput
	 *
	 * @param {Object} inputEl   [Ext.element]
	 *
	 * @return void
	 */
	_activateInput(inputEl) {
		this._getContentParent(inputEl)
			.addClass('focus');
		this._lastInputValue = inputEl.getValue();
	}

	/**
	 * Deactivate input method
	 *
	 * @method _deactivateInput
	 *
	 * @param {Object} ev    Event object
	 *
	 * @return void
	 */
	_deactivateInput(ev) {
		var el = Ext.get(ev.target);

		if (el) {
			el.blur();
		}
	}

	/**
	 * Restores the value of the input element.
	 *
	 * @method _restoreInputValue
	 *
	 * @param {Object} ev   Event object
	 */
	_restoreInputValue(ev) {
		Ext.fly(ev.target).dom.value = this._lastInputValue;
	}

	/**
	 * Restores the value of the input element after save title failed
	 *
	 * @method restoreInputValueAfterFailed
	 *
	 * @param {Object} ev   Event object
	 */
	restoreInputValueAfterFailed(ev) {
		ev.targetEl.select('.' + this.mediaInputCls).item(0).dom.value = this._lastInputValue;
	}

	/**
	 * Update the title in the folder, after title change
	 *
	 * @method updateFolderTitle
	 *
	 * @param {Object} mediaId   Media id
	 * @param {String} newName   new title
	 * @DEPRECATED?!
	 * @return mediaTitle
	 */
	updateFolderTitle(mediaId, newName) {
		var activeFolderEl = Ext.get('content_' + mediaId);
		if (activeFolderEl) {
			var folderNameEl = activeFolderEl.select(this.folderNameCls.dot()).item(0);
			folderNameEl.dom.innerHTML = newName;
		}
	}

	/**
	 * Delete button click event handler
	 *
	 * @method onDeleteButtonClick
	 * @param {Object} ev   Event object
	 *
	 * @return void
	 */
	onDeleteButtonClick(ev) {
		ev.preventDefault();
		var targetEl = Ext.get(ev.target),
			mediaBox = this._getContentParent(targetEl);
		mediaBox.select('.' + this.confirmDeleteCls).removeClass(this.hideCls);
	}

	/**
	 * Remove clicked media box
	 * @param mediaBox   Ext element removable media box
	 *
	 * @return
	 */
	removeMedia(mediaBox) {
		if (typeof mediaBox === 'string') {
			const mediaBoxes = document.querySelectorAll(`.mediaBox[data-id="${mediaBox}"]`);
			if (!mediaBoxes) {
				return;
			}
			Array.from(document.querySelectorAll(`.mediaBox[data-id="${mediaBox}"]`)).forEach(el => el.remove());
		}
		else {
			mediaBox.remove();
		}

		this.fireEvent(MediaManagerView.EVENT_MEDIA_REMOVE_DONE, { scope : this });
		Chaos.fireEvent(GlobalProgressIndicator.GLOBALEVENT_HIDE_INDICATOR);
	}

	replaceMedia(id, html) {
		const item = document.querySelector(`.mediaBox[data-id="${id}"]`);
		const tmp = document.createElement('div');
		tmp.innerHTML = html;
		InitRiot(tmp.firstChild);
		item.parentNode.insertBefore(tmp.firstChild, item.nextSibling);
		item.remove();
	}

	updateTitle(title, id) {
		document.querySelector(`.mediaBox[data-id="${id}"] .title span`).textContent = title;
	}

	updatePrice(price, id) {
		document.querySelector(`.mediaBox[data-id="${id}"] .price span`).textContent = price + ' ' + _`credits`;
	}

	/**
	 * Rotate Button Click
	 *    - Animate image
	 *    - Set and store image rotation params
	 *
	 * @method onRotateButtonClick
	 * @public
	 *   @param {Object} ev Click object
	 *   @param {boolean} isLeft Rotation direction
	 */
	onRotateButtonClick(ev, isLeft) {
		ev.preventDefault();
		var targetEl = Ext.get(ev.target),
			mediaBox = this._getContentParent(targetEl),
			imageEl = mediaBox.select('img').item(0),
			currentRotate,
			rotationsParams = {};
		this._sentAjaxRequest = false;

		if (typeof this.lastClickedId !== 'undefined' &&
			this.lastClickedId !== this._getMediaId(imageEl) &&
			this._sentDelayedAjaxRequest === false) {
			this._rotateMediaDelayedTask();
			this._sentAjaxRequest = true;
			this.imageEl.data('rotation-value', 0);
			this.imageEl.data('rotation-css', this.rotationCssValue);
			this.rotationCssValue = 0;
		}
		if (typeof this.lastClickedId === 'undefined' || this.lastClickedId !== this._getMediaId(imageEl)) {
			this.lastClickedId = this._getMediaId(imageEl);
		}
		this.imageEl = imageEl;
		currentRotate = this._calculateRotationValue(imageEl, isLeft);
		rotationsParams.contentId = this._getMediaId(imageEl);
		rotationsParams.rotationValue = currentRotate;
		this._sentDelayedAjaxRequest = false;
		// Rotate the image
		this.rotateImage(imageEl, currentRotate);
		// Set the new rotation value
		this.setImageRotateCurrentValue(imageEl, currentRotate);
		// Store image rotation params
		this._rotationParams = this.storeImagesRotationParams(rotationsParams);
		this._rotateMedia(ev, rotationsParams, mediaBox);
	}

	/**
	 * Store rotation params
	 *
	 * @method storeImagesRotationParams
	 * @public
	 * @param {Object} rotationsParams Store image content id, and rotation degree
	 *
	 * @return {Object}
	 */
	storeImagesRotationParams(rotationsParams) {
		var key = rotationsParams.contentId;
		this._editedImages[key] = this._calculateRotationModulus(rotationsParams.rotationValue);
		// Remove images with rotation value 0
		this._removeNoneRotatedImages();
	}

	/**
	 * If image is rotating, it adds a class to it which
	 * gives visibility to the loader and tooltip
	 *
	 * @method markRotatingMediaItems
	 * @param {String} mongoId	Mongo Id of the image
	 * @param {String} command	Add || undefined
	 *
	 * @return void;
	 */
	toggleRotatingClass(mongoId, command) {
		var rotatingMediaBox = this.element.select('[data-id="' + mongoId + '"]');
		if (command === 'add') {
			rotatingMediaBox.addClass(this.rotatingCls);
		}
		else {
			rotatingMediaBox.removeClass(this.rotatingCls);
		}
	}

	/**
	 * Modulus division
	 *
	 * @method _calculateRotationModulus
	 *
	 * @param {Number} rotationValue    rotationValue
	 *
	 * @return {Number} Rotation modulus
	 */
	_calculateRotationModulus(rotationValue) {
		return rotationValue % 360;
	}

	/**
	 * Remove the 0 degree rotation from the array
	 *
	 * @method _removeNoneRotatedImages

	 * @return {Object}
	 */
	_removeNoneRotatedImages() {
		for (var prop in this._editedImages) {
			if (this._editedImages.hasOwnProperty(prop)) {
				if (this._editedImages[prop] === 0) {
					delete this._editedImages[prop];
				}
			}
		}
		return this._editedImages;
	}

	/**
	 * Calculate Rotation Value
	 *
	 * @method _calculateRotationValue
	 *
	 * @param {Object} imageEl Image Ext element
	 *
	 * @return {Number} dataRotateValue Current rotation value
	 */
	_calculateRotationValue(imageEl, isLeft) {
		var dataRotateValue = parseInt(imageEl.data('rotation-value'), 10);
		return dataRotateValue + this.rotationDegree * (isLeft ? -1 : 1);
	}

	/**
	 * Rotate image animation
	 *
	 * @method rotateImage
	 * @public
	 * @param {Object} imageEl         Image element [Ext.Element]
	 * @param {Number} currentRotate   Rotation value
	 *
	 * @return {Object} this
	 */
	rotateImage(imageEl, currentRotate) {
		const rotationValue = parseInt(imageEl.data('rotation-css'), 10) + currentRotate;
		try {
			anime({
				targets    : imageEl.dom,
				duration   : 400,
				translateX : ['-50%', '-50%'],
				translateY : ['-50%', '-50%'],
				rotate     : rotationValue,
				easing     : 'linear'
			});
		}
		catch (e) {
			/* webpack-strip-block:removed */
		}
		return this;
	}

	/**
	 * Set image rotation data attribute value
	 *
	 * @method setImageRotateCurrentValue
	 * @public
	 * @param {Object} imageEl         Image element [Ext.Element]
	 * @param {Number} currentRotate   Rotation value
	 */
	setImageRotateCurrentValue(imageEl, currentRotate) {
		imageEl.data('rotation-value', currentRotate);
	}

	/**
	 * Send rotation params instant
	 *
	 * @method _rotateMedia
	 * @public
	 * @param {Object}  ev  Event object
	 * @param {Object}  rotationsParams  Rotation Parameters
	 * @param {Object}  mediaBox         Media box attributes
	 *
	 * @return void
	 */
	_rotateMedia(ev, rotationsParams, mediaBox) {
		var storedRotationValue = rotationsParams.rotationValue % 360;
		if (storedRotationValue !== 0) {
			this._rotationItems[rotationsParams.contentId] = storedRotationValue;
		}
		else {
			// If no rotation changes made remove image from list
			delete this._rotationItems[rotationsParams.contentId];
		}
		if (!(this._rotationDelayedTask instanceof Ext.util.DelayedTask) && this._sentAjaxRequest === false) {
			this.mediaBox = mediaBox;
			this._rotationDelayedTask = new Ext.util.DelayedTask(this._rotateMediaDelayedTask, this);
		}
		if (this._rotationDelayedTask) {
			this._rotationDelayedTask.delay(this.imageRotationDelay);
		}
	}

	/**
	 * Rotate image Delayed Task
	 *
	 * @method _rotateMediaDelayedTask
	 * @public
	 *
	 * @return void
	 */
	_rotateMediaDelayedTask() {
		let rotationValue = parseInt(this.imageEl.data('rotation-value'), 10);
		let rotationCss = parseInt(this.imageEl.data('rotation-css'), 10);
		if (Object.getOwnPropertyNames(this._rotationItems).length > 1) {
			document.body.click(); // Closes all dropdowns
			this.fireEvent(MediaManagerView.EVENT_MEDIA_ROTATE, {
				scope         : this,
				rotationItems : this._rotationItems,
				channelType   : this._channelType,
				parentEl      : this.mediaBox
			});
		}
		this.rotationCssValue = rotationValue + rotationCss;
		this.imageEl.data('rotation-value', 0);
		this.imageEl.data('rotation-css', this.rotationCssValue);
		this._rotationItems = [];
		this._sentDelayedAjaxRequest = true;
	}

	/**
	 * Description
	 *
	 * @method onEditMediaButtonClick
	 * @param {Object} ev   Event object
	 *
	 * @return void;
	 */
	onEditMediaButtonClick(ev) {
		ev.preventDefault();
		var inputEl = Ext.fly(ev.target).parent().select(this.mediaInputCls.dot());
		if (inputEl.getCount()) {
			this._activateInput(inputEl.item(0));
		}
	}

	/**
	 * Hides all the protips on the box
	 *
	 * @method hideAllProtips
	 * @param {Object} el Subject element to hide tooltips from
	 *
	 * @return void;
	 */
	hideAllProtips(el) {
		if (el) {
			el.jq().protipHide();
		}
	}

	onSimpleDelete({ id }) {
		this.removeMedia(id);
	}

	/**
	 * Binds events
	 */
	bind() {
		super.bind();

		Broadcaster.on(MediaManagerView.EVENT.DELETE, this.onSimpleDelete, this);

		Broadcaster.on(
			MediaManagerView.EVENT_MEDIA_DELETE_OVERLAY_CONFIRMED,
			this.onDeleteConfirmOverlayClick,
			this
		);
		this.element.on('focusin', this.onInputFocus, this, {
			delegate : this.mediaInputCls.dot()
		});
		this.element.on('keyup', this.onInputKeyPress, this, {
			delegate : this.mediaInputCls.dot()
		});
		this.element.on('focusout', this.onInputBlur, this, {
			delegate : this.mediaInputCls.dot()
		});
		this.element.on('click', this.onEditMediaButtonClick, this, {
			delegate : this.editMediaTitleButton.dot()
		});
	}

	/**
	 * Unbind events
	 */
	unbind() {
		this.autoUnbind();
	}
}
