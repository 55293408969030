// Disabled because of IE11 files
/* eslint-disable guard-for-in */
import $ from 'jquery';
import Chaos from '../../lib/chaos/Chaos';
import '../../lib/chaos/AjaxGet';
import HTML5Uploader from './HTML5Uploader';
import HTML5MultiUploaderController from './HTML5MultiUploaderController';
import MWHDocumentStatusCheckerController from '../MyContent/MWHDocumentStatusCheckerController';
import MWHDocumentStatusCheckerModel from '../MyContent/MWHDocumentStatusCheckerModel';

export default class AttachmentHTML5MultiUploaderControllerMWH {
	constructor() {
		this.element = document.querySelector('.uploadBlock');
		this.setMWHUploader();
		this.setMWHStatusChecker();
		this.uploaderEl = this.element.querySelector('.uploader5__items');
		this.uploaderEl.addEventListener('click', ::this.onUploaderClick);
		this.fileInput = $('.uploader5 input');
		this.fileInput.change(::this.onFileAdded);
		this.fileList = [];
		this.fileNameIndex = 0;
	}

	/**
	 * Item is the firstly generated container. the rest is just a content for the inner part when anything changes
	 *
	 * @returns {{item: item container, success: success template, error: error template, inProgress: progress bar}}
	 */
	get template() {
		return {
			item: $(this.element.querySelector('.support-attachment-item-template')).html(),
			success: $(this.element.querySelector('.success-template')).html(),
			error: $(this.element.querySelector('.error-template')).html(),
			inProgress: $(this.element.querySelector('.in-progress-template')).html()
		};
	}

	/**
	 * When item with remove class is clicked, it starts the process
	 * to remove the item.
	 *
	 * @param {Object} ev   Click event
	 */
	onUploaderClick(ev) {
		ev.preventDefault();
		if (ev.target.className === 'remove') {
			this.removeFile(ev.target);
		}
	}

	/**
	 * If this is a file with error, the href will be empty and just need to be removed from the dom.
	 * When it's already an uploaded content, it sends a request to remove it and then it removes
	 * the filename from the storage.
	 *
	 * @param {Object} target   dom element
	 */
	removeFile(target) {
		if (target.href !== '#') {
			this.removeFileFromStorage(target.dataset.name);
			this.removeFromFileList(target.parentNode.dataset.file);
			this.fileInput.val('');
		}
		target.parentNode.remove();
	}

	/**
	 * originalFileNames are a mandatory parameters for MWH uploading. We must
	 * get these tokens associated with file names. Therefore we maintain this
	 * array. In this case, we remove the file names what we do not need anymore.
	 *
	 * @param {String}  fileName
	 */
	removeFileFromStorage(fileName) {
		let originalFiles = this.MWHUploader.options.formData.originalFileNames,
			wrongFilePosition = originalFiles.indexOf(fileName);
		if (wrongFilePosition !== -1) {
			originalFiles.splice(wrongFilePosition, 1);
		}
	}

	removeFromFileList(fileName) {
		this.fileList = this.fileList.filter(item => { return fileName !== item.name });
	}

	get totalSizeExceededErrorMessage() {
		const text = this.MWHUploader.options.errorMessages[HTML5Uploader.ERROR.TOTAL_SIZE_EXCEEDED];
		const totalSize = window.Config.config.AttachmentValidation.size.total / 1024 / 1024;

		return text.tpl({
			totalSize
		});
	}

	/**
	 * Calls a function that returns the files without duplication, then
	 * append them to the dom and send them to upload based on the feature flag
	 *
	 * @param {Object} event    Event Object
	 */
	onFileAdded(event) {
		this.MWHUploader.hideErrors(this.fileInput);
		let originalFiles = this.getFilesWithoutDuplication(event.target.files);
		let files = [];
		for (let i = 0; i < originalFiles.length; i++) {
			this.fileNameIndex++;
			let newFileName = 'attachment_' + this.fileNameIndex + '.' + originalFiles[i].name.split('.').pop();
			files[i] = new File([originalFiles[i]], newFileName, originalFiles[i]);
			files[i].id = originalFiles[i].name + originalFiles[i].size;
			$(this.uploaderEl).append(this.createUploadingItem(files[i]));
		}
		if (!this.isTotalSizeValid()) {
			Array.from(files).forEach(file => {
				this.renderErrorWithMessage(file, this.totalSizeExceededErrorMessage);
			});
			this.fileInput.val('');
			return;
		}
		if (files.length) {
			this.uploadToMWH(files);
		}
		this.fileInput.val('');
	}

	createUploadingItem(file) {
		this.fileList.push(file);
		return this.template.item.tpl({
			id: file.id,
			fileName: file.name,
			status: this.template.inProgress
		});
	}

	createErrorItem(file) {
		this.removeFromFileList(file.name);
		return this.template.error.tpl({
			fileName: file.name,
			message: file.error,
			id: file.id,
			url: '#'
		});
	}

	createUploadedItem(file) {
		return this.template.success.tpl({
			fileName: file.name,
			id: file.id,
			url: '#'
		});
	}

	isTotalSizeValid() {
		const total = this.fileList.reduce((accumulator, itemValue) => accumulator + itemValue.size, 0);
		const totalMaxSize = window.Config.config.AttachmentValidation.size.total;

		return total <= totalMaxSize;
	}

	/**
	 * Returns a dom element based on a file name
	 * @param {String} fileName
	 *
	 * @returns {Object}    jquery dom element
	 */
	getItem(fileName) {
		return $(this.element).find('[data-file="' + fileName + '"]');
	}

	/**
	 * Returns a dom element based on a file id
	 * @param {String} fileId
	 *
	 * @returns {Object}    jquery dom element
	 */
	getItemById(fileId) {
		return $(this.element).find('[data-id="' + fileId + '"]');
	}

	onValidationError(file) {
		if (file.error) {
			this.removeFileFromStorage(file.name);
			this.renderError(file);
		}
	}

	/**
	 * Gets the dom item by fine name, than replaces the content with error template
	 * @param {Object} file
	 */
	renderError(file) {
		this.getItem(file.name).html(this.createErrorItem(file));
	}

	/**
	 * Compares the files in the list with the files given in the param.
	 * Returns the files array without duplication.
	 * hasOwnProperty() is skipped because IE11 in case of file upload returns false
	 *
	 * @param {Object}   files
	 *
	 * @returns {Array} Files without duplication
	 */
	getFilesWithoutDuplication(files) {
		let newFiles = [];
		for (let key in files) {
			let item = this.getItemById(files[key].name + files[key].size);

			if (!item[0] && files[key].size) {
				newFiles.push(files[key]);
			}
			else if (item[0]) {
				const errorMessage = this.MWHUploader.options.errorMessages[HTML5Uploader.ERROR.ATTACHMENT_EXISTS];
				this.MWHUploader.showError(this.fileInput, errorMessage);
			}
		}
		return newFiles;
	}

	/**
	 * Instantiates a multi uploader to Jasmin2 MWH
	 */
	setMWHUploader() {
		this.MWHUploader = new HTML5MultiUploaderController(
			document.querySelector('.mwh-uploader'),
			{
				name: 'MWH Uploader',
				url: window.GlobalConfig.MWHMediaUploadURL,
				validate: window.Config.config.AttachmentValidation,
				tokenUrl: Chaos.getUrl('SupportMessengerAttachment/CreateTokens', { filterType: 'support' }),
				formData: {
					originalFileNames: []
				},
				shouldRenderProgressOnly: true
			}
		);
		this.MWHUploader._processTokens = this.processMWHTokens;
		this.MWHUploader._onDone = function() {};
		this.MWHUploader.on('validation-error', ::this.onValidationError);
		this.MWHUploader.on(HTML5Uploader.EV.GET_TOKENS_OK, ::this.startCheckingConvertingItems);
	}

	/**
	 * Starts status checking with MWH
	 *
	 * @method startChecking
	 * @param {Array}   ev    Array of pending status id-s
	 *
	 * @return void;
	 */
	startCheckingConvertingItems(ev) {
		let documentIds = {};
		for (let i = 0; i < ev.data.length; i++) {
			documentIds[ev.data[i].attachmentId] = 'file';
		}
		this.MWHStatusChecker.addStatusesToCheck(documentIds);
	}

	/**
	 * In the original method it's getting back the tokens as value.token and mongoID-s as value.mongoId
	 * Due to the change in the response pattern this had to be overwritten
	 * Here in this case we are getting the tokens as value.id, and id-s as attachmentId.
	 * As the original class operates with _mongos array, it was not changed.
	 * We need these details for checking the file statuses later
	 *
	 * @param {Object} response Saving the tokens for file checking
	 * @returns {boolean} true if we have tokens
	 */
	processMWHTokens(response) {
		try {
			this._tokens = [];
			this._mongos = [];
			response.data.forEach(function(value) {
				this._tokens.push(value.id);
				this._mongos.push(value.attachmentId);
			}.bind(this));

			return !!this._tokens;
		}
		catch (e) {
			/* webpack-strip-block:removed */
		}
		return false;
	}

	/**
	 * Sets a controller for mwh document checking
	 *
	 * @method _setMWHDocumentStatusCheckerController
	 * @private
	 *
	 * @return {Object} instance of MWH Document Status Checker Controller
	 */
	setMWHStatusChecker() {
		this.MWHStatusChecker = new MWHDocumentStatusCheckerController({
			items : {
				MWHDocumentStatusCheckerModel : {
					component : new MWHDocumentStatusCheckerModel(document.body, {
						mediaDocumentStatusUrlRoute : 'SupportMessengerAttachment/Find',
						filterType: 'support',
						documentIdKey: 'attachmentIds'

					}),
					listeners : {
						'get-document-status-success' : 'onGetDocumentStatusSuccess'
					}
				}
			}
		});
		this.MWHStatusChecker.on(
			MWHDocumentStatusCheckerController.EVENT_FILE_CONVERT_FAILED,
			this.onFileStatusFailed,
			this
		);
		this.MWHStatusChecker.on(
			MWHDocumentStatusCheckerController.EVENT_CONTENT_STATUS_ENABLED,
			this.onContentStatusEnabled,
			this
		);
		return this.MWHStatusChecker;
	}

	/**
	 * Returns container element of the items.
	 *
	 * @overrides
	 */
	getItemsContainer() {
		return this.element.jq().next(this.cls.itemsContainer.dot());
	}

	/**
	 * Checks if a file already exists.
	 *
	 * @param fileName
	 * @returns {boolean}
	 * @private
	 */
	_fileExists(fileName) {
		return !!this.getItemsContainer().find('[data-file="' + fileName + '"]').length;
	}

	/**
	 * originalFileNames are a mandatory parameters for MWH uploading. We must
	 * get these tokens associated with file names. Therefore we maintain this
	 * array. In this case, we push the names into that array before sending them
	 * to upload.
	 *
	 * @param {Array}  files
	 */
	uploadToMWH(files) {
		this.MWHUploader.options.formData.originalFileNames = [];
		for (let i = 0; i < files.length; i++) {
			this.MWHUploader.options.formData.originalFileNames.push(files[i].name);
		}
		this.MWHUploader.add(files);
	}

	/**
	 * This event indicates when the uploaded document to the MWH has been successfully converted.
	 *
	 * @param {Object} data     Status checker response
	 */
	onContentStatusEnabled(data) {
		let documents = data.document;
		for (let key in documents) {
			if (documents.hasOwnProperty(key)) {
				let document = documents[key];
				if (document.status === 'converted') {
					let item = this.getItem(document.fileName),
						id = item[0].dataset.id;
					item.html(this.createUploadedItem(
						Object.assign(document, { name: document.fileName, oldId: id }))
					);
					this.removeFileFromStorage(document.fileName);
				}
			}
		}
	}

	renderErrorWithMessage(file, message) {
		file.error = message;
		this.renderError(file);
		this.removeFileFromStorage(document.fileName);
	}

	/**
	 * This event indicates when the uploaded document to the MWH has been failed due to some reasons.
	 *
	 * @param {Object} data     Status checker response
	 */
	onFileStatusFailed(data) {
		let document = data.document[0];
		this.getItem(document.fileName).html(this.createErrorItem(
			Object.assign(document, {
				name: document.fileName,
				message: 'Error has occured'
			}))
		);
		this.removeFileFromStorage(document.fileName);
		this.removeFromFileList(document.fileName);
	}

	/**
	 * On Done callback.
	 *
	 * @overrides
	 * @param ev
	 * @private
	 */
	_onDone(ev) {
		super._onDone(ev, true);

		try {
			JSON.parse(ev.result);
		}
		catch (e) {
			/* webpack-strip-block:removed */
		}
	}

	/**
	 * Bind up the events.
	 *
	 * @overrides
	 */
	bind() {
		super.bind();
		this.getItemsContainer().on('click', this.cls.remove.dot(), this._onRemoveClick.bind(this));
	}
}
