import riot from 'riot';
import './IgnoreBlacklistInput.scss';

riot.tag('ignore-blacklist-input',
	`<yield></yield>`,

	function() {
		this.mixin('form');

		this.on('mount', () => {
			this.input = this.form.getInput('screenName');
			this.displayNameCheckboxBlockEl = document.querySelector('.displayNameCheckboxBlock');
			if (this.displayNameCheckboxBlockEl) {
				this.checkbox = this.form.getInput('ignoreBlacklist');
				this.input.on('keydown', this.hideCheckbox);
				this.form.on(this.form.CONST.END_VALIDATION_EVENT, this.handleValidation);
			}
		});

		this.handleValidation = (hasError, input, hasGlobalError, validator) => {
			if (validator && validator.Rule.rule === `/${window.GlobalConfig.language}/check-screen-name`) {
				hasError ? this.form.tags['form-submit'].disable() : this.form.tags['form-submit'].enable();

				if (validator.Rule.isHighlighted === true) {
					this.displayNameCheckboxBlockEl.classList.remove('hide');
				}
				else if (!hasError && validator.Rule.requestParams && validator.Rule.requestParams.ignoreBlacklist) {
					this.showCheckbox();
					let highlightedEl = document.querySelector('.highlightLayer span');
					highlightedEl && highlightedEl.remove();
				}
				else {
					this.hideCheckbox();
				}
			}
		};

		this.hideCheckbox = () => {
			this.displayNameCheckboxBlockEl.classList.add('hide');
			this.checkbox.refs.input.checked = false;
			this.input.scenario = {};
		};

		this.showCheckbox = () => {
			this.displayNameCheckboxBlockEl.classList.remove('hide');
			this.checkbox.refs.input.checked = true;
		};
	});
