/**
 * With this component you can easily add analytics events to the Google Tag Manager's dataLayer,
 * by using simple data attributes in the DOM.
 *
 * Use Case #1:
 * If you need to report a simple click event on a DOM element, add 'ga' class to the target DOM element.
 * Then you can customize the event with 'data-ga-' prefixed attributes. 'data-ga-event-category' attribute
 * will add the 'eventCategory' field to the dataLayer event, 'data-ga-event-label' will add 'eventLabel'.
 * The component uses mutationObserver, so the dinamically added attributes/DOMElements will be handled properly.
 *
 * Use Case #2:
 * If you need to report special events to the Google Tag Manager, like success event of an ajax request, then
 * you can send these events programatically by importing the 'push' function of this component, and call it with
 * an object as the first parameter, which contains the necessary event fields.
 *
 * Examples can be found in Confluence.
 *
 * /display/DEVZONE/MSC+-+DEV+-+Frontend+-+Google+Analytics+Service
 */

// GA event labels
const DEFAULT_EVENT = 'GA - Event - Generic Event';

// Body.id of the page, and the pair how it should be tracked in google analytics
const PAGES = {
	/* eslint-disable */
	appinstallguide_index   : 'instruction page',
	/* eslint-enable */
};

/**
 * Returns an analytics event name based on pageId
 * @param pageId Id of the page
 * @returns {string}
 */
export const getDecodedPageId = (pageId = document.body.id) => PAGES[pageId];

/**
 * Push data to GA dataLayer
 *
 * @param {Object} options   Event fields , which should be sent to GTM
 */
export const push = (options = {}) => {
	const eventData = {
		event            : DEFAULT_EVENT,
		eventCategory    : getDecodedPageId(),
		eventInteraction : true,
		...options
	};
	const { dataLayer } = window;
	if (!eventData.eventCategory) {
		/* webpack-strip-block:removed */
		return;
	}
	if (!dataLayer) {
		/* webpack-strip-block:removed */
		return;
	}

	dataLayer.push(eventData);
};

/**
 * Returns only a namespaced portion of a dataset.
 * @param ds Original dataset
 * @param ns Namespace
 */
const getDatasetNamespace = (ds, ns) => {
	const dataset = {};
	for (const [key, value] of Object.entries(ds)) {
		if (key.startsWith(ns)) {
			dataset[key.replace(ns, '').lcFirst()] = value;
		}
	}
	return dataset;
};

// Handles clicking on .ga elements
const clickHandler = ({ currentTarget : { dataset } }) => {
	push({
		eventAction      : 'click',
		eventValue       : 0,
		eventInteraction : true,
		...getDatasetNamespace(dataset, 'ga')
	});
};

// Add/remove every event listener on DOM change.
// No delegated events because they can have stopPropagation which would not be caught by body click.
const mutationHandler = () => {
	for (const el of document.querySelectorAll('.ga')) {
		el.removeEventListener('click', clickHandler);
		el.addEventListener('click', clickHandler, { passive : true });
	}
};
const observer = new MutationObserver(mutationHandler);
observer.observe(
	document.body,
	{ attributes : false, childList : true, characterData : false, subtree : true }
);

// Call handler once when body initialized
mutationHandler();
