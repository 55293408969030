import Copy2Clipboard from './Copy2Clipboard';

export default class Copy2ClipboardMC extends Copy2Clipboard {
	get properties() {
		return { ...super.properties,
			/** @var {Object} _linkEl                   Link element */
			_linkEl : undefined,

			/** @var {String} _hoverClass               The hover class*/
			_hoverClass : 'hoverState',

			/** @var {String} _activeClass              The active class*/
			_activeClass : 'activeState',

			/** @var {String} _successSel               Selector of the success element*/
			_successSel : '.success',

			/** @var {String} _successEl                Success element (now using the wrapper of the button)*/
			_successEl : undefined,

			/** @var {String} _successShowClass         Show class of the succes element*/
			_successShowClass : 'showSuccess',

			/** @var {String} _successTimeoutObj        Timeout to remove the show class*/
			_successTimeoutObj : undefined
		};
	}
	init(el, config) {
		this._linkEl = this.element.select('a').item(0);
		this._successEl = this.element;

		super.init(el, config);
	}

	/**
	 * On button mouseover
	 */
	onMouseover() {
		this._linkEl.addClass(this._hoverClass);
	}

	/**
	 * On button mouseout
	 */
	onMouseout() {
		this._linkEl.removeClass(this._hoverClass);
	}

	/**
	 * On button mousedown
	 */
	onMousedown() {
		this._linkEl.addClass(this._activeClass);
	}

	/**
	 * On button mouseup
	 */
	onMouseup() {
		this._linkEl.removeClass(this._activeClass);
	}

	/**
	 *
	 */
	onTextCopied() {
		var self = this;

		if (this._successTimeoutObj) {
			clearTimeout(this._successTimeoutObj);
		}

		this._successEl.addClass(this._successShowClass);

		this._successTimeoutObj = setTimeout(function() {
			self._successEl.removeClass(self._successShowClass);
		}, 2000);
	}

	/**
	 * Esemenykezelok feliratkozasa
	 */
	bind() {
		super.bind();

		this.element
			.on('mouseover', this.onMouseover, this)
			.on('mouseout', this.onMouseout, this)
			.on('mousedown', this.onMousedown, this)
			.on('mouseup', this.onMouseup, this);

		this.on(Copy2Clipboard.EVENT_TEXT_COPIED, this.onTextCopied, this);
	}

	/**
	 * Esemenykezelok torlese
	 */
	unbind() {
		super.unbind();

		this.element
			.un('mouseover', this.onMouseover, this)
			.un('mouseout', this.onMouseout, this)
			.un('mousedown', this.onMousedown, this)
			.un('mouseup', this.onMouseup, this);

		this.un(Copy2Clipboard.EVENT_TEXT_COPIED, this.onTextCopied, this);
	}
}