import React from 'react';
import { getFeatureFlag } from 'utils/featureFlag';
import { FeatureFlagEnum } from 'configs/featureFlags';
import { globalConfig } from 'configs/globalConfig';
import { AccountViewType, ViewType } from 'contracts/window';
import { emarsysService } from 'domains/Emarsys/Emarsys.serviceFactory';
import useAppSelector from 'store/hooks/useAppSelector';

const useEmarsys = (): void => {
    const { me, myPerformer } = useAppSelector((state) => state.performer);
    const { viewType, accountViewType } = globalConfig;
    const isStudioView = viewType === ViewType.STUDIO || accountViewType === AccountViewType.STUDIO;
    const shouldForceEmarsys = !!getFeatureFlag(FeatureFlagEnum.ForceEmarsys);

    const getEmarsysContactDetails = React.useCallback((xActorType: 'studio' | 'performer', xActorId: string) => {
        emarsysService.getEmarsysContactDetails(xActorId, xActorType).then((response) => {
            window.ScarabQueue.push(['setCustomerId', response.id]);
            window.ScarabQueue.push(['go']);
        });
    }, []);

    React.useEffect(() => {
        if (viewType === ViewType.REGISTRATION || !me) return;
        if (isStudioView && (shouldForceEmarsys || !me?.studio.isTestAccount))
            getEmarsysContactDetails('studio', me.studio.id.toString());
        else if (myPerformer && (shouldForceEmarsys || !myPerformer?.isTestAccount))
            getEmarsysContactDetails('performer', myPerformer.id.toString());
    }, [me, myPerformer, viewType, isStudioView, shouldForceEmarsys]);
};

export default useEmarsys;
