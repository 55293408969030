import ChaosObject from '../../lib/chaos/Object';
import Connection from '../../lib/chaos/Connection';

/**
 * ContentViewerModel
 *
 * Service for ContentViewerController
 *
 * Gives back the details of the new block
 * to the overlay
 *
 */
export default class ContentViewerModel extends ChaosObject {
	static EVENT_NEW_CONTENT_DETAILS_READY = 'new-content-details-ready';
	static EVENT_IMAGE_RESOLVE_READY = 'image-resolve-ready';
	static EVENT_TURN_ON_COMMENTS = 'turn-on-comments';

	get properties() {
		return { ...super.properties,
			/** @var {String}           Model name. */
			name : 'ContentViewerModel'
		};
	}

	/**
	 * Standard init function
	 *
	 * @param {Object} el
	 * @param {Object} config
	 *
	 * @return void
	 */
	init(el, config) {
		super.init(el, config);
		this.addEvents(
			ContentViewerModel.EVENT_NEW_CONTENT_DETAILS_READY,
			ContentViewerModel.EVENT_IMAGE_RESOLVE_READY,
			ContentViewerModel.EVENT_TURN_ON_COMMENTS
		);
	}

	/**
	 * Returns the hash resolved images
	 *
	 * @method getResolvedImageUris
	 * @param url   Url for the hash resolver
	 *
	 * @return {Object} resolvedImages
	 */
	getResolvedImageUris(url) {
		Connection.Ajax.request({
			type    : 'json',
			method  : 'GET',
			url     : url,
			scope   : this,
			success : function(response) {
				this.fireEvent(ContentViewerModel.EVENT_IMAGE_RESOLVE_READY, response.json.content.urls);
			},
			error : function() {

			}
		});
	}

	/**
	 * Returns with comment details
	 *
	 * @method getCommentDetails
	 * @param url   Url of the comment route
	 *
	 * @return {Object} commentDetails
	 */
	getCommentDetails(url) {
		Connection.Ajax.request({
			type    : 'json',
			method  : 'GET',
			url     : url,
			scope   : this,
			success : function(response) {
				this.fireEvent(ContentViewerModel.EVENT_TURN_ON_COMMENTS, response.json.data);
			},
			error : function() {

			}
		});
	}

	/**
	 * Binds the initial event handlers
	 */
	bind() {
		super.bind();
	}

	/**
	 * Unbinds all event handlers
	 */
	unbind() {
		super.unbind();
	}
}
