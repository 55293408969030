import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { MessengerApi } from 'apiClients/msc-api-gateway/proxy/messenger/client/messenger-api';
import { MessengerTranslationDataMapper } from './MessengerTranslation.dataMapper';
import { MessengerTranslationService } from './MessengerTranslation.service';
import { CachedTranslationMessage, TranslatedMessage } from './MessengerTranslation.types';

export interface MessengerTranslationServiceInterface {
    getSubscriptionKey(actorId: string, actorType: string): Promise<string>;
    initialize(subscriptionKey: string): void;
    translate(messageId: number, translateTo: string, text: string): Promise<TranslatedMessage>;
    persistTranslatedMessage(
        language: string,
        threadId: number,
        messageId: number,
        originalText: string,
        translatedText: string,
    ): Promise<void>;
    updateThreadLastOpenedDate(threadId: number): Promise<void>;
    getTranslatedMessages(threadId: number): Promise<CachedTranslationMessage[]>;
    getTranslatedMessage(threadId: number, messageId: number): Promise<CachedTranslationMessage | undefined>;
    removeInvalidTranslations(language: string): Promise<void>;
}

const messengerApiClient = new MessengerApi(apiGwConfig, apiHost, axiosApiGw);
const dataMapper = new MessengerTranslationDataMapper(messengerApiClient);
export const messengerTranslationService = new MessengerTranslationService(dataMapper);
