import {
    TranslateResultAllItem,
    TranslateResultAllItemTranslationsItem,
} from '@azure/cognitiveservices-translatortext/esm/models';
import { TranslationResponseItem } from './Translation.type';

const mapTranslations = (translations?: TranslateResultAllItemTranslationsItem[]) => {
    if (!translations) {
        return [];
    }

    return translations.map((translation) => {
        return {
            text: translation.text || '',
        };
    });
};

export const mapTranslationResponse = (response: TranslateResultAllItem[]): TranslationResponseItem[] => {
    return response.map((item) => {
        return {
            detectedLanguage: {
                language: item?.detectedLanguage?.language || '',
                score: item?.detectedLanguage?.score || 0,
            },
            translations: mapTranslations(item.translations),
        };
    });
};
