import { JawsApiInterface } from 'apiClients/msc-api-gateway/proxy/jaws/client/jaws-api';
import { JawsDataMapperInterface } from './Jaws.service';
import { JawsToken } from './Jaws.types';

export class JawsDataMapper implements JawsDataMapperInterface {
    constructor(private apiClient: JawsApiInterface) {}

    public async getJawsToken(performerId: number): Promise<JawsToken> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .createJawsToken(`${performerId}`, 'performer')
                .then((response) => {
                    if (!response.data.data) {
                        reject(new Error('no response'));
                        return;
                    }

                    const { token: accessToken } = response.data.data;

                    resolve({ accessToken });
                })
                .catch((error) => reject(error));
        });
    }
}
