/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AvailabilityResponseSchema } from '../../../../msc-api-gateway/proxy/jaws/models';
// @ts-ignore
import { InnerApiError } from '../../../../msc-api-gateway/proxy/jaws/models';
// @ts-ignore
import { TokenResponseSchema } from '../../../../msc-api-gateway/proxy/jaws/models';
// @ts-ignore
import { UnauthorizedError } from '../../../../msc-api-gateway/proxy/jaws/models';
/**
 * JawsApi - axios parameter creator
 * @export
 */
export const JawsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJawsToken: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('createJawsToken', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('createJawsToken', 'xActorType', xActorType);
            const localVarPath = `/gw/inner/v1/jaws/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJawsAvailability: async (
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'xActorId' is not null or undefined
            assertParamExists('getJawsAvailability', 'xActorId', xActorId);
            // verify required parameter 'xActorType' is not null or undefined
            assertParamExists('getJawsAvailability', 'xActorType', xActorType);
            const localVarPath = `/gw/inner/v1/jaws/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xActorId !== undefined && xActorId !== null) {
                localVarHeaderParameter['X-Actor-Id'] = String(xActorId);
            }

            if (xActorType !== undefined && xActorType !== null) {
                localVarHeaderParameter['X-Actor-Type'] = String(xActorType);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * JawsApi - functional programming interface
 * @export
 */
export const JawsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = JawsApiAxiosParamCreator(configuration);
    return {
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJawsToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJawsToken(xActorId, xActorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJawsAvailability(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilityResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJawsAvailability(
                xActorId,
                xActorType,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * JawsApi - factory interface
 * @export
 */
export const JawsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JawsApiFp(configuration);
    return {
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJawsToken(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): AxiosPromise<TokenResponseSchema> {
            return localVarFp
                .createJawsToken(xActorId, xActorType, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Jaws
         * @param {string} xActorId Id of the actor who calls endpoint
         * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJawsAvailability(
            xActorId: string,
            xActorType: 'performer' | 'studio' | 'user',
            options?: any,
        ): AxiosPromise<AvailabilityResponseSchema> {
            return localVarFp
                .getJawsAvailability(xActorId, xActorType, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * JawsApi - interface
 * @export
 * @interface JawsApi
 */
export interface JawsApiInterface {
    /**
     * Jaws
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JawsApiInterface
     */
    createJawsToken(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        options?: any,
    ): AxiosPromise<TokenResponseSchema>;

    /**
     * Jaws
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JawsApiInterface
     */
    getJawsAvailability(
        xActorId: string,
        xActorType: 'performer' | 'studio' | 'user',
        options?: any,
    ): AxiosPromise<AvailabilityResponseSchema>;
}

/**
 * JawsApi - object-oriented interface
 * @export
 * @class JawsApi
 * @extends {BaseAPI}
 */
export class JawsApi extends BaseAPI implements JawsApiInterface {
    /**
     * Jaws
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JawsApi
     */
    public createJawsToken(xActorId: string, xActorType: 'performer' | 'studio' | 'user', options?: any) {
        return JawsApiFp(this.configuration)
            .createJawsToken(xActorId, xActorType, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Jaws
     * @param {string} xActorId Id of the actor who calls endpoint
     * @param {'performer' | 'studio' | 'user'} xActorType Type of the actor who calls endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JawsApi
     */
    public getJawsAvailability(xActorId: string, xActorType: 'performer' | 'studio' | 'user', options?: any) {
        return JawsApiFp(this.configuration)
            .getJawsAvailability(xActorId, xActorType, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
