import Controller from '../../lib/chaos/Controller';

export default class ChannelOverlayController extends Controller {
	static EVENT_MEDIA_ITEM_CLICK = 'media-item-click';

	/**
	 * Init
	 *
	 * @param {Element} el      Context element
	 * @param {Object} config   Config object of this controller
	 */
	init(el, config) {
		super.init(el, config);
		this.addEvents(ChannelOverlayController.EVENT_MEDIA_ITEM_CLICK);
	}

	/**
	 * Eventhandler for a view event when a media element was clicked.
	 *
	 * @param {Object} ev   ChannelOverlayView event object
	 */
	onMediaItemClick(ev) {
		this.fireEvent(ChannelOverlayController.EVENT_MEDIA_ITEM_CLICK, {
			scope : this,
			ev    : ev
		});
	}

	/**
	 * Binds events
	 */
	bind() {
		super.bind();
	}

	/**
	 * Unbind events
	 */
	unbind() {
		super.unbind();
	}
}
