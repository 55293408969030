import { ActorTypes } from 'domains/Common.types';
import { TranslationService } from 'domains/Translation/Translation.service';
import { MessengerApiInterface } from 'apiClients/msc-api-gateway/proxy/messenger/client/messenger-api';
import { MessengerTranslationDataMapperInterface } from './MessengerTranslation.service';
import { mapTranslationResponse } from './MessengerTranslation.mapper';
import { TranslatedMessage } from './MessengerTranslation.types';

export class MessengerTranslationDataMapper implements MessengerTranslationDataMapperInterface {
    private apiClient: TranslationService | undefined;

    constructor(private messengerApiClient: MessengerApiInterface) {}

    public getSubscriptionKey(actorId: string, actorType: ActorTypes): Promise<string> {
        return new Promise((resolve, reject) => {
            this.messengerApiClient
                .getMessengerTranslationSubscriptionKey(actorId, actorType)
                .then((response) => {
                    if (response.data.data?.subscriptionKey) {
                        resolve(response.data.data.subscriptionKey);
                        return;
                    }

                    reject(new Error('Failed to get subscription key'));
                })
                .catch(reject);
        });
    }

    public setApiClient(apiClient: TranslationService): void {
        this.apiClient = apiClient;
    }

    public translate(messageId: number, translateTo: string, text: string): Promise<TranslatedMessage> {
        return new Promise((resolve, reject) => {
            if (!this.apiClient) {
                reject(
                    new Error(
                        'Translation API client is not set. Did you call MessengerTranslationService.initialize(subscriptionKey) before calling .translate?',
                    ),
                );
                return;
            }

            this.apiClient
                .translate([translateTo], [{ text }])
                .then((response) => {
                    try {
                        resolve(mapTranslationResponse(response, messageId));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(reject);
        });
    }
}
