import React, { FC, useState, useContext, ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import ChannelOverlay from 'components/ChannelOverlay/ChannelOverlay';

export interface MediaOverlayContent {
    id: string;
    type: ChannelOverlayTypeEnum;
    mediaType?: ChannelOverlayMediaTypes;
}

interface OverlayContextState {
    overlay: {
        open: (newContent: MediaOverlayContent) => void;
    };
}

const defaultState: OverlayContextState = {
    overlay: { open() {} },
};

export const OverlayContext = React.createContext<OverlayContextState>(defaultState);

export const useOverlayContext = () => useContext(OverlayContext);

interface OverlayContextProviderProps {
    children: ReactNode;
}

export const OverlayContextProvider: FC<OverlayContextProviderProps> = ({ children }) => {
    const [overlayState, setOverlayState] = useState<OverlayState>({
        id: '',
        type: '',
        mediaType: undefined,
        isOpened: false,
    });
    const openMediaOverlay = (newContent: MediaOverlayContent) => {
        if (newContent && newContent.id && newContent.type) {
            setOverlayState({
                id: newContent.id,
                type: newContent.type,
                mediaType: newContent.mediaType,
                isOpened: true,
            });
        } else {
            console.debug('newContent is not valid: ', newContent);
        }
    };
    const hideOverlay = () => setOverlayState((currentValues) => ({ ...currentValues, isOpened: false }));

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue: OverlayContextState = {
        ...defaultState,
        overlay: {
            open: openMediaOverlay,
        },
    };

    return (
        <OverlayContext.Provider value={contextValue}>
            {children}
            {overlayState.isOpened ? (
                <ChannelOverlay
                    id={overlayState.id}
                    type={overlayState.type}
                    mediaType={overlayState.mediaType}
                    onClose={hideOverlay}
                />
            ) : null}
        </OverlayContext.Provider>
    );
};

interface OverlayState {
    id: string;
    type: string;
    mediaType?: ChannelOverlayMediaTypes;
    isOpened: boolean;
}

export enum ChannelOverlayMediaTypes {
    VIDEO = 'video',
    PHOTO = 'photo',
}

export enum ChannelOverlayTypeEnum {
    PAYING = 'paying',
    FREE = 'free',
}
