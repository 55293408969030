import riot from 'riot';

export default function (root = document) {
	Array.from(root.querySelectorAll('.riot-mount'))
	.forEach(el => {
		try {
			let tag = riot.mount(el);
			if (tag.length) {
				el.classList.remove('riot-mount');
			}
		}
		catch (e) {
			/* webpack-strip-block:removed */
		}
	});
}