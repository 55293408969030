import { Periods } from 'contracts/window';
import { PersonZodiacSignEnum } from 'apiClients/msc-api-gateway/accountservices/models';
import { ProfilePictureStatuses } from 'domains/ProfilePicture/ProfilePicture.types';
import { CurrencyType, ProfilePictureVersionMediaKeyEnum } from '../Common.types';

export interface MePersonEntity {
    isGiftSendingDisabled: boolean;
    viewType?: 'single' | 'studio' | 'model' | 'agency';
    firstName?: string;
}

export interface MeEntity {
    id: number;
    studio: StudioEntity;
    email: string | undefined;
    person: MePersonEntity;
    defaultPerformerId?: number | null;
}

export interface StudioEntity {
    id: number;
    ownerUserId: number;
    name?: string;
    isCertified?: boolean;
    certificationFlag: number;
    isTestAccount: boolean;
}

export interface PerformerEntity {
    id: number;
    screenName: string;
    displayName: string;
    status: PerformerEntityStatus;
    category: PerformerCategory;
    isBirthdayVisible: boolean;
    isSelected: boolean;
    persons: PerformerPerson[];
    hasReachedEarning: boolean;
    isExclusivePartner: boolean;
    migration: MigrationProps;
    displayNameDetails: DisplayNameDetails;
    subscription?: PerformerSubscription;
    createdAt: string;
    liveShow: PerformerLiveShow;
    hasInvalidDisplayName: boolean;
    profilePictures: PerformerProfilePicture[];
    isActionsRequestDisabled: boolean;
    isTestAccount: boolean;
    isTestReadingFinished: boolean;
    isInvisible: boolean;
    battle: BattleAccess;
    exclusiveBadgeStatus: ExclusiveBadgeAccess;
}

export interface BattleAccess {
    isAvailable: boolean;
}

export enum PerformerEntityStatus {
    NEW = 'new',
    PENDING = 'pending',
    REJECTED = 'rejected',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    SUSPENDED = 'suspended',
    CLOSED = 'closed',
}

export interface PerformerCategory {
    id: number;
    name: string;
    label: string;
    personCount: number;
}

export interface PerformerPerson {
    id: number;
    zodiacSign: PersonZodiacSignEnum;
    hasBirthday: boolean;
}

export interface MigrationProps {
    isMigrationFinished: boolean;
}

export interface DisplayNameDetails {
    isDisplayNameChangePending: boolean;
    isDisplayNameChangeForced: boolean;
    newDisplayName: string;
    isDisplayNameFeatureFlagEnabled: boolean;
}

export interface PerformerPricing {
    creditType: CurrencyType;
    liveShow: PerformerPricingEntity;
    twoWayAudio: PerformerPricingEntity;
    cam2Cam: PerformerPricingEntity;
    videoCall: PerformerPricingEntity;
    message: PerformerPricingEntity;
    mediaMessage: {
        video: PerformerDefaultPricingEntity;
        image: PerformerDefaultPricingEntity;
    };
    storyHighlight: {
        video: PerformerDefaultPricingEntity;
        image: PerformerDefaultPricingEntity;
        text: PerformerDefaultPricingEntity;
    };
    myStory: PerformerPricingEntity;
}

export interface PerformerPricingEntity {
    price: number;
    options: number[];
}

export interface PerformerDefaultPricingEntity {
    defaultPrice: number;
    options: number[];
    titleRequiredThreshold?: number | null;
}

export interface PerformerPeriod extends Periods {}

export interface PerformerSubscription {
    isSubscriptionEnabled: boolean;
    subscriptionPackageId?: string;
}

export interface PerformerLiveShow {
    isOnline: boolean;
    price: number;
}

export interface PerformerProfilePicture {
    mediaKey: ProfilePictureVersionMediaKeyEnum;
    url: string;
}

export interface SelectedPerformerProfilePicture extends PerformerProfilePicture {
    status: ProfilePictureStatuses;
}

export interface PerformerTwoWayAudio {
    spokenLanguages: string[];
    isEnabled: boolean;
}

export interface NewbieStatus {
    isNewbie: boolean;
    newbieUntil: string;
}

export interface ExclusiveBadgeAccess {
    id?: ExclusiveBadgeStatusEnum;
    label?: string;
}

export enum ExclusiveBadgeStatusEnum {
    Default = 0,
    Available = 1,
    Pending = 2,
    Active = 3,
    Disabled = 4,
    Rejected = 5,
}
