import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { AccountServicesApi } from 'apiClients/msc-api-gateway/accountservices/client/account-services-api';
import { RegistrationApi } from 'apiClients/msc-api-gateway/proxy/registration/client/registration-api';
import { PerformersApi } from 'apiClients/msc-api-gateway/proxy/client/performers-api';
import sessionStorage from 'utils/storage/sessionStorage';
import DataCacher from 'utils/dataCacher';
import { PerformerDataMapper } from './Performer.dataMapper';
import { PerformerService } from './Performer.service';

const accountServicesApiClient = new AccountServicesApi(apiGwConfig, apiHost, axiosApiGw);
const registrationApiClient = new RegistrationApi(apiGwConfig, apiHost, axiosApiGw);
const performersApiClient = new PerformersApi(apiGwConfig, apiHost, axiosApiGw);
const dataMapper = new PerformerDataMapper(accountServicesApiClient, registrationApiClient, performersApiClient);
const dataCacher = new DataCacher(sessionStorage, 'my-performer-v1', 60000);
export const performerService = new PerformerService(dataMapper, dataCacher);
